/* ****************************************** */
/* Textarea */
/* ****************************************** */

// Textarea
const bokstaver_igjen                       = "_bokstaver_igjen";
const bokstav_igjen                         = "_bokstav_igjen";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    bokstaver_igjen,
    bokstav_igjen
};


/* ****************************************** */
/* End */
/* ****************************************** */
