import {useCallback, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
    lay_yp,
    scroll_smooth
} from "../../utils/konstanter/gen/_layout";
import {
    grp_icon_norm_48_12,
    grp_icon_tittel_norm
} from "../../utils/konstanter/gen/_grp";
import {
    redux_header,
    redux_path
} from "../../utils/konstanter/gen/_redux";
import {
    delay_scroll_1,
    delay_scroll_2_push_yp
} from "../../utils/konstanter/gen/_time";
import {
    doc_branch_rem_per_level_indent,
    doc_branch_arrow_start_level
} from "../../utils/konstanter/pages/_doc";
import {
    is_txt_grp
} from "../../utils/utility/_util_group";
import {
    lay_is_ref
} from "../../utils/utility/_util_layout";

import {setBlokkYYMatPush} from "../../pages/doc/redux_doc_feature";

import Icon from "../pic/icon/icon";


const Tittel = (props) => {

    // Redux
    const dispatch                                  = useDispatch();

    // Path
    const {path_yy}                                 = useSelector((store) => {return store[redux_path]});

    // Header
    const {header_yy}                               = useSelector((store) => {return store[redux_header]});

    // Doc
    const set_blokk_yy_mat_push                     = useCallback( (arr) => {dispatch(setBlokkYYMatPush(arr))}, [dispatch]);

    // Tittel
    const tittel_ref                                = useRef(null);

    // Scroll
    const scroll_standard                           = () => tittel_ref.current.scrollIntoView()
    const scroll_to_yp_async                        = useCallback (() => {

        // If blokk selected > Arg i do_navigate
        if (props.blokk_selected_index === props.blokk_index) {

            // Scroll to yp > Async
            const _scroll_to_yp_async = () => {

                // Init
                let yp = 0;

                // Ingen scroll (bypassed)
                if (props.blokk_selected_index === 0) {};

                // Scroll > YP
                if (props.blokk_selected_index !== 0) {
                    yp
                    = lay_is_ref(tittel_ref, lay_yp)
                    - header_yy
                    - path_yy
                };

                // Debug
                // console.log("> Scroll > tittel.js") // DEV_auto_scroll

                // Window > Scroll to
                window.scrollTo({
                    top:        yp,
                    behavior:   scroll_smooth
                });
            };
            setTimeout(() => {_scroll_to_yp_async()}, delay_scroll_1);
        };

        // Dep
    }, [header_yy,
        path_yy,
        props.blokk_index,
        props.blokk_selected_index]);
    const scroll_push_yp_async                      = useCallback( () => {

        // Scroll push yp > Async
        const _scroll_push_yp_async = () => {

            const yp = lay_is_ref(tittel_ref, lay_yp);

            if (props.slug) {
                set_blokk_yy_mat_push([props.slug, yp]);
            };
        };

        setTimeout(() => {_scroll_push_yp_async()}, delay_scroll_2_push_yp);

        // Dep
    }, [set_blokk_yy_mat_push,
        tittel_ref,
        props.slug])

    // Nested > Indent > Calc
    const branch_marg_left                          = () => {

        // Indent
        const indent = doc_branch_rem_per_level_indent * props.level;

        // Indent > Return
        return `${indent}rem`;
    };
    const _icon_forward                             = () => {

        // Hvis ikke arrow på første level > props.level er større enn 1 (doc_branch_arrow_start_level)
        if (props.level > doc_branch_arrow_start_level) {

            return(
                <Icon
                    icon_size={grp_icon_norm_48_12}
                    icon_cls="icon_arrow_collapsed_beige_2"/>
            )
        };
    };

    // Debug
    const _txt_debug                                = (debug) => {

        // Debug > True > Rød txt
        if (debug === true) {
            return "txt_norm_red"

        // Debug > False > Standard
        } else {
            return is_txt_grp(props.txt_grp)
        };
    };

    // useEffect
    useEffect( () => {

        // If scroll > F.eks. Doc
        if (props.is_scroll === true) {

            // Scroll to yp > Call
            scroll_to_yp_async();

            // Scroll push yp > Call
            scroll_push_yp_async();

        };

        // If scroll > F.eks. Doc
        if (props.is_scroll_standard === true) {

            scroll_standard();

        };

        // Dep
    }, [scroll_to_yp_async,
        scroll_push_yp_async,
        props.is_scroll_standard,
        props.is_scroll]);

    // Return
    return (
        <>

        {/* Ikke nested titler > F.eks. path */}
        {!props.nested &&
            <>
            {/* on_click */}
            {props.on_click &&

                <>
                {/* Tittel */}
                <div
                    ref={tittel_ref}
                    className={is_txt_grp(props.txt_grp)}
                    onClick={() => props.on_click()}>
                        {props.txt}
                </div>
                </>}

             {/* Ingen on_click > F.eks. siste el i path */}
            {!props.on_click &&
                <>
                {/* Tittel */}
                <div
                    ref={tittel_ref}
                    className={is_txt_grp(props.txt_grp)}>
                        {props.txt}
                </div>
                </>}
            </>}

        {/* Nested titler > F.eks. branch */}
        {props.nested &&
            <div
                className="flex_row_LC_G05"
                style={{marginLeft: `${branch_marg_left()}`}}>

                    {/* Arrow */}
                    {_icon_forward()}

                    {/* Icon pre */}
                    {props.icon_cls_pre &&
                        <div className="flex_row_LC_G05">

                            {/* Icon */}
                            <Icon
                                icon_size={grp_icon_tittel_norm}
                                icon_cls={props.icon_cls_pre}/>

                            {/* Tittel */}
                            <div
                                className={_txt_debug(props.txt_debug)}
                                onClick={() => props.on_click()}>
                                    {props.txt}
                            </div>
                        </div>}

                    {/* Ingen icon */}
                    {!props.icon_cls_pre &&
                    !props.icon_cls_post &&
                        <>
                        {/* Tittel */}
                        <div
                            className={_txt_debug(props.txt_debug)}
                            onClick={() => props.on_click()}>
                                {props.txt}
                        </div>
                        </>}

                    {/* Icon post */}
                    {props.icon_cls_post &&
                        <div className="flex_row_LC_G05">

                            {/* Tittel */}
                            <div
                                className={is_txt_grp(props.txt_grp)}
                                onClick={() => props.on_click()}>
                                    {props.txt}
                            </div>

                            {/* Icon */}
                            <Icon
                                icon_size={grp_icon_tittel_norm}
                                icon_cls={props.icon_cls_post}/>
                        </div>}

            </div>}
        </>
    )

};


export default Tittel;
