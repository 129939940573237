/* ****************************************** */
/* Debug */
/* ****************************************** */

// Timer
const is_ruter_timer_on                     = 0;    // 0: Av, 1: På


/* ****************************************** */
/* Export */
/* ****************************************** */

export {

    is_ruter_timer_on

};


/* ****************************************** */
/* End */
/* ****************************************** */
