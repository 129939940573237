/* ****************************************** */
/* Fil */
/* ****************************************** */

// Fil
const fil_download_zip_navn_prefix          = "_fil_download_zip_navn_prefix";
const fil_bytes_txt                         = "_fil_bytes_txt";
const fil_KB_txt                            = "_fil_KB_txt";
const fil_MB_txt                            = "_fil_MB_txt";
const fil_GB_txt                            = "_fil_GB_txt";
const fil_TB_txt                            = "_fil_TB_txt";
const fil_0_bytes_txt                       = "_fil_0_bytes_txt";
const fil_create_fil_txt                    = "_fil_create_fil_txt";
const fil_create_folder_txt                 = "_fil_create_folder_txt";
const fil_drop_zone_fil_type_ikke_gyldig    = "_fil_drop_zone_fil_type_ikke_gyldig";
const fil_drop_zone_fil_type_ugyldig_fjern  = "_fil_drop_zone_fil_type_ugyldig_fjern";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    fil_download_zip_navn_prefix,
    fil_bytes_txt,
    fil_KB_txt,
    fil_MB_txt,
    fil_GB_txt,
    fil_TB_txt,
    fil_0_bytes_txt,
    fil_create_fil_txt,
    fil_create_folder_txt,
    fil_drop_zone_fil_type_ikke_gyldig,
    fil_drop_zone_fil_type_ugyldig_fjern
};


/* ****************************************** */
/* End */
/* ****************************************** */
