import {
    redux_doc
} from "../../utils/konstanter/gen/_redux";

import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    doc_current:            {},
    doc_index:              0,
    doc_arr:                [],
    doc_fetch:              true, // denne var true

    doc_fetch_urly_lock:    false,

    blokk_selected_slug:    "",
    blokk_selected_index:   0,
    blokk_link:             "",
    blokk_yy_mat:           [],

};

const docSlice = createSlice({
    name:                   redux_doc,
    initialState:           initialState,
    reducers: {

        // Doc current
        setDocCurrent(state, action) {

            // DEV > Forklar / eksempel på input-param
            state.doc_current = action.payload;

        },
        setDocIndex(state, action) {

            // DEV > Forklar / eksempel på input-param
            state.doc_index = action.payload;

        },
        setDocArr(state, action) {

            state.doc_arr = action.payload;

        },
        setDocFetch(state, action) {

            // DEV > Forklar / eksempel på input-param
            state.doc_fetch = action.payload;

        },
        setDocAndFetch(state, action) {

            // DEV > Forklar / eksempel på input-param
            state.doc_fetch     = true;

        },

        // Urly
        setDocFetchUrlyLock(state, action) {

            // DEV > Forklar / eksempel på input-param
            state.doc_fetch_urly_lock = action.payload;

        },

        // Blokk
        setBlokkSelectedSlug(state, action) {

            // DEV > Forklar / eksempel på input-param
            state.blokk_selected_slug = action.payload;

        },
        setBlokkSelected(state, action) {

            // DEV > Forklar / eksempel på input-param
            state.blokk_selected_index = action.payload;

        },
        setBlokkLink(state, action) {

            // DEV > Forklar / eksempel på input-param
            state.blokk_link = action.payload;

        },
        setBlokkLinkReset(state) {

            // DEV > Forklar / eksempel på input-param
            state.blokk_link = "";

        },
        setBlokkYYMatReset(state) {

            // DEV > Forklar / eksempel på input-param
            state.blokk_yy_mat = [];

        },
        setBlokkYYMatPush(state, action) {

            // DEV > Forklar / eksempel på input-param
            state.blokk_yy_mat.push(action.payload);

        }
    }
});


export const {

    // Doc
    setDocCurrent,
    setDocIndex,
    setDocArr,
    setDocFetch,
    setDocAndFetch,

    // Urly
    setDocFetchUrlyLock,

    // Blokk
    setBlokkSelectedSlug,
    setBlokkSelected,
    setBlokkLink,
    setBlokkLinkReset,
    setBlokkYYMatReset,
    setBlokkYYMatPush

} = docSlice.actions;


export default docSlice.reducer;
