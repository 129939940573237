/* ****************************************** */
/* Filter */
/* ****************************************** */

// Filter
const filter_nyeste_txt                     = "_filter_nyeste_txt";
const filter_topp_txt                       = "_filter_topp_txt";
const filter_tittel_txt                     = "_filter_tittel_txt";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    filter_nyeste_txt,
    filter_topp_txt,
    filter_tittel_txt
};


/* ****************************************** */
/* End */
/* ****************************************** */
