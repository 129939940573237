/* ****************************************** */
/* Landing */
/* ****************************************** */

// Info
const questions_small                       = "_question_small";

// Quest > Create
const quest_tittel_create_txt               = "_quest_tittel_create_txt";
const quest_tittel_beskrivelse_txt          = "_quest_tittel_beskrivelse_txt";
const quest_tittel_drop_zone_txt            = "_quest_tittel_drop_zone_txt";
const quest_create_post_txt                 = "_quest_create_post_txt";

// Quest > Read
const quest_spurt                           = "_quest_spurt";
const quest_aktiv                           = "_quest_aktiv";
const quest_i_dag                           = "_quest_i_dag";
const quest_author_spurt                    = "_quest_author_spurt";

// Quest > List
const quest_list_create_btn                 = "_quest_list_create_btn";
const quest_list_create_tittel              = "_quest_list_create_tittel";

// Quest > Ord
const quest_ord_Quest                       = "_quest_ord_Quest";

// Quest > Item
const uni_root_quest_quest_list_quest_item  = "_uni_root_quest_quest-list_quest-item";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    questions_small,

    quest_tittel_create_txt,
    quest_tittel_beskrivelse_txt,
    quest_tittel_drop_zone_txt,
    quest_create_post_txt,

    quest_spurt,
    quest_aktiv,
    quest_i_dag,
    quest_author_spurt,

    quest_list_create_btn,
    quest_list_create_tittel,

    quest_ord_Quest,

    uni_root_quest_quest_list_quest_item
};


/* ****************************************** */
/* End */
/* ****************************************** */
