import {
    redux_loading
} from "../../utils/konstanter/gen/_redux";

import {createSlice} from "@reduxjs/toolkit";

const initialState = {

    inity_loading_inc:                  0,      // 0: Init, 1 / 2: path / header done

};

const isLoadingSlice = createSlice({
    name:                               redux_loading,
    initialState:                       initialState,
    reducers: {

        // Inity
        setInityLoadingInc(state) {

            // 0: Init, 1 & 2: header / path data fetched (varierende tid)
            if (state.inity_loading_inc === 0 || state.inity_loading_inc === 1) {
                state.inity_loading_inc += 1;
            }

        }

    }

});


export const {

    // Inity
    setInityLoadingInc

} = isLoadingSlice.actions;


export default isLoadingSlice.reducer;
