/* ****************************************** */
/* Quest */
/* ****************************************** */

// Quest
const quest_model_quest                     = "Quest";
const quest_model_svar                      = "Svar";
const quest_crud_typ_quest                  = "_quest";
const quest_crud_typ_svar_arr               = "_svar_arr";
const quest_crud_typ_svar_create            = "_svar_create";
const quest_crud_typ_kom_arr                = "_quest_crud_typ_kom_arr";
const quest_crud_typ_create_kom             = "_quest_crud_typ_create_kom";
const quest_crud_typ_fil_edit               = "_quest_crud_typ_fil_edit";
const quest_svar_create_slug_tmp            = "_tmp_svar_create_slug";
const quest_list_page_caller_profil         = "_quest_list_page_caller_profil";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    quest_model_quest,
    quest_model_svar,
    quest_crud_typ_quest,
    quest_crud_typ_svar_arr,
    quest_crud_typ_svar_create,
    quest_crud_typ_kom_arr,
    quest_crud_typ_create_kom,
    quest_crud_typ_fil_edit,
    quest_svar_create_slug_tmp,
    quest_list_page_caller_profil
};


/* ****************************************** */
/* End */
/* ****************************************** */
