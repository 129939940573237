import {
    btn_typ_col,
    btn_typ_tagg,
    btn_typ_txt_link,
    btn_typ_input
} from "../../utils/konstanter/components/_btn";
import {
    is_btn_grp
} from "../../utils/utility/_util_group";

import Icon from "../pic/icon/icon";


const Btn = (props) => {

    // Return
    return (
        <>
        {props.btn_typ === btn_typ_col &&
            <div
                className={is_btn_grp(props.btn_grp)}
                onClick={() => props.on_click()}>
                    <>

                    {/* Med icon */}
                    {props.icon_cls &&
                        <div className="flex_row_LC_G05">
                            <Icon
                                id           = {props.id}
                                icon_size    = {props.icon_size}
                                icon_cls     = {props.icon_cls}
                                icon_wrapper = {props.icon_wrapper}
                            />
                            {props.txt}
                        </div>}

                    {/* Uten icon */}
                    {!props.icon_cls &&
                        props.txt}
                    </>
            </div>}

        {props.btn_typ === btn_typ_tagg &&
            <div
                className   = {is_btn_grp(props.btn_grp)}
                id          = {props.id}
                ref         = {props.is_ref}
                onClick     = {(e) => props.on_click(e)}
                >
                    <>
                    {/* Med icon */}
                    {props.icon_cls &&
                        <div
                            className           = "flex_row_LC_G05"
                            id                  = {props.id}>
                            <Icon
                                id              = {props.id}
                                icon_size       = {props.icon_size}
                                icon_cls        = {props.icon_cls}
                                icon_wrapper    = {props.icon_wrapper}

                                onClick         = {(e) => props.on_click(e)}

                                />
                            {props.txt}
                        </div>}

                    {/* Uten icon*/}
                    {!props.icon_cls &&
                        props.txt}
                    </>
            </div>}

        {props.btn_typ === btn_typ_txt_link &&
            <div
                className   = {is_btn_grp(props.btn_grp)}
                onClick     = {() => props.on_click()}
                >
                    {props.txt}
            </div>}

        {props.btn_typ === btn_typ_input &&
            <div
                className   = {is_btn_grp(props.btn_grp)}
                onClick     = {() => props.on_click()}
                >
                    {props.txt}
            </div>}

        </>
    )
};


export default Btn;

