import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import {
    is_strict_mode
} from "../src/utils/settings/gen/_app";

// Strict mode bypassed > 0 > Development
if (is_strict_mode === 1) {
    ReactDOM.render(
        <React.StrictMode>
            <App/>
        </React.StrictMode>,
        document.getElementById("root")
    );
};

// Strict mode bypassed
if (is_strict_mode === 0) {
    ReactDOM.render(
            <App/>,
        document.getElementById("root")
    );
};
