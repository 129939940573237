/* ****************************************** */
/* Debug */
/* ****************************************** */

// Alfabet > Eng
const _a_s                                  = "a";
const _b_s                                  = "b";
const _c_s                                  = "c";
const _d_s                                  = "d";
const _e_s                                  = "e";
const _f_s                                  = "f";
const _g_s                                  = "g";
const _h_s                                  = "h";
const _i_s                                  = "i";
const _j_s                                  = "j";
const _k_s                                  = "k";
const _l_s                                  = "l";
const _m_s                                  = "m";
const _n_s                                  = "n";
const _o_s                                  = "o";
const _p_s                                  = "p";
const _q_s                                  = "q";
const _r_s                                  = "r";
const _s_s                                  = "s";
const _t_s                                  = "t";
const _u_s                                  = "u";
const _v_s                                  = "v";
const _w_s                                  = "w";
const _x_s                                  = "x";
const _y_s                                  = "y";
const _z_s                                  = "z";

// Alfabet > Special
const _a_hatt_s                             = "â";
const _e_hatt_s                             = "ê";

// Alfabet
const alfa_eng_small                        = [
    _a_s, _b_s, _c_s, _d_s, _e_s, _f_s,
    _g_s, _h_s, _i_s, _j_s, _k_s, _l_s,
    _m_s, _n_s, _o_s, _p_s, _q_s, _r_s,
    _s_s, _t_s, _u_s, _v_s, _w_s, _x_s,
    _y_s, _z_s
];


/* ****************************************** */
/* Export */
/* ****************************************** */

export {

    _a_s,
    _b_s,
    _c_s,
    _d_s,
    _e_s,
    _f_s,
    _g_s,
    _h_s,
    _i_s,
    _j_s,
    _k_s,
    _l_s,
    _m_s,
    _n_s,
    _o_s,
    _p_s,
    _q_s,
    _r_s,
    _s_s,
    _t_s,
    _u_s,
    _v_s,
    _w_s,
    _x_s,
    _y_s,
    _z_s,

    _a_hatt_s,
    _e_hatt_s,

    alfa_eng_small

};
