import {
    _eng,
    _no
} from "../../tekst/_param/param"


/* ****************************************** */
/* Human lang */
/* ****************************************** */

// Human lang
const is_human_lang_enabled                 = 1; // 0: Språk-valg vises ikke, 1: Språk-valg vises

let _human_lang_selected                    = _no;
let _human_lang_default                     = "";
if (_human_lang_selected === _eng)          {_human_lang_default = _eng};   // Select variabel
if (_human_lang_selected === _no)           {_human_lang_default = _no};    // Select variabel

// Default > Hardkodet
const human_lang_default_hardkodet          = _no;     // -"- > Hardkodet fordi context ikke er definert enda


/* ***************************************** */
/* Export */
/* ****************************************** */

export {
    is_human_lang_enabled,
    _human_lang_default,
    human_lang_default_hardkodet
};


/* ****************************************** */
/* End */
/* ****************************************** */
