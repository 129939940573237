import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
// import {useParams} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import AuthContext from "../../context/auth_context";
import {useDispatch, useSelector} from "react-redux";

import {
    debug_path_use_effect_initx,
    debug_path_use_effect_doc_true_initx,
    debug_path_fetch_pre_initx,
    debug_path_fetch_post_doc_initx,
    debug_path_click_item_cont,
    debug_path_use_effect_cont,
} from "../../utils/debug/_debug";
import {
    grp_txt_norm_grey,
    grp_txt_norm_link,
    grp_icon_norm_48_12
} from "../../utils/konstanter/gen/_grp";
import {
    _http_method_post,
    http_headers_content_type
} from "../../utils/konstanter/gen/_http";
import {
    lay_yy,
    lay_standard_reset,
    lay_path_update,
} from "../../utils/konstanter/gen/_layout";
import {
    redux_path,
    redux_loading,
    redux_ruter,
    redux_doc,
    redux_quest
} from "../../utils/konstanter/gen/_redux";
import {
    slug_root_landing
} from "../../utils/konstanter/gen/_slug";
import {
    delay_load
} from "../../utils/konstanter/gen/_time";
import {
    url_route_ruter,
    url_api_doc,
} from "../../utils/konstanter/gen/_url";
import {
    is_ruter_timer_on
} from "../../utils/settings/pages/_ruter";
import {
    get_txt
} from "../../utils/tekst/_translator/translator";
import {
    uni_root_quest_quest_list_quest_item
} from "../../utils/tekst/pages/_quest";
import {
    lay_is_ref
} from "../../utils/utility/_util_layout";
import {
    username_status
} from "../../utils/utility/_util_obj";
import {
    tekst_debug
} from "../../utils/utility/_util_tekst";

import {
    setDoRuterPre,
    setDoRuterPost
} from "../../pages/ruter/redux_ruter_feature";
import {
    setDocCurrent,
    setDocIndex,
    setDocArr
} from "../../pages/doc/redux_doc_feature";
import {
    setQuestTittelSelect
} from "../../pages/quest/redux_quest_feature";
import {
    setFilNodeFilMatResetAdapterDoc,
    setFilNodeFilMatReset
} from "../fil/redux_fil_feature";
import {
    setInityLoadingInc
} from "../loading/redux_is_loading_feature";
import {
    setCRUDDocReset
} from "../crud/redux_crud_feature";

import Icon             from "../pic/icon/icon";
import Tittel           from "../txt/tittel";
import ModalLoading     from "../loading/modal_loading";


const Path = () => {

    // User
    const {
        user,
        human_lang
    }                                               = useContext(AuthContext);

    // Params
    // const {pk}                                      = useParams();

    // Navigate
    const navigate                                  = useNavigate();

    // Redux
    const dispatch                                  = useDispatch();

    // Ruter
    const {is_spinner}                              = useSelector( (store) => {return store[redux_ruter]});
    const {ruter_slug}                              = useSelector( (state) => {return state[redux_ruter]});
    const {ruter_timer}                             = useSelector( (state) => {return state[redux_ruter]});
    const {ruter_timer_delta}                       = useSelector( (state) => {return state[redux_ruter]});
    const {ruter_timer_on}                          = useSelector( (state) => {return state[redux_ruter]});
    const set_do_ruter_pre                          = useCallback( (arr) => {dispatch(setDoRuterPre(arr))}, [dispatch]);
    const set_do_ruter_post                         = useCallback( (arr) => {dispatch(setDoRuterPost(arr))}, [dispatch]);

    // Loading
    const {inity_loading_inc}                       = useSelector( (store) => {return store[redux_loading]});
    const set_inity_loading_inc                     = useCallback( () => {dispatch(setInityLoadingInc())}, [dispatch]);
    const [is_load_done, setIsLoadDone]             = useState(false);
    const set_is_load_done_async                    = useCallback( () => {
        const _set_is_load_done_async = () => {
            setIsLoadDone(true);
        };
        setTimeout(() => {_set_is_load_done_async(delay_load)}, );
    }, []);

    // Fil
    const set_fil_node_fil_mat_reset_adapter_doc    = useCallback( (arr) => {dispatch(setFilNodeFilMatResetAdapterDoc(arr))}, [dispatch]);
    const set_fil_node_fil_mat_reset                = useCallback( () => {dispatch(setFilNodeFilMatReset())}, [dispatch]);

    // CRUD
    const set_crud_doc_reset                        = useCallback( (arr) => {dispatch(setCRUDDocReset(arr))}, [dispatch]);

    // Path
    const {pc_path_yp}                              = useSelector( (state) => {return state[redux_ruter]});
    const {path_is_visible}                         = useSelector( (state) => {return state[redux_path]});
    const path_ref                                  = useRef(null);

    // Doc
    const {doc_fetch}                               = useSelector( (store) => {return store[redux_doc]});
    const {doc_arr}                                 = useSelector( (store) => {return store[redux_doc]});
    const set_doc_current                           = useCallback( (val) => {dispatch(setDocCurrent(val))}, [dispatch]);
    const set_doc_index                             = useCallback( (typ) => {dispatch(setDocIndex(typ))}, [dispatch]);
    const set_doc_arr                               = useCallback( (typ) => {dispatch(setDocArr(typ))}, [dispatch]);

    // Quest
    const {quest_select}                            = useSelector( (store) => {return store[redux_quest]});
    const set_quest_tittel_select                   = useCallback( (val) => {dispatch(setQuestTittelSelect(val))}, [dispatch]);

    // Path > Is display
    const _is_disp_tittel_path_switch               = (path) => {

        // Tittel > Hidden
        if (path === 0) {return false};

        // Tittel > Show
        if (path === 1) {return true};
        if (path === 2) {return true};

    };
    const _is_disp_path_0_hide                      = (slug) => {

        if (slug === slug_root_landing) { return true }

        return false

    };
    const _is_disp_path_1_hide                      = (slug) => {

        if (slug === slug_root_landing)                 { return false }

        return true

    };
    const _is_disp_path_2_hide                      = (slug) => {

        if (slug === slug_root_landing) { return false }

        return true

    };
    const _is_disp_arrow_hide                       = (center, slug) => {

        // Center
        if (center === slug)                            { return false };

        // Root
        if (slug === slug_root_landing)                 { return false };

        // Return > true
        return true

    };
    const _is_quest_custom                          = (tittel) => {

        if (tittel === uni_root_quest_quest_list_quest_item)        { return quest_select }

        return tittel

    };

    // Click
    const click_path_item                           = useCallback( (slug) => {

        // Reset
        set_quest_tittel_select("");

        // Debug > Initx
        if (debug_path_click_item_cont === 1) {console.log(tekst_debug("Cont", "0000", "path.js", "click_path_item()", "slug", slug));};

        // Ruter > Pre
        set_do_ruter_pre([slug, "", lay_standard_reset, []]);

        // Dep
    }, [set_do_ruter_pre,
        set_quest_tittel_select]);

    // Fetch > Automatisk
    const fetch_get_doc_item                        = useCallback( (slug) => { // console.log("Fetch path!")

        // Debug > Initx
        if (debug_path_fetch_pre_initx === 1 && inity_loading_inc !== 2) { console.log(tekst_debug("Initx", "0000", "path.js", "fetch_get_doc_item() > Pre", "slug", slug)); };

        const _url = `${url_api_doc}/${slug}/`

        // Initx
        fetch(_url, {
            method:     _http_method_post,
            headers:    http_headers_content_type,
            body: JSON.stringify({
                "username":         username_status(user)[0],
                "is_guest":         username_status(user)[1],
            })
        })
        .then(res => res.json())
        .then(dat => { // console.log("dat :: ", dat)

            // Debug > Initx
            if (debug_path_fetch_post_doc_initx === 1 && inity_loading_inc !== 2) {
                console.log(tekst_debug("Initx", "F000", "path.js", "fetch_get_doc_item() > Post", "dat", dat[0]));
                console.log("Initx > Done :: ", true)
            };

            // Center index
            const _center_index = dat[0].center_index
            set_doc_current(dat[_center_index]);
            set_doc_arr(dat);
            set_doc_index(_center_index);

            // CRUD
            const _blokk_arr = dat[_center_index].blokk_arr;
            if (_blokk_arr.length > 0) { set_crud_doc_reset(_blokk_arr); };

            // Fil
            set_fil_node_fil_mat_reset_adapter_doc([
                dat[_center_index].slug,
                dat[_center_index].user,
                dat[_center_index].tittel,
                dat[_center_index].tre_arr,
                dat[_center_index].fil_mat,
                _blokk_arr
            ]);
            set_fil_node_fil_mat_reset();

            if (inity_loading_inc === 2) {

                // Ruter > Post
                set_do_ruter_post([lay_path_update, [lay_is_ref(path_ref, lay_yy)]]);
                navigate(url_route_ruter); // eslint

            };

            // Inity > Inc / Loading path done
            if (inity_loading_inc === 0 || inity_loading_inc === 1) { set_inity_loading_inc(); };

        })

        // eslint-disable-next-line
    }, [set_fil_node_fil_mat_reset, // Linter > navigate
        set_fil_node_fil_mat_reset_adapter_doc,
        set_crud_doc_reset,
        set_doc_arr,
        set_doc_index,
        set_doc_current,
        set_inity_loading_inc,
        set_do_ruter_post,
        inity_loading_inc,
        user]);

    // useEffect
    useEffect( () => {

        // Debug > Initx
        if (debug_path_use_effect_initx === 1 && inity_loading_inc !== 2) {console.log(tekst_debug("Initx", "0000", "path.js", "useEffect()", "", ""));};
        if (debug_path_use_effect_cont  === 1 && inity_loading_inc === 2) {console.log(tekst_debug("Cont", "0000", "path.js", "useEffect()", "", ""));};

        // Load > Done > Async
        set_is_load_done_async();

        // Fetch > Ment som callback
        if (doc_fetch === true) {

            // Debug > Initx
            if (debug_path_use_effect_doc_true_initx === 1 && inity_loading_inc !== 2) { console.log(tekst_debug("Initx", "0000", "path.js", "useEffect()", "doc_fetch", "_true"));};

            // Fetch - doc_slug
            fetch_get_doc_item(ruter_slug);

        };

        // eslint-disable-next-line
    }, [
        ruter_slug,
        // doc_fetch,
        // set_is_load_done_async,
        // fetch_get_doc_item
    ]);

    // Return
    return (
        <>
        {is_load_done &&
        path_is_visible &&
            <div
                className="flex_path_wrapper"
                style={{
                    top:        `${pc_path_yp}px`,
                    opacity:    "1",
                }}>
                    <div
                        ref         = {path_ref}
                        className   = "flex_path">
                        {doc_arr.map((item, index) =>
                            <React.Fragment key={index}>

                                {/* Debug > {console.log("doc_arr :: ", doc_arr)} {console.log("item.slug :: ", item.slug)} */}

                                {/* Landing > Special case */}
                                {item.path === 0 &&
                                _is_disp_path_0_hide(item.slug) && // item.slug

                                    <Tittel
                                        txt         = {get_txt(item.tittel, human_lang)}
                                        txt_grp     = {grp_txt_norm_link}
                                        on_click    = {() => click_path_item(item.slug)}/>}

                                {/* All > Alle el fra root > Ikke siste el - {console.log(item.tittel)} */}
                                {item.path === 1 &&
                                _is_disp_path_1_hide(item.slug) &&

                                    <Tittel
                                        txt         = {get_txt(item.tittel, human_lang)}
                                        txt_grp     = {grp_txt_norm_link}
                                        on_click    = {() => click_path_item(item.slug)}/>}

                                {/* Last > Siste el > Ingen link */}
                                {item.path === 2 &&
                                _is_disp_path_2_hide(item.slug) &&

                                    <Tittel
                                        txt         = {_is_quest_custom(get_txt(item.tittel, human_lang))}
                                        txt_grp     = {grp_txt_norm_grey} // {_is_disp_pk(item.slug)}
                                        />}

                                {/* Arrow */}
                                {_is_disp_tittel_path_switch(item.path) &&
                                _is_disp_arrow_hide(item.center, item.slug) &&

                                    <Icon
                                        icon_size   = {grp_icon_norm_48_12}
                                        icon_cls    = "icon_arrow_collapsed_beige_2_hoverless"/>}

                            </React.Fragment>
                        )}

                        {/* Ruter timer */}
                        {is_ruter_timer_on === 1 &&
                            <>
                            {/* OFF */}
                            {!ruter_timer_on &&
                                <div className="tekst">
                                    ({ruter_timer_delta})
                                </div>}

                            {/* ON */}
                            {ruter_timer_on &&
                                <div className="tekst">
                                    ({ruter_timer})
                                </div>}
                            </>}

                        {/* Spinner */}
                        <ModalLoading is_loading = {is_spinner} />

                    </div>

            </div>}
        </>
    )

};


export default Path;
