/* ****************************************** */
/* App */
/* ****************************************** */

// Version
const version_navn                          = "24.04.07-2-reg";      // Forrige: "23.6.17", Midlertig løsning

// Developer / Production > Test-email > contact@matematikkas.com
const _is_production                        = 1;                    // Finpuss > Søkeord > _is_production, 0: Devloper, 1: Production,

// Strict mode
const is_strict_mode                        = 1;                    // 0: Av, 1: Strict mode


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    version_navn,
    _is_production,
    is_strict_mode
};


/* ****************************************** */
/* End */
/* ****************************************** */
