import {
    is_img_grp
} from "../../../utils/utility/_util_group";

const Img = (props) => {

    // Return
    return (
        <>
        {/* Med on_click > Standard */}
        {props.on_click &&
            <img
                className={is_img_grp(
                    props.img_grp,
                    props.img_subgrp)}
                src={props.img_src}
                alt="alt"
                width="100%"
                objectfit="contain"
                onClick={() => props.on_click()}/>}

        {/* Uten on_click */}
        {!props.on_click &&
            <img
                className={is_img_grp(
                    props.img_grp,
                    props.img_subgrp)}
                src={props.img_src}
                alt="alt"
                width="100%"
                objectfit="contain"/>}
        </>
    )
};


export default Img;
