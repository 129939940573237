/* ****************************************** */
/* Tagg */
/* ****************************************** */

// Tagg
const tagg_tittel                           = "_tagg_tittel";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    tagg_tittel
};


/* ****************************************** */
/* End */
/* ****************************************** */
