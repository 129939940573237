import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import AuthContext from "../../context/auth_context";
import {useDispatch, useSelector} from "react-redux";

import {
    debug_header_use_effect_initx,
    debug_header_fetch_pre_initx,
    debug_header_fetch_post_initx,
    debug_header_click_doc_par_cont,
    debug_header_click_doc_ch_cont,
} from "../../utils/debug/_debug";
import {
    btn_typ_col
} from "../../utils/konstanter/components/_btn";
import {
    header_dropdown_mobile_hele_fixed,
    header_dropdown_mobile_hele_fixed_limit,
    header_dropdown_mobile_hele_dynamisk,
    header_dropdown_mobile_hele_dynamisk_none
} from "../../utils/konstanter/components/_header";
import {
    _http_method_get,
    http_headers_content_type
} from "../../utils/konstanter/gen/_http";
import {
    grp_icon_norm_24_24,
    grp_icon_norm_48_12,
    grp_icon_norm_48_20,
    grp_img_mikro,
    grp_img_sub_bilde_profil,
    grp_btn_trans_white
} from "../../utils/konstanter/gen/_grp";
import {
    lay_window_width_limit_xx_1,
    lay_window_width_limit_xx_2,
    header_desktop_par_extra_marg,
    header_banner_yy,
    header_gap_min,
    header_gap_max,
    lay_yy,
    lay_dropdown_clicked,
    lay_standard_reset,
    lay_window_resize,
    lay_window_device_view_mobile_1,
    lay_window_device_view_mobile_2,
    lay_window_device_view_desktop,
    lay_window_device_view_no_device,
    scroll_item,
    scroll_smooth
} from "../../utils/konstanter/gen/_layout";
import {
    _key_open,
    _key_ch_arr
} from "../../utils/konstanter/gen/_key";
import {
    redux_header,
    redux_loading,
    redux_ruter,
    redux_doc,
} from "../../utils/konstanter/gen/_redux";
import {
    slug_root,
    slug_root_landing,
    slug_root_adminx,
    slug_root_lang,
    slug_root_profil,
    slug_root_profil_disp,
    slug_root_profil_list,
    slug_root_signup,
    slug_root_signin,
    slug_root_signout,
    slug_root_quest,
    slug_root_quest_create,
    slug_root_quest_list,
    slug_root_the_editor
} from "../../utils/konstanter/gen/_slug";
import {
    delay_load,
    delay_warning_qf
} from "../../utils/konstanter/gen/_time";
import {
    url_api_doc_header,
} from "../../utils/konstanter/gen/_url";
import {
    get_txt
} from "../../utils/tekst/_translator/translator";
import {
    signup_tittel
} from "../../utils/tekst/pages/_reg";
import {
    lay_is_ref
} from "../../utils/utility/_util_layout";
import {
    tekst_debug
} from "../../utils/utility/_util_tekst";

import {
    setWinTyp,
    setHeaderDropdownMobileHeleR
} from "./redux_header_feature";
import {
    setPathIsVisible
} from "../../components/02_path/redux_path_feature";
import {
    setInityLoadingInc
} from "../../components/loading/redux_is_loading_feature";
import {
    setPCReset,
    setPCResetLock,
    setMenyToggleReset,
    setDoRuterReset,
    setDoRuterPre,
    setDoRuterPost,
    setHeaderDocItemMat,
    setHeaderDocItemMatOpen,
    setHeaderDocItemMatOpenReset,
    setMenyToggleCalcAsync
} from "../../pages/ruter/redux_ruter_feature";

import Btn  from "../btn/btn";
import Icon from "../pic/icon/icon";
import Img  from "../pic/img/img";
import Logo from "../pic/logo/logo";


const Header = () => {

    // User
    const {
        user,
        is_superuser,
        human_lang,
        img_profil,
        signout_user
    }                                               = useContext(AuthContext);

    // Redux
    const dispatch                                  = useDispatch();

    // Loading
    const {inity_loading_inc}                       = useSelector( (state) => {return state[redux_loading]});
    const [is_load_done, setIsLoadDone]             = useState(false);
    const set_inity_loading_inc                     = useCallback( () => {dispatch(setInityLoadingInc())}, [dispatch]);
    const set_is_load_done_load_async               = useCallback( () => {setTimeout( () => {setIsLoadDone(true);}, delay_load);}, []);

    // Ruter
    const set_pc_reset                              = useCallback( () => {dispatch(setPCReset())}, [dispatch]);
    const set_pc_reset_lock                         = useCallback( (val) => {dispatch(setPCResetLock(val))}, [dispatch]);
    const set_meny_toggle_reset                     = useCallback( () => {dispatch(setMenyToggleReset())}, [dispatch]);
    const set_meny_toggle_calc_async                = useCallback( (arr) => {dispatch(setMenyToggleCalcAsync(arr))}, [dispatch]);
    const meny_toggle_calc_async                    = () => {

        const _set_meny_toggle_calc_async = () => {
            const _dropdown_yy = lay_is_ref(header_desktop_or_dropdown_ref, lay_yy); // console.log("_dropdown_yy :: ", _dropdown_yy)
            set_meny_toggle_calc_async([lay_dropdown_clicked, [_dropdown_yy]]);
        };
        setTimeout(() => {_set_meny_toggle_calc_async()}, 50);

    };
    const set_do_ruter_reset                        = useCallback( (arr) => {dispatch(setDoRuterReset(arr))}, [dispatch]);
    const set_do_ruter_pre                          = useCallback( (arr) => {dispatch(setDoRuterPre(arr))}, [dispatch]);
    const set_do_ruter_post                         = useCallback( (arr) => {dispatch(setDoRuterPost(arr))}, [dispatch]);
    const {pc_reset_lock}                           = useSelector( (state) => {return state[redux_ruter]});

    // Header
    const {header_doc_item_mat}                     = useSelector( (state) => {return state[redux_ruter]});
    const {header_dropdown_is_down_global}          = useSelector( (state) => {return state[redux_ruter]});
    const {ruter_slug}                              = useSelector( (state) => {return state[redux_ruter]});
    const {header_is_visible}                       = useSelector( (state) => {return state[redux_header]});
    const header_mobile_ref                         = useRef(null);
    const header_desktop_or_dropdown_ref            = useRef(null);
    const set_header_doc_item_mat                   = useCallback( (arr) => {dispatch(setHeaderDocItemMat(arr))}, [dispatch]);
    const set_header_doc_item_mat_open              = (arr) => {dispatch(setHeaderDocItemMatOpen(arr))};
    const set_header_doc_item_mat_open_reset        = ()    => {dispatch(setHeaderDocItemMatOpenReset())};
    const header_mobile_styles                      = (header_dropdown_is_down_global, cls_par_ch) => {

        // Mobile
        if (cls_par_ch === "header_item_mobile_par_pointer_wrapper") {
            const _is_header_dropdown_is_down = () => {

                // Dropdown > Expanded
                if (header_dropdown_is_down_global === true)    {return "auto"};

                // Dropdown > Collapsed
                if (header_dropdown_is_down_global === false)   {return "0px"};
            };

            return {
                width:              "100%",
                height:             `${_is_header_dropdown_is_down()}`
            };
        };

        // Desktop
        if (cls_par_ch === "header_item_desktop_par_ch") {
            return {
                width:          "100%"
            }
        };
    };

    // Path
    const set_path_is_visible                       = useCallback( (bl) => {dispatch(setPathIsVisible(bl))}, [dispatch]);

    // Window > xx
    const _winow_xx_is_debug                        = false;
    const [window_width, setWindowWidth]            = useState(window.innerWidth);
    const set_window_width                          = () => {setWindowWidth(window.innerWidth)};
    const window_is_device_view                     = useCallback( () => { // console.log("xx > ", window_width)

        // Mobile 1
        if (window_width > lay_window_width_limit_xx_2 && window_width < lay_window_width_limit_xx_1)      {return lay_window_device_view_mobile_1}

        // Mobile 2
        else if (window_width <= lay_window_width_limit_xx_2)      {return lay_window_device_view_mobile_2}

        // Desktop
        else if (window_width >= lay_window_width_limit_xx_1)     {return lay_window_device_view_desktop}

        // No device
        else                                                    {return lay_window_device_view_no_device}

        // Dep
    }, [window_width]);
    const set_win_typ                               = useCallback( (val) => {dispatch(setWinTyp(val))}, [dispatch]);
    const window_width_dyn                          = useCallback( () => {

        /* Søkeord > FIX_header_warning */

        // Win typ
        const _win_typ = window_is_device_view();
        setTimeout(() => {set_win_typ(_win_typ);}, delay_warning_qf);   // Ori (uten delay_warning_qf) > set_win_typ(_win_typ);

        // Mobile 1
        if      (_win_typ   === lay_window_device_view_mobile_1)        { set_pc_reset_lock(false); }

        // Mobile 2
        else if (_win_typ   === lay_window_device_view_mobile_2)        { set_pc_reset_lock(false); }

        // Desktop
        else if (_win_typ   === lay_window_device_view_desktop)         {

            if (pc_reset_lock === false) {

                set_pc_reset();
                set_pc_reset_lock(true);

            };

        }

        // No device
        else if (_win_typ   === lay_window_device_view_no_device)       {} // Bypass

        // Dep
    }, [window_is_device_view,
        set_win_typ,
        set_pc_reset,
        set_pc_reset_lock,
        pc_reset_lock]);

    // Window > yy
    const [window_yy, setWindowYY]                  = useState(window.innerHeight);
    const set_window_width_yy                       = () => {setWindowYY(window.innerHeight)};

    // Header / Path
    // eslint-disable-next-line
    const scroll_yy_arr                             = [0, 0];
    const set_header_dropdown_mobile_hele_r         = useCallback( (val) => {dispatch(setHeaderDropdownMobileHeleR(val))}, [dispatch]);
    const {header_dropdown_mobile_hele_r}           = useSelector( (state) => {return state[redux_header]});

    // Scroll
    const handle_scroll                             = useCallback( () => {

        // Debug
        const _debug_lay        = false;
        const _debug_diff       = false;

        // Init
        const _offset_yp        = window.pageYOffset; // VSC mener depreciated > settings.json > showDeprecated
        const _drop_yy          = lay_is_ref(header_desktop_or_dropdown_ref, lay_yy);
        const _dropdown_yy
        = _drop_yy
        - _offset_yp
        + header_banner_yy;

        // Ubrukt > Scroll arr > Direction > Ubrukt, men kan bli praktisk
        scroll_yy_arr.push(_offset_yp);

        // Ubrukt > Diff > * (-1) -> Scroll nedover er positivt
        const _diff
        = (scroll_yy_arr[scroll_yy_arr.length - 2]
        - scroll_yy_arr[scroll_yy_arr.length - 1]) * (-1);
        if (_debug_diff === true) {
            console.log("diff :: ", _diff)
        };

        // Gap > Mellom dropdown og resten av siden (i bakgrunnen)
        const _gap
        = window_yy
        - _drop_yy
        - header_banner_yy
        + _offset_yp

        // Green > Default innenfor siden > Ingen scroll
        if (_dropdown_yy < window_yy) {
            if (header_dropdown_mobile_hele_r !== header_dropdown_mobile_hele_fixed) { // console.log("header_dropdown_mobile_hele > TRX :: ", header_dropdown_mobile_hele_r)
                set_header_dropdown_mobile_hele_r(header_dropdown_mobile_hele_fixed);
            };
        };

        // Red > På grensen av max scollet / å gå over siden > Ingen scroll
        if (_dropdown_yy === window_yy) {
            set_header_dropdown_mobile_hele_r(header_dropdown_mobile_hele_dynamisk_none);
        };

        // Blue > Over siden > Scroll
        if (_dropdown_yy > window_yy) {
            set_header_dropdown_mobile_hele_r(header_dropdown_mobile_hele_dynamisk);
        };

        // Orange > Max scrollet > Utenfor siden > Ingen scroll
        if (_gap > header_gap_min && _gap < header_gap_max) {
            set_header_dropdown_mobile_hele_r(header_dropdown_mobile_hele_fixed_limit);
        };

        // Debug > Lay
        if (_debug_lay === true) {
            console.log("***")
            console.log("win > yy :: ", window_yy)
            console.log("drp > yy :: ", _dropdown_yy)
            console.log("drp > yp :: ", _offset_yp);
        };

        // Dep
    }, [scroll_yy_arr,
        window_yy,
        header_dropdown_mobile_hele_r,
        set_header_dropdown_mobile_hele_r]);
    const dropdown_status                           = () => {

        // Window > DEV // Debug console.log("> Scroll > _Adminx.js") // DEV_auto_scroll
        window.scrollTo({top: 0, behavior: scroll_smooth});

        // Async
        const funk_async = () => {
            const _funk_async = () => {

                // Scroll init
                handle_scroll();

            };
            setTimeout(() => {_funk_async()}, 100);
        };
        funk_async();

    };
    const _get_top                                  = () => {

        const _top_yy
        = window_yy
        - lay_is_ref(header_desktop_or_dropdown_ref, lay_yy)
        - header_banner_yy; // Alternativ hvis gap under max mobile > _banner_yy + 16 eller 32

        if (header_dropdown_mobile_hele_r === header_dropdown_mobile_hele_fixed_limit)      {return _top_yy}
        if (header_dropdown_mobile_hele_r === header_dropdown_mobile_hele_fixed)            {return 0}
        if (header_dropdown_mobile_hele_r === header_dropdown_mobile_hele_dynamisk)         {return 0}
        if (header_dropdown_mobile_hele_r === header_dropdown_mobile_hele_dynamisk_none)    {return _top_yy}

    };

    // Doc
    const {doc_arr}                                 = useSelector( (store) => {return store[redux_doc]});

    // Click
    const click_meny_burger                         = () => { // console.log("header.js > click_meny_burger()")

        // Virker kun nå doc arr er loadet
        if (doc_arr.length > 0) {

            dropdown_status();
            set_meny_toggle_reset();
            meny_toggle_calc_async();

        };

    };
    const click_logo                                = () => {

        // Virker kun nå doc arr er loadet
        if (doc_arr.length > 0) {

            // Ruter > Pre
            set_do_ruter_pre([slug_root, "", lay_standard_reset, []]);

            // Ruter > Post-force > Async
            const _set_do_ruter_post = () => {

                // En av få steder denne kalles > Kun for å stoppe spinner
                set_do_ruter_post([lay_standard_reset, []]);

            };
            setTimeout(() => {_set_do_ruter_post()}, 500);

        };

    };
    const click_profil_img                          = () => { // console.log("header.js > click_profil_img()")

        // Virker kun nå doc arr er loadet
        if (doc_arr.length > 0) {

            // Ruter > Pre
            set_do_ruter_pre([slug_root_profil_disp, user.username, lay_standard_reset, []]);

        };

    };
    const click_doc_page_og_par                     = (i, item_par) => { // console.log("> click_doc_page_og_par > item_par :: ", item_par.slug) // console.log("> click_doc_page_og_par > ruter_slug :: ", ruter_slug)

        // Samme slug > Ingen ruting
        if (item_par.slug === ruter_slug) {

            // Ruter > Reset > Dette er ikke Pre og tar derfor kun 2 arg
            set_do_ruter_reset([lay_standard_reset, []]);

        };

        // Ny slug > Ruting
        if (item_par.slug !== ruter_slug) {

            // Dropdown scroll > Pause
            dropdown_status();

            // Ingen route > Kun for expand / collapse par el
            if (item_par[_key_ch_arr].length > 0) { // console.log("> Click par el :: ", item_par)

                // Mobile > Kan expande flere par
                if (header_dropdown_is_down_global === true) {
                    set_header_doc_item_mat_open([i, !header_doc_item_mat[i][_key_open]]);
                    meny_toggle_calc_async();

                };

                // Desktop > Kan kun expande 1 par
                if (header_dropdown_is_down_global === false) {
                    set_header_doc_item_mat_open_reset();
                    set_header_doc_item_mat_open([i, !header_doc_item_mat[i][_key_open]]);
                };

                // Header > YY > Oppdater > Ingen delay / Ingen async > set_header_yy_path_yp_async(delay_arg_0);

            };

            // Route > Navigate
            if (item_par[_key_ch_arr].length === 0) { // console.log("> Click par el :: ", item_par.slug)

                // Debug > Cont
                if (debug_header_click_doc_par_cont === 1) {console.log(tekst_debug("Cont", "0000", "path.js", "click_doc_page_og_par()", "item_par.slug", item_par.slug));};

                // Reg
                if (item_par.slug === slug_root_signup) { // console.log("header.js > click_doc_page_og_par() > slug_root_signup")

                    // Path > Hide
                    set_path_is_visible(false)

                    // Ruter > Pre
                    set_do_ruter_pre([slug_root_signup, "", lay_standard_reset, []]);

                };
                if (item_par.slug === slug_root_signin) { // console.log("header.js > click_doc_page_og_par() > slug_root_signin")

                    // Path > Hide
                    set_path_is_visible(false)

                    // Ruter > Pre
                    set_do_ruter_pre([slug_root_signin, "", lay_standard_reset, []]);

                };
                if (item_par.slug === slug_root_signout) { // console.log("header.js > click_doc_page_og_par() > slug_root_signout()")

                    // Ruter > Pre
                    set_do_ruter_pre([slug_root, "", lay_standard_reset, []]);

                    // Signout
                    signout_user();

                    // Ruter > Post-force > Async
                    const _set_do_ruter_post = () => {

                        // En av få steder denne kalles > Kun for å stoppe spinner
                        set_do_ruter_post([lay_standard_reset, []]);

                    };
                    setTimeout(() => {_set_do_ruter_post()}, 500);

                };

                // The Editor
                if (item_par.slug === slug_root_the_editor) { // console.log("header.js > click_doc_page_og_par() > slug_root_the_editor")

                    // Ruter > Pre
                    set_do_ruter_pre([slug_root_the_editor, "", lay_standard_reset, []]);

                };

                // Adminx
                if (item_par.slug === slug_root_adminx) { // console.log("header.js > click_doc_page_og_par() > slug_root_adminx")

                    // Ruter > Pre
                    set_do_ruter_pre([slug_root_adminx, "", lay_standard_reset, []]);

                };

                // Quest
                if (item_par.slug === slug_root_quest) { // console.log("header.js > click_doc_page_og_par() > slug_root_adminx")

                    // Ruter > Pre
                    set_do_ruter_pre([slug_root_quest, "", lay_standard_reset, []]);

                };
                if (item_par.slug === slug_root_quest_create) { // console.log("header.js > click_doc_page_og_par() > slug_root_adminx")

                    // Ruter > Pre
                    set_do_ruter_pre([slug_root_quest_create, "", lay_standard_reset, []]);

                };
                if (item_par.slug === slug_root_quest_list) { // console.log("header.js > click_doc_page_og_par() > slug_root_adminx")

                    // Ruter > Pre
                    set_do_ruter_pre([slug_root_quest_list, "", lay_standard_reset, []]);

                };

            };

        };


    };
    const click_doc_ch                              = useCallback( (slug) => {

        console.log("click_doc_ch > slug :: ", slug)

        // Debug > Cont
        if (debug_header_click_doc_ch_cont === 1) {console.log(tekst_debug("Cont", "0000", "path.js", "click_path_item()", "slug", slug));};

        // Samme slug > Ingen ruting
        if (slug === ruter_slug) {

            // Ruter > Reset > Dette er ikke Pre og tar derfor kun 2 arg
            set_do_ruter_reset([lay_standard_reset, []]);

        };

        // Ny slug > Ruting
        if (slug !== ruter_slug) {

            // pk
            let _pk = "";

            // Slugs
            if (slug === slug_root_profil_disp) { _pk = user.username; };

            // Ruter > Pre
            set_do_ruter_pre([slug, _pk, lay_standard_reset, []]);

        };

        // eslint-disable-next-line
    }, [set_do_ruter_reset, // user.username > Breaker når empty
        set_do_ruter_pre,
        ruter_slug]);
    const click_signup                              = () => {

        // Virker kun nå doc arr er loadet
        if (doc_arr.length > 0) {

            // Path > Hide
            set_path_is_visible(false);

            // Ruter > Pre
            set_do_ruter_pre([slug_root_signup, "", lay_standard_reset, []]);

        };

    };
    const click_custom                              = (slug) => {

        // Page core > Exit
        // page_core_exit();

        // Ruter > Pre
        set_do_ruter_pre([slug, "", lay_standard_reset, []]);

    };

    // Is
    const is_item_auth                              = (slug) => { // console.log("slug :: ", slug)

        // Filtrert ut > Landing
        if (slug === slug_root_landing)                             { return false };

        // Alle > Programmeringsspråk
        if (slug === slug_root_lang)                                { return true };

        // User > Profil disp
        if (slug === slug_root_profil)          { if (user)         { return true } else { return false }; };

        // User > Profil disp
        if (slug === slug_root_profil_disp)     { if (user)         { return true } else { return false }; };

        // User > Profil disp
        if (slug === slug_root_profil_list)                         { return true };

        // Alle > The Editor
        if (slug === slug_root_the_editor)                          { return true };

        // Alle / kontekst > Signup > Innlogget (vises ikke) / Logget ut (vises)
        if (slug === slug_root_signup)          { if (user)         { return false } else { return true }; };

        // Alle / kontekst > Signin > Innlogget (vises ikke) / Logget ut (vises)
        if (slug === slug_root_signin)          { if (user)         { return false } else { return true }; };

        // Alle / kontekst > Signout > Innlogget (vises) / Logget utt (vises ikke)
        if (slug === slug_root_signout)         { if (user)         { return true } else { return false }; };

        // Admin > Adminx - user && is_superuser > DEV_timeout
        // if (slug === slug_root_adminx)          { if (is_superuser) { return true } else { return false }; };

        // Alle > Quest
        if (slug === slug_root_quest)                               { return true };

        // Alle > Quest
        if (slug === slug_root_quest_create)                        { return true };

        // User > Quest
        if (slug === slug_root_quest_list)      { if (user)         { return true } else { return false }; };

        return false;

    };
    const is_item_cls                               = () => {

        // Mobile > Ch el / Custom el / Developer el
        if (header_dropdown_is_down_global === true)    {return "header_item_mobile_ch_custom"}

        // Desktop > Logo / Custom el / Developer el
        if (header_dropdown_is_down_global === false)   {return "header_item_desktop_par_default"};

    };
    const is_item_doc_desktop_par_style_marg_extra  = (item_par) => {

        // Init
        let _style = {};

        // Desktop > Par el og tagg_src > Marg extra > Quickfix
        if (item_par.ch_arr.length > 0 &&
            item_par.ch_arr[0].tagg_src === 1) {

                // Style > Margin left
                _style = {
                    marginLeft:     `${header_desktop_par_extra_marg}rem`,
                    background:     "transparent" // transparent, debug: "blue"
                };
        };

        // Return > Style
        return _style
    };
    const is_par_arrow_cls                          = (item_par) => { // console.log("item_par[_key_ch_arr].length :: ", item_par[_key_ch_arr].length)

        // Styler tittel-teksten til par med arrow og ikke for resten

        // Mobile > Par el > Arrow > Txt
        if (item_par[_key_ch_arr].length >   0) {return "txt_norm_beige_2_link_marg_L_header_par_mobile"}

        // Mobile > Standard el / ikke par el > No arrow > Txt
        if (item_par[_key_ch_arr].length === 0) {return "txt_norm_beige_2_link_marg_L_header_par_mobile_no_arrow"}

    };

    // JSX
    const _mobile_header                            = () => {
        return (
            <div
                className   = "header_banner_mobile"
                ref         = {header_mobile_ref}>

                <div className = "flex_col_TL">
                    <div className = "flex_row_LT">

                        <div className = "header_banner_mobile_left">
                            <div className = "header_item_desktop_par_default">
                                <Icon
                                    icon_size   = {grp_icon_norm_24_24}
                                    icon_cls    = "icon_menu_burger_beige_2"
                                    on_click    = {() => click_meny_burger()}/>
                            </div>
                        </div>
                        <div className = "header_banner_mobile_center">
                            <div className = "header_item_desktop_par_default">
                                <Logo
                                    cls         = "logo_header_mobile"
                                    on_click    = {() => click_logo()}/>
                            </div>
                        </div>
                        <div className = "header_banner_mobile_right">
                            <div className = "header_item_desktop_par_default">
                                {user ? (
                                    <Img
                                        img_grp         = {grp_img_mikro}
                                        img_subgrp      = {grp_img_sub_bilde_profil}
                                        img_src         = {img_profil}
                                        on_click        = {() => click_profil_img()}/>

                                ) : (
                                    <Btn
                                        btn_typ         = {btn_typ_col}
                                        btn_grp         = {grp_btn_trans_white}
                                        txt             = {get_txt(signup_tittel, human_lang)}
                                        on_click        = {() => click_signup()}/>
                                )}
                            </div>
                        </div>

                        {/* Debug > Window xx */}
                        {_winow_xx_is_debug &&
                            <div className = "tekst">
                                {window_width}
                            </div>}

                    </div>
                </div>
            </div>
        )
    };
    const _item_doc                                 = (item_par, index, cls_par_ch, cls_par, cls_wrap) => {
        return (
            <>
            {/* Ser om krever auth for å se header-el */}
            {is_item_auth(item_par.slug) &&
                <>
                {/* Item -- {console.log("item_par :: ", item_par)} */}
                <div
                    className   = {cls_par_ch}
                    style       = {header_mobile_styles(header_dropdown_is_down_global, cls_par_ch)}
                    onClick     = {() => click_doc_page_og_par(index, item_par)}>

                    {/* Par > Collapsed / Expanded */}
                    <div
                        className   = {cls_wrap}>

                        {/* Mobile */}

                            {/* Mobile > Collapsed */}
                            {header_dropdown_is_down_global &&
                            item_par[_key_ch_arr].length > 0 &&
                            !item_par[_key_open] &&
                                <Icon
                                    icon_size       = {grp_icon_norm_48_12}
                                    icon_cls        = "icon_arrow_collapsed_beige_2"
                                    icon_wrapper    = "inline_icon_wrapper"
                                    on_click        = {() => click_doc_page_og_par(index, item_par)}/>}

                            {/* Mobile > Expanded */}
                            {header_dropdown_is_down_global &&
                            item_par[_key_ch_arr].length > 0 &&
                            item_par[_key_open] &&

                                <Icon
                                    icon_size       = {grp_icon_norm_48_20}
                                    icon_cls        = "icon_arrow_expanded_beige_2"
                                    icon_wrapper    = "inline_icon_wrapper"
                                    on_click        = {() => click_doc_page_og_par(index, item_par)}/>}

                        {/* Desktop */}

                            {/* Desktop > Collapsed */}
                            {!header_dropdown_is_down_global &&
                            item_par[_key_ch_arr].length > 0 &&
                            !item_par[_key_open] &&
                                <Icon
                                    icon_size       = {grp_icon_norm_48_12}
                                    icon_cls        = "icon_arrow_collapsed_beige_2"
                                    icon_wrapper    = "inline_icon_wrapper"
                                    on_click        = {() => click_doc_page_og_par(index, item_par)}/>}

                            {/* Desktop > Expanded */}
                            {!header_dropdown_is_down_global &&
                            item_par[_key_ch_arr].length > 0 &&
                            item_par[_key_open] &&
                                <Icon
                                    icon_size       = {grp_icon_norm_48_20}
                                    icon_cls        = "icon_arrow_expanded_beige_2"
                                    icon_wrapper    = "inline_icon_wrapper"
                                    on_click        = {() => click_doc_page_og_par(index, item_par)}/>}

                        {/* Mobile > Par > Tittel */}
                        {header_dropdown_is_down_global &&
                            <div className  ={is_par_arrow_cls(item_par)}>
                                {get_txt(item_par.tittel, human_lang)}
                            </div>}

                        {/* Desktop > Par > Tittel */}
                        {!header_dropdown_is_down_global &&
                            <div
                                className   = {cls_par}
                                style       = {is_item_doc_desktop_par_style_marg_extra(item_par)}>
                                {get_txt(item_par.tittel, human_lang)}
                            </div>}

                    </div>

                    {/* Ch */}

                        {/* Ch > Mobile > Icon / Tittel */}
                        {header_dropdown_is_down_global &&
                        item_par[_key_open] &&

                            <>
                            {item_par[_key_ch_arr].map(item_ch =>
                                <React.Fragment key={item_ch.tittel}>
                                    <div className="strek_header_ch"></div>
                                    <div
                                        className="txt_norm_beige_2_link_marg_LT_header_ch_mobile"
                                        onClick={() => click_doc_ch(item_ch.slug)}>
                                            <div className="flex_row_LC_G05">
                                                <Icon
                                                    icon_size   = "norm_tittel"
                                                    icon_cls    = {item_ch.icon}/>
                                                <div className  = {is_item_cls()}>
                                                    {get_txt(item_ch.tittel, human_lang)}
                                                </div>
                                            </div>
                                    </div>
                                </React.Fragment>
                            )}
                            </>}

                        {/* Ch > Desktop > (Inkludert dropdown) */}
                        {!header_dropdown_is_down_global &&
                        item_par[_key_open] &&

                            <div
                                className   = "header_desktop_dropdown_ch"
                                // Debug > style       = {{zIndex: -10, backgroundColor: "green"}}
                                >
                                {item_par[_key_ch_arr].map(item_sub =>
                                    <div
                                        key={item_sub.tittel}
                                        className="txt_norm_beige_2_link_marg_LT_header_desktop"
                                        // Debug > style={{zIndex: -10, backgroundColor: "red"}}
                                        onClick={() => click_doc_ch(item_sub.slug)}>
                                            {get_txt(item_sub.tittel, human_lang)}
                                    </div>
                                )}
                            </div>}

                </div>

                {/* Strek */}
                {header_dropdown_is_down_global &&
                    <div className = "strek_header"></div>}
                </>}
            </>
        );
    };
    const _item_arr                                 = (header_cls, cls_par_ch, cls_par, cls_wrap) => {
        return (
            <div
                ref         = {header_desktop_or_dropdown_ref}
                className   = {header_cls}
                style       = {{
                    top:        `${_get_top()}px`,
                    zIndex:     "var(--z_index_header_dropdown_ch)"
                }}>

                    {window_width >= lay_window_width_limit_xx_1 &&
                        <div className="header_item_desktop_par_default">

                            {/* Logo*/}
                            <Logo
                                cls      = "logo_header_desktop"
                                on_click = {() => click_logo()}/>

                            {/* Debug > Window xx */}
                            {_winow_xx_is_debug &&
                                <div className="tekst">
                                    {window_width}
                                </div>}

                        </div>}

                    {/* Custom - Profil list */}
                    {_item_custom(
                        "Profiler",
                        slug_root_profil_list,
                        true)}

                    {/* Doc items -- {console.log("header_doc_item_mat :: ", header_doc_item_mat)}*/}
                    {header_doc_item_mat.map((item_par, index) =>
                        <React.Fragment key={index}>
                            {_item_doc(
                                item_par,
                                index,
                                cls_par_ch,
                                cls_par,
                                cls_wrap)}
                        </React.Fragment>)}

            </div>
        )
    };
    const _item_custom                              = (tit, slug, show) => {
        return (
            <>
                {show &&
                !user &&
                    <>
                    <div
                        className   = {is_item_cls()}
                        onClick     = {() => click_custom(slug)}>

                            {/* *** Custom *** */}
                            <div className="txt_norm_beige_2_link_header">
                                {tit}
                            </div>

                    </div>
                    <div className="strek_header"></div>
                    </>}
            </>
        );
    };
    const _master_responsive                        = () => {
        return (
            <div>

            {header_is_visible &&
                <>

                    {/* Dyn bredde > Søkeord > FIX_header_warning */}
                    {window_width_dyn()}

                    {/* 01_header.css > @media > show / hide */}
                    {_mobile_header()}

                    {header_dropdown_is_down_global ? (
                        _item_arr(
                            /// header_dropdown_mobile_hele,
                            header_dropdown_mobile_hele_r,
                            "header_item_mobile_par_pointer_wrapper",
                            "txt_norm_beige_2_link_marg_L_header_par_mobile",
                            "header_item_mobile_par_wrapper"
                            )
                    ) : (
                        _item_arr(
                            "header_banner_desktop", // 01_header.css > @media > show / hide
                            "header_item_desktop_par_ch",
                            "txt_norm_beige_2_link_marg_header_desktop",
                            "header_item_desktop_par_wrapper")
                    )}

                </>}

            </div>
        )
    };

    // Fetch
    const fetch_get_doc_header_arr                  = useCallback( () => {

        // Debug > Initx
        if (debug_header_fetch_pre_initx === 1 && inity_loading_inc !== 2) { console.log(tekst_debug("Initx", "0000", "header.js", "fetch_get_doc_header_arr() > Pre", "", "")); };

        // Fetch > Doc > Header arr > Issue > Warning: Cannot update a component (`LandingPage`) while rendering a different component (`Header`).

        // Initx > Executes 1 gang (2 ganger raskt etter hverandre pga. initx)
        fetch(url_api_doc_header, { // "/api/doc_header/"
            method:     _http_method_get,
            headers:    http_headers_content_type
        })
        .then(res => res.json())
        .then(dat => { // console.log("fetch_get_doc_header_arr > dat :: ", dat)

            // Debug > Initx
            if (debug_header_fetch_post_initx === 1 && inity_loading_inc !== 2) { console.log(tekst_debug("Initx", "F000", "header.js", "fetch_get_doc_header_arr() > Post", "dat", dat[0])); };

            // Header > Doc item
            set_header_doc_item_mat(dat);

            // Load > Done > Warnin issue
            set_is_load_done_load_async();

            // Inity > Inc / Loading header done
            if (inity_loading_inc === 0 || inity_loading_inc === 1) { set_inity_loading_inc(); };

        })

        // Dep
    }, [
        set_header_doc_item_mat,
        set_is_load_done_load_async,
        set_inity_loading_inc,
        inity_loading_inc
    ]);

    // Window
    const add_window_events                         = useCallback( () => {

        // eslint > handle_scroll > Infinite loop

        // Window > Add event listener > Resize
        window.addEventListener(lay_window_resize, set_window_width);

        // Window > Add event listener > Resize
        window.addEventListener("resize_yy", set_window_width_yy);

        // WIN 2 > Window scroll > Forklaring > DEV
        window.addEventListener(scroll_item, handle_scroll, { passive: true });

        // eslint-disable-next-line
    }, []);

    // useEffect
    useEffect( () => {

        if (debug_header_use_effect_initx === 1 && inity_loading_inc !== 2) { console.log(tekst_debug("Initx", "0000", "header.js", "useEffect()", "", "")); };

        add_window_events();

        fetch_get_doc_header_arr();

        // eslint-disable-next-line
    }, [
        // fetch_get_doc_header_arr,
        // add_window_events
    ]);

    // Return
    return (<>{is_load_done && _master_responsive()}</>)

};


export default Header;
