/* ****************************************** */
/* Editor kode */
/* ****************************************** */

// Info
const editor_kode_the_editor_navn_small     = "_editor_kode_the_editor_navn_small";
const editor_kode_kode_small                = "_editor_kode_kode_small";
const editor_kode_programmer_siste_small    = "_editor_kode_programmer_siste_small";

// Gen
const editor_kode_term_tittel               = "_editor_kode_term_tittel";
const editor_kode_term_frontend             = "_editor_kode_term_frontend";
const editor_kode_file_tittel               = "_editor_kode_file_tittel";
const editor_kode_placeholder               = "_editor_kode_placeholder";
const editor_kode_ny_editor                 = "_editor_kode_ny_editor";
const editor_kode_kopier_editor             = "_editor_kode_kopier_editor";
const editor_kode_delete_editor             = "_editor_kode_delete_editor";
const editor_kode_home_tittel_1             = "_editor_kode_home_tittel_1";
const editor_kode_home_tittel_2             = "_editor_kode_home_tittel_2";

/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    editor_kode_the_editor_navn_small,
    editor_kode_kode_small,
    editor_kode_programmer_siste_small,

    editor_kode_term_tittel,
    editor_kode_term_frontend,
    editor_kode_file_tittel,
    editor_kode_placeholder,
    editor_kode_ny_editor,
    editor_kode_kopier_editor,
    editor_kode_delete_editor,
    editor_kode_home_tittel_1,
    editor_kode_home_tittel_2
};


/* ****************************************** */
/* End */
/* ****************************************** */
