/* ****************************************** */
/* Time */
/* ****************************************** */

// Time now
const get_time_now_mark = () => {

    // Date now
    const _time_now = Date.now();

    // Return > "1668287030921"
    return _time_now

};

export {
    get_time_now_mark
};
