/* ****************************************** */
/* No url */
/* ****************************************** */

// Info
const no_url_info                           = "_no_url_info";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    no_url_info
};

  
/* ****************************************** */
/* End */
/* ****************************************** */
