import {
    lay_path_yp_init,
    lay_path_yy_init,
    lay_standard_reset
} from "../../utils/konstanter/gen/_layout";
import {
    key_open
} from "../../utils/konstanter/gen/_model";
import {
    redux_ruter
} from "../../utils/konstanter/gen/_redux";

import {
    close_all,
    xy_all
} from "./ruter_util";

import {createSlice} from "@reduxjs/toolkit";

const initialState = {

    is_spinner:                         false,                  // false, true

    header_doc_item_mat:                [],
    header_dropdown_is_down_global:     false,

    pc_reset_lock:                      false,

    pc_header_yp:                       0,                      // Alltid 0
    pc_header_yy:                       64,                     // 64
    pc_dropdown_yp:                     64,                     // 64
    pc_dropdown_yy:                     0,                      // Foreløpig ubrukt
    pc_path_yp:                         lay_path_yp_init,       // 64 = 16 * 4 = 64 (1 rem * 4)
    pc_path_yy:                         lay_path_yy_init,       // 34 = 16 * 2 + 1 * 2 (rem * 2 + 1 * 2=
    pc_page_yp:                         98,                     // 98 = 64 + 34
    pc_page_yy:                         0,                      // Foreløpig ubrukt

    ruter_urly:                         "",     // xyz/abc/
    ruter_route:                        "",     // Sett som root? "/"
    ruter_slug:                         "root", // abc
    ruter_pk:                           "",     // abc/xyz der xyz = pk, f.eks. user-profil/admx
    ruter_reload:                       true,   // Skifter mellom true/false hver gang den kalles for å trigge callbacks og reloade ruter

    // Fra address-bar
    ruter_is_doc_tmp:                   false,   // urly = "xyz" -> false, urly = "xyz/abc/"" -> true

    // Time
    ruter_timer:                        0,
    ruter_timer_delta:                  0,
    ruter_timer_on:                     false, // ok
    ruter_timer_lock:                   true,

};

const ruterSlice = createSlice({
    name:                               redux_ruter,
    initialState:                       initialState,
    reducers: {

        // Spinner
        setIsSpinner(state, action) {

            // True når fetcher data
            state.is_spinner = action.payload

        },

        // Dropdown
        setIsHeaderDropdownOmvendtToggle(state) {

            // Dropdown > Toggle > Expanded / Collapsed
            state.header_dropdown_is_down_global = !state.header_dropdown_is_down_global;

        },
        setHeaderDropdownIsFalseGlobal(state) {

            // Dropdown > Set false > Collapsed
            state.header_dropdown_is_down_global = false;

        },

        // Header mat
        setHeaderPageCoreStandardReset(state) {

            // setHeaderDocItemMatOpenReset > Iterate doc item mat
            for (let k = 0; k < state.header_doc_item_mat.length; k++) {

                // Item mat > Reset > Set all > false
                state.header_doc_item_mat[k][key_open] = false;

            };

            // setHeaderDropdownIsFalseGlobal > Dropdown > Set false > Collapsed
            state.header_dropdown_is_down_global = false;

        },
        setHeaderDocItemMat(state, action) {

            // Item mat > Set fra API
            state.header_doc_item_mat = action.payload;

        },
        setHeaderDocItemMatOpen(state, action) {

            // Set open > True / False
            state.header_doc_item_mat[action.payload[0]][key_open] = action.payload[1];

        },
        setHeaderDocItemMatOpenReset(state) {

            // Iterate doc item mat
            for (let k = 0; k < state.header_doc_item_mat.length; k++) {

                // Item mat > Reset > Set all > false
                state.header_doc_item_mat[k][key_open] = false;

            };

        },

        // Page core reset
        setPCReset(state) {

            // setHeaderPageCoreStandardReset > setHeaderDocItemMatOpenReset > Iterate doc item mat
            for (let k = 0; k < state.header_doc_item_mat.length; k++) {

                // Item mat > Reset > Set all > false
                state.header_doc_item_mat[k][key_open] = false;

            };

            // setHeaderDropdownIsFalseGlobal > Dropdown > Set false > Collapsed
            state.header_dropdown_is_down_global = false;

            // setCalcYAll
            const _xy_arr               = xy_all(lay_standard_reset, [], state.pc_dropdown_yy, state.pc_path_yy);
            state.pc_header_yp          = _xy_arr[0];
            state.pc_header_yy          = _xy_arr[1];
            state.pc_dropdown_yy        = _xy_arr[2];
            state.pc_path_yy            = _xy_arr[3];
            state.pc_dropdown_yp        = _xy_arr[4];
            state.pc_path_yp            = _xy_arr[5];
            state.pc_page_yp            = _xy_arr[6];

        },
        setPCResetLock(state, action) {

            state.pc_reset_lock = action.payload;

        },

        // Meny
        setMenyToggleReset(state) {

            // setIsHeaderDropdownOmvendtToggle > Dropdown > Toggle > Expanded / Collapsed
            state.header_dropdown_is_down_global = !state.header_dropdown_is_down_global;

            // setHeaderDocItemMatOpenReset
            state.header_doc_item_mat   = close_all(state.header_doc_item_mat);

        },
        setMenyToggleCalcAsync(state, action) {

            // Init
            const _typ                  = action.payload[0];
            const _y_arr                = action.payload[1];

            // setCalcYAll
            const _xy_arr               = xy_all(_typ, _y_arr, state.pc_dropdown_yy, state.pc_path_yy);
            state.pc_header_yp          = _xy_arr[0];
            state.pc_header_yy          = _xy_arr[1];
            state.pc_dropdown_yy        = _xy_arr[2];
            state.pc_path_yy            = _xy_arr[3];
            state.pc_dropdown_yp        = _xy_arr[4];
            state.pc_path_yp            = _xy_arr[5];
            state.pc_page_yp            = _xy_arr[6];

        },

        // Do ruter
        setDoRuterReset(state, action) {

            // Init
            const _typ                  = action.payload[0];
            const _y_arr                = action.payload[1];

            // setHeaderDocItemMatOpenReset
            state.header_doc_item_mat   = close_all(state.header_doc_item_mat);
            state.header_dropdown_is_down_global = false;

            // setCalcYAll
            const _xy_arr               = xy_all(_typ, _y_arr, state.pc_dropdown_yy, state.pc_path_yy);
            state.pc_header_yp          = _xy_arr[0];
            state.pc_header_yy          = _xy_arr[1];
            state.pc_dropdown_yy        = _xy_arr[2];
            state.pc_path_yy            = _xy_arr[3];
            state.pc_dropdown_yp        = _xy_arr[4];
            state.pc_path_yp            = _xy_arr[5];
            state.pc_page_yp            = _xy_arr[6];

        },
        setDoRuterPre(state, action) { 

            // Init
            const _slug                 = action.payload[0];
            const _pk                   = action.payload[1];
            const _typ                  = action.payload[2];
            const _y_arr                = action.payload[3];

            // setRuterTimerStart > Ruter timer start > Reset ruter timer og slå på
            state.ruter_timer           = 0;
            state.ruter_timer_on        = true;

            // setRuterSlug > Setter ruter slug > Fra f.eks. header
            state.ruter_slug            = _slug;

            // setRuterPK
            state.ruter_pk              = _pk;

            // setHeaderDocItemMatOpenReset
            state.header_doc_item_mat   = close_all(state.header_doc_item_mat);
            state.header_dropdown_is_down_global = false;

            // setCalcYAll
            const _xy_arr               = xy_all(_typ, _y_arr, state.pc_dropdown_yy, state.pc_path_yy);
            state.pc_header_yp          = _xy_arr[0];
            state.pc_header_yy          = _xy_arr[1];
            state.pc_dropdown_yy        = _xy_arr[2];
            state.pc_path_yy            = _xy_arr[3];
            state.pc_dropdown_yp        = _xy_arr[4];
            state.pc_path_yp            = _xy_arr[5];
            state.pc_page_yp            = _xy_arr[6];

            // setIsSpinner > True når fetcher data
            state.is_spinner = true;

        },
        setDoRuterPost(state, action) {

            // set_ruter_timer_stop();
            // set_calc_yy_all(["path_update", [lay_is_ref(path_ref, layy_yy)]]);
            // set_is_spinner(false);

            // Init
            const _typ                  = action.payload[0];
            const _y_arr                = action.payload[1];

            // setRuterTimerStop > Sett ruter timer til current tid og slå av timer
            state.ruter_timer_delta     = state.ruter_timer;
            state.ruter_timer_on        = false;

            // setCalcYAll
            const _xy_arr               = xy_all(_typ, _y_arr, state.pc_dropdown_yy, state.pc_path_yy);
            state.pc_header_yp          = _xy_arr[0];
            state.pc_header_yy          = _xy_arr[1];
            state.pc_dropdown_yy        = _xy_arr[2];
            state.pc_path_yy            = _xy_arr[3];
            state.pc_dropdown_yp        = _xy_arr[4];
            state.pc_path_yp            = _xy_arr[5];
            state.pc_page_yp            = _xy_arr[6];

            // setIsSpinner > Data er fetchet
            state.is_spinner = false;

        },

        // Ruter
        setRuterUrly(state, action) {

            // Init
            const _ruter_urly = action.payload;

            // Setter ruter urly, f.eks. "/python/funk/oppgave"
            state.ruter_urly = _ruter_urly;

            // Setter ruter slug > Fra address-bar
            if (_ruter_urly !== "") {

                // Iterer baklengs til treffer "/", f.eks. "oppgave"
                let _ruter_slug = "";
                let i = _ruter_urly.length - 1;
                while (_ruter_urly[i] !== "/") {
                    _ruter_slug = _ruter_urly[i] + _ruter_slug;
                    i -= 1;
                };

                // Setter ruter slug
                state.ruter_slug = _ruter_slug;

            };

        },
        setRuterRoute(state, action) {

            // Init
            const _ruter_route = action.payload;

            // Setter ruter route, f.eks. "/doc"
            state.ruter_route = _ruter_route;

        },
        setRuterSlug(state, action) {

            // Init
            const _ruter_slug = action.payload;

            // Setter ruter slug > Fra f.eks. header
            state.ruter_slug = _ruter_slug;

        },
        setRuterPK(state, action) {

            // Init
            const _ruter_pk = action.payload;

            // Setter ruter slug > Fra f.eks. header
            state.ruter_pk = _ruter_pk;

        },
        setRuterReload(state) {

            // Init
            const _ruter_reload = state.ruter_reload;

            // Setter ruter reload til motsatt for å trigge callbacks og reloading
            state.ruter_reload = !_ruter_reload;

        },
        setRuterTimerInc(state) {

            // Inc vari med 1
            state.ruter_timer += 1;

        },
        setRuterTimerLockOff(state) {

            // Starter åpen og låses etter første aktivering i path.js > useEffect()
            state.ruter_timer_lock = false;

        },
        setRuterTimerStart(state) {

            // Reset ruter timer og slå på
            state.ruter_timer       = 0;
            state.ruter_timer_on    = true;
        },
        setRuterTimerStop(state) {

            // Init
            const _ruter_timer = state.ruter_timer;

            // Sett ruter timer til current tid og slå av timer
            state.ruter_timer_delta     = _ruter_timer
            state.ruter_timer_on        = false;

        },

    }
});


export const {

    // Spinner
    setIsSpinner,

    // Dropdown
    setIsHeaderDropdownOmvendtToggle,
    setHeaderDropdownIsFalseGlobal,

    // Header mat
    setHeaderPageCoreStandardReset,
    setHeaderDocItemMat,
    setHeaderDocItemMatOpen,
    setHeaderDocItemMatOpenReset,

    // Page core reset
    setPCReset,
    setPCResetLock,

    // Meny
    setMenyToggleReset,
    setMenyToggleCalcAsync,

    // Do Ruter
    setDoRuterReset,
    setDoRuterPre,
    setDoRuterPost,

    // Routing
    setRuterUrly,
    setRuterRoute,
    setRuterSlug,
    setRuterPK,
    setRuterReload,

    // Ruter timer
    setRuterTimerInc,
    setRuterTimerLockOff,
    setRuterTimerStart,
    setRuterTimerStop

} = ruterSlice.actions;


export default ruterSlice.reducer;
