import {
    redux_path
} from "../../utils/konstanter/gen/_redux";
import {
    lay_path_yy_init,
    lay_path_yp_init
} from "../../utils/konstanter/gen/_layout";

import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    path_is_visible:    true,
    path_yy:            lay_path_yy_init,
    path_yp:            lay_path_yp_init,
    path_yy_toggle:     false,
};

const pathSlice = createSlice({
    name:               redux_path,
    initialState:       initialState,
    reducers: {

        // Visibile
        setPathIsVisible(state, action) {

            // Is visible
            state.path_is_visible = action.payload;

        },

        // YY
        setPathYY(state, action) {

            // DEV > Forklaring
            state.path_yy = action.payload;

        },
        setPathYYToggle(state) {

            // Kun for å oppdatere path yy etter navigasjon
            state.path_yy_toggle = !state.path_yy_toggle;

        },

        // YP
        setPathYP(state, action) {

            // Init
            const _header_yy = action.payload;

            // header_yy == 0 > path_yp_init
            if (_header_yy === 0) {
                state.path_yp = lay_path_yp_init;
            };

            // header_yy != 0 > Videre > F.eks. header_dropdown > collapsed / expanded
            if (_header_yy !== 0) {
                state.path_yp = _header_yy;
            };
        }
    }
});


export const {

    // Visibile
    setPathIsVisible,

    // YY
    setPathYY,
    setPathYYToggle,

    // YP
    setPathYP

} = pathSlice.actions;


export default pathSlice.reducer;
