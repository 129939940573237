import {
    lay_dropdown_clicked,
    lay_dropdown_par_clicked,
    lay_standard_reset,
    lay_path_update
} from "../../utils/konstanter/gen/_layout";
import {
    key_open
} from "../../utils/konstanter/gen/_model";


/* ****************************************** */
/* Utils */
/* ****************************************** */

const close_all                             = (arr) => {

    // Init
    let _arr                                = arr;

    // setHeaderDocItemMatOpenReset > Iterate doc item mat
    for (let k = 0; k < _arr.length; k++) {

        // Item mat > Reset > Set all > false
        _arr[k][key_open] = false;

    };

    return _arr

};

const xy_all                                = (_typ, _y_arr, _pc_dropdown_yy, _pc_path_yy) => {

    // Init
    let _xy_arr                             = [];
    let _pc_header_yp                       = 0;                    // Konst
    let _pc_header_yy                       = 64;                   // Konst
    let _pc_dropdown_yp                     = -10;                  // Init
    let _pc_path_yp                         = -10;                  // Init
    let _pc_page_yp                         = -10;                  // Init

    if (_typ === lay_dropdown_clicked)      { _pc_dropdown_yy       = _y_arr[0]; };
    if (_typ === lay_dropdown_par_clicked)  { _pc_dropdown_yy       = 0; };
    if (_typ === lay_standard_reset)        { _pc_dropdown_yy       = 0; };
    if (_typ === lay_path_update)           { _pc_path_yy           = _y_arr[0]; }

    _pc_dropdown_yp                         = _pc_header_yp         + _pc_header_yy;
    _pc_path_yp                             = _pc_dropdown_yp       + _pc_dropdown_yy;
    _pc_page_yp                             = _pc_path_yp           + _pc_path_yy;

    _xy_arr = [
        _pc_header_yp,
        _pc_header_yy,
        _pc_dropdown_yy,
        _pc_path_yy,
        _pc_dropdown_yp,
        _pc_path_yp,
        _pc_page_yp
    ];

    return _xy_arr

};


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    close_all,
    xy_all
};
