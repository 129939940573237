/* ****************************************** */
/* Header */
/* ****************************************** */

// Header
const header_dropdown_mobile_hele_fixed             = "header_dropdown_mobile_hele_fixed";
const header_dropdown_mobile_hele_fixed_limit       = "header_dropdown_mobile_hele_fixed_limit";
const header_dropdown_mobile_hele_dynamisk          = "header_dropdown_mobile_hele_dynamisk";
const header_dropdown_mobile_hele_dynamisk_none     = "header_dropdown_mobile_hele_dynamisk_none";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    header_dropdown_mobile_hele_fixed,
    header_dropdown_mobile_hele_fixed_limit,
    header_dropdown_mobile_hele_dynamisk,
    header_dropdown_mobile_hele_dynamisk_none
};
