/* ****************************************** */
/* Editor kode */
/* ****************************************** */

const editor_kode_align_row                 = "row";
const editor_kode_align_col                 = "col";
const editor_kode_term_selected_terminal    = "terminal";
const editor_kode_term_selected_frontend    = "frontend";
const editor_kode_html_prefix               = "data:text/html;charset=utf-8,"; // Alt (data): text/javascript
const editor_kode_script_tag_start          = "<script>";
const editor_kode_script_tag_end            = "</script>";
const editor_kode_style_tag_start           = "<style>";
const editor_kode_style_tag_end             = "</style>";
const editor_kode_iframe_title              = "editor_kode_iframe_title";
const editor_kode_mode_default              = "default";
const editor_kode_mode_python               = "python";
const editor_kode_mode_javascript           = "javascript";
const editor_kode_mode_html                 = "html";
const editor_kode_mode_css                  = "css";
const editor_kode_theme_prog_default        = "prog_default";
const editor_kode_theme_prog_python         = "prog_python";
const editor_kode_theme_prog_javascript     = "prog_javascript";
const editor_kode_theme_prog_html           = "prog_html";
const editor_kode_theme_prog_css            = "prog_css";
const editor_kode_theme_prog_json           = "prog_json";
const editor_kode_term_last_typ_inp         = "_term";
const editor_kode_term_last_typ_run         = "_run";
const editor_kode_term_prompt               = "$"
const editor_kode_cmd_clear                 = "clear";
const editor_kode_caller_user               = "_user";
const editor_kode_caller_api_norm           = "_api_norm";
const editor_kode_caller_api_inp            = "_api_inp";
const editor_kode_caller_api_cmd            = "_api_cmd";
const _editor_kode_fetch_typ_build          = "_build";
const _editor_kode_fetch_typ_delete         = "_delete";
const _editor_kode_fetch_typ_cmd            = "_cmd";
const editor_kode_reset_typ_all             = "_all";
const editor_kode_reset_typ_inp             = "_inp";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    editor_kode_align_row,
    editor_kode_align_col,
    editor_kode_term_selected_terminal,
    editor_kode_term_selected_frontend,
    editor_kode_html_prefix,
    editor_kode_script_tag_start,
    editor_kode_script_tag_end,
    editor_kode_style_tag_start,
    editor_kode_style_tag_end,
    editor_kode_iframe_title,
    editor_kode_mode_default,
    editor_kode_mode_python,
    editor_kode_mode_javascript,
    editor_kode_mode_html,
    editor_kode_mode_css,
    editor_kode_theme_prog_default,
    editor_kode_theme_prog_python,
    editor_kode_theme_prog_javascript,
    editor_kode_theme_prog_html,
    editor_kode_theme_prog_css,
    editor_kode_theme_prog_json,
    editor_kode_term_last_typ_inp,
    editor_kode_term_last_typ_run,
    editor_kode_term_prompt,
    editor_kode_cmd_clear,
    editor_kode_caller_user,
    editor_kode_caller_api_norm,
    editor_kode_caller_api_inp,
    editor_kode_caller_api_cmd,
    _editor_kode_fetch_typ_build,
    _editor_kode_fetch_typ_delete,
    _editor_kode_fetch_typ_cmd,
    editor_kode_reset_typ_all,
    editor_kode_reset_typ_inp
};
