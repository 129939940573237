import {
    redux_quest
} from "../../utils/konstanter/gen/_redux";

import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    quest_select:           "",
    quest_tittel_select:    "", // DEV_quest_tittel
    kom_inp:                "",
};

const questSelectSlice = createSlice({
    name:               redux_quest,
    initialState:       initialState,
    reducers: {

        // Quest select
        setQuestSelect(state, action) {

            // Select
            state.quest_select = action.payload;

        },
        setQuestTittelSelect(state, action) {

            // Select
            state.quest_tittel_select = action.payload;

        },

        // Kom inp
        setKomInpReset(state) {

            // Reset
            state.kom_inp = "";

        },
        setKomInp(state, action) {

            // Inp
            state.kom_inp = action.payload;

        }

    }

});


export const {

    // Quest select
    setQuestSelect,
    setQuestTittelSelect,

    // Kom inp
    setKomInpReset,
    setKomInp

} = questSelectSlice.actions;


export default questSelectSlice.reducer;
