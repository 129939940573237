/* ****************************************** */
/* Doc */
/* ****************************************** */

// Doc
const doc_branch_rem_per_level_indent       = 1;
const doc_branch_arrow_start_level          = 0;


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    doc_branch_rem_per_level_indent,
    doc_branch_arrow_start_level,
};


/* ****************************************** */
/* End */
/* ****************************************** */
