/* ****************************************** */
/* Profil */
/* ****************************************** */

// Profil set > Account
const profil_set_public_profil_item         = "item_public_profil";
const profil_set_account_item               = "item_account";
const profil_set_lang_item                  = "item_lang";
const profil_set_notifications_item         = "item_notifications";
const profil_set_email_item                 = "item_email";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    profil_set_public_profil_item,
    profil_set_account_item,
    profil_set_lang_item,
    profil_set_notifications_item,
    profil_set_email_item
};


/* ****************************************** */
/* End */
/* ****************************************** */
