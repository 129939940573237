import {
    redux_spill
} from "../../utils/konstanter/gen/_redux";
import {
    _sym_dot
} from "../../utils/konstanter/components/_sym";
import {
    inp_str_el_disp_typ_riktig,
    inp_str_el_disp_typ_feil,
    cell_txt_hidden
} from "../../utils/konstanter/pages/_spill";
import {
    del_navn_sym
} from "../../utils/utility/_util_string";
import {
    _contains
} from "../../utils/utility/_util_op";

import {createSlice} from "@reduxjs/toolkit";

const initialState = {

    /* ****************************************** */
    /* User */
    /* ****************************************** */

    // Slug
    doc_spill_slug:             "",
    is_restaring_spill:         false,

    // Scene
    scene:                      -10,

    // Level
    level_selected:             -10,
    level_filter:               -10,

    // Dato
    dato_filter:                "",

    // Poeng
    poeng_level:                0,

    // Tid
    tid_start:                  "",
    tid_zero:                   "0",
    tid_delta:                  "",
    tid_delta_arr:              [],
    tid_final:                  "",

    // Tries
    tries_current:              -10,

    // Spill list
    spill_list_item_arr:        [],
    fetch_spill_list_toggle:    false,
    fetch_spill_update_lock:    false,


    /* ****************************************** */
    /* Data spill */
    /* ****************************************** */

    // Debug
    _is_debug_fn:               false,
    _is_debug_control_flow:     false,
    _is_debug_poeng:            false,

    // Inc
    _level_inc:                 -10,
    _oppg_inc:                  -10,
    _del_inc:                   -10,
    _gitt_inc:                  -10,
    _instruks_inc:              -10,
    _uttrykk_inc:               -10,
    _steg_inc:                  -10,

    // Spill
    _level_arr:                 [],

    // Level
    _level_current:             {},
    _level_nr_current:          -10,

    // Oppg
    _oppg_arr:                  [],
    _oppg_done_arr:             [],
    _oppg_current:              {},
    _oppg_nr_current:           -10,
    _oppg_typ_current:          "",     // "del_uttrykk", "del_instruks"

    // Del
    _del_arr:                   [],
    _del_done_arr:              [],
    _del_current:               {},
    _del_nr_current:            -10,
    _del_navn_current:          "",

    // Gitt
    _gitt_current:              {},
    _gitt_nr_current:           -10,
    _gitt_str_current:          "",

    // Instruks
    _instruks_current:          {},
    _instruks_nr_current:       -10,
    _instruks_str_current:      "",

    // Uttrykk
    _uttrykk_current:           {},
    _uttrykk_nr_current:        -10,
    _uttrykk_str_current:       "",

    // Steg
    _steg_arr:                  [],
    _steg_current:              {},
    _fasit_str_current:         "",
    _fasit_val_float_current:   -10.0,
    _poeng_add_max:             -10,

    // Input
    _input_str:                 "",
    _input_str_arr:             [], // Søkeord > _input_str_arr_el_

};

const spillSlice = createSlice({
    name:                               redux_spill,
    initialState:                       initialState,
    reducers: {

        // Doc > Set spill slug
        setDocSpillSlug(state, action) {

            // Setter doc_spill_slug når klikker på doc-link (spill-linken)
            state.doc_spill_slug = action.payload;

            // Reset pga. redux husker route-endringer
            state.is_restaring_spill    = true;

        },

        // Scene
        setSceneReset(state) {

            // Level > Reset
            state.scene                     = 1;
            state.level_filter              = 1;    // Default
            state.fetch_spill_list_toggle   = !state.fetch_spill_list_toggle;

            // Reset pga. redux husker route-endringer
            if (state.is_restaring_spill === true) {

                // Kommer fra doc > route > Reset
                state._input_str_arr            = [];
                state._oppg_done_arr            = [];
                state._del_done_arr             = [];

                // Slå av reset > Executer kun hvis fra route igjen
                state.is_restaring_spill        = false;

            };

        },

        // Level
        setLevelReset(state, action) {

            // Note > Se README.txt > tries

            // Debug
            if (state._is_debug_fn === true) {
                console.log("setLevelReset");
            };

            // Init
            const _level_arr                = action.payload[0];
            const _spill_liste_item_arr     = action.payload[1];
            const _username                 = action.payload[2];
            let _is_tries_current           = false;
            let _tries_current_fetched      = -10; // Default


            /* ****************************************** */
            /* Level */
            /* ****************************************** */

            // Highscore-liste
            state._level_arr                = _level_arr;
            state.spill_list_item_arr       = _spill_liste_item_arr;

            // Inc
            state._level_inc                = 0;
            state._oppg_inc                 = 0;
            state._del_inc                  = 0;
            state._gitt_inc                 = 0;
            state._instruks_inc             = 0;
            state._uttrykk_inc              = 0;
            state._steg_inc                 = 0;


            /* ****************************************** */
            /* Tries */
            /* ****************************************** */

            // Iterer gjennom hele for å finne bruker tries (tries_current), og ikke get som egen field fra api_get_spill_list_arr()
            for (let i = 0; i < state.spill_list_item_arr.length; i++) {

                // Trenger kun en false i arr for å returnere false
                if (state.spill_list_item_arr[i].username_current === _username) {

                    // Set tries current tmp
                    _tries_current_fetched = state.spill_list_item_arr[i].tries;

                    // Set bool
                    _is_tries_current = true;

                };

            };

            // Eksisterer ikke > Første forsøk
            if (_is_tries_current === false)    { // console.log("Første forsøk!")
                state.tries_current = 0;};

            // Eksisterer > Videre forsøk
            if (_is_tries_current === true)     { // console.log("Videre forsøk!")
                state.tries_current = _tries_current_fetched;};

        },
        setLevelStart(state, action) {

            // Init
            const _level_selected           = action.payload;

            // Reset _level_arr > Trigger re-fetch > VAR PAUSE
            state._level_arr                = [];

            // Level > Start
            state.scene                     = 2;
            state.level_selected            = _level_selected;

            // SLETT
            state.poeng_level               = 0;


            state.tid_start                 = performance.now();
            state.tid_delta_arr             = [];
            state.fetch_spill_list_toggle   = !state.fetch_spill_list_toggle;

            // Hist
            state._input_str_arr            = [];
            state._oppg_done_arr            = [];
            state._del_done_arr             = [];

        },
        setLevelUpdateCurrentAll(state) {

            // Debug
            if (state._is_debug_fn === true) {
                console.log("setLevelUpdateCurrentAll");
            };

            // Current > Level
            state._level_current            = state._level_arr[state._level_inc];
            state._level_nr_current         = state._level_current.level_nr;

            // Current > Oppg
            state._oppg_arr                 = state._level_current.oppg_arr; //
            state._oppg_current             = state._level_current.oppg_arr[state._oppg_inc];
            state._oppg_nr_current          = state._oppg_current.oppg_nr;
            state._oppg_typ_current         = state._oppg_current.oppg_typ;

            // Current > Del
            state._del_arr                  = state._oppg_current.del_arr;
            state._del_current              = state._oppg_current.del_arr[state._del_inc];
            state._del_nr_current           = state._del_current.del_nr;
            state._del_navn_current         = del_navn_sym(state._del_nr_current - 1);

            // Current > Gitt
            state._gitt_current             = state._del_current.gitt_arr[state._gitt_inc];
            state._gitt_nr_current          = state._gitt_current.gitt_nr;
            state._gitt_str_current         = state._gitt_current.gitt_str;

            // Current > Instruks
            state._instruks_current         = state._del_current.instruks_arr[state._instruks_inc];
            state._instruks_nr_current      = state._instruks_current.instruks_nr;
            state._instruks_str_current     = state._instruks_current.instruks_str;

            // Current > Uttrykk
            state._uttrykk_current          = state._del_current.uttrykk_arr[state._uttrykk_inc];
            state._uttrykk_nr_current       = state._uttrykk_current.uttrykk_nr;
            state._uttrykk_str_current      = state._uttrykk_current.uttrykk_str;

            // Current > Steg > Fasit / Poeng add
            state._steg_arr                 = state._del_current.steg_arr;
            state._steg_current             = state._del_current.steg_arr[state._steg_inc];
            state._fasit_str_current        = state._steg_current.fasit_str;
            state._fasit_val_float_current  = state._steg_current.fasit_val_float;
            state._poeng_add_max            = state._steg_current.poeng_add_int;

        },
        setLevelInputStr(state, action) {

            // DEV > Lag state._input_val fra extern funk som regner ut denne

            // Init
            const _input_str_   = action.payload;

            // Set uttrykk f.eks, txt lik "4 * 4"
            state._input_str    = _input_str_;

        },
        setLevelInputStrElHide(state, action) {

            // Init
            const _ix       = action.payload;

            // Iterate for valgt index
            for (let i = 0; i < state._input_str_arr.length; i++) {

                // Gjeldende el
                if (i === _ix) {

                    // Tmp el
                    const _input_str_arr_el_ = {
                        "oppg_inc":     state._input_str_arr[i].oppg_inc,
                        "del_inc":      state._input_str_arr[i].del_inc,
                        "input_str":    state._input_str_arr[i].input_str,
                        "disp_typ":     state._input_str_arr[i].disp_typ,
                        "show":         false,
                        "deleted":      state._input_str_arr[i].deleted,
                        "poeng":        state._input_str_arr[i].poeng,
                        "tid":          state._input_str_arr[i].tid
                    };

                    // Update el
                    state._input_str_arr[i] = _input_str_arr_el_;

                };

            };

        },
        setLevelInputStrElShowAll(state) {

            // Iterate for alle el > Show
            for (let i = 0; i < state._input_str_arr.length; i++) {

                // Tmp el
                const _input_str_arr_el_ = {
                    "oppg_inc":     state._input_str_arr[i].oppg_inc,
                    "del_inc":      state._input_str_arr[i].del_inc,
                    "input_str":    state._input_str_arr[i].input_str,
                    "disp_typ":     state._input_str_arr[i].disp_typ,
                    "show":         true,
                    "deleted":      state._input_str_arr[i].deleted,
                    "poeng":        state._input_str_arr[i].poeng,
                    "tid":          state._input_str_arr[i].tid
                };

                // Update el
                state._input_str_arr[i] = _input_str_arr_el_;

            };

        },
        setLevelInputStrElDelete(state, action) {

            // Init
            const _ix       = action.payload;

            // Iterate for valgt index
            for (let i = 0; i < state._input_str_arr.length; i++) {

                // Gjeldende el
                if (i === _ix) {

                    // Tmp el
                    const _input_str_arr_el_ = {
                        "oppg_inc":     state._input_str_arr[i].oppg_inc,
                        "del_inc":      state._input_str_arr[i].del_inc,
                        "input_str":    state._input_str_arr[i].input_str,
                        "disp_typ":     state._input_str_arr[i].disp_typ,
                        "show":         false,
                        "deleted":      true,
                        "poeng":        state._input_str_arr[i].poeng,
                        "tid":          state._input_str_arr[i].tid
                    };

                    // Update el
                    state._input_str_arr[i] = _input_str_arr_el_;

                };

            };

        },
        setLevelInputStrElDeleteAll(state) {

            console.log("YO ::")

            // Iterate for alle
            for (let i = 0; i < state._input_str_arr.length; i++) {

                // Tmp el
                const _input_str_arr_el_ = {
                    "oppg_inc":     state._input_str_arr[i].oppg_inc,
                    "del_inc":      state._input_str_arr[i].del_inc,
                    "input_str":    state._input_str_arr[i].input_str,
                    "disp_typ":     state._input_str_arr[i].disp_typ,
                    "show":         state._input_str_arr[i].show,
                    "deleted":      true,
                    "poeng":        state._input_str_arr[i].poeng,
                    "tid":          state._input_str_arr[i].tid
                };

                // Update el
                state._input_str_arr[i] = _input_str_arr_el_;

            };

        },
        setLevelEval(state) {

            // Debug
            if (state._is_debug_fn === true) {
                console.log("setLevelEval");
            };

            // Init
            const _ja                   = "_ja";
            const _nei                  = "_nei";
            const _feil                 = "feil";
            const _riktig               = "riktig";
            let _riktig_eval            = "";
            let _riktig_disp_typ        = "";
            let _steg_ferdig            = "";
            let _del_ferdig             = "";
            let _oppg_ferdig            = "";
            let _level_ferdig           = "";
            let _poeng_add              = -10;
            let _tid_diff               = -10;
            let _tid_diff_avrundet      = -10;
            let _show                   = false;
            let _input_str_arr_el_      = {};
            let oppg_inc                = cell_txt_hidden;
            let del_inc                 = cell_txt_hidden;


            /* ****************************************** */
            /* Tid per steg */
            /* ****************************************** */

            /*
            - Note
                - Tid delta regnes ut slik > performance.now() - state.tid_start;
                - Tid diff er differansen mellom to delta-verdier
            */

            // Delta el nr. 1 > Er tid_delta direkte
            if (state.tid_delta_arr.length === 0) {

                // Set tid_diff direkte
                _tid_diff               = parseFloat(state.tid_delta);

            };

            // Delta el større enn nr. 1 > Regn ut diff
            if (state.tid_delta_arr.length > 0) {

                // Siste index
                const _ix_t             = state.tid_delta_arr.length - 1;

                // Delta 1 og 2
                const _tid_delta_2      = parseFloat(state.tid_delta);
                const _tid_delta_1      = parseFloat(state.tid_delta_arr[_ix_t]);

                // Regn ut diff
                _tid_diff               = parseFloat(_tid_delta_2 - _tid_delta_1);

            };

            // Rund av tid diff til 2 desimaler
            _tid_diff_avrundet              = String(_tid_diff.toFixed(2)); // Debug > String(4.21)

            /* Inneholder "." */
            if (_contains(_tid_diff_avrundet, _sym_dot)) {

                // Init desimal
                const _desimal              = _tid_diff_avrundet.split(_sym_dot)[1];

                /* En desimal */
                if (_desimal.length === 1) {

                    // F.eks. 1.2 -> 1.20
                    const _desimal_zero_1   = `${_sym_dot}0`;
                    _tid_diff_avrundet      += _desimal_zero_1;

                }

                /* To desimaler, f.eks. 1.34 > Bypass (slik det skal være) */
                else { };}

            /* Inneholder ikke "." */
            else {

                // F.eks. 1 -> 1.00
                const _desimal_zero_2        = `${_sym_dot}00`;
                _tid_diff_avrundet          += _desimal_zero_2;

            };

            // Push tid_delta til arr for neste _tid_diff
            state.tid_delta_arr.push(parseFloat(state.tid_delta));



            /* ****************************************** */
            /* Poeng calc */
            /* ****************************************** */

            // Tid - _poeng_add_max - state.poeng_level
            const _poeng_calc               = state._poeng_add_max / _tid_diff;
            const _poeng_avrundet           = parseFloat(_poeng_calc.toFixed(0));

            // Debug
            if (state._is_debug_poeng === true) {
                console.log("");
                console.log("***");
                console.log("tid_delta       :: ", state.tid_delta);
                console.log("tid_delta_arr   :: ", state.tid_delta_arr);
                console.log("_tid diff       :: ", _tid_diff_avrundet); // _tid_diff
                console.log("poeng_level     :: ", state.poeng_level);
                console.log("_poeng_calc     :: ", _poeng_calc);
                console.log("_poeng_avrundet :: ", _poeng_avrundet);
            };


            /* ****************************************** */
            /* Push oppg / del inc til done arr */
            /* ****************************************** */

            // Push oppg inc til arr hvis ikke ekisterer > Slik at ikke oppg inc vises på hver line i hist
            if (!_contains(state._oppg_done_arr, state._oppg_inc)) {

                // Push
                state._oppg_done_arr.push(state._oppg_inc);

                // Update current
                oppg_inc = state._oppg_inc;

            };

            // Push oppg inc til arr hvis ikke ekisterer > Slik at ikke del inc vises på hver line i hist
            if (!_contains(state._del_done_arr, state._del_inc)) {

                // Push
                state._del_done_arr.push(state._del_inc);

                // Update current
                del_inc = state._del_inc;

            };


            /* ****************************************** */
            /* Riktig / Feil */
            /* ****************************************** */

            /* Riktig */
            if (state._input_str === state._fasit_str_current) { // console.log("Riktig!");

                // DEV
                // - Legacy > Før denne var type cast fra str -> float
                // - Lag cleanup og fjern string-el som sniker seg inn i arr
                //   "x = 3 + 2" og ikke {input_str: "x = 3 + 2"}

                _riktig_eval        = _riktig;

                // Disp typ til el > Riktig
                _riktig_disp_typ    = inp_str_el_disp_typ_riktig;

                // Poeng add > El
                _poeng_add          = _poeng_avrundet;

                // Show > True
                _show               = true;

                // Poeng add
                state.poeng_level   += _poeng_add;}

            /* Feil > 0 poeng > Bypass */
            else {

                _riktig_eval        = _feil;

                // Poeng add > El
                _poeng_add          = 0;

                // Show > False
                _show               = false;

                // Disp typ til el > Feil
                _riktig_disp_typ    = inp_str_el_disp_typ_feil;

            };

            // Inp str dic
            _input_str_arr_el_ = {
                "oppg_inc":     oppg_inc,
                "del_inc":      del_inc,
                "input_str":    state._input_str,
                "disp_typ":     _riktig_disp_typ,
                "show":         _show,
                "deleted":      false,
                "poeng":        _poeng_add,
                "tid":          _tid_diff_avrundet
            };

            /* Poeng > Debug */
            if (state._is_debug_control_flow === true) {
                console.log("_riktig_eval :: ", _riktig_eval)
            };


            /* ****************************************** */
            /* Reset input str */
            /* ****************************************** */

            // Push til arr
            state._input_str_arr.push(_input_str_arr_el_);

            // Reset input str
            state._input_str    = "";


            /* ****************************************** */
            /* Steg eval */
            /* ****************************************** */

            /* Steg riktig > Riktig / feil  */
            if (_riktig_eval === _riktig) {

                /* Steg ferdig > Nei > 0 vs. ["a"] > 0 vs. ["a", "b"] */
                if (state._steg_inc < state._steg_arr.length - 1) {

                    _steg_ferdig        = _nei;
                    state._steg_inc     += 1;

                }

                /* Steg ferdi > Ja */
                else {

                    _steg_ferdig        = _ja;
                    state._steg_inc     = 0;

                };

                /* Del > Debug */
                if (state._is_debug_control_flow === true) {
                    console.log("_steg_ferdig :: ", _steg_ferdig)
                };

            };


            /* ****************************************** */
            /* Del eval */
            /* ****************************************** */

            /* Steg ferdig > Ja > Del ferdig > Ja / nei  */
            if (_steg_ferdig === _ja) {

                /* Del ferdig > Nei */
                if (state._del_inc < state._del_arr.length - 1) {

                    _del_ferdig             = _nei;
                    state._del_inc          += 1;
                    state._gitt_inc         = 0;
                    state._instruks_inc     = 0;
                    state._uttrykk_inc      = 0; // DEV > Avhenig av oppg-type

                }

                /* Del ferdig > Ja */
                else {

                    _del_ferdig             = _ja;
                    state._del_inc          = 0;
                    state._gitt_inc         = 0;
                    state._instruks_inc     = 0;
                    state._uttrykk_inc      = 0; // DEV > Avhenig av oppg-type

                };

                /* Del > Debug */
                if (state._is_debug_control_flow === true) {
                    console.log("_del_ferdig :: ", _del_ferdig)
                };

                // Hide for hver del-oppgave HIterate for alle el > Show > False
                for (let i = 0; i < state._input_str_arr.length; i++) {

                    // Tmp el
                    const _input_str_arr_el_ = {
                        "oppg_inc":     state._input_str_arr[i].oppg_inc,
                        "del_inc":      state._input_str_arr[i].del_inc,
                        "input_str":    state._input_str_arr[i].input_str,
                        "disp_typ":     state._input_str_arr[i].disp_typ,
                        "show":         false,
                        "deleted":      state._input_str_arr[i].deleted,
                        "poeng":        state._input_str_arr[i].poeng,
                        "tid":          state._input_str_arr[i].tid
                    };

                    // Update el
                    state._input_str_arr[i] = _input_str_arr_el_;

                };

            };


            /* ****************************************** */
            /* Oppg eval */
            /* ****************************************** */

            /* Del ferdig > Oppg ferdig > Ja / Nei */
            if (_del_ferdig === _ja) {

                /* Reset _del_done_arr */
                state._del_done_arr     = [];

                /* Oppg ferdig > Nei */
                if (state._oppg_inc < state._oppg_arr.length - 1) {

                    _oppg_ferdig        = _nei;
                    state._oppg_inc     += 1;}

                /* Oppg ferdig > Ja */
                else {

                    _oppg_ferdig        = _ja;
                    state._oppg_inc     = 0;

                };

                /* Oppg > Debug */
                if (state._is_debug_control_flow === true) {
                    console.log("_oppg_ferdig :: ", _oppg_ferdig)
                };

            };


            /* ****************************************** */
            /* Level eval */
            /* ****************************************** */

            /* Oppg ferdig > Ja > Level ferdig > Ja / Nei */
            if (_oppg_ferdig === _ja) {

                /* Oppg ferdig > Nei */
                if (state._level_inc < state._level_arr.length - 1) {

                    _level_ferdig       = _nei;
                    state._level_inc    += 1;}

                /* Oppg ferdig > Ja */
                else {

                    _level_ferdig       = _ja;
                    state._level_inc    = 0;

                };

                /* Oppg > Debug */
                if (state._is_debug_control_flow === true) {
                    console.log("_level_ferdig :: ", _level_ferdig)
                };

            };


            /* ****************************************** */
            /* Level done */
            /* ****************************************** */

            if (_level_ferdig === _ja) {

                // Scene
                state.scene                     = 3;
                state.tid_final                 = state.tid_delta;

                // Spill update
                state.fetch_spill_update_lock   = true;

            };

        },

        // Dato
        setDatoFilter(state, action) {

            // DEV > Dato -> DEV ikke ekportert enda

            // Set dato filter
            state.dato_filter = action.payload;

        },

        // Poeng
        setPoengLevel(state, action) {

            // DEV > Forklaring > av hva som skjer
            state.poeng_level = action.payload;

        },

        // Tid
        setTidDelta(state) {

            // Brukes i _Spill.js > setInterval(_tid, 1)

            // Tid > Nå
            let _val            = performance.now() - state.tid_start;

            // Skift slik at 10- og 100-dels sek er bak desimalmerket
            _val                = _val / 1000;

            // Rund av til kun 10- og 100-deler
            const _result       = _val.toFixed(2);

            // Set tid delta
            state.tid_delta     = _result;

        },

        // Spill list
        setSpillListItemArr(state, action) {

            // Highscore-liste
            state.spill_list_item_arr = action.payload;

        },
        setFetchSpillListToggle(state) {

            state.fetch_spill_list_toggle = !state.fetch_spill_list_toggle

        },
        setFetchSpillUpdateLock(state, action) {

            // Init
            const _bl = action.payload;

            // Set lock
            state.fetch_spill_update_lock = _bl;

            // Reset _level_arr (er en lock når =[]) slik at ny, fetched level data vises i highscore
            state._level_arr                = [];

            // Toggle user list (highscore) for ny fetch
            state._set_fetch_user_list_toggle = !state._set_fetch_user_list_toggle;

        },

    }

});


export const {

    // Spill slug
    setDocSpillSlug,

    // Scene
    setSceneReset,

    // Level
    setLevelReset,
    setLevelStart,
    setLevelUpdateCurrentAll,
    setLevelInputStr,
    setLevelInputStrElHide,
    setLevelInputStrElShowAll,
    setLevelInputStrElDelete,
    setLevelInputStrElDeleteAll,
    setLevelEval,

    // Poeng
    setPoengLevel,

    // Tid
    setTidDelta,

    // Spill list
    setSpillListItemArr,
    setFetchSpillListToggle,
    setFetchSpillUpdateLock,

} = spillSlice.actions;


export default spillSlice.reducer;
