/* ****************************************** */
/* Reg */
/* ****************************************** */

// I
const signup_tittel                         = "_signup_tittel ";
const signup_success_tittel                 = "_signup_success_tittel ";
const signup_allerede_reg                   = "_signup_allerede_reg";
const signin_tittel                         = "_signin_tittel";
const signin_ny_prog                        = "_signin_ny_prog";

// II
const signin_glemt_pass                     = "_signin_glemt_pass";
const signin_glemt_pass_tittel              = "_signin_glemt_pass_tittel";
const signin_glemt_pass_confirm             = "_signin_glemt_pass_confirm";         // Alternativ: "Reset-epost sendt"
const signin_glemt_pass_confirm_btn         = "_signin_glemt_pass_confirm_btn";     // Velg ord: resett / tilbakestill
const signin_glemt_pass_sett_nytt           = "_signin_glemt_pass_sett_nytt";
const signin_glemt_pass_sett_nytt_fra_epost = "_signin_glemt_pass_sett_nytt_fra_epost";
const signout_tittel                        = "_signout_tittel";

// Reg > Sign
const reg_ugyldig                           = "_reg_ugyldig";

// Reg > Username
const username_tittel                       = "_username_tittel";
const username_sub_ikke_gyldig              = "_username_sub_ikke_gyldig_txt";      // "Minst 6 tegn, ingen spesialtegn"
const username_sub_ikke_gyldig_opptatt      = "_username_sub_ugyldig_opptatt";
const username_sub_gyldig                   = "_username_sub_gyldig";
const username_sub_gyldig_max               = "_username_sub_gyldig_max";

// Epost
const email_tittel                          = "_email_tittel";
const email_sub_ugyldig                     = "_email_sub_ugyldig";
const email_sub_gyldig                      = "_email_sub_gyldig";                  // const email_txt_sub_gyldig_max = "Email er gyldig (max lengde)" // "Gyldig";
const email_sub_gyldig_max                  = "_email_sub_gyldig_max";
const reg_email_send_user_confirm_developer_txt     = "_reg_email_send_user_confirm_developer_txt";
const reg_email_send_glemt_passord_developer_txt    = "_reg_email_send_glemt_passord_developer_txt";

// Reg > Password > I
const password_tittel                       = "_password_tittel";
const password_sub_gyldig                   = "_password_sub_gyldig";
const password_sub_ikke_gyldig              = "_password_sub_ikke_gyldig";          // "Minst 6 tegn"
const password_svakt                        = "_password_svakt";

// Reg > Password > II
const password_middels                      = "_password_middels";
const password_sterkt                       = "_password_sterkt";
const password_max_lengde                   = "_password_max_lengde";
const password_for_langt                    = "_password_for_langt";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    signup_tittel,
    signup_success_tittel,
    signup_allerede_reg,
    signin_tittel,
    signin_ny_prog,

    signin_glemt_pass,
    signin_glemt_pass_tittel,
    signin_glemt_pass_confirm,
    signin_glemt_pass_confirm_btn,
    signin_glemt_pass_sett_nytt,
    signin_glemt_pass_sett_nytt_fra_epost,
    signout_tittel,

    reg_ugyldig,

    username_tittel,
    username_sub_ikke_gyldig,
    username_sub_ikke_gyldig_opptatt,
    username_sub_gyldig,
    username_sub_gyldig_max,

    email_tittel,
    email_sub_ugyldig,
    email_sub_gyldig,
    email_sub_gyldig_max,
    reg_email_send_user_confirm_developer_txt,
    reg_email_send_glemt_passord_developer_txt,

    password_tittel,
    password_sub_gyldig,
    password_sub_ikke_gyldig,
    password_svakt,

    password_middels,
    password_sterkt,
    password_max_lengde,
    password_for_langt
};


/* ****************************************** */
/* End */
/* ****************************************** */
