import {createSlice} from "@reduxjs/toolkit";

import {
    header_dropdown_mobile_hele_fixed
} from "../../utils/konstanter/components/_header";
import {
    redux_header
} from "../../utils/konstanter/gen/_redux";


const initialState = {
    header_is_visible:                  true,
    header_yy:                          0,
    header_yy_init_val:                 0,
    header_xx:                          0,
    win_typ:                            "",

    header_dropdown_mobile_hele_r:      header_dropdown_mobile_hele_fixed
};

const headerSlice = createSlice({
    name:                               redux_header,
    initialState:                       initialState,
    reducers: {

        // Visible
        setIsHeaderVisible(state, action) {

            // Is visible
            state.header_is_visible = action.payload;

        },

        // Header YY
        setHeaderYY(state, action) {

            // Header yy
            state.header_yy = action.payload;

        },
        setHeaderYYInitVal(state, action) {

            // Header yy init val
            state.header_yy_init_val = action.payload;

        },
        setHeaderYYReset(state) {

            // YY > Resetter til YY init-verdi
            state.header_yy = state.header_yy_init_val;

        },

        // Win typ
        setWinTyp(state, action) {

            // Set win typ
            state.win_typ = action.payload

        },

        // CSS Clas > redux
        setHeaderDropdownMobileHeleR(state, action) {

            // Init
            const _css = action.payload;

            // Set
            state.header_dropdown_mobile_hele_r = _css;

        }



    }
});


export const {

    // Visible
    setIsHeaderVisible,

    // Header YY
    setHeaderYY,
    setHeaderYYInitVal,
    setHeaderYYReset,

    // Win typ
    setWinTyp,

    // CSS class
    setHeaderDropdownMobileHeleR

} = headerSlice.actions;


export default headerSlice.reducer;
