/* ****************************************** */
/* HTTP */
/* ****************************************** */

// Method
const _http_method_post                     = "POST";
const _http_method_get                      = "GET";
const _http_method_put                      = "PUT";
const _http_method_delete                   = "DELETE";

// Response
const _http_response_ok                     = "_http_response_ok";
const _http_response_fail                   = "_http_response_fail";

// Content-Type
const http_headers_content_type             = {
    "Content-Type": "application/json"
};

// Status code
const http_status_code_200                  = 200;
const http_status_code_400                  = 400;
const http_status_code_401                  = 401;
const http_status_code_404                  = 404;
const http_status_code_405                  = 405;


/* ****************************************** */
/* Export */
/* ****************************************** */

export {

    _http_method_post,
    _http_method_get,
    _http_method_put,
    _http_method_delete,

    _http_response_ok,
    _http_response_fail,

    http_headers_content_type,

    http_status_code_200,
    http_status_code_400,
    http_status_code_401,
    http_status_code_404,
    http_status_code_405

};


/* ****************************************** */
/* End */
/* ****************************************** */