/* ****************************************** */
/* Layout */
/* ****************************************** */

// Unit
const lay_unit_px_per_rem                   = 16;           // px

// Window > Limits > Søkeord CSS_Media
const lay_window_width_limit_xx_1           = 701;          // px > Søkeord
const lay_window_width_limit_xx_2           = 530;          // px > Søkeord

// Header
const header_desktop_par_extra_marg         = 0.25;         // rem
const header_banner_yy                      = 4 * 16;       // 6 > Debug (ekstra linje under meny), 5 > Eksakt
const header_gap_min                        = 0;
const header_gap_max                        = 32;

// Path
const lay_path_yp_init                      = lay_unit_px_per_rem * 4;              // rem
const lay_path_yy_init                      = lay_unit_px_per_rem * 2 + 1 * 2;      // px, 2 rem (standard yy) + 1px border * 2 (oppe og nede)

// Dropdown
const dropdown_gap_yy                       = lay_unit_px_per_rem / 2;      // 0.5rem

// Editor kode
const editor_data_nav_yy                    = 36;           // px
const editor_kode_xx_min                    = 36 * 2;       // px, 42 > DEV > Virker denne?
const editor_term_xx_min                    = 36 * 1;       // px, x2, 70 > DEV > Virker denne?
const editor_kode_hele_yy_min               = 36 * 6;       // px, liten: 2, stor: 4
const editor_kode_main_yy_min               = 36 * 6;       // px, i praksis blir det yy til home / create-new file view
const editor_kode_term_main_yy_min          = 36 * 4;       // px, liten: 2, stor: 4
const editor_default_ratio_file_xx          = 2;            // Develop > Big kode_xx: 2, Big fil_xx: 5, Prod: 2, 1 -> legacy: [1, 5] for _align_col
const editor_default_ratio_kode_xx          = 5;            // Develop > Big kode_xx: 5, Big fil_xx: 2, Prod: 5, 3
const editor_default_ratio_term_xx          = 5;            // Develop > Big kode_xx: 5, Big fil_xx: 2, Prod: 5, 2
const editor_kode_px_per_line               = 16;           // 16 px per line med fontsize = 12,
const editor_kode_font_diff_yy_init         = 16 * 2;       // px, For kode_main, må legge til en ekstra yy til kode-ruten når skifter mellom col / row, DEV > 16 * 1 er den perfekte verdien, men lagger på første key down og gjør det styggere > Dette kan sikkert tweakes til med enda flere bools, men droppes nå pga. tid og økt code smell
const editor_kode_font_diff_yy_disabled     = -10;          // num_kode (ikke px), For kode_main
const editor_kode_term_font_yy_diff_val     = 16 * 3;       // px, Må legge til en ekstra yy til term-ruten når skifter mellom col / row (primært når term_yy > kode_yy i row-mode), Ser perfekt ut, trengs ikke tweak nå > Økes kanskje også med denne når term_yy < kode_yy, men er isåfall ikke spesielt merkbart
const editor_kode_col_final_add_yy          = 32;           // Magic number > Legges til _col_yy_max helt til slutt > Kan kanskje refackes inn på et bedre sted, men fungerer supert nå, og er så mange vildcard-parametre i mixen som f.eks. laggende key down osv.
const editor_kode_col_fil_final_add_yy      = 16;           // Magic numer > Legges til i "_fil() JSX"-rute
const editor_kode_get_yy_mode               = 3;            // 0: av, 1: RT, 2: async, 3: aync custom
const editor_kode_get_yy_trig_render_cnt    = 10;           // 20, Gjør at get_yy_max() executes X antall ganger slik at alle JSX rendres riktig

// Fil
const fil_icon_xp                           = 20;

// Progress bar
const progress_bar_yy                       = 2;    // px
const progress_bar_yp                       = 1;    // rem
const progress_bar_border_radius            = 50;   // unitless
const progress_bar_fill_border_radius       = 0.3;  // rem
const progress_bar_lbl_yp                   = -2;   // px

// Textarea
const textarea_min_height                   = 2.25;     // rem, DEV: Sjekk xy med css root
const textarea_padding                      = 0.5;      // rem
const textarea_padding_btm                  = 0.75;     // rem
const textarea_auto_resize_yy               = 2;        // rem

// Spill
const lay_xy_matte_strek                    = 2; // px
const spill_expr_lbl_yy                     = 20.0; // px, Default egentlig 18, men 20 pga curser residual

// xy
const lay_xx                                = "xx";
const lay_xp                                = "xp";
const lay_yy                                = "yy";
const lay_yp                                = "yp";

// Calc yy
const lay_dropdown_clicked                  = "lay_dropdown_clicked";
const lay_dropdown_par_clicked              = "lay_dropdown_par_clicked";
const lay_standard_reset                    = "lay_standard_reset";
const lay_path_update                       = "lay_path_update";

// Window
const lay_window_resize                     = "resize";
const lay_window_device_view_mobile_1       = "mobile_1";
const lay_window_device_view_mobile_2       = "mobile_2";
const lay_window_device_view_desktop        = "desktop";
const lay_window_device_view_no_device      = "no_device";

// Scroll
const scroll_item                           = "scroll";
const scroll_smooth                         = "smooth";

/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    lay_unit_px_per_rem,

    lay_window_width_limit_xx_1,
    lay_window_width_limit_xx_2,

    header_desktop_par_extra_marg,
    header_banner_yy,
    header_gap_min,
    header_gap_max,

    lay_path_yp_init,
    lay_path_yy_init,

    dropdown_gap_yy,

    editor_data_nav_yy,
    editor_kode_xx_min,
    editor_term_xx_min,
    editor_kode_hele_yy_min,
    editor_kode_main_yy_min,
    editor_kode_term_main_yy_min,
    editor_default_ratio_file_xx,
    editor_default_ratio_kode_xx,
    editor_default_ratio_term_xx,
    editor_kode_px_per_line,
    editor_kode_font_diff_yy_init,
    editor_kode_font_diff_yy_disabled,
    editor_kode_term_font_yy_diff_val,
    editor_kode_col_final_add_yy,
    editor_kode_col_fil_final_add_yy,
    editor_kode_get_yy_mode,
    editor_kode_get_yy_trig_render_cnt,

    fil_icon_xp,

    progress_bar_yy,
    progress_bar_yp,
    progress_bar_border_radius,
    progress_bar_fill_border_radius,
    progress_bar_lbl_yp,

    textarea_min_height,
    textarea_padding,
    textarea_padding_btm,
    textarea_auto_resize_yy,

    lay_xy_matte_strek,
    spill_expr_lbl_yy,  

    lay_xx,
    lay_xp,
    lay_yy,
    lay_yp,

    lay_dropdown_clicked,
    lay_dropdown_par_clicked,
    lay_standard_reset,
    lay_path_update,

    lay_window_resize,
    lay_window_device_view_mobile_1,
    lay_window_device_view_mobile_2,
    lay_window_device_view_desktop,
    lay_window_device_view_no_device,

    scroll_item,
    scroll_smooth
};


/* ****************************************** */
/* End */
/* ****************************************** */