/* ****************************************** */
/* Spill */
/* ****************************************** */

// Spill
const txt_about_eng                         = `
  programmering.no is made by Matematikk AS.
  Matematikk AS offers private lessons in programmering and matemathematics.
  Please contact us at kontakt@matematikk.as for a free trial lesson.
`;
const txt_about_no                          = `
    programmering.no er laget av Matematikk AS.
    Matematikk AS driver bl.a. med privatundervisning i programmering og matematikk.
    Ta kontakt på kontakt@matematikk.as for en gratis prøvetime.
`;


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    txt_about_eng,
    txt_about_no
};


/* ****************************************** */
/* End */
/* ****************************************** */
