import {
    is_icon_grp
} from "../../../utils/utility/_util_group";

// DEV > Ikke switch på cls, lag heller typ > Se textarea_auto_resize.js

const Icon = (props) => {

    // Return
    return (
        <>

        {/* Ikke debug-cls > Standard > Med icon-size */}
        {!props.cls &&
            <>

            {/* PRØV > Ikke icon_wrapper > STANDARD > on_click() */}
            {!props.icon_wrapper &&
            props.on_click &&
                <div
                    className   = {is_icon_grp(
                        props.icon_cls,
                        props.icon_size)}
                    id          = {props.id}
                    onClick     = {() => props.on_click()}>
                </div>}

            {/* PRØV Ikke icon_wrapper > STANDARD > uten on_click() */}
            {!props.icon_wrapper &&
            !props.on_click &&
                <div
                    className   = {is_icon_grp(
                        props.icon_cls,
                        props.icon_size)}
                    id          = {props.id}>
                </div>}

            {/* Med icon_wrapper > Når f.eks. icon er inline > F.eks. Quest > Kommentar > CRUD-ikoner */}
            {props.icon_wrapper &&
                <div
                    className={props.icon_wrapper}>
                    <div
                        className   = {is_icon_grp(
                            props.icon_cls,
                            props.icon_size)}
                        id          = {props.id}>
                    </div>
                </div>}
            </>}

        {/* Med debug-cls > Debugging > For raskt å se ikonet */}
        {props.cls &&
            <div  
                className   = {props.cls}
                onClick     = {() => props.on_click()}>
            </div>}
        </>
    )

};

export default Icon;
