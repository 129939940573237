/* ****************************************** */
/* Human lang */
/* ****************************************** */

// Init
const human_lang_init                       = "human_lang_init";

// Valg
const _eng                                  = "_eng";
const _no                                   = "_no";

// Tittel
const eng_tittel                            = "_eng_tittel";
const no_tittel                             = "_no_tittel";

// Txt > Human lang
const _txt_human_lang_tittel                = "Human lang"; // Ubrukt > Vises foreløpig ikke
const _txt_human_lang_typ                   = "human_lang";

// Arr
const txt_human_lang_typ_arr                = [
    {
        typ:        _txt_human_lang_typ,
        tittel:     _txt_human_lang_tittel
    }
];
const txt_human_lang_arr                    = [
    {
        slug:       _no,
        tittel:     "Norsk",
        typ:        _txt_human_lang_typ
    },
    {
        slug:       _eng,
        tittel:     "Engelsk",
        typ:        _txt_human_lang_typ
    },
];

// Txt > Lengths
const txt_len_max_countdown                 = 5;
const txt_len_max_maximum                   = 10000;
const txt_len_info_standard_medium          = 100;
const txt_len_username_max                  = 14;   // Er også hardkodet i reg > txt_regex_username (ikke nødvendig, stoppes JSX-elementet)
const txt_len_email_max                     = 254;  // 254;  // RFC 5321-standard (https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address), ikke... hardkodet i email-regex (se over)
const txt_len_password_min                  = 6;
const txt_len_password_max                  = 20;
const txt_len_url_max                       = 500;
const txt_len_user_profil_bio_max           = 500;
const txt_len_quest_create_tittel_max       = 100;
const txt_len_quest_create_tittel_max_cntd  = 10;   // Eksempel
const txt_len_kom_create_max                = 200;

// Txt > Regex
const txt_regex_username                    = /^[a-z][^\W_]{0,14}$/i; // Alternativ: /^[a-zA-Z\-]+$/;
const txt_regex_email                       = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const txt_regex_url_clean                   = /([^:])(\/\/+)/g;


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    human_lang_init,
    
    _eng,
    _no,

    eng_tittel,
    no_tittel,

    txt_human_lang_typ_arr,
    txt_human_lang_arr,

    txt_len_max_countdown,
    txt_len_max_maximum,
    txt_len_info_standard_medium,
    txt_len_username_max,
    txt_len_email_max,
    txt_len_password_min,
    txt_len_password_max,
    txt_len_url_max,
    txt_len_user_profil_bio_max,
    txt_len_quest_create_tittel_max,
    txt_len_quest_create_tittel_max_cntd,
    txt_len_kom_create_max,

    txt_regex_username,
    txt_regex_email,
    txt_regex_url_clean
};


/* ****************************************** */
/* End */
/* ****************************************** */