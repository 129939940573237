import {
    txt_len_username_max,
    txt_len_password_min,
    txt_len_password_max,
    txt_regex_username
} from "../../utils/tekst/_param/param";
import {
    username_sub_ikke_gyldig,
    username_sub_ikke_gyldig_opptatt,
    username_sub_gyldig,
    username_sub_gyldig_max,
    password_sub_gyldig,
    password_sub_ikke_gyldig,
    password_svakt,
    password_middels,
    password_sterkt,
    password_max_lengde,
    password_for_langt
} from "../../utils/tekst/pages/_reg";


const change_username_get_info_cls_txt          = (is_username_opptatt, txt, len) => {

    // Init
    let _username_info_cls          = "";
    let _username_info_txt          = "";
    let _is_username_gyldig         = false;

    // Alignment hack > Bypass
    if (
        is_username_opptatt === false &&
        txt.match(txt_regex_username) &&
        len < txt_len_username_max) {
            _username_info_cls      = "txt_liten_green";
            _username_info_txt      = username_sub_gyldig; // ORI >> get_txt(username_sub_gyldig, "_noo");
            _is_username_gyldig     = true;}

    // Len <0, max] > "Brukernavn er ledig (maks lengde)" (green)
    else if (
        is_username_opptatt === false &&
        txt.match(txt_regex_username) &&
        len === txt_len_username_max) {
            _username_info_cls      = "txt_liten_green";
            _username_info_txt      = username_sub_gyldig_max; // ORI >> get_txt(username_sub_gyldig_max, "_no");
            _is_username_gyldig     = true;}

    // Len > 0 > Reset cls / txt / gyldig
    else if (
        is_username_opptatt === false &&
        !txt.match(txt_regex_username) &&
        len === 0) {
            _username_info_cls      = "";
            _username_info_txt      = "";
            _is_username_gyldig     = false;}

    // Opptatt > "Brukernavn er opptatt" (red)
    else if (
        is_username_opptatt === true &&
        len !== 0) {
            _username_info_cls      = "txt_liten_red";
            _username_info_txt      = username_sub_ikke_gyldig_opptatt; // get_txt(username_sub_ikke_gyldig_opptatt, "_no");
            _is_username_gyldig     = false;}

    // Ugyldig > F.eks "def$" > "Brukernavn er ugyldig" (yellow)
    else {
        _username_info_cls          = "txt_liten_yellow";
        _username_info_txt          = username_sub_ikke_gyldig; // get_txt(username_sub_ikke_gyldig, "_no");
        _is_username_gyldig         = false;};

    // Return > Arr > cls / txt // gyldig
    return [_username_info_cls, _username_info_txt, _is_username_gyldig]

};

const change_password_1_get_info_cls_txt        = (len) => {

    // Init
    let _password_info_cls          = "";
    let _password_info_txt          = "";

    // Alignment hack > Bypass
    if (len === 0) {
        _password_info_cls          = ""; // ori > txt_liten_yellow
        _password_info_txt          = "";}

    // Len: [0, 3] > "Passord svakt" (orange)
    else if (len >= 0 && len <= 3) {
        _password_info_cls          = "txt_liten_orange";
        _password_info_txt          = `${password_svakt}`;}

    // Len: <3, 6] > "Passord middels" (yellow)
    else if (len > 3 && len <= 6) {
        _password_info_cls          = "txt_liten_yellow";
        _password_info_txt          = `${password_middels}`;}

    // Len: [6, max] > "Password sterkt" (green)
    else if (len > 6 && len < txt_len_password_max) {
        _password_info_cls          = "txt_liten_green";
        _password_info_txt          = `${password_sterkt}`;}

    // Len: max > "Maks lengde" (green)
    else if (len === txt_len_password_max) {
        _password_info_cls          = "txt_liten_green";
        _password_info_txt          = `${password_max_lengde}`;}

    // Len: over max > "For langt" (red) > Kun debug, stoppes av max len-prop i input-el
    else {
        _password_info_cls          = "txt_liten_red";
        _password_info_txt          = `${password_for_langt}`;
    };

    // Return > Arr > cls / txt
    return [_password_info_cls, _password_info_txt]
};

const change_password_2_get_info_cls_txt        = (password_1, txt, len) => {

    // Init
    let _password_2_info_cls        = "";
    let _password_2_info_txt        = "";
    let _is_password_gyldig         = false;

    // Alignment hack > Bypass
    if (len === 0) {
        _password_2_info_cls        = "";
        _password_2_info_txt        = "";
        _is_password_gyldig         = false;}

    // Pass 1 = Pass 2 > Gyldig > Større enn min (max er begrenset av input-el)
    else if (
        txt === password_1 &&
        len >= txt_len_password_min) {
            _password_2_info_cls    = "txt_liten_green";
            _password_2_info_txt    = password_sub_gyldig;
            _is_password_gyldig     = true;}

    // Pass 1 != Pass 2 > Ikke gyldig
    else {
        _password_2_info_cls        = "txt_liten_yellow";
        _password_2_info_txt        = password_sub_ikke_gyldig;
        _is_password_gyldig         = false;};

    // Return > Arr > cls / txt
    return [_password_2_info_cls, _password_2_info_txt, _is_password_gyldig]
};


export {
    change_username_get_info_cls_txt,
    change_password_1_get_info_cls_txt,
    change_password_2_get_info_cls_txt
};
