/* ****************************************** */
/* Page core */
/* ****************************************** */

// Page core
const visninger                             = "_visninger";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    visninger
};


/* ****************************************** */
/* End */
/* ****************************************** */
