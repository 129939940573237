/* ****************************************** */
/* Reg */
/* ****************************************** */

// Reg
const reg_signup_typ                        = "signup_typ";
const reg_signup_success_typ                = "signup-success-typ"
const reg_signin_typ                        = "signin_typ";
const reg_signin_email_confirm_typ          = "signin_email_confirm_typ";
const reg_signin_glemt_passord_typ          = "signin_glemt_passord_typ";
const reg_signin_glemt_passord_confirm_typ  = "signin_glemt_passord_confirm_typ"; // Bruk - og ikke _
const reg_signin_email_passord_fra_epost    = "signin_glemt_passord_fra_epost_typ";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {

    // Reg
    reg_signup_typ,
    reg_signup_success_typ,
    reg_signin_typ,
    reg_signin_email_confirm_typ,
    reg_signin_glemt_passord_typ,
    reg_signin_glemt_passord_confirm_typ,
    reg_signin_email_passord_fra_epost

};


/* ****************************************** */
/* End */
/* ****************************************** */
