import {
    filter_order_nyeste_typ
} from "../../utils/konstanter/components/_filter";
import {
    redux_filter
} from "../../utils/konstanter/gen/_redux";

import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    filter_order:       filter_order_nyeste_typ,
    filter_tagg_arr:    [],
};

const filterSlice = createSlice({
    name:               redux_filter,
    initialState:       initialState,
    reducers: {

        // Order
        setFilterOrder(state, action) {

            // Filter order
            state.filter_order = action.payload;

        },

        // Filter tagg selected
        setFilterTaggArr(state, action) {

            // Tagg arr > DEV > eksempel
            state.filter_tagg_arr = action.payload;

        },
        setFilterTaggPushArr(state, action) {

            // Tagg arr push > DEV > eksempel
            state.filter_tagg_arr.push(action.payload);

        },
        setFilterTaggPopArr(state, action) {

            /* DEV > Forklaring */

            // DEV > Denne kan kanskje refackes ut med state.filter_tagg_arr.splice(action.payload, 1);
            for (let i = 0; i < state.filter_tagg_arr.length; i++) {
                if (state.filter_tagg_arr[i] === action.payload) {

                    // DEV > Eksempel
                    state.filter_tagg_arr.splice(i, 1);

                };

            };

        },

    }
});


export const {

    // Order
    setFilterOrder,

    // Filter tagg selected
    setFilterTaggArr,
    setFilterTaggPushArr,
    setFilterTaggPopArr,

} = filterSlice.actions;

export default filterSlice.reducer;
