import {
    _sym_hyphen,
    _sym_div_slash
} from "../konstanter/components/_sym";


/* ****************************************** */
/* Tid */
/* ****************************************** */

const get_tid_monthly                              = () => {

    // Date
    const _tid          = new Date();

    // DD/MM/YYYY
    const _tid_hele     = _tid.toLocaleDateString();

    // Init
    let _slash_cnt      = 0;
    // let _str_day        = "";
    let _str_mnd        = "";
    let _str_aar        = "";
    let _str_hele       = "";

    // Iterate DD/MM/YYYY -> YYYY-MM-DD
    for (let i = 0; i < _tid_hele.length; i++) {

        // Ikke slash > Heltall
        if (_tid_hele[i] !== _sym_div_slash) {
            if (_slash_cnt === 0) {

                // Ubrukt her > Se over > get_tid_dayly()
                // _str_day += _tid_hele[i];

            };
            if (_slash_cnt === 1) {
                _str_mnd += _tid_hele[i];
            };
            if (_slash_cnt === 2) {
                _str_aar += _tid_hele[i];
            };
        };

        // Slash > Inc slash cnt
        if (_tid_hele[i] === _sym_div_slash) {
            _slash_cnt += 1;
        };

    };

    // Concat
    _str_hele = `${_str_aar}${_sym_hyphen}${_str_mnd}`; // Dayly > {_sym_hyphen}${_str_day}

    // Return
    return _str_hele

};


/* ****************************************** */
/* Logic */
/* ****************************************** */

const _is_all_true                          = (arr) => {

    // Init > Antar at en eller alle er true
    let _ret_bl = true;

    // Iterate
    for (let i = 0; i < arr.length; i++) {

        // Trenger kun en false i arr for å returnere false
        if (arr[i] === false) {
            _ret_bl = false;
        };

    };

    // Return > bool > true / false
    return _ret_bl

};

const _contains                             = (item, el) => {

    // Note > Item kan være både _xyz_arr = [], og _el = ""

    // Return true hvis el er i arr
    if (item.includes(el)) { return true }

    // Return false hvis ikke
    else { return false };

};


/* ****************************************** */
/* Arr */
/* ****************************************** */

const _str_til_str_ch_arr                   = (_str) => {

    let _str_arr  = []

    if (_str.length > 0) { // temp

        /// for i in 0...str.count - 1 {
        ///     strArr.append(str[i])
        /// }

        for (let i = 0; i < _str.length; i++) {

            _str_arr.push(_str[i])

        };

    }

    return _str_arr

};


export {
    get_tid_monthly,
    _is_all_true,
    _contains,
    _str_til_str_ch_arr
};
