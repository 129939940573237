/* ****************************************** */
/* Fil */
/* ****************************************** */

// Fil
const fil_k                                 = 1024;
const fil_ext_css                           = ".css";
const fil_ext_html                          = ".html";
const fil_ext_js                            = ".js";
const fil_ext_json                          = ".json";
const fil_ext_py                            = ".py";        // Med punktum (samme som preff)
const fil_ext_zip                           = "zip";        // Dotless
const fil_type_text_x_python_script         = "text/x-python-script";
const fil_type_application_x_javascript     = "application/x-javascript";
const fil_type_text_css                     = "text/css";
const fil_type_text_html                    = "text/html";
const fil_type_drop_zone_file_valid_arr     = [
    fil_type_text_x_python_script,
    fil_type_application_x_javascript,
    fil_type_text_html,
    fil_type_text_css,
    // "image/jpeg",
    // "image/jpg",
    // "image/png",
    // "image/gif",
    // "image/x-icon",
];
const fil_edit_mat_id_0                     = "_fil_edit_mat_id_0";
const fil_edit_mat_id_1                     = "_fil_edit_mat_id_1";
const fil_edit_mat_id_2                     = "_fil_edit_mat_id_2";
const fil_item_typ_fil                      = "_fil_item_typ_fil";
const fil_item_typ_folder                   = "_fil_item_typ_folder";
const fil_slug_tmp_default                  = "fil_slug_tmp_default";
const fil_navn_default_blank                = "";
const fil_content_default_blank             = "";
const fil_abs_path_default_blank            = "";
const fil_level_i_offset                    = -2;
const fil_par_slug_root                     = "_fil_par_slug_root";
const fil_folder_slug                       = "folder";
const fil_tre_str_root                      = "+ root";



/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    fil_k,
    fil_ext_css,
    fil_ext_html,
    fil_ext_js,
    fil_ext_json,
    fil_ext_py,
    fil_ext_zip,

    fil_type_text_x_python_script,
    fil_type_application_x_javascript,
    fil_type_text_css,
    fil_type_text_html,

    fil_type_drop_zone_file_valid_arr,

    fil_edit_mat_id_0,
    fil_edit_mat_id_1,
    fil_edit_mat_id_2,
    fil_item_typ_fil,
    fil_item_typ_folder,
    fil_slug_tmp_default,
    fil_navn_default_blank,
    fil_content_default_blank,
    fil_abs_path_default_blank,
    fil_level_i_offset,

    fil_par_slug_root,
    fil_folder_slug,
    fil_tre_str_root 
};
