/* ****************************************** */
/* Context */
/* ****************************************** */

// Token
const auth_tokens                           = "authTokens";
const auth_user_local                       = "user";
const auth_user_id_jwt                      = "user_id";
const auth_postfix_local                    = "_init";

// Fields
const auth_user_id_felt_local               = "user_id_felt";           // Settes som user etter fetch
const auth_username_current_local           = "username_current";
const auth_human_lang_local                 = "human_lang";
const auth_is_superuser_local               = "is_superuser";
const auth_img_profil_local                 = "img_profil";
const auth_full_name_local                  = "full_name";
const auth_email_public_local               = "email_public";
const auth_bio_public_local                 = "bio_public";
const auth_url_public_local                 = "url_public";
const auth_company_public_local             = "company_public";
const auth_poeng_local                      = "poeng";
 
// Author
const author_init                           = "_author_init";
const author_admin_tmp                      = "_author_admin_tmp"; // Tmp > Kun for doc, kanskje bruke user / super_user

// Guest
const auth_guest                            = "guest";

// Pass
const auth_is_pass_hidden_stars             = "******";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    auth_tokens,
    auth_user_local,
    auth_user_id_jwt,
    auth_postfix_local,

    auth_user_id_felt_local,
    auth_username_current_local,
    auth_human_lang_local,
    auth_is_superuser_local,
    auth_img_profil_local,
    auth_full_name_local,
    auth_email_public_local,
    auth_bio_public_local,
    auth_url_public_local,
    auth_company_public_local,
    auth_poeng_local,

    author_init,
    author_admin_tmp,

    auth_guest,

    auth_is_pass_hidden_stars
};


/* ****************************************** */
/* End */
/* ****************************************** */
