/* ****************************************** */
/* Landing */
/* ****************************************** */

// Info
const push_el_new_file                      = "_push_el_new_file";
const add_dir                               = "_add_dir";
const remove_dir                            = "_remove_dir";

// I
const admin_db_tittel                       = "_admin_db_tittel";
const admin_user_profil_disp_data           = "_admin_user_profil_disp_data";
const admin_json_backend_cap                = "_admin_json_backend_cap";

// II
const admin_user_profil_disp_tittel_cap     = "_admin_user_profil_disp_tittel_cap";
const admin_user_arr_tittel_cap             = "_admin_user_arr_tittel_cap";
const admin_email_developer_cap             = "_admin_email_developer_cap";
const admin_test_navigation_cap             = "_admin_test_navigation_cap";
const admin_test_navigation_btn             = "_admin_test_navigation_btn";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    push_el_new_file,
    add_dir,
    remove_dir,

    admin_db_tittel,
    admin_user_profil_disp_data,
    admin_json_backend_cap,

    admin_user_profil_disp_tittel_cap,
    admin_user_arr_tittel_cap,
    admin_email_developer_cap,
    admin_test_navigation_cap,
    admin_test_navigation_btn
};


/* ****************************************** */
/* End */
/* ****************************************** */
