/* ****************************************** */
/* Btn */
/* ****************************************** */

// Btn
const btn_edit                              = "_btn_edit";
const btn_upload_bilde                      = "_btn_upload_bilde";
const btn_update                            = "_btn_update";
const btn_update_profil                     = "_btn_update_profil";
const btn_update_profil_bilde               = "_btn_update_profil_bilde";
const btn_publish                           = "_btn_publish";
const btn_delete                            = "_btn_delete";
const btn_complete                          = "_btn_complete";
const btn_cancel                            = "_btn_cancel";
const btn_load                              = "_btn_load";
const btn_dump                              = "_btn_dump";
const btn_cycle                             = "_btn_cycle";
const btn_cleanup_bu                        = "_btn_cleanup_bu";
const btn_remove                            = "_btn_remove";
const btn_add                               = "_btn_add";
const btn_push                              = "_btn_push";
const btn_mal_single                        = "_btn_mal_single";
const btn_mal_many                          = "_btn_mal_many";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    btn_edit,
    btn_upload_bilde,
    btn_update,
    btn_update_profil,
    btn_update_profil_bilde,
    btn_publish,
    btn_delete,
    btn_complete,
    btn_cancel,
    btn_load,
    btn_dump,
    btn_cycle,
    btn_cleanup_bu,
    btn_remove,
    btn_add,
    btn_push,
    btn_mal_single,
    btn_mal_many
};


/* ****************************************** */
/* End */
/* ****************************************** */
