/* ****************************************** */
/* Profil */
/* ****************************************** */

// User profil > Disp > I
const user_profil_tittel                    = "_user_profil_tittel";
const user_profil_img_profil_tittel_txt     = "_user_profil_img_profil_tittel_txt";
const user_profil_navn                      = "_user_profil_navn";
const user_profil_navn_placeholder          = "_user_profil_navn_placeholder";
const user_profil_public_email              = "_user_profil_public_email";
const user_profil_public_email_placeholder  = "_user_profil_public_email_placeholder";

// User profil > Disp > II
const user_profil_bio                       = "_user_profil_bio";
const user_profil_bio_placeholder           = "_user_profil_bio_placeholder";
const user_profil_url_public                = "_user_profil_url_public";
const user_profil_url_public_placeholder    = "_user_profil_url_public_placeholder";
const user_profil_company                   = "_user_profil_company";
const user_profil_company_placeholder       = "_user_profil_company_placeholder";

// User profil > Item
const user_profil_item_tittel_1             = "_user_profil_item_tittel_1";
const user_profil_item_tittel_2             = "_user_profil_item_tittel_2";
const user_profil_item_tittel_3             = "_user_profil_item_tittel_3";
const user_profil_item_tittel_4             = "_user_profil_item_tittel_4";

// User profil > Account > Set > I
const user_profil_account_tittel            = "_user_profil_account_tittel ";
const user_profil_account_konto_gruppe      = "_user_profil_account_konto_gruppe";
const user_profil_account_item_public_profil_tittel     = "_user_profil_account_item_public_profil_tittel";
const user_profil_account_item_account_tittel           = "_user_profil_account_item_account_tittel";
const user_profil_account_item_lang_tittel  = "_user_profil_account_item_lang_tittel";

// User profil > Account > Set > II
const user_profil_account_item_account_passord_endret_alert_success_confirm     = "_user_profil_account_item_account_passord_endret_alert_success_confirm";
const user_profil_account_item_account_passord_endret_alert_fail_confirm        = "_user_profil_account_item_account_passord_endret_alert_fail_confirm";
const user_profil_account_item_notifications_tittel                             = "_user_profil_account_item_notifications_tittel";
const user_profil_account_item_email_tittel = "_user_profil_account_item_email_tittel";

// User profil > Account > Set > Konto > Username
const usernavn_nytt_tittel                  = "_usernavn_nytt_tittel";
const usernavn_nytt_btn_endre               = "_usernavn_nytt_btn_endre";
const usernavn_nytt_btn_update              = "_usernavn_nytt_btn_update";

// User profil > Account > Set > Konto > Password
const password_nytt_tittel                  = "_password_nytt_tittel";
const password_nytt_btn_endre               = "_password_nytt_btn_endre";
const password_nytt_btn_update              = "_password_nytt_btn_update";
const password_dum                          = "_password_dum";
const password_bekreft_tittel               = "_password_bekreft_tittel";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    user_profil_tittel,
    user_profil_img_profil_tittel_txt,
    user_profil_navn,
    user_profil_navn_placeholder,
    user_profil_public_email,
    user_profil_public_email_placeholder,

    user_profil_bio,
    user_profil_bio_placeholder,
    user_profil_url_public,
    user_profil_url_public_placeholder,
    user_profil_company,
    user_profil_company_placeholder,

    user_profil_item_tittel_1,
    user_profil_item_tittel_2,
    user_profil_item_tittel_3,
    user_profil_item_tittel_4,

    user_profil_account_tittel,
    user_profil_account_konto_gruppe,
    user_profil_account_item_public_profil_tittel,
    user_profil_account_item_account_tittel,
    user_profil_account_item_lang_tittel,

    user_profil_account_item_account_passord_endret_alert_success_confirm,
    user_profil_account_item_account_passord_endret_alert_fail_confirm,
    user_profil_account_item_notifications_tittel,
    user_profil_account_item_email_tittel,

    usernavn_nytt_tittel,
    usernavn_nytt_btn_endre,
    usernavn_nytt_btn_update,

    password_nytt_tittel,
    password_nytt_btn_endre,
    password_nytt_btn_update,
    password_dum,
    password_bekreft_tittel
};


/* ****************************************** */
/* End */
/* ****************************************** */
