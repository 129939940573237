import {createSlice} from "@reduxjs/toolkit";
import {
    redux_crud
} from "../../utils/konstanter/gen/_redux";
  

const initialState = {
    beskrivelse_mat:    [],
    kom_create_mat:     [],
    kom_mat:            [],
    edit_mat:           [],
    delete_mat:         [],
    crud_textarea:      false
};

const beskrivelseMatSlice = createSlice({
    name: redux_crud,
    initialState: initialState,
    reducers: {

        // Beskrivelse
        setIndexBeskrivelseMatVal(state, action) {
            state.beskrivelse_mat[action.payload[0]][action.payload[1]] = action.payload[2]
        },

        // Kom create
        setIndexKomCreateMat1(state, action) {
            state.kom_create_mat[action.payload[0]][action.payload[1]] = 1
        },

        // Edit
        setIndexEditMat0(state, action) {
            state.edit_mat[action.payload[0]][action.payload[1]] = 0
        },
        setIndexEditMat1(state, action) {
            state.edit_mat[action.payload[0]][action.payload[1]] = 1
        },

        // Delete
        setIndexDeleteMat0(state, action) {
            state.delete_mat[action.payload[0]][action.payload[1]] = 0
        },
        setIndexDeleteMat1(state, action) {
            state.delete_mat[action.payload[0]][action.payload[1]] = 1
        },

        // Doc
        setCRUDDocReset(state, action) {
            state.beskrivelse_mat = []
            state.edit_mat = []
            for (let i = 0; i < action.payload.length; i++) {
                state.beskrivelse_mat.push([])
                state.edit_mat.push([])
                state.beskrivelse_mat[i].push(action.payload[i].beskrivelse)
                state.edit_mat[i].push(0)
            };
        },

        // Quest
        setCRUDQuestReset(state, action) {

            // - Note
            //   - action.payload[0] > dat.beskrivelse
            //   - action.payload[1] > dat.kom_arr
            //   - action.payload[2] > dat.svar_arr

            state.beskrivelse_mat = []
            state.beskrivelse_mat.push([])
            state.beskrivelse_mat.push([])
            state.beskrivelse_mat[0].push(action.payload[0])

            state.kom_create_mat = []
            state.kom_create_mat.push([])
            state.kom_create_mat.push([])
            state.kom_create_mat[0].push(0)
            state.kom_create_mat[1].push(0)

            state.kom_mat = []
            state.kom_mat.push([])
            state.kom_mat.push([])
            state.kom_mat[0].push("")

            state.edit_mat = []
            state.edit_mat.push([])
            state.edit_mat.push([])
            state.edit_mat[0].push(0)
            state.edit_mat[1].push(0)

            state.edit_fil_mat = []
            state.edit_fil_mat.push([])
            state.edit_fil_mat.push([])
            state.edit_fil_mat[0].push(0)
            state.edit_fil_mat[1].push(0)

            state.delete_mat = []
            state.delete_mat.push([])
            state.delete_mat.push([])
            state.delete_mat[0].push(0)
            state.delete_mat[1].push(0)

            // dat.kom_arr > Quest kom arr
            for (let i = 0; i < action.payload[1].length; i++) {
                state.beskrivelse_mat[0].push(action.payload[1][i].beskrivelse)
                state.kom_create_mat[0].push(0)
                state.kom_mat[0].push(action.payload[1][i])
                state.edit_mat[0].push(0)
                state.delete_mat[0].push(0)
            };

            // dat.svar_arr
            for (let i = 0; i < action.payload[2].length; i++) {

                state.beskrivelse_mat.push([])
                state.beskrivelse_mat[i + 2].push(action.payload[2][i].beskrivelse)

                state.kom_create_mat.push([])
                state.kom_create_mat[i + 2].push(0)

                state.kom_mat.push([])
                state.kom_mat[i + 2].push("")

                state.edit_mat.push([])
                state.edit_mat[i + 2].push(0)

                state.edit_fil_mat.push([])
                state.edit_fil_mat[i + 2].push(0)

                state.delete_mat.push([])
                state.delete_mat[i + 2].push(0)

                // dat.svar_arr > Svar kom arr
                for (let j = 0; j < action.payload[2][i].kom_arr.length; j++) {

                    if (action.payload[2][i].kom_arr[j]?.user === undefined) {
                        state.beskrivelse_mat[i + 2].push("")
                        state.kom_mat[i + 2].push("")
                    } else {
                        state.beskrivelse_mat[i + 2].push(action.payload[2][i].kom_arr[j]?.beskrivelse)
                        state.kom_mat[i + 2].push(action.payload[2][i].kom_arr[j])
                    };

                    state.edit_mat[i + 2].push(0)
                    state.delete_mat[i + 2].push(0)
                };
            }
        },

        // Textarea
        setCRUDTextareaToggle(state) {

            // Kun for å execute useEffect i textarea > Setter riktig textare yy når CRUD starter
            state.crud_textarea_toggle = !state.crud_textarea_toggle;

        }

    }
});


export const {

    // Beskrivelse
    setIndexBeskrivelseMatVal,

    // Kom create
    setIndexKomCreateMat1,

    // Edit
    setIndexEditMat0,
    setIndexEditMat1,

    // Delete
    setIndexDeleteMat0,
    setIndexDeleteMat1,

    // Doc
    setCRUDDocReset,

    // Quest
    setCRUDQuestReset,

    // Textarea
    setCRUDTextareaToggle

} = beskrivelseMatSlice.actions;


export default beskrivelseMatSlice.reducer;
