/* ****************************************** */
/* Color */
/* ****************************************** */

// Root
const col_transparent                       = "transparent";
const col_beige_1                           = "#cad1d8";            // "#cad1d8", Rød > #E55E51
const col_purple                            = "purple";

// Debug
const col_debug                             = col_purple;

// Page core
const col_page_core_bg                      = "rgb(15, 17, 22)";    // Som field > `${col_page_core_bg}`

// Progress bar
const col_progress_bar_bg                   = "rgb(2, 4, 9)";       // --farge_black_1: rgb(2, 4, 9);
const col_progress_bar_lbl                  = "rgb(55, 59, 64)";
const col_progress_bar_data_bg              = "rgb(67, 132, 64)";   // --farge_green_1: rgb(67, 132, 64);

// Spill
const col_spill_txt_norm                    = col_beige_1;
const col_spill_txt_curs                    = "#cad1d9";            // Rød > #E55E52, "#00FF00", // Hardkodet før final
const col_spill_bg_norm                     = col_transparent;


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    col_debug,
    col_page_core_bg,

    col_progress_bar_bg,
    col_progress_bar_lbl,
    col_progress_bar_data_bg,

    col_transparent,
    col_spill_txt_norm,
    col_spill_txt_curs,
    col_spill_bg_norm
};