/* ****************************************** */
/* Root / Landing */
/* ****************************************** */

// Nettside
const nettside                              = "programmering.no";
const selskap_as                            = "MAS";                // Matematikk AS


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    nettside,
    selskap_as
};


/* ****************************************** */
/* End */
/* ****************************************** */