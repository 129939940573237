/* ****************************************** */
/* Btn */
/* ****************************************** */

// Btn
const btn_typ_col                           = "btn_typ_col";
const btn_typ_tagg                          = "btn_typ_tagg";
const btn_typ_txt_link                      = "btn_typ_txt_link";
const btn_typ_input                         = "btn_typ_input";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    btn_typ_col,
    btn_typ_tagg,
    btn_typ_txt_link,
    btn_typ_input
};
