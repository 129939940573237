/* ****************************************** */
/* Vote */
/* ****************************************** */

// Vote
const vote_stemmer                          = "_vote_stemmer";
const vote_svar                             = "_vote_svar";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    vote_stemmer,
    vote_svar
};


/* ****************************************** */
/* End */
/* ****************************************** */
