/* ****************************************** */
/* Key */
/* ****************************************** */

// Editor kode > API
const _key_editor_kode_typ_ny               = "_key_editor_kode_typ_ny";        // DEV > Kanskje slett
const _key_editor_kode_typ_kopier           = "_key_editor_kode_typ_kopier";    // DEV > Kanskje slett
const _key_editor_kode_typ_delete           = "_key_editor_kode_typ_delete";

// Gen > Model
const _key_vote_tot                         = "vote_tot";

// Gen > Mal > API
const _key_mal                              = "mal";

// main > Fil > API
const _key_fil_upload_tmp_pre               = "_fil_";
const _key_is_editing                       = "is_editing";

// account > UsrModel > Model
const _key_username                         = "username";

// account > UserProfil > Model
const _key_img_profil                       = "img_profil";

// doc > Blokk > API
const _key_blokk_arr                        = "blokk_arr";
const _key_ch_arr                           = "ch_arr";
const _key_open                             = "open";

// JS
const _key_invalid                          = "invalid";

// Import
const _key_blob                             = "blob";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    _key_editor_kode_typ_ny,
    _key_editor_kode_typ_kopier,
    _key_editor_kode_typ_delete,

    _key_vote_tot,

    _key_mal,

    _key_fil_upload_tmp_pre,
    _key_is_editing,

    _key_username,

    _key_img_profil,

    _key_blokk_arr,
    _key_ch_arr,
    _key_open,

    _key_invalid,

    _key_blob
};


/* ****************************************** */
/* End */
/* ****************************************** */