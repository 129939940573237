/* ****************************************** */
/* Note */
/* ****************************************** */

// Søkeord > Backend_frontend_speilbilder > prim_url_api0.py <-> _url.js


/* ****************************************** */
/* Route */
/* ****************************************** */

// Root
const url_route_root                        = "/";

// Ruter
const url_route_ruter                       = "/ruter";

// Mal
const url_route_mal                         = "/mal";

// About
const url_route_about                       = "/about";

// Adminx
const url_route_adminx                      = "/adminx";

// Doc
const url_route_doc                         = "/doc";

// No url
const url_route_no_url                      = "/no_url";

// Profil
const url_route_profil_disp                 = "/profil_disp";
const url_route_profil_set                  = "/profil_set";
const url_route_user_profil_list            = "/user_profil_list";

// Reg
const url_route_signup                      = "/signup";
const url_route_signup_success              = "/signup-success";
const url_route_signin_email_confirm        = "/signin_email_confirm";
const url_route_signin_glemt_passord        = "/signin_glemt_passord";
const url_route_signin_glemt_ps_confirm     = "/signin_glemt_passord_confirm"; // bruk - og ikke _
const url_route_signin_glemt_ps_fra_epost   = "/signin-glemt-passord-fra-epost"; // signin_glemt-passord-fra-epost
const url_route_signin                      = "/signin";

// Quest
const url_route_quest                       = "/quest";
const url_route_quest_create                = "/quest-create";
const url_route_quest_item                  = "/quest-item";
const url_route_quest_list                  = "/quest-list";

// Spill
const url_route_spill                       = "/spill";         // DEV > Kanskje lag pk for hvilket spill (havner i url)

// The Editor
const url_route_the_editor                  = "/the_editor";


/* ****************************************** */
/* API > _main */
/* ****************************************** */

const url_api_mal                           = "/api/mal/";

const url_api_db_load                       = "/api/db_load/";
const url_api_db_delete                     = "/api/db_delete/";
const url_api_db_cycle                      = "/api/db_cycle/";

const url_api_db_cycle_initialisation       = "/api/db_cycle_initialisation/";

const url_api_admin_json_back_add           = "/api/admin_json_back_add/";
const url_api_admin_json_back_remove        = "/api/admin_json_back_remove/";
const url_api_admin_json_back_push          = "/api/admin_json_back_push/";

const url_api_admin_user_arr_dump_cleanup_bu    = "/api/admin_user_arr_dump_cleanup_bu/";
const url_api_admin_user_arr_dump               = "/api/admin_user_arr_dump/";
const url_api_admin_user_arr                    = "/api/admin_user_arr/";

const url_api_is_obj                        = "/api/is_obj/";

const url_api_tagg                          = "/api/tagg/";
const url_api_kode_exe                      = "/api/kode_exe/";
const url_api_vote                          = "/api/vote/";


/* ****************************************** */
/* API > doc */
/* ****************************************** */

// Doc
const url_api_doc_header                    = "/api/doc_header"; // Øverst / først pga. matching
const url_api_doc                           = "/api/doc";

const url_api_blokk_pk                      = "/api/blokk/";
const url_api_get_doc_slug_pk               = "/api/get_doc_slug/";


/* ****************************************** */
/* API > account */
/* ****************************************** */

// JWT token
const url_api_token_refresh                 = "/api/token/refresh/"; // Sjekk, slutter med slash / ubrukt
const url_api_token                         = "/api/token/";

// User profil
const url_api_user_profil_item              = "/api/user_profil_item";

// User > Reg > Create user
const url_api_user_reg_sett_inactive        = "/api/user_reg_sett_inactive";

const url_api_developer_send_email_user_confirm     = "/api/developer_send_email_user_confirm/";
const url_api_developer_send_email_glemt_passord    = "/api/developer_send_email_glemt_passord/";

const url_api_user_glemt_passord_sett_nytt_pk       = "/api/user_glemt_passord_sett_nytt/";
const url_api_user_glemt_passord_pk                 = "/api/user_glemt_passord/";

const url_api_user_update_username_pk       = "/api/user_update_username/";
const url_api_user_update_password_pk       = "/api/user_update_password/";

const url_api_user_profil_img_pk            = "/api/user_profil_img/";
const url_api_user_profil_human_lang_pk     = "/api/user_profil_human_lang/";
const url_api_username_fra_current_pk       = "/api/username_fra_current/";

const url_api_user_opptatt_username_pk      = "/api/user_opptatt_username/";
const url_api_user_opptatt_email_pk         = "/api/user_opptatt_email/";

const url_api_user                          = "/api/user/";

const url_api_user_profil_list_filter_arr   = "/api/user_profil_list_filter_arr/";


/* ****************************************** */
/* API > quest */
/* ****************************************** */

// Quest
const url_api_quest_item_get                = "/api/quest_item_get";

const url_api_quest_list_filter_arr         = "/api/quest_list_filter_arr/";
const url_api_quest_pk                      = "/api/quest/";
const url_api_quest                         = "/api/quest/";

const url_api_svar_pk                       = "/api/svar/";
const url_api_svar                          = "/api/svar/";

const url_api_kom_pk                        = "/api/kom/";
const url_api_kom                           = "/api/kom/";


/* ****************************************** */
/* API > Spill */
/* ****************************************** */

// Spill
const url_api_spill_list_arr                = "/api/spill_list_arr/";
const url_api_spill                         = "/api/spill/";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {

    /* ****************************************** */
    /* Route */
    /* ****************************************** */

    url_route_root,

    url_route_ruter,

    url_route_mal,

    url_route_about,

    url_route_adminx,

    url_route_doc,

    url_route_no_url,

    url_route_profil_disp,
    url_route_profil_set,
    url_route_user_profil_list,

    url_route_signup,
    url_route_signup_success,
    url_route_signin_email_confirm,
    url_route_signin_glemt_passord,
    url_route_signin_glemt_ps_confirm,
    url_route_signin_glemt_ps_fra_epost,
    url_route_signin,

    url_route_quest,
    url_route_quest_create,
    url_route_quest_item,
    url_route_quest_list,

    url_route_spill,

    url_route_the_editor,


    /* ****************************************** */
    /* API > Main */
    /* ****************************************** */

    url_api_mal,
    url_api_db_load,
    url_api_db_delete,
    url_api_db_cycle,
    url_api_db_cycle_initialisation,
    url_api_admin_json_back_add,
    url_api_admin_json_back_remove,
    url_api_admin_json_back_push,
    url_api_admin_user_arr_dump_cleanup_bu,
    url_api_admin_user_arr_dump,
    url_api_admin_user_arr,

    url_api_is_obj,

    url_api_tagg,
    url_api_kode_exe,
    url_api_vote,


    /* ****************************************** */
    /* API > Doc */
    /* ****************************************** */

    url_api_doc_header,
    url_api_doc,

    url_api_blokk_pk,
    url_api_get_doc_slug_pk,


    /* ****************************************** */
    /* API > Account */
    /* ****************************************** */

    url_api_token_refresh,
    url_api_token,

    url_api_user_profil_item,

    url_api_user_reg_sett_inactive,

    url_api_developer_send_email_user_confirm,
    url_api_developer_send_email_glemt_passord,

    url_api_user_glemt_passord_sett_nytt_pk,
    url_api_user_glemt_passord_pk,

    url_api_user_update_username_pk,
    url_api_user_update_password_pk,

    url_api_user_profil_img_pk,
    url_api_user_profil_human_lang_pk,
    url_api_username_fra_current_pk,

    url_api_user_opptatt_username_pk,
    url_api_user_opptatt_email_pk,

    url_api_user,

    url_api_user_profil_list_filter_arr,


    /* ****************************************** */
    /* API > Quest */
    /* ****************************************** */

    url_api_quest_item_get,

    url_api_quest_list_filter_arr,
    url_api_quest_pk,
    url_api_quest,

    url_api_svar_pk,
    url_api_svar,

    url_api_kom_pk,
    url_api_kom,


    /* ****************************************** */
    /* API > Spill */
    /* ****************************************** */

    url_api_spill_list_arr,
    url_api_spill

};


/* ****************************************** */
/* End */
/* ****************************************** */
