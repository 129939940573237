/* ****************************************** */
/* Key */
/* ****************************************** */

const key_open                              = "open";   // ...


/* ****************************************** */
/* Export */
/* ****************************************** */

export {

    key_open,

};


/* ****************************************** */
/* End */
/* ****************************************** */