import {
    font_family_times_new_roman_,
    font_family_verdana_
} from "../../utils/konstanter/gen/_font";
import {
    _l_s,
    _n_s,
    _o_s,
    _r_s,
    _t_s,
} from "../../utils/konstanter/components/_alfabet";
import {
    _t_1,
    _t_2,
    _t_3,
    _t_4,
    _t_5,
    _t_6,
    _t_7,
    _t_8,
    _t_9,
    _t_0,
    _pos_1,
} from "../../utils/konstanter/components/_num";
import {
    _blank_str,
    _L_1,
    _R_1,
    _sym_dot,
    _sym_semikolon,
    _sym_pluss,
    _sym_minus,
    _sym_mul_dot,
    _sym_div_slash,
    _sym_expo,
    _sym_lik,
    _sym_empty,
    _sym_curs
} from "../../utils/konstanter/components/_sym";
import {
    col_transparent
} from "../../utils/konstanter/gen/_color";
import {
    redux_solver
} from "../../utils/konstanter/gen/_redux";
import {
    _kol_postfix,

    _ln,
    _funk,

    _likning,

    _l_empty,
    _l_curs,
    _l_num,
    _l_vari,
    _l_punktum,
    _l_lik,
    _l_pluss,
    _l_minus,
    _l_ganging,
    _l_deling,
    _l_expo,
    _l_funk,
    _l_root,
    _l_par_1_L,
    _l_par_1_R,
    _l_kolon_semi,

    spill_expr_html_init,
    spill_expr_debug_init,
    spill_expr_col_txt_init,
    spill_expr_col_bg_init
} from "../../utils/konstanter/pages/_spill";

import {
    _str_til_str_ch_arr
} from "../../utils/utility/_util_op";

import {
    get_v_el,
    create_0_expo,
    create_gui
} from "./solver_util";

import {createSlice} from "@reduxjs/toolkit"; // current


const initialState = {

    // Solver
    solver_txt:                 "solv_testy",

    // Vari
    _t_0_arr:                   [],
    _t_1_arr:                   [],
    _e_0_arr:                   [],
    _e_1_arr:                   [],

    _e_0_mat:                   [],
    _e_1_mat:                   [],

    _i_0_arr:                   [],
    _i_1_arr:                   [],
    _str_impl:                  [],

    _v_arr:                     [],
    _v_arr_sub:                 [],
    _v_arr_0:                   [],

    _matematikk_obj_typ:        "",

    _sw_bool:                   false,

    _l_temp_4_i:                -0.0,
    _l_temp_4_i_1:              -0.0,

    _col_bg:                    "",

    _debug_mega_cnt_iter:       0,      // 2023
    _stuck_cnt:                 -0.0,   // 2023

    _str_final:                 "",
    _str_final_curs_hidden:     "",
    _str_final_debug_xy:        "",

    // Input
    _str_input:                 "",

    _curs_pos:                  -10,
    _curs_moved_bool:           false,
    _curs_timer:                0,

};

const solverSlice = createSlice({
    name:                               redux_solver,
    initialState:                       initialState,
    reducers: {

        // Prepare
        setCreateReset(state) {

            state._t_0_arr              = [];
            state._t_1_arr              = [];
            state._e_0_arr              = [];
            state._e_1_arr              = [];

            state._e_0_mat              = [];
            state._e_1_mat              = [];

            state._i_0_arr              = [];
            state._i_1_arr              = [];
            state._str_impl             = [];

            state._v_arr                = [];
            state._v_arr_sub            = [];
            state._v_arr_0              = [];

            state._matematikk_obj_typ   = "";

            state._sw_bool              = false;

            state._l_temp_4_i           = -0.0;
            state._l_temp_4_i_1         = -0.0;

            state._col_bg               = "";

            state._debug_mega_cnt_iter  = 0;        // 2023
            state._stuck_cnt            = -0.0;     // 2023

        },
        setCreateSplit(state, action) {

            // Debug
            const _e        = 1;
            const _d        = 0;

            // Exe
            if (_e === 1) {

                // Init
                const _str      = action.payload;
                state._t_0_arr  = _str_til_str_ch_arr(_str);

            };

            // Debug
            if (_d === 1) {

                console.log("")
                console.log("+++++ CREATE_SPLIT")
                console.log("t0Arr :: ", state._t_0_arr)

            };

        },
        setCreateSplitInput(state) {

            // 2024

            // Debug
            const _e        = 1;
            const _d        = 0;

            // Exe
            if (_e === 1) {

                // Init
                state._t_0_arr  = _str_til_str_ch_arr(state._str_input);

            };

            // Debug
            if (_d === 1) {

                console.log("")
                console.log("+++++ CREATE_SPLIT")
                console.log("t0Arr :: ", state._t_0_arr)

            };

        },
        setCreateRedef(state) {

            // Debug
            const _e        = 1;
            const _d        = 0;

            // Exe
            if (_e === 1) {

                // Loop
                for (let i = 0; i < state._t_0_arr.length; i++) {

                    // Basic
                    if          (state._t_0_arr[i] === _sym_empty)          { state._t_1_arr.push(_l_empty) }
                    else if     (state._t_0_arr[i] === _sym_curs)           { state._t_1_arr.push(_l_curs) }

                    // Num
                    else if     (state._t_0_arr[i] === _t_1)                { state._t_1_arr.push(_l_num) }
                    else if     (state._t_0_arr[i] === _t_2)                { state._t_1_arr.push(_l_num) }
                    else if     (state._t_0_arr[i] === _t_3)                { state._t_1_arr.push(_l_num) }
                    else if     (state._t_0_arr[i] === _t_4)                { state._t_1_arr.push(_l_num) }
                    else if     (state._t_0_arr[i] === _t_5)                { state._t_1_arr.push(_l_num) }
                    else if     (state._t_0_arr[i] === _t_6)                { state._t_1_arr.push(_l_num) }
                    else if     (state._t_0_arr[i] === _t_7)                { state._t_1_arr.push(_l_num) }
                    else if     (state._t_0_arr[i] === _t_8)                { state._t_1_arr.push(_l_num) }
                    else if     (state._t_0_arr[i] === _t_9)                { state._t_1_arr.push(_l_num) }
                    else if     (state._t_0_arr[i] === _t_0)                { state._t_1_arr.push(_l_num) }

                    // Par
                    else if     (state._t_0_arr[i] === _L_1)                { state._t_1_arr.push(_l_par_1_L) }
                    else if     (state._t_0_arr[i] === _R_1)                { state._t_1_arr.push(_l_par_1_R) }

                    // Txt
                    else if     (state._t_0_arr[i] === _sym_dot)            { state._t_1_arr.push(_l_punktum) }
                    else if     (state._t_0_arr[i] === _sym_semikolon)      { state._t_1_arr.push(_l_kolon_semi) }

                    // Op
                    else if     (state._t_0_arr[i] === _sym_pluss)          { state._t_1_arr.push(_l_pluss) }
                    else if     (state._t_0_arr[i] === _sym_minus)          { state._t_1_arr.push(_l_minus) }
                    else if     (state._t_0_arr[i] === _sym_mul_dot)        { state._t_1_arr.push(_l_ganging) }
                    else if     (state._t_0_arr[i] === _sym_div_slash)      { state._t_1_arr.push(_l_deling) }
                    else if     (state._t_0_arr[i] === _sym_expo)           { state._t_1_arr.push(_l_expo) }

                    // Rela
                    else if     (state._t_0_arr[i] === _sym_lik)            { state._t_1_arr.push(_l_lik) }

                    else                                                    { state._t_1_arr.push(_l_vari) }

                };

            };

            // Disp
            if (_d === 1) {

                console.log("")
                console.log("> CREATE_REDEF")
                console.log(">> _t_0_arr")
                console.log(">>> ", state._t_0_arr)
                for (let i = 0; i < state._t_0_arr.length; i++) {
                    console.log("_t_0_arr >> ", state._t_0_arr[i])
                };
                console.log(">> _t_1_arr")
                for (let i = 0; i < state._t_1_arr.length; i++) {
                    console.log("_t_1_arr >> ", state._t_1_arr[i])
                };

            };

        },
        setCreateJoin(state) {

            // Debug
            const _e        = 1;
            const _d        = 0;

            // Exe
            if (_e === 1) {

                // root
                const _create_join_root = (i) => {

                    if (state._t_0_arr[i + 0] === _r_s &&
                        state._t_0_arr[i + 1] === _o_s &&
                        state._t_0_arr[i + 2] === _o_s &&
                        state._t_0_arr[i + 3] === _t_s) {

                            return true

                    } else { return false }

                };

                // ln
                const _create_join_ln = (i) => {

                    if (state._t_0_arr[i + 0] === _l_s &&
                        state._t_0_arr[i + 1] === _n_s) {

                            return true

                    } else { return false }

                };

                // root / ln
                let i = 0;
                while (i < state._t_0_arr.length - 1) {

                    // root
                    if (i > -1 &&
                    _create_join_root(i)) {

                        // console.log("create join root!")

                        state._t_0_arr[i] = _l_root;
                        state._t_1_arr[i] = _l_funk;

                        // state._t_0_arr.pop(i + 3);
                        // state._t_0_arr.pop(i + 2);
                        // state._t_0_arr.pop(i + 1);

                        state._t_0_arr.splice(i + 3, 1);
                        state._t_0_arr.splice(i + 2, 1);
                        state._t_0_arr.splice(i + 1, 1);

                        // state._t_1_arr.pop(i + 3);
                        // state._t_1_arr.pop(i + 2);
                        // state._t_1_arr.pop(i + 1);

                        state._t_1_arr.splice(i + 3, 1);
                        state._t_1_arr.splice(i + 2, 1);
                        state._t_1_arr.splice(i + 1, 1);

                        i = -1;

                    };

                    // ln
                    if (i > -1 &&
                    _create_join_ln(i)) {

                        console.log("create join ln!")

                        state._t_0_arr[i] = _ln;
                        state._t_1_arr[i] = _l_funk;

                        state._t_0_arr.pop(i + 1);
                        state._t_1_arr.pop(i + 1);

                        i = -1;

                    };

                    i += 1;

                };

                // num
                i = 0;
                while (i < state._t_0_arr.length - 1) {

                    // num, num
                    if (i > -1 &&
                    state._t_1_arr[i]     === _l_num &&
                    state._t_1_arr[i + 1] === _l_num) {

                        state._t_0_arr[i] = state._t_0_arr[i] + state._t_0_arr[i + 1];

                        state._t_0_arr.splice(i + 1, 1);
                        state._t_1_arr.splice(i + 1, 1);

                        i = -1;

                    };

                    // num, punktum
                    if (i > -1 &&
                    state._t_1_arr[i]     === _l_num &&
                    state._t_1_arr[i + 1] === _l_punktum) {

                        console.log("create join num, punktum!")

                        state._t_0_arr[i] = state._t_0_arr[i] + state._t_0_arr[i + 1];

                        state._t_0_arr.splice(i + 1, 1);
                        state._t_1_arr.splice(i + 1, 1);

                        i = -1;

                    };

                    i += 1;

                };

            };

            // Disp
            if (_d === 1) {

                console.log("")
                console.log("> CREATE_JOIN")
                console.log(">> _t_0_arr")
                for (let i = 0; i < state._t_0_arr.length; i++) {
                    console.log("_t_0_arr >> ", state._t_0_arr[i])
                };
                console.log(">> _t_1_arr")
                for (let i = 0; i < state._t_1_arr.length; i++) {
                    console.log("_t_1_arr >> ", state._t_1_arr[i])
                };

            };


        },

        // Expl
        setCreateExplArr(state) {

            // Debug
            const _e        = 1;
            const _d        = 0;

            // Exe
            if (_e === 1) {

                state._e_0_arr = state._t_0_arr.slice()
                state._e_1_arr = state._t_1_arr.slice()

            };

            // Disp
            if (_d === 1) {

                console.log("")
                console.log("> CREATE_EXPL_ARR")
                console.log(">> E_0_arr")
                for (let i = 0; i < state._e_0_arr.length; i++) {
                    console.log("_e_0_arr >> ", state._e_0_arr[i])
                };
                console.log(">> E_1_arr")
                for (let i = 0; i < state._e_1_arr.length; i++) {
                    console.log("_e_1_arr >> ", state._e_1_arr[i])
                };

            };

        },
        setCreateExplMake(state) {

            // Debug
            const _e        = 1;
            const _d        = 0;

            // Exe
            if (_e === 1) {

                // Remove e
                const _remove_e = (i) => {

                    // Debug
                    const _e        = 1;
                    const _d        = 1;

                    // Exe
                    if (_e === 1) {

                        // Note > .splice(index, 1), "1" >remove 1 item)
                        state._e_0_arr.splice(i, 1)
                        state._e_1_arr.splice(i, 1)

                    };

                    // Disp
                    if (_d === 1) {};

                };

                // > -1 >> Pluss
                let i = state._e_0_arr.length - 1
                while (i > -1) {

                    // Pluss
                    if (i < state._e_0_arr.length &&
                    state._e_1_arr[0] === _l_num) {

                        // Note > Insert > .splice(insert index, delete index, el)
                        state._e_0_arr.splice(0, 0, _sym_pluss);
                        state._e_1_arr.splice(0, 0, _l_pluss);

                        i = state._e_0_arr.length;

                    };

                    i -= 1;

                };

                // > -1 >> Vari / ln
                i = state._e_0_arr.length - 1
                while (i > -1) {

                    // Vari
                    if (i < state._e_0_arr.length &&
                    state._e_1_arr[0] === _l_vari) {

                        /*
                        console.log("PRE")
                        for (let i = 0; i < state._e_0_arr.length; i++) {
                            console.log("_e_0_arr >> ", state._e_0_arr[i])
                        };
                        */

                        state._e_0_arr.splice(0, 0, _sym_mul_dot);
                        state._e_1_arr.splice(0, 0, _l_ganging);

                        state._e_0_arr.splice(0, 0, _pos_1);
                        state._e_1_arr.splice(0, 0, _l_num);

                        /*
                        console.log("POST")
                        for (let i = 0; i < state._e_0_arr.length; i++) {
                            console.log("_e_0_arr >> ", state._e_0_arr[i])
                        };
                        */

                        i = state._e_0_arr.length;

                    };

                    // ln
                    if (i < state._e_0_arr.length &&
                    state._e_0_arr[0] === _ln) {

                        state._e_0_arr.splice(0, 0, _sym_mul_dot);
                        state._e_1_arr.splice(0, 0, _l_ganging);

                        state._e_0_arr.splice(0, 0, _pos_1);
                        state._e_1_arr.splice(0, 0, _l_num);

                        i = state._e_0_arr.length;

                    };

                    i -= 1;

                };

                //  DEV >> større enn null > 0 >> num
                i = state._e_0_arr.length - 1
                while (i > 0) {

                    // num, vari -> num * vari
                    if (i < state._e_0_arr.length &&
                    state._e_1_arr[i - 1] === _l_num &&
                    state._e_1_arr[i] === _l_vari) {

                        state._e_0_arr.splice(i, 0, _sym_mul_dot);
                        state._e_1_arr.splice(i, 0, _l_ganging);

                        i = state._e_0_arr.length;

                    };

                    // Fortegn
                    if (i < state._e_0_arr.length &&
                    state._e_1_arr[i - 1] === _l_pluss &&
                    state._e_1_arr[i] === _l_num) {

                        state._e_0_arr[i - 1] = state._e_0_arr[i - 1] + state._e_0_arr[i];
                        state._e_1_arr[i - 1] = _l_num;

                        // dev
                        _remove_e(i);

                    };

                    // Pluss vari
                    if (i < state._e_0_arr.length &&
                    state._e_1_arr[i - 1] === _l_pluss &&
                    state._e_1_arr[i] === _l_vari) {

                        state._e_0_arr[i - 1] = state._e_0_arr[i - 1] + _t_1;
                        state._e_1_arr[i - 1] = _l_num;

                        state._e_0_arr.splice(i, 0, _sym_mul_dot);
                        state._e_1_arr.splice(i, 0, _l_ganging);

                    };

                    // Funk
                    if (i < state._e_0_arr.length &&
                    state._e_1_arr[i - 1] === _l_pluss &&
                    state._e_1_arr[i] === _funk) {

                        state._e_0_arr.splice(i, 0, _t_1);
                        state._e_1_arr.splice(i, 0, _l_num);

                        i = state._e_0_arr.length;

                    };

                    // Minus
                    if (i < state._e_0_arr.length &&
                    state._e_1_arr[i - 1] === _l_minus &&
                    state._e_1_arr[i] === _l_num) {

                        state._e_0_arr[i - 1] = state._e_0_arr[i - 1] + state._e_0_arr[i];
                        state._e_1_arr[i - 1] = _l_num

                        _remove_e(i);

                        i = state._e_0_arr.length;

                    };

                    // Par, funk
                    if (i < state._e_0_arr.length &&
                    state._e_1_arr[i - 1] === _l_par_1_L &&
                    state._e_1_arr[i] === _funk) {

                        // dev
                        state._e_0_arr.splice(i, 0, _pos_1)
                        state._e_1_arr.splice(i, 0, _l_num)

                        i = state._e_0_arr.length;

                    };

                    // num, funk
                    if (i < state._e_0_arr.length &&
                    state._e_1_arr[i - 1] === _l_num &&
                    state._e_1_arr[i] === _funk) {

                        state._e_0_arr.splice(i, 0, _sym_mul_dot);
                        state._e_1_arr.splice(i, 0, _l_ganging);

                        i = state._e_0_arr.length;

                    };

                    i -= 1;

                };

                // num, fortegn til konstanter, f.eks. 12 -> +12
                i = state._e_0_arr.length - 1
                while (i > -1) {

                    if (i < state._e_0_arr.length &&
                    state._e_0_arr[i][0] !== _sym_pluss &&
                    state._e_0_arr[i][0] !== _sym_minus &&
                    state._e_1_arr[i] === _l_num) {

                        state._e_0_arr[i] = _sym_pluss + state._e_0_arr[i]

                        i = state._e_0_arr.length;

                    };

                    i -= 1;

                };

            };

            // Disp
            if (_d === 1) {

                console.log("")
                console.log("> CREATE_EXPL_MAKE")
                console.log(">> E_0_arr")
                for (let i = 0; i < state._e_0_arr.length; i++) {
                    console.log("_e_0_arr >> ", state._e_0_arr[i])
                };
                console.log(">> E_1_arr")
                for (let i = 0; i < state._e_1_arr.length; i++) {
                    console.log("_e_1_arr >> ", state._e_1_arr[i])
                };

            };

        },
        setCreateExplParPostModify(state) {

            // Debug
            const _e        = 1;
            const _d        = 0;

            // Exe
            if (_e === 1) {

                let i = 0;
                while (i < state._e_1_arr.length) {

                    // Expo
                    if (i < state._e_1_arr.length &&
                    state._e_1_arr[i] === _l_expo &&
                    state._e_1_arr[i + 1] === _l_par_1_L) {

                        state._e_1_arr[i + 1] = `${state._e_1_arr[i + 1]}${_kol_postfix}`;

                        let l           = i + 2;
                        let _p_1_L_cnt  = 1;
                        let _p_1_R_cnt  = 0;

                        while (_p_1_R_cnt < _p_1_L_cnt) {

                            state._e_1_arr[l] = `${state._e_1_arr[l]}${_kol_postfix}`

                            if (state._e_0_arr[l] === _L_1) {

                                _p_1_L_cnt += 1;

                            };
                            if (state._e_0_arr[l] === _R_1) {

                                _p_1_R_cnt += 1;

                            };

                            l += 1;

                        };

                        i = 1;

                    };

                    // Funk
                    if (i < state._e_1_arr.length &&
                    state._e_1_arr[i] === _funk &&
                    state._e_1_arr[i + 1] === _l_par_1_L) {

                        state._e_1_arr[i + 1] = `${state._e_1_arr[i + 1]}${_kol_postfix}`

                        let l               = i + 2;
                        let _p_1_L_cnt      = 1;
                        let _p_1_R_cnt      = 0;

                        while (_p_1_R_cnt < _p_1_L_cnt) {

                            state._e_1_arr[l] = `${state._e_1_arr[l]}{_kol_postfix}`

                            if (state._e_0_arr[l] === _L_1) {

                                _p_1_L_cnt += 1;

                            };

                            if (state._e_0_arr[l] === _R_1) {

                                _p_1_R_cnt += 1;

                            };

                            l += 1;

                        };

                        i = l;

                    };

                    i += 1;

                };

            };

            // Disp
            if (_d === 1) {

                console.log("")
                console.log("> CREATE_EXPL_PAR_POST_MODIFY")
                console.log(">> E_0_arr")
                for (let i = 0; i < state._e_0_arr.length; i++) {
                    console.log("_e_0_arr >> ", state._e_0_arr[i])
                };
                console.log(">> E_1_arr")
                for (let i = 0; i < state._e_1_arr.length; i++) {
                    console.log("_e_1_arr >> ", state._e_1_arr[i])
                };

            };

        },
        setCreateExplEMat(state) {

            // Debug
            const _e        = 1;
            const _d        = 0;

            // Exe
            if (_e === 1) {

                const _add_rad = (i, k) => {

                    state._e_0_mat.push([]);
                    state._e_0_mat[k].push(state._e_0_arr[i]);

                    state._e_1_mat.push([]);
                    state._e_1_mat[k].push(state._e_1_arr[i]);

                    k += 1;

                    return k

                };

                const _add_col = (i, k) => {

                    let _k = k;

                    if (_k > 0) {

                        state._e_0_mat[_k - 1].push(state._e_0_arr[i]);
                        state._e_1_mat[_k - 1].push(state._e_1_arr[i]);

                    };

                    if (_k === 0) {

                        state._e_0_mat.push([]);
                        state._e_0_mat[_k].push(state._e_0_arr[i]);

                        state._e_1_mat.push([]);
                        state._e_1_mat[_k].push(state._e_1_arr[i]);

                        _k = _k + 1;

                    };

                    return _k

                };

                let k = 0;
                for (let i = 0; i < state._e_1_arr.length; i++) {

                    if          (state._e_1_arr[i] === _l_num)          { k = _add_rad(i, k); }

                    else if     (state._e_1_arr[i] === _l_par_1_L)      { k = _add_rad(i, k); }
                    else if     (state._e_1_arr[i] === _l_par_1_R)      { k = _add_rad(i, k); }

                    else if     (state._e_1_arr[i] === _l_lik)          { k = _add_rad(i, k); }

                    else if     (state._e_1_arr[i] === _l_pluss)        { k = _add_rad(i, k); }
                    else if     (state._e_1_arr[i] === _l_minus)        { k = _add_rad(i, k); }
                    else if     (state._e_1_arr[i] === _l_deling)       { k = _add_rad(i, k); }

                    else if     (state._e_1_arr[i] === _ln)             { k = _add_rad(i, k); }

                    else                                                { k = _add_col(i, k);};

                };

            };

            // Disp
            if (_d === 1) {

                console.log("")
                console.log("> CREATE_EXPL_E_MAT")
                console.log(">> E_0_mat")
                for (let i = 0; i < state._e_0_mat.length; i++) {
                    console.log("_e_0_mat >> ", state._e_0_mat[i])
                };
                console.log(">> E_1_arr")
                for (let i = 0; i < state._e_1_mat.length; i++) {
                    console.log("_e_1_mat >> ", state._e_1_mat[i])
                };

            };

        },
        setCreateExplTyp(state) {

            // Debug
            const _e        = 1;
            const _d        = 0;

            // Exe
            if (_e === 1) {

                for (let i = 0; i < state._e_0_mat.length; i++) {

                    if (state._e_0_mat[i][0] === _sym_lik) {

                        state._matematikk_obj_typ = _likning;

                    };

                };

            };

            // Disp
            if (_d === 1) {

                console.log("")
                console.log("> CREATE_EXPL_TYP")
                console.log(">> _matematikk_obj_typ :: ")
                console.log(">>> ", state._matematikk_obj_typ)

            };


        },

        // Impl
        setCreateImplArr(state) {

            // Debug
            const _e        = 1;
            const _d        = 0;

            // Exe
            if (_e === 1) {

                state._i_0_arr = state._t_0_arr.slice()
                state._i_1_arr = state._t_1_arr.slice()

            };

            // Disp
            if (_d === 1) {

                console.log("")
                console.log("> CREATE_IMPL_ARR")
                console.log(">> I_0_arr")
                for (let i = 0; i < state._i_0_arr.length; i++) {
                    console.log("I_0_arr >> ", state._i_0_arr[i])
                };
                console.log(">> I_1_arr")
                for (let i = 0; i < state._i_1_arr.length; i++) {
                    console.log("I_1_mat >> ", state._i_1_arr[i])
                };

            };

        },
        setCreateImplMake(state) {

            // Debug
            const _e        = 0;
            const _d        = 0;

            // Exe
            if (_e === 1) {

                // i > -1
                let i = state._i_0_arr.length - 1;
                while (i > -1) {

                    // 2023 > Fortegn > res_str = str[:2] + str[3:]
                    if (i < state._i_0_arr.length &&
                    state._i_0_arr[0].length > 0 && //  2023 > Ekstra linje for swift-justering
                    state._i_0_arr[0][0] === _sym_pluss &&
                    state._i_1_arr[1]    === _l_num) {

                        // Søkeord > Endrer_kun_en_arr

                        // Fortsettelse > Vet ikke hvordan denne fungerer

                        // i0Arr[0].remove(at: i0Arr[0].index(i0Arr[0].startIndex, offsetBy: 0))
                        state._i_0_arr[0] = ""; // DEV > Alternativ til Swift-linjen over

                        i = state._i_0_arr.length;

                    };

                    // 2023 > Fortegn > res_str = str[:2] + str[3:]
                    if (i < state._i_0_arr.length && // _i_0_arr[i - 0] == _._l_lik and
                    state._i_1_arr[i - 1] === _l_lik &&
                    state._i_0_arr[i] === _sym_pluss &&
                    state._i_1_arr[i + 1] === _l_num) {

                        // Søkeord > Endrer_kun_en_arr

                        // Fortsettelse > Vet ikke hvordan denne fungerer

                        // i0Arr[0].remove(at: i0Arr[0].index(i0Arr[0].startIndex, offsetBy: 0))

                        state._i_0_arr[i] = ""; // DEV > Alternativ til Swift-linjen over

                        i = state._i_0_arr.length;

                    };

                    i -= 1;

                };

                // i > 1
                i = state._i_0_arr.length - 1;
                while (i > 1) {

                    // Num, vari
                    if (i < state._i_0_arr.length &&
                    state._i_1_arr[i - 2] === _l_num &&
                    state._i_1_arr[i - 1] === _l_ganging &&
                    state._i_1_arr[i]     === _l_vari) {

                        state._i_0_arr.splice(i - 1, 1);
                        state._i_1_arr.splice(i - 1, 1);

                        i = state._i_0_arr.length;

                    };

                    // Num, funk
                    if (i < state._i_0_arr.length &&
                    state._i_1_arr[i - 2] === _l_num &&
                    state._i_1_arr[i - 1] === _l_ganging &&
                    state._i_1_arr[i]     === _funk) {

                        state._i_0_arr.splice(i - 1, 1);
                        state._i_1_arr.splice(i - 1, 1);

                        i = state._i_0_arr.length;

                    };

                    // Lik
                    if (i < state._i_0_arr.length &&
                    state._i_1_arr[i - 2]    === _l_lik &&
                    state._i_1_arr[i - 1][0] === _sym_pluss &&
                    state._i_1_arr[i]        === _l_num) {

                        // Søkeord > Endrer_kun_en_arr

                        // i0Arr[i - 1].remove(at: i0Arr[i - 1].index(i0Arr[i - 1].startIndex, offsetBy: 0))

                        state._i_0_arr[i - 1] = "";

                        i = state._i_0_arr.length;

                    };

                    i -= 1;

                };

                // i > 0
                i = state._i_0_arr.length - 1;
                while (i > 0) {

                    // DEV > Husk å skille mellom pluss
                    // foran num (fortegn) og ikke vanlig pluss

                    // Fortegn
                    if (i < state._i_0_arr.length &&
                    state._i_0_arr[i - 1] === _t_1 &&
                    state._i_1_arr[i]    === _l_vari) {

                        state._i_0_arr.splice(i - 1, 1);
                        state._i_1_arr.splice(i - 1, 1);

                        i = state._i_0_arr.length

                    };

                    // Funk
                    if (i < state._i_0_arr.length &&
                    state._i_0_arr[i - 1] === _t_1 &&
                    state._i_1_arr[i]    === _funk) {

                        state._i_0_arr.splice(i - 1, 1);
                        state._i_1_arr.splice(i - 1, 1);

                        i = state._i_0_arr.length;

                    };

                    // OPS
                    if (i < state._i_0_arr.length &&
                    state._i_0_arr[i - 1] === _sym_div_slash &&
                    state._i_1_arr[i][0]  === _sym_pluss) {

                        // Søkeord > Endrer_kun_en_arr

                        // i0Arr[i].remove(at: i0Arr[i].index(i0Arr[i].startIndex, offsetBy: 0))

                        state._i_0_arr[0] = "";

                        i = state._i_0_arr.length;

                    };

                    // PAR
                    if (i < state._i_0_arr.length &&
                    state._i_0_arr[i - 1] === _L_1 &&
                    state._i_1_arr[i][0]  === _sym_pluss) {

                        // Søkeord > Endrer_kun_en_arr

                        // i0Arr[i].remove(at: i0Arr[i].index(i0Arr[i].startIndex, offsetBy: 0))

                        state._i_0_arr[0] = "";

                        i = state._i_0_arr.length;

                    };

                    i -= 1;

                };

            };

            // Disp
            if (_d === 1) {

                console.log("")
                console.log("> CREATE_IMPL_MAKE")
                console.log(">> I_0_arr")
                for (let i = 0; i < state._i_0_arr.length; i++) {
                    console.log("I_0_arr >> ", state._i_0_arr[i])
                };
                console.log(">> I_1_arr")
                for (let i = 0; i < state._i_1_arr.length; i++) {
                    console.log("I_1_mat >> ", state._i_1_arr[i])
                };

            };

        },
        setCreateImplMakeStr(state) {

            // Debug
            const _e        = 1;
            const _d        = 0;

            // Exe
            if (_e === 1) {

                // Iterate > str
                for (let i = 0; i < state._i_0_arr.length; i++) {

                    state._str_impl += state._i_0_arr[i]

                };

            };

            // Disp
            if (_d === 1) {

                console.log("")
                console.log("> CREATE_IMPL_MAKE_STR")
                console.log(">> _str_impl :: ")
                console.log(">>> ", state._str_impl)

            };

        },

        // SCLV
        setCreateSCLV(state) {

            // Debug
            const _e        = 1;
            const _d        = 0;

            // Exe
            if (_e === 1) {

                for (let i = 0; i < state._t_0_arr.length; i++) {

                    state._v_arr.push(
                        get_v_el(
                            `${state._t_0_arr[i]}`,
                            spill_expr_html_init,
                            `${state._t_1_arr[i]}`,
                            -0.0,
                            -0.0,
                            -0.0,
                            -0.0,
                            -0.0,
                            -0,
                            spill_expr_col_txt_init,
                            spill_expr_col_bg_init,
                            spill_expr_debug_init
                        )
                    );

                };

            };

            // Disp
            if (_d === 1) {

                console.log("");
                console.log("> CREATE_SCLV");
                console.log(">> _v_arr");
                for (let i = 0; i < state._v_arr.length; i++) {
                    console.log("v_arr_[i] :: ", state._v_arr[i]);
                };

            };

        },

        // GUI
        setCreateGUI(state) {

            // Debug
            const _exe                  = 1;
            const _debug_html_settings  = 0; // Forhindrer grey-out
            const _debug_done_v_arr     = 1;
            const _debug_DONE           = 0;

            // Exe
            if (_exe === 1) {

                /* ****************************************** */
                /* Create expo */
                /* ****************************************** */

                state._v_arr    = create_0_expo(state._v_arr);


                /* ****************************************** */
                /* Create regular */
                /* ****************************************** */

                // Regular
                state._v_arr    = create_gui(state._v_arr, 1);


                /* ****************************************** */
                /* Debug > Done */
                /* ****************************************** */

                // Debug
                if (_debug_done_v_arr === 1) {

                    console.log("");
                    console.log("*** DONE ***");
                    // console.log("DONE > CREATE_2_REGU_SIZE_1 ::", state._debug_mega_cnt_iter);
                    for (let i = 0; i < state._v_arr.length; i++) {
                        console.log("-> _v_arr :: ", state._v_arr[i]);
                    };

                };
                if (_debug_DONE === 1) {

                    // console.log("")
                    console.log("*** DONE ***");
                };
                if (_debug_html_settings === 1) {

                    // Forskjellige final-versjoner
                    state._str_final = "<div className='tekst'>YO</div>";;
                    state._str_final = '<div style="color:red;">something</div>';
                    state._str_final = state._v_arr[0].debug;

                };


                /* ****************************************** */
                /* HTML */
                /* ****************************************** */

                // Final
                state._str_final                = state._v_arr[0].html // html / debug

                // eslint-disable-next-line
                state._str_final_curs_hidden    = state._str_final.replace(/\#cad1d9/g, col_transparent); // Rød > cad1d9 E55E52


                /* ****************************************** */
                /* Debug > str > xy */
                /* ****************************************** */

                // Debug > Init
                let _str_final_debug_xy         = state._v_arr[0].str;

                // Debug >Remove curs
                _str_final_debug_xy             = _str_final_debug_xy.replace(_sym_curs, _blank_str)

                // Debug > HTML > El > Custom
                const _is_custom_font           = 0; // 0 > CSS > spill_box_html_xy_debug
                const _debug_curs_solo          = 0;

                // Debug > Font > Init
                const _font_family_nr           = 1;
                let _font_family                = "";
                let _font_size                  = 16;

                // Debug > Font > Family
                if (_font_family_nr === 1) { _font_family = font_family_times_new_roman_ };
                if (_font_family_nr === 2) { _font_family = font_family_verdana_ };

                // Debug > HTML > El
                const _str_html_debug_xy
                    = `<div style=`
                    + '"'
                    + `font-family:             ${_font_family};`
                    + `font-size:               ${_font_size}px;`
                    + `color:                   yellow;`
                    + `background-color:        purple;`
                    + '"'
                    + `>`
                    + _str_final_debug_xy
                    + `</div>`;

                // Custom str > Debug xy > HTML > set
                if (_is_custom_font === 0) {
                    state._str_final_debug_xy   = _str_final_debug_xy;
                };
                if (_is_custom_font === 1) {
                    state._str_final_debug_xy   = _str_html_debug_xy;
                };

                // Solo
                if (_debug_curs_solo === 1) {
                    state._str_final_debug_xy = _sym_curs;
                };

                // console.log("Expo-done!")

            };

        },

        // Input
        setSolverInputReset(state) {

            // Input
            state._str_input                = _sym_curs; // "";

            state._curs_pos                 = 0;
            state._curs_moved_bool          = false;
            state._curs_timer               = 0;

        },
        setPressEl(state, action) {

            // Init
            let _key            = action.payload;

            // Debug
            const _d_inp        = 0;
            if (_d_inp === 1) { console.log("_key :: ", _key); };

            // Remove ^ som følger med key (etter at ^ allerde er lagt til på forrige key)
            if (_key.includes(_sym_expo)) {
                _key = _key.replace(_sym_expo, _blank_str);
            };

            // Curs > Add curs på _curs_pos
            state._str_input        = [
                state._str_input.slice(0, state._curs_pos),
                _key,
                state._str_input.slice(state._curs_pos)].join("");

            // Curs > Remove
            if (state._str_input.includes(_sym_curs)) {
                state._str_input    = state._str_input.replace(_sym_curs, _blank_str);
            };

            // Curs > Inc _curs_pos
            state._curs_pos         += 1;

            // Curs > Add curs på _curs_pos
            state._str_input        = [
                state._str_input.slice(0, state._curs_pos),
                _sym_curs,
                state._str_input.slice(state._curs_pos)].join(_blank_str);

            /*
            if (state._str_input.includes("^(^(")) {
                console.log("FUXXX-START")
                state._str_input    = state._str_input.replace("^(^(", "^(");
            };
            if (state._str_input.includes("$)$)")) {
                console.log("FUXXX-SLUTT")
                state._str_input    = state._str_input.replace("$)$)", "$)");
            };
            */

        },
        setPressDelete(state) {

            if (state._curs_pos > 0) {

                // Debug
                const _debug = 0;

                // Debug
                if (_debug === 1) {
                    console.log("1 ", state._str_input[state._curs_pos - 2]);
                    console.log("2 ", state._str_input[state._curs_pos - 1]);
                    console.log("3 ", state._str_input[state._curs_pos + 0]);
                    console.log("4 ", state._str_input[state._curs_pos + 1]);
                };

                const _delete_seq = () => {

                    // Delete
                    const _ix = state._curs_pos - 1;
                    state._str_input = state._str_input.slice(0, _ix) + state._str_input.slice(_ix + 1);

                    // Curs
                    state._curs_pos -= 1;

                };

                if (state._str_input[state._curs_pos - 1] === _sym_div_slash &&
                    state._str_input[state._curs_pos + 0] === _sym_curs &&
                    state._str_input[state._curs_pos + 1] === _sym_empty) { // console.log("Skip 1")

                    // Curs
                    state._curs_pos -= 2;

                    // Delete seq
                    _delete_seq();

                    // Curs > Remove
                    if (state._str_input.includes(_sym_curs)) {
                        state._str_input    = state._str_input.replace(_sym_curs, _blank_str);
                    };

                    // Curs > Add curs på _curs_pos
                    state._str_input        = [
                        state._str_input.slice(0, state._curs_pos),
                        _sym_curs,
                        state._str_input.slice(state._curs_pos)].join(_blank_str);


                // "$)|"
                }

                /*
                else if (
                    state._str_input[state._curs_pos - 2] === _sym_empty &&
                    state._str_input[state._curs_pos - 1] === _R_1 &&
                    state._str_input[state._curs_pos + 0] === _sym_curs) {

                    // Curs
                    state._curs_pos -= 2;

                    // Delete seq
                    _delete_seq();

                    // Curs > Remove
                    if (state._str_input.includes(_sym_curs)) {
                        state._str_input    = state._str_input.replace(_sym_curs, _blank_str);
                    };

                    // Curs > Add curs på _curs_pos
                    state._str_input        = [
                        state._str_input.slice(0, state._curs_pos),
                        _sym_curs,
                        state._str_input.slice(state._curs_pos)].join(_blank_str);

                // Delete "^(|$"
                }
                */

                else if (
                    state._str_input[state._curs_pos - 2] === _sym_expo &&
                    state._str_input[state._curs_pos - 1] === _L_1 &&
                    state._str_input[state._curs_pos + 0] === _sym_curs &&
                    state._str_input[state._curs_pos + 1] === _sym_empty) {

                    // Curs
                    state._curs_pos -= 2;

                    // Delete seq

                    // Delete
                    const _ix = state._curs_pos + 0; // -1
                    state._str_input = state._str_input.slice(0, _ix) + state._str_input.slice(_ix + 5);


                    // Curs > Remove
                    if (state._str_input.includes(_sym_curs)) {
                        state._str_input    = state._str_input.replace(_sym_curs, _blank_str);
                    };

                    // Curs > Add curs på _curs_pos
                    state._str_input        = [
                        state._str_input.slice(0, state._curs_pos),
                        _sym_curs,
                        state._str_input.slice(state._curs_pos)].join(_blank_str);

                // Delete fra nevn til tell
                } else if (
                    state._str_input[state._curs_pos - 1] === _L_1 &&
                    state._str_input[state._curs_pos + 0] === _sym_curs &&
                    state._str_input[state._curs_pos + 1] === _sym_empty) {

                        // Curs > Remove
                        if (state._str_input.includes(_sym_curs)) {
                            state._str_input    = state._str_input.replace(_sym_curs, _blank_str);
                        };

                        // Curs > Inc _curs_pos > Norm
                        state._curs_pos         -= 1;

                        // Curs > Add curs på _curs_pos
                        state._str_input        = [
                            state._str_input.slice(0, state._curs_pos),
                            _sym_curs,
                            state._str_input.slice(state._curs_pos)].join(_blank_str);

                        // Delete intervall (...)
                        const _ix_1 = state._curs_pos + 1;
                        const _ix_2 = state._curs_pos + 6;
                        state._str_input = state._str_input.slice(0, _ix_1) + state._str_input.slice(_ix_2);

                } else if (
                    state._str_input[state._curs_pos - 1] === _R_1 &&
                    state._str_input[state._curs_pos + 0] === _sym_curs) {

                        // Count ant par
                        let k           = state._curs_pos - 2;
                        let l           = k;
                        let _p_1_L      = 0
                        let _p_1_R      = 1
                        while (k > -1 &&
                        _p_1_L < _p_1_R) {

                            // PAR
                            if (state._str_input[k] === _L_1)   { _p_1_L += 1; }
                            if (state._str_input[k] === _R_1)   { _p_1_R += 1; }

                            k -= 1;

                        };

                        // Delete intervall (...)
                        const _ix_1 = k + 1;
                        const _ix_2 = l + 2;
                        state._str_input = state._str_input.slice(0, _ix_1) + state._str_input.slice(_ix_2);

                        // Curs > Inc _curs_pos > Norm
                        state._curs_pos         = k + 1;

                } else {

                    _delete_seq();

                };

                /* Quickfix > Etter delete expo */
                if (state._str_input.includes(`^${_sym_curs}`)) {
                    state._str_input = state._str_input.replace(`^${_sym_curs}`, _sym_curs);

                    // Curs
                    state._curs_pos -= 1;

                };

            };

        },
        setPressArrowLeft(state) {

            if (state._curs_pos > 0) {

                // Debug
                const _debug = 0;

                // Debug 2-3-4 ^(|
                if (_debug === 1) {
                    console.log("1 ", state._str_input[state._curs_pos - 3]);
                    console.log("2 ", state._str_input[state._curs_pos - 2]);
                    console.log("3 ", state._str_input[state._curs_pos - 1]);
                    console.log("4 ", state._str_input[state._curs_pos + 0]);
                    console.log("5 ", state._str_input[state._curs_pos + 1]);
                };

                // Init
                let _empty_sym_skip_ant = 1;

                // Skip > "/|$"
                if (state._str_input[state._curs_pos - 1] === _sym_div_slash &&
                    state._str_input[state._curs_pos + 0] === _sym_curs &&
                    state._str_input[state._curs_pos + 1] === _sym_empty) { // console.log("Skip 1")
                    _empty_sym_skip_ant = 2;
                };

                // Skip > "/|X"
                if (state._str_input[state._curs_pos - 1] === _sym_div_slash &&
                    state._str_input[state._curs_pos + 0] === _sym_curs) { // console.log("Skip 2")
                    _empty_sym_skip_ant = 2;
                };

                // Skip > ")|"
                if (state._str_input[state._curs_pos - 1] === _R_1 &&
                    state._str_input[state._curs_pos + 0] === _sym_curs) { // console.log("Skip 3")
                    _empty_sym_skip_ant = 2;
                };

                // Skip > ^(|
                if (state._str_input[state._curs_pos - 2] === _sym_expo &&
                    state._str_input[state._curs_pos - 1] === _L_1 &&
                    state._str_input[state._curs_pos + 0] === _sym_curs) {
                    _empty_sym_skip_ant = 2;
                };

                // Curs > Remove
                if (state._str_input.includes(_sym_curs)) {
                    state._str_input = state._str_input.replace(_sym_curs, _blank_str);
                };

                // Curs > Dec _curs_pos
                state._curs_pos         -= _empty_sym_skip_ant;

                // Curs > Add curs på _curs_pos
                state._str_input        = [
                    state._str_input.slice(0, state._curs_pos),
                    _sym_curs,
                    state._str_input.slice(state._curs_pos)].join(_blank_str);

            };

        },
        setPressArrowRight(state) {

            if (state._curs_pos < state._str_input.length - 1) {

                // Debug
                const _debug    = 0;
                const _d_skip   = 0;

                // Debug
                if (_debug === 1) {
                    // console.log("1 ", state._str_input[state._curs_pos - 3]);
                    // console.log("2 ", state._str_input[state._curs_pos - 2]);
                    console.log("1 ", state._str_input[state._curs_pos - 1]);
                    console.log("2 ", state._str_input[state._curs_pos + 0]);
                    console.log("3 ", state._str_input[state._curs_pos + 1]);
                    console.log("4 ", state._str_input[state._curs_pos + 2]);
                    console.log("5 ", state._str_input[state._curs_pos + 3]);
                    console.log("6 ", state._str_input[state._curs_pos + 4]);
                };

                let _empty_sym_skip_ant = 1;

                // Skip 1 > "$"
                if (state._str_input[state._curs_pos + 1] === _sym_empty) {
                    if (_d_skip === 1) { console.log("Skip 1"); };
                    _empty_sym_skip_ant = 2 // 2;
                };

                // Skip 2 > "|^"
                if (state._str_input[state._curs_pos + 0] === _sym_curs &&
                    state._str_input[state._curs_pos + 1] === _sym_expo) {
                    if (_d_skip === 1) { console.log("Skip 2"); };
                    _empty_sym_skip_ant = 2 // 2;
                };

                // Curs > Remove
                if (state._str_input.includes(_sym_curs)) {
                    state._str_input    = state._str_input.replace(_sym_curs, _blank_str);
                };

                // Curs > Inc _curs_pos
                state._curs_pos         += _empty_sym_skip_ant;

                // Curs > Add curs på _curs_pos
                state._str_input        = [
                    state._str_input.slice(0, state._curs_pos),
                    _sym_curs,
                    state._str_input.slice(state._curs_pos)].join(_blank_str);

            };

        },
        setPressBrok(state) {

            // Curs > Remove
            if (state._str_input.includes(_sym_curs)) {
                state._str_input = state._str_input.replace(_sym_curs, _blank_str);
            };

            // Brok > str
            const _brok
            = _L_1
            + _sym_empty
            + _sym_div_slash
            + _sym_empty
            + _R_1;

            // Curs > Add curs på _curs_pos
            state._str_input        = [
                state._str_input.slice(0, state._curs_pos),
                _brok,
                state._str_input.slice(state._curs_pos)].join(_blank_str);

            // Curs > Inc _curs_pos
            state._curs_pos         += 1;

            // Curs > Add curs på _curs_pos
            state._str_input        = [
                state._str_input.slice(0, state._curs_pos),
                _sym_curs,
                state._str_input.slice(state._curs_pos)].join(_blank_str);

        },
        setPressExpo(state) {

            // Init
            // const _key      = action.payload;

            // Debug
            const _debug = 0;

            // Debug ^(|$
            if (_debug === 1) {
                // console.log("1 ", state._str_input[state._curs_pos - 4]);
                // console.log("2 ", state._str_input[state._curs_pos - 3]);

                console.log("3 ", state._str_input[state._curs_pos - 2]);
                console.log("4 ", state._str_input[state._curs_pos - 1]);
                console.log("5 ", state._str_input[state._curs_pos + 0]);
                console.log("6 ", state._str_input[state._curs_pos + 1]);
            };

            // Init
            let _is_dobbel_expo_quickfix_go = true;

            // Quickfix > Forhindrer dobbel expo > ^(^( > JS-/browser-/input-issue med f.eks. asd^asd
            if (state._str_input[state._curs_pos - 2] === _sym_expo &&
                state._str_input[state._curs_pos - 1] === _L_1 &&
                state._str_input[state._curs_pos + 0] === _sym_curs &&
                state._str_input[state._curs_pos + 1] === _sym_empty) {

                _is_dobbel_expo_quickfix_go = false;

            };

            // Go
            if (_is_dobbel_expo_quickfix_go === true) {

                // Curs > Remove
                if (state._str_input.includes(_sym_curs)) {
                    state._str_input    = state._str_input.replace(_sym_curs, _blank_str);
                };

                const _expo
                = _sym_expo
                + _L_1
                + _sym_empty
                + _R_1;

                // Curs > Add curs på _curs_pos
                state._str_input        = [
                    state._str_input.slice(0, state._curs_pos),
                    _expo,
                    state._str_input.slice(state._curs_pos)].join(_blank_str);

                // Curs > Inc _curs_pos
                state._curs_pos         += 2;

                // Curs > Add curs på _curs_pos
                state._str_input        = [
                    state._str_input.slice(0, state._curs_pos),
                    _sym_curs,
                    state._str_input.slice(state._curs_pos)].join(_blank_str);

            };

        },

        // Curs
        setCursTimer(state, action) {

            // Init
            const _typ              = action.payload;

            // Reset / Stanard
            if (_typ === 0) {

                // Inc +1
                state._curs_timer   += 1;

            };

            // On key down
            if (_typ === 1) {

                // Set negativ før videre inc + 1
                state._curs_timer   = -1;

            };

        }

    }

});

export const {

    // Prepare
    setCreateReset,
    setCreateSplit,
    setCreateSplitInput,
    setCreateRedef,
    setCreateJoin,

    // Expl
    setCreateExplArr,
    setCreateExplMake,
    setCreateExplParPostModify,
    setCreateExplEMat,
    setCreateExplTyp,

    // Impl
    setCreateImplArr,
    setCreateImplMake,
    setCreateImplMakeStr,

    // SCLV
    setCreateSCLV,

    // GUI
    setCreateGUI,

    // Input
    setSolverInputReset,
    setPressEl,
    setPressDelete,
    setPressArrowLeft,
    setPressArrowRight,
    setPressBrok,
    setPressExpo,

    // Curs
    setCursTimer

} = solverSlice.actions;


export default solverSlice.reducer;
