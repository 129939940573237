import {useCallback, useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import AuthContext from "../../context/auth_context";

import {
    lay_standard_reset
} from "../../utils/konstanter/gen/_layout";
import {
    redux_fil, redux_ruter
} from "../../utils/konstanter/gen/_redux";
import {
    slug_root_about
} from "../../utils/konstanter/gen/_slug";
import {
    delay_footer_load
} from "../../utils/konstanter/gen/_time";
import {
    version_navn
} from "../../utils/settings/gen/_app";
import {
    get_txt
} from "../../utils/tekst/_translator/translator";
import {
    footer_copyright,
    footer_about_tittel
} from "../../utils/tekst/components/_footer";
import {
    versjon
} from "../../utils/tekst/gen/_app";

// -*-

import {
    setFilNodeFilNavnDone
} from "../fil/redux_fil_feature";
import {
    setDoRuterPre,
    setHeaderDropdownIsFalseGlobal
} from "../../pages/ruter/redux_ruter_feature";

import Logo from "../pic/logo/logo";


const Footer = () => {

    // User
    const {human_lang}                              = useContext(AuthContext);

    // Redux
    const dispatch                                  = useDispatch();

    // Load
    const [is_load_done, setIsLoadDone]             = useState(false);
    const set_is_load_done_load_async               = useCallback( () => {setTimeout(() => {setIsLoadDone(true);}, delay_footer_load);}, []);

    // Ruter
    const {ruter_slug}                              = useSelector((state) => {return state[redux_ruter]});
    const set_do_ruter_pre                          = useCallback( (arr) => {dispatch(setDoRuterPre(arr))}, [dispatch]);

    // Header
    const set_header_dropdown_is_false_global       = useCallback( () => {dispatch(setHeaderDropdownIsFalseGlobal())}, [dispatch]);

    // Fil
    const {fil_node_fil_navn_inp_arr}               = useSelector((state) => {return state[redux_fil]});
    const {fil_node_is_editing_done}                = useSelector((state) => {return state[redux_fil]});
    const set_fil_node_fil_navn_done                = useCallback( (arr) => {dispatch(setFilNodeFilNavnDone(arr))}, [dispatch]);

    // Page core
    const page_core_reset                           = () => { // console.log("footer.js")

        // Header dropdown
        set_header_dropdown_is_false_global();

        // Fil navn done
        set_fil_node_fil_navn_done([fil_node_is_editing_done, fil_node_fil_navn_inp_arr]);

    };

    // Click
    const click_footer_link                         = () => {

        // Ruter
        if (ruter_slug !== slug_root_about) {

            // Page core > Reset
            page_core_reset();

            // Ruter > Pre
            set_do_ruter_pre([slug_root_about, "", lay_standard_reset, []]);

        };

    };
    const click_logo                                = () => {

        // Navigate

    };

    // JSX
    const _footer_link                              = (tit, slug) => {
        return (
            <div
                className="txt_liten_link"
                onClick={() => click_footer_link()}>
                    {tit}
            </div>
        )
    };

    // useEffect
    useEffect( () => {

        // Load
        set_is_load_done_load_async();

        // Dep
    }, [set_is_load_done_load_async]);

    // Return
    return (
        <>
        {is_load_done &&
            <div
                className="footer_mobile"
                onClick={() => page_core_reset()}>

                    {/* Strek */}
                    <div className="strek_footer"></div>

                    {/* Links */}
                    <div className="footer_link_wrapper">

                        {/* PAUSE
                        {_footer_link(get_txt(footer_terms_tittel, human_lang), "footer_terms")}
                        {_footer_link(get_txt(footer_kurs_tittel, human_lang), "footer_kurs")}
                        */}

                        {_footer_link(get_txt(footer_about_tittel, human_lang), "footer_about")}

                    </div>

                    {/* Logo / Copyright */}
                    <div className="footer_logo_copyright">

                        {/* Logo */}
                        <Logo
                            cls      = "logo_footer"
                            on_click = {() => click_logo()}/>

                        {/* Copyright */}
                        <div className="txt_liten_grey">
                            {get_txt(footer_copyright, human_lang)}
                        </div>

                    </div>

                    {/* Versjon */}
                    <div className="txt_liten_grey">
                        {get_txt(versjon, human_lang)} {version_navn}
                    </div>

            </div>}
        </>
    )

};


export default Footer;
