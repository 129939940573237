/* ****************************************** */
/* App */
/* ****************************************** */

// Versjon
const versjon                               = "Vers";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    versjon
};

 
/* ****************************************** */
/* End */
/* ****************************************** */
