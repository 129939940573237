import {
    _R_1
} from "../../konstanter/components/_sym";


/* ****************************************** */
/* Spill */
/* ****************************************** */

// Spill
const spill_del_sym                         = _R_1;     // Oppgave 1)


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    spill_del_sym
};


/* ****************************************** */
/* End */
/* ****************************************** */
