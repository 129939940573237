import {
    redux_user_profil
} from "../../utils/konstanter/gen/_redux";
  
import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    loading:        false,
    user_profil:    {}, // Tidligere init :: ""
    errorMessage:   null
};


const userProfilSlice = createSlice({
    name:                   redux_user_profil,
    initialState:           initialState,
    reducers: {

        // Doc current
        setUserProfil(state, action) {

            // DEV > Forklar / eksempel på input-param
            state.user_profil = action.payload;

        },


    }
});


export const {

    // Doc
    setUserProfil

} = userProfilSlice.actions;


export default userProfilSlice.reducer;