/* ****************************************** */
/* Spill */
/* ****************************************** */

// Types
const _kol_postfix                          = "_k";

// Funk
const _ln                                   = "ln";
const _funk                                 = "funk";

// Obj
const _likning                              = "likning";

// lbl
const _l_empty                              = "empty";
const _l_curs                               = "curs";
const _l_num                                = "num";
const _l_vari                               = "vari";
const _l_punktum                            = "punktum";
const _l_lik                                = "lik";
const _l_pluss                              = "pluss";
const _l_minus                              = "minus";
const _l_ganging                            = "ganging";
const _l_deling                             = "deling";
const _l_expo                               = "expo";
const _l_funk                               = "funk";
const _l_root                               = "root";
const _l_par_1_L                            = "par_1_L";
const _l_par_1_R                            = "par_1_R";
const _l_kolon_semi                         = "kolon_semi";

// Funk view lbs
const _SIZE_1                               = "SIZE_1";
const _SIZE_1_EMPTY                         = "SIZE_1_EMPTY";
const _SIZE_1_CURS                          = "SIZE_1_CURS";
const _SIZE_2                               = "SIZE_2";
const _SIZE_2_EMPTY                         = "SIZE_2_EMPTY";
const _SIZE_2_CURS                          = "SIZE_2_CURS";
const _NORM                                 = "NORM";
const _NORM_NORM                            = "NORMNORM";
const _NORM_BROK                            = "NORMBROK";
const _TELL                                 = "TELL";
const _TELL_NEVN                            = "TELLNEVN";
const _NEVN                                 = "NEVN";
const _BROK                                 = "BROK";
const _BROK_NORM                            = "BROKNORM";
const _G                                    = "G";
const _E                                    = "E";
const _GE                                   = "GE";
const _R1R2                                 = "R1R2";

// The Spill
const level_1_int                           = 1;
const level_2_int                           = 2;
const level_3_int                           = 3;
const level_4_int                           = 4;
const level_5_int                           = 5;
const level_6_int                           = 6;
const list_typ_highscore                    = "_list_typ_highscore";
const list_typ_solo                         = "_list_typ_solo";
const list_typ_hist                         = "_list_typ_oppg";
const oppg_typ_del_uttrykk                  = "del_uttrykk";
const oppg_typ_del_instruks                 = "del_instruks";
const inp_str_el_disp_typ_riktig            = "_inp_str_el_disp_typ_riktig";
const inp_str_el_disp_typ_feil              = "_inp_str_el_disp_typ_feil";
const cell_txt_hidden                       = -10;
const cell_txt_header                       = -100;
const cell_txt_standard                     = 0;
const cell_rad_main                         = "_cell_rad_main";
const cell_rad_hist_small                   = "_cell_rad_hist_small";
const cell_rad_hist_big_header              = "_cell_rad_hist_big_header";
const cell_rad_hist_big_item_riktig         = "_cell_rad_hist_big_item_riktig";
const cell_rad_hist_big_item_feil           = "_cell_rad_hist_big_item_feil";

// Expr
const spill_expr_html_init                  = "_html_init";
const spill_expr_debug_init                 = "_debug_init";
const spill_expr_col_txt_init               = "_col_txt_init";
const spill_expr_col_bg_init                = "_col_bg_init";
const spill_expr_curs_blink_intervall       = 600; // ms, Microsoft > 530


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    _kol_postfix,

    _ln,
    _funk,

    _likning,

    _l_empty,
    _l_curs,
    _l_num,
    _l_vari,
    _l_punktum,
    _l_lik,
    _l_pluss,
    _l_minus,
    _l_ganging,
    _l_deling,
    _l_expo,
    _l_funk,
    _l_root,
    _l_par_1_L,
    _l_par_1_R,
    _l_kolon_semi,

    _SIZE_1,
    _SIZE_1_EMPTY,
    _SIZE_1_CURS,
    _SIZE_2,
    _SIZE_2_EMPTY,
    _SIZE_2_CURS,
    _NORM,
    _NORM_NORM,
    _NORM_BROK,
    _TELL,
    _TELL_NEVN,
    _NEVN,
    _BROK,
    _BROK_NORM,
    _G,
    _E,
    _GE,
    _R1R2,

    level_1_int,
    level_2_int,
    level_3_int,
    level_4_int,
    level_5_int,
    level_6_int,
    list_typ_highscore,
    list_typ_solo,
    list_typ_hist,
    oppg_typ_del_uttrykk,
    oppg_typ_del_instruks,
    inp_str_el_disp_typ_riktig,
    inp_str_el_disp_typ_feil,
    cell_txt_hidden,
    cell_txt_header,
    cell_txt_standard,
    cell_rad_main,
    cell_rad_hist_small,
    cell_rad_hist_big_header,
    cell_rad_hist_big_item_riktig,
    cell_rad_hist_big_item_feil,

    spill_expr_html_init,
    spill_expr_debug_init,
    spill_expr_col_txt_init,
    spill_expr_col_bg_init,
    spill_expr_curs_blink_intervall
};


/* ****************************************** */
/* End */
/* ****************************************** */
