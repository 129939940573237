import {
    redux_page_core
} from "../../utils/konstanter/gen/_redux";

import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    scroll_pos_yp:                  0,          // Søkeord > Scroll_yp
    scroll_pos_yp_is_done:          false,      // Søkeord > Scroll_yp

};

const pageCoreSlice = createSlice({
    name:               redux_page_core,
    initialState:       initialState,
    reducers: {

        // Scroll > Søkeord > Scroll_yp
        setScrollPosYP(state, action) {

            // DEV > Forklar input param
            state.scroll_pos_yp = action.payload;

        },
        setScrollPosYPIsDone(state, action) {

            // DEV > Forklar input param
            state.scroll_pos_yp_is_done = action.payload;

        },

    }
});


export const {

    setScrollPosYP,
    setScrollPosYPIsDone,

} = pageCoreSlice.actions;

export default pageCoreSlice.reducer;
