/* ****************************************** */
/* Slug */
/* ****************************************** */

// Root
const slug_root                             = "root";
const slug_root_landing                     = "root_landing";

// Ruter
const slug_root_ruter_psaudo                = "root_ruter";

// Mal
const slug_root_mal                         = "root_mal";

// About
const slug_root_about                       = "root_about";

// Adminx
const slug_root_adminx                      = "root_adminx";

// Doc
const slug_root_doc_psaudo                  = "root_doc";

// Profil
const slug_root_profil                      = "root_profil";
const slug_root_profil_disp                 = "root_profil_profil-disp";
const slug_root_profil_set                  = "root_profil_profil-set";
const slug_root_profil_list                 = "root_profil_user-profil-list";

// Reg
const slug_root_reg_psaudo                  = "root_reg_psaudo";
const slug_root_signup                      = "root_signup";
const slug_root_signin                      = "root_signin";
const slug_root_signin_epost_confirm        = "root_signin-epost-confirm"; // Egentlig signup ikke signin
const slug_root_signup_success_psaudo       = "signup-success";
const slug_root_signin_glemt_passord        = "root_signin-glemt-passord";
const slug_root_signin_glemt_ps_confirm     = "root_signin-glemt-passord-confirm";
const slug_root_signin_glemt_ps_fra_epost   = "root_signin-glemt-passord-fra-epost";
const slug_root_signout                     = "root_signout";

// Quest
const slug_root_quest                       = "root_quest";
const slug_root_quest_create                = "root_quest_quest-create";
const slug_root_quest_item                  = "root_quest_quest-list_quest-item"; // "root_quest_quest-item";
const slug_root_quest_list                  = "root_quest_quest-list";

// Spill
const slug_root_spill_tmp                   = "root_spill";

// The Editor
const slug_root_the_editor                  = "root_the-editor";

// Lang
const slug_root_lang                        = "root_lang";
const slug_root_lang_python                 = "root_lang_python";
const slug_root_lang_python_eksamen         = "root_lang_python_eksamen";
const slug_root_lang_python_funksjoner      = "root_lang_python_funksjoner";
const slug_root_lang_python_programmer      = "root_lang_python_programmer";
const slug_root_lang_python_oppgaver        = "root_lang_python_oppgaver";
const slug_root_lang_python_opplaring       = "root_lang_python_opplaring";
const slug_root_lang_python_tester          = "root_lang_python_tester";
const slug_root_lang_css                    = "root_lang_css";
const slug_root_lang_javascript             = "root_lang_javascript";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    slug_root,
    slug_root_landing,

    slug_root_ruter_psaudo,

    slug_root_mal,

    slug_root_about,

    slug_root_adminx,

    slug_root_doc_psaudo,

    slug_root_profil,
    slug_root_profil_disp,
    slug_root_profil_set,
    slug_root_profil_list,

    slug_root_reg_psaudo,
    slug_root_signup,
    slug_root_signin,
    slug_root_signin_epost_confirm,
    slug_root_signup_success_psaudo,
    slug_root_signin_glemt_passord,
    slug_root_signin_glemt_ps_confirm,
    slug_root_signin_glemt_ps_fra_epost,
    slug_root_signout,

    slug_root_quest,
    slug_root_quest_create,
    slug_root_quest_item,
    slug_root_quest_list,

    slug_root_spill_tmp,

    slug_root_the_editor,

    slug_root_lang,
    slug_root_lang_python,
    slug_root_lang_python_eksamen,
    slug_root_lang_python_funksjoner,
    slug_root_lang_python_programmer,
    slug_root_lang_python_oppgaver,
    slug_root_lang_python_opplaring,
    slug_root_lang_python_tester,
    slug_root_lang_css,
    slug_root_lang_javascript
};


/* ****************************************** */
/* End */
/* ****************************************** */
