/* ****************************************** */
/* Tekst */
/* ****************************************** */

const _siffer_spacing                       = (index) => { // Ubrukt

    const _str = String(index);

    if (_str.length === 2)                  {return `  ${index}`};
    if (_str.length === 3)                  {return ` ${index}`};
    if (_str.length === 4)                  {return `${index}`};

    return ""

};

const tekst_debug                           = (typ, tid, filnavn, fn, vari, val) => {

    // Init
    let _typ        = "";
    let _tid        = "";
    let _filnavn    = "";
    let _fn         = "";
    let _vari       = "";
    let _val        = "";

    if (typ)        {_typ           = `${typ} `};
    if (tid)        {_tid           = `> ${_siffer_spacing(tid)} `};
    if (filnavn)    {_filnavn       = `> ${filnavn} `};
    if (fn)         {_fn            = `> ${fn} `};
    if (vari)       {_vari          = `> ${vari} :: `};
    if (val)        {_val           = `${val} `};

    const _ret = `${_typ}${_tid}${_filnavn}${_fn}${_vari}${_val}`

    return _ret

};


/* ****************************************** */
/* Export */
/* ****************************************** */

export {

    tekst_debug

};