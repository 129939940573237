/* ****************************************** */
/* Spill */
/* ****************************************** */

// Spill
const spill_matte_velg_level                = "_brokmaskin_level_velg";
const spill_matte_cancel                     = "_brokmaskin_cancel";
const level_1_txt                           = "_level_1";
const level_2_txt                           = "_level_2";
const level_3_txt                           = "_level_3";
const level_4_txt                           = "_level_4";
const level_5_txt                           = "_level_5";
const level_6_txt                           = "_level_6";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    spill_matte_velg_level,
    spill_matte_cancel,
    level_1_txt,
    level_2_txt,
    level_3_txt,
    level_4_txt,
    level_5_txt,
    level_6_txt
};


/* ****************************************** */
/* End */
/* ****************************************** */
