/* ****************************************** */
/* Master */
/* ****************************************** */
 
// Initx
const _debug_master_initx                   = 0;        // Initx

// Cont
const _debug_master_cont                    = 0;        // Cont

// Urly
const _debug_master_urly                    = 0;        // ...

// Ruter
const _debug_master_ruter_slow              = 0;        // ...

// Reg
const _debug_master_signup_all              = 0;        // ...
const _debug_master_signin_all              = 0;        // ...


/* ****************************************** */
/* Initx */
/* ****************************************** */

let debug_app_root_initx                    = 0;        // Initx
let debug_auth_initx                        = 0;        // ...
let debug_header_use_effect_initx           = 0;        // ...
let debug_header_fetch_pre_initx            = 0;        // ...
let debug_header_fetch_post_initx           = 0;        // ...
let debug_path_use_effect_initx             = 0;        // ...
let debug_path_use_effect_doc_true_initx    = 0;        // ...
let debug_path_fetch_pre_initx              = 0;        // ...
let debug_path_fetch_post_doc_initx         = 0;        // ...
let debug_landing_use_effect_initx          = 0;        // ...
let debug_landing_fetch_db_inix_pre         = 0;        // ...
let debug_landing_fetch_db_inix_post        = 0;        // ...


/* ****************************************** */
/* Continue */
/* ****************************************** */

let debug_header_click_doc_par_cont         = 0;        // Cont
let debug_header_click_doc_ch_cont          = 0;        // ...
let debug_path_click_item_cont              = 0;        // ...
let debug_path_use_effect_cont              = 0;        // ...
let debug_ruter_use_effect_slug_cont        = 0;        // ...
let debug_ruter_use_effect_route_doc_cont   = 0;        // ...
let debug_ruter_use_effect_route_cont       = 0;        // ...
let debug_adminx_use_effect_cont            = 0;        // ...


/* ****************************************** */
/* Urly */
/* ****************************************** */

let debug_landing_use_effect_urly           = 0;        // Urly



/* ****************************************** */
/* Ruter */
/* ****************************************** */

// Mellomstopp
const debug_ruter_mellomstop                = 0;        // 0: Navigerer til route, 1: Stopper på ruter

// Load / fetch
let debug_ruter_page_core_visible           = 0;        // 0: Viser kun spinner, 1: Viser page core
let debug_load_control_slow_jsx             = 0;        // 0: Viser ikke de 3 stegene, men kun f.eks. page core (hvis ikke den også er skjult), 1: Viser de 3 stegene, 1) Data fetching, 2) Data received, 3) Navigert til page
let debug_load_control_is_slow              = 0;        // 0: Vanlig delay, 1: Legger til lang ekstra tid alle delay,
const debug_load_control_slow_delay         = 3000;     // Legg til ekstra delay


/* ****************************************** */
/* Context */
/* ****************************************** */

// Signup > Console
let debug_auth_signup_fetch_signup_user     = 0;        // DEV
let debug_auth_signup_fetch_user_profil     = 0;        // DEV
let debug_auth_fetch_confirmation_redirect  = 0;        // DEV
let debug_auth_fetch_signout_user           = 0;        // DEV

// Signin > Console
let debug_auth_fetch_await_signin_user      = 0;        // DEV


/* ****************************************** */
/* Util */
/* ****************************************** */

// _util_bg
const debug_page_core_bg_col                = 0;        // DEV
const debug_page_core_style                 = 1;        // DEV


/* ****************************************** */
/* Master av / på */
/* ****************************************** */

// Initx
if (_debug_master_initx === 1) {

    debug_app_root_initx                    = 1;
    debug_auth_initx                        = 1;
    debug_header_use_effect_initx           = 1;
    debug_header_fetch_pre_initx            = 1;
    debug_header_fetch_post_initx           = 1;
    debug_path_use_effect_initx             = 1;
    debug_path_use_effect_doc_true_initx    = 1;
    debug_path_fetch_pre_initx              = 1;
    debug_path_fetch_post_doc_initx         = 1;
    debug_landing_use_effect_initx          = 1;
    debug_landing_fetch_db_inix_pre         = 1;
    debug_landing_fetch_db_inix_post        = 1;

};

// Continue
if (_debug_master_cont === 1) {

    debug_header_click_doc_par_cont         = 1;
    debug_header_click_doc_ch_cont          = 1;
    debug_path_click_item_cont              = 1;
    debug_path_use_effect_cont              = 1;
    debug_ruter_use_effect_slug_cont        = 1;
    debug_ruter_use_effect_route_doc_cont   = 1;
    debug_ruter_use_effect_route_cont       = 1;
    debug_adminx_use_effect_cont            = 1;

};

// Urly
if (_debug_master_urly === 1) {

    debug_landing_use_effect_urly                      = 1;

};

// Ruter
if (_debug_master_ruter_slow === 1) {

    debug_ruter_page_core_visible           = 1;
    debug_load_control_slow_jsx             = 1;
    debug_load_control_is_slow              = 1;

};

// Reg > Signup
if (_debug_master_signup_all === 1) {

    // Auth
    debug_auth_signup_fetch_signup_user     = 1;
    debug_auth_signup_fetch_user_profil     = 1;
    debug_auth_fetch_confirmation_redirect  = 1;
    debug_auth_fetch_signout_user           = 1;

};

// Reg > Signin
if (_debug_master_signin_all === 1) {

    debug_auth_fetch_await_signin_user      = 1;

};




/* ****************************************** */
/* Export */
/* ****************************************** */

export {

    // Initx
    debug_app_root_initx,
    debug_auth_initx,
    debug_header_use_effect_initx,
    debug_header_fetch_pre_initx,
    debug_header_fetch_post_initx,
    debug_path_use_effect_initx,
    debug_path_use_effect_doc_true_initx,
    debug_path_fetch_pre_initx,
    debug_path_fetch_post_doc_initx,
    debug_landing_use_effect_initx,
    debug_landing_fetch_db_inix_pre,
    debug_landing_fetch_db_inix_post,

    // Cont
    debug_header_click_doc_par_cont,
    debug_header_click_doc_ch_cont,
    debug_path_click_item_cont,
    debug_path_use_effect_cont,
    debug_ruter_use_effect_slug_cont,
    debug_ruter_use_effect_route_doc_cont,
    debug_ruter_use_effect_route_cont,
    debug_adminx_use_effect_cont,

    // Urly
    debug_landing_use_effect_urly,

    // Ruter
    debug_ruter_mellomstop,
    debug_ruter_page_core_visible,
    debug_load_control_slow_jsx,
    debug_load_control_is_slow,
    debug_load_control_slow_delay,

    // Signup
    debug_auth_signup_fetch_signup_user,
    debug_auth_signup_fetch_user_profil,
    debug_auth_fetch_confirmation_redirect,
    debug_auth_fetch_signout_user,

    // Signin
    debug_auth_fetch_await_signin_user,

    // Util
    debug_page_core_bg_col,
    debug_page_core_style

};


/* ****************************************** */
/* End */
/* ****************************************** */
