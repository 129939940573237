/* ****************************************** */
/* Error */
/* ****************************************** */

// Kode
const err_msg_auth_context_signin_user_400_error            = "_err_msg_auth_context_signin_user_400_error";
const err_msg_auth_context_signin_user_401_error            = "_err_msg_auth_context_signin_user_401_error";
const err_msg_auth_context_signin_user_404_error            = "_err_msg_auth_context_signin_user_404_error";
const err_msg_auth_context_signin_user_405_error            = "_err_msg_auth_context_signin_user_405_error";
const err_msg_auth_context_signin_user_ukjent_status_kode   = "_err_msg_auth_context_signin_user_ukjent_status_kode";
const err_msg_auth_update_token_400_error                   = "_err_msg_auth_update_token_400_error";
const err_msg_auth_update_token_401_error                   = "_err_msg_auth_update_token_401_error";
const err_msg_auth_update_token_404_error                   = "_err_msg_auth_update_token_404_error";
const err_msg_auth_update_token_ukjent_status_kode          = "_err_msg_auth_update_token_ukjent_status_kode";

// Custom
const err_msg_user_profil_redux_user_profil_feature_error   = "_err_msg_user_profil_redux_user_profil_feature_error";
const err_msg_quest_item_create_catch                       = "_err_msg_quest_item_create_catch"
const err_msg_redux_user_profil_feature_slice               = "_err_msg_redux_user_profil_feature_slice";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    err_msg_auth_context_signin_user_400_error,
    err_msg_auth_context_signin_user_401_error,
    err_msg_auth_context_signin_user_404_error,
    err_msg_auth_context_signin_user_405_error,
    err_msg_auth_context_signin_user_ukjent_status_kode,
    err_msg_auth_update_token_400_error,
    err_msg_auth_update_token_401_error,
    err_msg_auth_update_token_404_error,
    err_msg_auth_update_token_ukjent_status_kode,

    err_msg_user_profil_redux_user_profil_feature_error,
    err_msg_quest_item_create_catch,
    err_msg_redux_user_profil_feature_slice
};


/* ****************************************** */
/* End */
/* ****************************************** */
