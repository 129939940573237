import {
    alfa_eng_small
} from "../konstanter/components/_alfabet";
import {
    txt_regex_url_clean
} from "../tekst/_param/param";
import {
    spill_del_sym
} from "../settings/pages/_spill";

const tekst_format_json = (str) => {

    // Vari
    const _str = str
    
    // Return
    return _str
};

const tekst_letter_cap_first = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

const tekst_line_counter = (yy_str) => { // Ubrukt
    // return yy_str.split(/\r\n|\r|\n/).length
    return (yy_str.match(/\n/g) || '').length + 1 // + 1
};

const url_clean = (url) => {
    // Clean url > Fjerner double slash etter f.eks. https://www.autogazer.com/#/quest/Quest_importere_funksjoner_fra_andre_filer
    const _clean_url    = url.replace(txt_regex_url_clean, "$1/");

    return _clean_url
};

const fil_ext_dotless = (fil_ext) => {

    // F.eks. ".html" > "html"
    const _fil_ext_dotless = fil_ext.slice(1, fil_ext.length);

    return _fil_ext_dotless

};

// Spill
const del_navn_sym = (int) => {

    // Finn alfa, f.eks. "a"
    const _del_navn = alfa_eng_small[int] + spill_del_sym

    // Return alfa, f.eks. "a"
    return _del_navn

};


export {
    tekst_format_json,
    tekst_letter_cap_first,
    tekst_line_counter,
    url_clean,
    fil_ext_dotless,
    del_navn_sym
};
