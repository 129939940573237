/* ****************************************** */
/* Landing */
/* ****************************************** */

// Mellom
const landing_tittel_sub                    = "_landing_tittel_sub";

// Info
const landing_info_1                        = "_lading_info_1";
const landing_info_2                        = "_lading_info_2";
const landing_info_3                        = "_lading_info_3";
const landing_info_4                        = "_lading_info_4";

// Lang desc
const landing_lang_tit_python               = "_landing_lang_tit_python";
const landing_lang_tit_js                   = "_landing_lang_tit_js";
const landing_lang_tit_html                 = "_landing_lang_tit_html";
const landing_lang_tit_css                  = "_landing_lang_tit_css";

// Lang desc
const landing_lang_desc_python              = "_landing_lang_desc_python";
const landing_lang_desc_js                  = "_landing_lang_desc_js";
const landing_lang_desc_html                = "_landing_lang_desc_html";
const landing_lang_desc_css                 = "_landing_lang_desc_css";

const landing_cards_tekst_arr               = [
    {
        tittel:     landing_lang_tit_python,
        content:    landing_lang_desc_python
    },
    {
        tittel:     landing_lang_tit_js,
        content:    landing_lang_desc_js
    },
    {
        tittel:     landing_lang_tit_html,
        content:    landing_lang_desc_html
    },
    {
        tittel:     landing_lang_tit_css,
        content:    landing_lang_desc_css,
    },
]


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    landing_tittel_sub,

    landing_info_1,
    landing_info_2,
    landing_info_3,
    landing_info_4,

    landing_lang_tit_python,
    landing_lang_tit_js,
    landing_lang_tit_html,
    landing_lang_tit_css,

    landing_lang_desc_python,
    landing_lang_desc_js,
    landing_lang_desc_html,
    landing_lang_desc_css,

    landing_cards_tekst_arr
};


/* ****************************************** */
/* End */
/* ****************************************** */
