/* ****************************************** */
/* Switcher */
/* ****************************************** */

import {
    human_lang_init,
    _eng,
    _no,
    eng_tittel,
    no_tittel
} from "../_param/param";
import {
    alert_email_confirm,
    alert_fil_navn_opptatt,
    alert_signup_success,
    alert_signin_glemt_pass,
    alert_signin_glemt_ps_confirm_info_1,
    alert_signin_glemt_ps_confirm_info_2,
    alert_signin_wrong_password,
    alert_username_updated_signin_again
} from "../components/_alert";
import {
    btn_edit,
    btn_upload_bilde,
    btn_update,
    btn_update_profil,
    btn_update_profil_bilde,
    btn_publish,
    btn_delete,
    btn_complete,
    btn_cancel,
    btn_load,
    btn_dump,
    btn_cycle,
    btn_cleanup_bu,
    btn_remove,
    btn_add,
    btn_push,
    btn_mal_single,
    btn_mal_many
} from "../components/_btn";
import {
    crop_bilde
} from "../components/_cropper";
import {
    editor_kode_the_editor_navn_small,
    editor_kode_kode_small,
    editor_kode_programmer_siste_small,
    editor_kode_term_tittel,
    editor_kode_term_frontend,
    editor_kode_file_tittel,
    editor_kode_placeholder,
    editor_kode_ny_editor,
    editor_kode_kopier_editor,
    editor_kode_delete_editor,
    editor_kode_home_tittel_1,
    editor_kode_home_tittel_2
} from "../components/_editor_kode";
import {
    fil_download_zip_navn_prefix,
    fil_bytes_txt,
    fil_KB_txt,
    fil_MB_txt,
    fil_GB_txt,
    fil_TB_txt,
    fil_0_bytes_txt,
    fil_create_fil_txt,
    fil_create_folder_txt,
    fil_drop_zone_fil_type_ikke_gyldig,
    fil_drop_zone_fil_type_ugyldig_fjern
} from "../components/_fil";
import {
    filter_nyeste_txt,
    filter_topp_txt,
    filter_tittel_txt
} from "../components/_filter";
import {
    footer_tittel_sub,
    footer_copyright,
    footer_terms_tittel,
    footer_kurs_tittel,
    footer_about_tittel,
    footer_about_tittel_sub
} from "../components/_footer";
import {
    msg_err_auth_context_400_error,
    msg_err_auth_context_401_error
} from "../components/_msg";
import {
    bokstaver_igjen,
    bokstav_igjen
} from "../components/_textarea";
import {
    visninger
} from "../components/_page_core";
import {
    tabell_header_rad_nr,
    tabell_header_spiller,
    tabell_header_poeng,
    tabell_header_tid,
    tabell_header_tries,
    tabell_header_oppg,
    tabell_header_del,
    tabell_header_steg
} from "../components/_tabell";
import {
    tagg_tittel
} from "../components/_tagg";
import {
    vote_stemmer,
    vote_svar
} from "../components/_vote";
import {
    err_msg_auth_context_signin_user_400_error,
    err_msg_auth_context_signin_user_401_error,
    err_msg_auth_context_signin_user_404_error,
    err_msg_auth_context_signin_user_405_error,
    err_msg_auth_context_signin_user_ukjent_status_kode,
    err_msg_auth_update_token_400_error,
    err_msg_auth_update_token_401_error,
    err_msg_auth_update_token_404_error,
    err_msg_auth_update_token_ukjent_status_kode,
    err_msg_user_profil_redux_user_profil_feature_error,
    err_msg_quest_item_create_catch,
    err_msg_redux_user_profil_feature_slice
} from "../gen/_error";
import {
    kommentar_create
} from "../gen/_kommentar";
import {
    versjon
} from "../gen/_app";
import {
    push_el_new_file,
    add_dir,
    remove_dir,
    admin_db_tittel,
    admin_user_profil_disp_data,
    admin_json_backend_cap,
    admin_user_profil_disp_tittel_cap,
    admin_user_arr_tittel_cap,
    admin_email_developer_cap,
    admin_test_navigation_cap,
    admin_test_navigation_btn
} from "../pages/_adminx";
import {
    landing_tittel_sub,
    landing_info_1,
    landing_info_2,
    landing_info_3,
    landing_info_4,
    landing_lang_tit_python,
    landing_lang_tit_js,
    landing_lang_tit_html,
    landing_lang_tit_css,
    landing_lang_desc_python,
    landing_lang_desc_js,
    landing_lang_desc_html,
    landing_lang_desc_css
} from "../pages/_landing";
import {
    no_url_info
} from "../pages/_no_url";
import {
    user_profil_tittel,
    user_profil_img_profil_tittel_txt,
    user_profil_navn,
    user_profil_navn_placeholder,
    user_profil_public_email,
    user_profil_public_email_placeholder,
    user_profil_bio,
    user_profil_bio_placeholder,
    user_profil_url_public,
    user_profil_url_public_placeholder,
    user_profil_company,
    user_profil_company_placeholder,
    user_profil_item_tittel_1,
    user_profil_item_tittel_2,
    user_profil_item_tittel_3,
    user_profil_item_tittel_4,
    user_profil_account_tittel,
    user_profil_account_konto_gruppe,
    user_profil_account_item_public_profil_tittel,
    user_profil_account_item_account_tittel,
    user_profil_account_item_lang_tittel,
    user_profil_account_item_account_passord_endret_alert_success_confirm,
    user_profil_account_item_account_passord_endret_alert_fail_confirm,
    user_profil_account_item_notifications_tittel,
    user_profil_account_item_email_tittel,
    usernavn_nytt_tittel,
    usernavn_nytt_btn_endre,
    usernavn_nytt_btn_update,
    password_nytt_tittel,
    password_nytt_btn_endre,
    password_nytt_btn_update,
    password_dum,
    password_bekreft_tittel
} from "../pages/_profil";
import {
    signup_tittel,
    signup_success_tittel,
    signup_allerede_reg,
    signin_tittel,
    signin_ny_prog,
    signin_glemt_pass,
    signin_glemt_pass_tittel,
    signin_glemt_pass_confirm,
    signin_glemt_pass_confirm_btn,
    signin_glemt_pass_sett_nytt,
    signin_glemt_pass_sett_nytt_fra_epost,
    signout_tittel,
    reg_ugyldig,
    username_tittel,
    username_sub_ikke_gyldig,
    username_sub_ikke_gyldig_opptatt,
    username_sub_gyldig,
    username_sub_gyldig_max,
    email_tittel,
    email_sub_ugyldig,
    email_sub_gyldig,
    email_sub_gyldig_max,
    reg_email_send_user_confirm_developer_txt,
    reg_email_send_glemt_passord_developer_txt,
    password_tittel,
    password_sub_gyldig,
    password_sub_ikke_gyldig,
    password_svakt,
    password_middels,
    password_sterkt,
    password_max_lengde,
    password_for_langt
} from "../pages/_reg";
import {
    questions_small,
    quest_tittel_create_txt,
    quest_tittel_beskrivelse_txt,
    quest_tittel_drop_zone_txt,
    quest_create_post_txt,
    quest_spurt,
    quest_aktiv,
    quest_i_dag,
    quest_author_spurt,
    quest_list_create_btn,
    quest_list_create_tittel,
    quest_ord_Quest,
    uni_root_quest_quest_list_quest_item
} from "../pages/_quest";
import {
    spill_matte_velg_level,
    spill_matte_cancel,
    level_1_txt,
    level_2_txt,
    level_3_txt,
    level_4_txt,
    level_5_txt,
    level_6_txt
} from "../pages/_spill";
import {
    txt_about_eng,
    txt_about_no
} from "../tekster/_about";
import {
    _human_lang_default
} from "../../settings/gen/_human_lang";
import {
    nettside,
    selskap_as
} from "../../settings/pages/_landing";


/* ****************************************** */
/* Init */
/* ****************************************** */

const _                                     = true


/* ****************************************** */
/* Get txt */
/* ****************************************** */

// Is
const _is_txt = (txt, txt_typ, human_lang, human_lang_typ) => {

    if (txt === txt_typ && human_lang === human_lang_typ) { return true }

    return false

};

// Get
const get_txt = (txt, lang) => {

    /* ****************************************** */
    /* Default */
    /* ****************************************** */

    if (lang === human_lang_init)           { lang = _human_lang_default }


    /* ****************************************** */
    /* Components */
    /* ****************************************** */

    // Alert
    if (_) {

        if (_is_txt(txt, alert_email_confirm,               lang, _eng))    { return "Confirm the user in the mail you´ve received." };
        if (_is_txt(txt, alert_email_confirm,               lang, _no))     { return "Bekreft brukeren i eposten som vi har sendt." };

        if (_is_txt(txt, alert_fil_navn_opptatt,            lang, _eng))    { return "A file or folder already exists at this location. Please choose a different name." };
        if (_is_txt(txt, alert_fil_navn_opptatt,            lang, _no))     { return "Navnet er opptatt i denne folderen" };

        if (_is_txt(txt, alert_signup_success,              lang, _eng))    { return "Congratulations! You can now log in." };
        if (_is_txt(txt, alert_signup_success,              lang, _no))     { return "Gratulerer! Du kan nå logge inn." };

        if (_is_txt(txt, alert_signin_glemt_pass,           lang, _eng))    { return "We´ll send you the reset link to your mail" };
        if (_is_txt(txt, alert_signin_glemt_pass,           lang, _no))     { return "Vi sender reset-linken til eposten din" };

        if (_is_txt(txt, alert_signin_glemt_ps_confirm_info_1,    lang, _eng))   { return "We have sendt you the reset link to your email" };
        if (_is_txt(txt, alert_signin_glemt_ps_confirm_info_1,    lang, _no))    { return "Vi har sendt resett-linken til din epost." }; // Ps reset #1

        if (_is_txt(txt, alert_signin_glemt_ps_confirm_info_2,    lang, _eng))   { return "Type your new password here." };
        if (_is_txt(txt, alert_signin_glemt_ps_confirm_info_2,    lang, _no))    { return "Skriv det nye passordet her." }; // Ps reset #2

        if (_is_txt(txt, alert_signin_wrong_password,       lang, _eng))   { return "Wrong username or password." };
        if (_is_txt(txt, alert_signin_wrong_password,       lang, _no))    { return "Feil brukernavn eller passord." };

        if (_is_txt(txt, alert_username_updated_signin_again,       lang, _eng))   {return "Sign in with your new username."};
        if (_is_txt(txt, alert_username_updated_signin_again,       lang, _no))    {return "Logg inn med det nye brukernavnet."};

    };

    // Btn
    if (_) {

        if (_is_txt(txt, btn_edit,                          lang, _eng))   { return "Edit" };
        if (_is_txt(txt, btn_edit,                          lang, _no))    { return "Edit" };

        if (_is_txt(txt, btn_upload_bilde,                  lang, _eng))   { return "Upload new profilbilde" };
        if (_is_txt(txt, btn_upload_bilde,                  lang, _no))    { return "Last opp nytt profilbilde" };

        if (_is_txt(txt, btn_update,                        lang, _eng))   { return "Update" };
        if (_is_txt(txt, btn_update,                        lang, _no))    { return "Oppdater" };

        if (_is_txt(txt, btn_update_profil,                 lang, _eng))   { return "Update profile" };
        if (_is_txt(txt, btn_update_profil,                 lang, _no))    { return "Oppdater profil" };

        if (_is_txt(txt, btn_update_profil_bilde,           lang, _eng))   { return "Update profile image" };
        if (_is_txt(txt, btn_update_profil_bilde,           lang, _no))    { return "Oppdater bilde" };

        if (_is_txt(txt, btn_publish,                       lang, _eng))   { return "Publish" };
        if (_is_txt(txt, btn_publish,                       lang, _no))    { return "Publiser" };

        if (_is_txt(txt, btn_delete,                        lang, _eng))   { return "Delete" };
        if (_is_txt(txt, btn_delete,                        lang, _no))    { return "Slett" };

        if (_is_txt(txt, btn_complete,                      lang, _eng))   { return "Complete the change" };
        if (_is_txt(txt, btn_complete,                      lang, _no))    { return "Fullfør endringen" };

        if (_is_txt(txt, btn_cancel,                        lang, _eng))   { return "Cancel" };
        if (_is_txt(txt, btn_cancel,                        lang, _no))    { return "Avbryt" };

        if (_is_txt(txt, btn_load,                          lang, _eng))   { return "Load" };
        if (_is_txt(txt, btn_load,                          lang, _no))    { return "Load" };

        if (_is_txt(txt, btn_dump,                          lang, _eng))   { return "Dump" };
        if (_is_txt(txt, btn_dump,                          lang, _no))    { return "Dump" };

        if (_is_txt(txt, btn_cycle,                         lang, _eng))   { return "Cycle" };
        if (_is_txt(txt, btn_cycle,                         lang, _no))    { return "Cycle" };

        if (_is_txt(txt, btn_cleanup_bu,                    lang, _eng))   { return "Cleanup" };
        if (_is_txt(txt, btn_cleanup_bu,                    lang, _no))    { return "Cleanup" };

        if (_is_txt(txt, btn_remove,                        lang, _eng))   { return "Remove" };
        if (_is_txt(txt, btn_remove,                        lang, _no))    { return "Remove" };

        if (_is_txt(txt, btn_add,                           lang, _eng))   { return "Add" };
        if (_is_txt(txt, btn_add,                           lang, _no))    { return "Add" };

        if (_is_txt(txt, btn_push,                          lang, _eng))   { return "Push" };
        if (_is_txt(txt, btn_push,                          lang, _no))    { return "Push" };

        if (_is_txt(txt, btn_mal_single,                    lang, _eng))   { return "Mal get single!" };
        if (_is_txt(txt, btn_mal_single,                    lang, _no))    { return "Mal get single!" };

        if (_is_txt(txt, btn_mal_many,                      lang, _eng))   { return "Mal get many!" };
        if (_is_txt(txt, btn_mal_many,                      lang, _no))    { return "Mal get many!" };

    };

    // Cards
    if (_) {

        // Tittel
        if (_is_txt(txt, landing_lang_tit_python,           lang, _eng))   { return "Python" };
        if (_is_txt(txt, landing_lang_tit_python,           lang, _no))    { return "Python" };

        if (_is_txt(txt, landing_lang_tit_js,               lang, _eng))   { return "Frontend Development" };
        if (_is_txt(txt, landing_lang_tit_js,               lang, _no))    { return "Frontend-utvikling" };

        if (_is_txt(txt, landing_lang_tit_html,             lang, _eng))   { return "HTML" };
        if (_is_txt(txt, landing_lang_tit_html,             lang, _no))    { return "HTML" };

        if (_is_txt(txt, landing_lang_tit_css,              lang, _eng))   { return "CSS and SASS" };
        if (_is_txt(txt, landing_lang_tit_css,              lang, _no))    { return "CSS og SASS" };

        // Desc
        if (_is_txt(txt, landing_lang_desc_python,          lang, _eng))   { return "Widely used in AI, backend dev and science" };
        if (_is_txt(txt, landing_lang_desc_python,          lang, _no))    { return "Brukes i AI, backend-utvikling og vitenskap" };

        if (_is_txt(txt, landing_lang_desc_js,              lang, _eng))   { return "Anything from vanilla JavaScriot to frameworks like React" };
        if (_is_txt(txt, landing_lang_desc_js,              lang, _no))    { return "Alt i fra standard JavaScript til frontend-frameworks som React" };

        if (_is_txt(txt, landing_lang_desc_html,            lang, _eng))   { return "All frontend dev includes HTML (content) and CSS (styling)" };
        if (_is_txt(txt, landing_lang_desc_html,            lang, _no))    { return "All frontend-utvikling har HTML og CSS. HTML er innhold og layout, og CSS er styling" };

        if (_is_txt(txt, landing_lang_desc_css,             lang, _eng))   { return "CSS og SASS brukes styler innhold og gjør sidene responsive" };
        if (_is_txt(txt, landing_lang_desc_css,             lang, _no))    { return "CSS og SASS brukes styler innhold og gjør sidene responsive" };

    };

    // Cropper
    if (_) {

        // Cropper
        if (_is_txt(txt, crop_bilde,                        lang, _eng))   { return "Crop your image" };
        if (_is_txt(txt, crop_bilde,                        lang, _no))    { return "Beskjær bildet ditt" };

    };

    // Editor kode
    if (_) {

        if (_is_txt(txt, editor_kode_the_editor_navn_small,             lang, _eng))   { return "the editor" };
        if (_is_txt(txt, editor_kode_the_editor_navn_small,             lang, _no))    { return "editoren" };

        if (_is_txt(txt, editor_kode_kode_small,                        lang, _eng))   { return "code" };
        if (_is_txt(txt, editor_kode_kode_small,                        lang, _no))    { return "kode" };

        if (_is_txt(txt, editor_kode_programmer_siste_small,            lang, _eng))   { return "the latest programs" };
        if (_is_txt(txt, editor_kode_programmer_siste_small,            lang, _no))    { return "de siste programmene" };

        if (_is_txt(txt, editor_kode_term_tittel,                       lang, _eng))   { return "TERMINAL" };     // BACKEND
        if (_is_txt(txt, editor_kode_term_tittel,                       lang, _no))    { return "TERMINAL" };     // BACKEND

        if (_is_txt(txt, editor_kode_term_frontend,                     lang, _eng))   { return "BROWSER" };      // FRONTEND
        if (_is_txt(txt, editor_kode_term_frontend,                     lang, _no))    { return "BROWSER" };      // FRONTEND

        if (_is_txt(txt, editor_kode_file_tittel,                       lang, _eng))   { return "FILES" };
        if (_is_txt(txt, editor_kode_file_tittel,                       lang, _no))    { return "FILER" };

        if (_is_txt(txt, editor_kode_placeholder,                       lang, _eng))   { return "Code" };
        if (_is_txt(txt, editor_kode_placeholder,                       lang, _no))    { return "Kode" };

        if (_is_txt(txt, editor_kode_ny_editor,                         lang, _eng))   { return "New editor" };
        if (_is_txt(txt, editor_kode_ny_editor,                         lang, _no))    { return "Ny editor" };

        if (_is_txt(txt, editor_kode_kopier_editor,                     lang, _eng))   { return "Copy editor" };
        if (_is_txt(txt, editor_kode_kopier_editor,                     lang, _no))    { return "Kopier editor" };

        if (_is_txt(txt, editor_kode_delete_editor,                     lang, _eng))   { return "Delete editor" };
        if (_is_txt(txt, editor_kode_delete_editor,                     lang, _no))    { return "Slett editor" };

        if (_is_txt(txt, editor_kode_home_tittel_1,                     lang, _eng))   { return nettside };
        if (_is_txt(txt, editor_kode_home_tittel_1,                     lang, _no))    { return nettside };

        if (_is_txt(txt, editor_kode_home_tittel_2,                     lang, _eng))   { return "Start programing!" };
        if (_is_txt(txt, editor_kode_home_tittel_2,                     lang, _no))    { return "Start programmering!" };

    };

    // Fil
    if (_) {

        if (_is_txt(txt, fil_download_zip_navn_prefix,                  lang, _eng))    { return nettside };
        if (_is_txt(txt, fil_download_zip_navn_prefix,                  lang, _no))     { return nettside };

        if (_is_txt(txt, fil_bytes_txt,                                 lang, _eng))    { return "Bytes" };
        if (_is_txt(txt, fil_bytes_txt,                                 lang, _no))     { return "Bytes" };

        if (_is_txt(txt, fil_KB_txt,                                    lang, _eng))    { return "KB" };
        if (_is_txt(txt, fil_KB_txt,                                    lang, _no))     { return "KB" };

        if (_is_txt(txt, fil_MB_txt,                                    lang, _eng))    { return "MB" };
        if (_is_txt(txt, fil_MB_txt,                                    lang, _no))     { return "MB" };

        if (_is_txt(txt, fil_GB_txt,                                    lang, _eng))    { return "GB" };
        if (_is_txt(txt, fil_GB_txt,                                    lang, _no))     { return "GB" };

        if (_is_txt(txt, fil_TB_txt,                                    lang, _eng))    { return "TB" };
        if (_is_txt(txt, fil_TB_txt,                                    lang, _no))     { return "TB" };

        if (_is_txt(txt, fil_0_bytes_txt,                               lang, _eng))    { return "0 Bytes" };
        if (_is_txt(txt, fil_0_bytes_txt,                               lang, _no))     { return "0 Bytes" };

        if (_is_txt(txt, fil_create_fil_txt,                            lang, _eng))    { return "New file..." };
        if (_is_txt(txt, fil_create_fil_txt,                            lang, _no))     { return "Ny fil..." };

        if (_is_txt(txt, fil_create_folder_txt,                         lang, _eng))    { return "New folder..." };
        if (_is_txt(txt, fil_create_folder_txt,                         lang, _no))     { return "Ny folder..." };

        if (_is_txt(txt, fil_drop_zone_fil_type_ikke_gyldig,            lang, _eng))    { return "*** Invalid file-type ***" };
        if (_is_txt(txt, fil_drop_zone_fil_type_ikke_gyldig,            lang, _no))     { return "*** Ugyldig fil-type ***" };

        if (_is_txt(txt, fil_drop_zone_fil_type_ugyldig_fjern,          lang, _eng))    { return "*** Remove invalid fil-type ***" };
        if (_is_txt(txt, fil_drop_zone_fil_type_ugyldig_fjern,          lang, _no))     { return "*** Fjern ugyldig fil-type ***" };

    };

    // Filter
    if (_) {

        if (_is_txt(txt, filter_nyeste_txt,         lang, _eng))   {return "Newest"};
        if (_is_txt(txt, filter_nyeste_txt,         lang, _no))    {return "Nyeste"};

        if (_is_txt(txt, filter_topp_txt,           lang, _eng))   {return "Top"};
        if (_is_txt(txt, filter_topp_txt,           lang, _no))    {return "Topp"};

        if (_is_txt(txt, filter_tittel_txt,         lang, _eng))   {return "Filter"};
        if (_is_txt(txt, filter_tittel_txt,         lang, _no))    {return "Filter"};

    };

    // Footer
    if (_) {

        // Tittel sub
        if (_is_txt(txt, footer_tittel_sub,                 lang, _eng))   { return "Write us at kontakt@programmering.no" };
        if (_is_txt(txt, footer_tittel_sub,                 lang, _no))    { return "Ta kontakt på kontakt@programmering.no" };

        // Info
        if (_is_txt(txt, footer_copyright,                  lang, _eng))   { return `© 2023 ${nettside}, ${selskap_as}` };
        if (_is_txt(txt, footer_copyright,                  lang, _no))    { return `© 2023 ${nettside}, ${selskap_as}` };

        if (_is_txt(txt, footer_terms_tittel,               lang, _eng))   { return "Terms" };
        if (_is_txt(txt, footer_terms_tittel,               lang, _no))    { return "Vilkår" };

        if (_is_txt(txt, footer_kurs_tittel,                lang, _eng))   { return "Courses" };
        if (_is_txt(txt, footer_kurs_tittel,                lang, _no))    { return "Kurs" };

        if (_is_txt(txt, footer_about_tittel,               lang, _eng))   { return "About" };
        if (_is_txt(txt, footer_about_tittel,               lang, _no))    { return "Om oss" };

        if (_is_txt(txt, footer_about_tittel_sub,           lang, _eng))   { return txt_about_eng };
        if (_is_txt(txt, footer_about_tittel_sub,           lang, _no))    { return txt_about_no };

    };

    // msg
    if (_) {

        // Error > Status code
        if (_is_txt(txt, msg_err_auth_context_400_error,    lang, _eng))   {return "*** ERROR > auth_context.js > fetch_await_signin_user > response > 400 > 1. Wrowng username / password? :: "};
        if (_is_txt(txt, msg_err_auth_context_400_error,    lang, _no))    {return "*** ERROR > auth_context.js > fetch_await_signin_user > response > 400 > 1. Feil brukernavn / passord? :: "};

        if (_is_txt(txt, msg_err_auth_context_401_error,    lang, _eng))   {return "*** ERROR > auth_context.js > fetch_await_signin_user > response > 401 > 1. Wrowng username / password? 2. is_active = False? :: "};
        if (_is_txt(txt, msg_err_auth_context_401_error,    lang, _no))    {return "*** ERROR > auth_context.js > fetch_await_signin_user > response > 401 > 1. Feil brukernavn / passord? 2. is_active = False? :: "};


    };

    // Page core
    if (_) {

        if (_is_txt(txt, visninger,                         lang, _eng))   { return "views" };
        if (_is_txt(txt, visninger,                         lang, _no))    { return "visninger" };

    };

    // Tabell
    if (_) {

        if (_is_txt(txt, tabell_header_rad_nr,              lang, _eng))    { return "#" };
        if (_is_txt(txt, tabell_header_rad_nr,              lang, _no))     { return "#" };

        if (_is_txt(txt, tabell_header_spiller,             lang, _eng))    { return "Player" };
        if (_is_txt(txt, tabell_header_spiller,             lang, _no))     { return "Spiller" };

        if (_is_txt(txt, tabell_header_poeng,               lang, _eng))    { return "Points" };
        if (_is_txt(txt, tabell_header_poeng,               lang, _no))     { return "Poeng" };

        if (_is_txt(txt, tabell_header_tid,                 lang, _eng))    { return "Time" };
        if (_is_txt(txt, tabell_header_tid,                 lang, _no))     { return "Tid" };

        if (_is_txt(txt, tabell_header_tries,               lang, _eng))   { return "Tries" };
        if (_is_txt(txt, tabell_header_tries,               lang, _no))    { return "Forsøk" };

        if (_is_txt(txt, tabell_header_oppg,                lang, _eng))   { return "Exercise" };
        if (_is_txt(txt, tabell_header_oppg,                lang, _no))    { return "Oppg" };

        if (_is_txt(txt, tabell_header_del,                 lang, _eng))   { return "Part" };
        if (_is_txt(txt, tabell_header_del,                 lang, _no))    { return "Del" };

        if (_is_txt(txt, tabell_header_steg,                lang, _eng))   { return "Step" };
        if (_is_txt(txt, tabell_header_steg,                lang, _no))    { return "Steg" };

    };

    // Tagg
    if (_) {

        if (_is_txt(txt, tagg_tittel,                       lang, _eng))   { return "Tag" };
        if (_is_txt(txt, tagg_tittel,                       lang, _no))    { return "Tagg" };

    };

    // Textarea
    if (_) {

        if (_is_txt(txt, bokstaver_igjen,                   lang, _eng))    { return "letters left" };
        if (_is_txt(txt, bokstaver_igjen,                   lang, _no))     { return "bokstaver igjen" };

        if (_is_txt(txt, bokstav_igjen,                     lang, _eng))    { return "letter left" };
        if (_is_txt(txt, bokstav_igjen,                     lang, _no))     { return "bokstav igjen" };

    };

    // Vote
    if (_) {

        if (_is_txt(txt, vote_stemmer,                      lang, _eng))   { return "votes" };
        if (_is_txt(txt, vote_stemmer,                      lang, _no))    { return "stemmer" };

        if (_is_txt(txt, vote_svar,                         lang, _eng))   { return "answer" };
        if (_is_txt(txt, vote_svar,                         lang, _no))    { return "svar" };

    };


    /* ****************************************** */
    /* Gen */
    /* ****************************************** */

    // App
    if (_) {

        // Versjon
        if (_is_txt(txt, versjon,                           lang, _eng))    { return "Ver" };
        if (_is_txt(txt, versjon,                           lang, _no))     { return "Ver" };

    };

    // Errors
    if (_) {

        if (_is_txt(txt, err_msg_auth_context_signin_user_400_error,         lang, _eng))   { return "*** ERROR > auth_context.js > fetch_await_signin_user > response > 400 > 1. Wrowng username / password? :: " };
        if (_is_txt(txt, err_msg_auth_context_signin_user_400_error,         lang, _no))    { return "*** ERROR > auth_context.js > fetch_await_signin_user > response > 400 > 1. Feil brukernavn / passord? :: " };

        if (_is_txt(txt, err_msg_auth_context_signin_user_401_error,         lang, _eng))   { return "*** ERROR > auth_context.js > fetch_await_signin_user > response > 401 > 1. Wrowng username / password? 2. is_active = False? :: " };
        if (_is_txt(txt, err_msg_auth_context_signin_user_401_error,         lang, _no))    { return "*** ERROR > auth_context.js > fetch_await_signin_user > response > 401 > 1. Feil brukernavn / passord? 2. is_active = False? :: " };

        if (_is_txt(txt, err_msg_auth_context_signin_user_404_error,         lang, _eng))   { return "*** ERROR > auth_context.js > fetch_await_signin_user > response > 404 > Web site doesn´t exist" };
        if (_is_txt(txt, err_msg_auth_context_signin_user_404_error,         lang, _no))    { return "*** ERROR > auth_context.js > fetch_await_signin_user > response > 404 > Nettsiden finnes ikke" };

        if (_is_txt(txt, err_msg_auth_context_signin_user_405_error,         lang, _eng))   { return "*** ERROR > auth_context.js > fetch_await_signin_user > response > 405 > Failed to load resource: the server responded with a status of 405 (Method Not Allowed) > Wrong method POST / GET / PUT / DELETE in views.py / api.py" };
        if (_is_txt(txt, err_msg_auth_context_signin_user_405_error,         lang, _no))    { return "*** ERROR > auth_context.js > fetch_await_signin_user > response > 405 > Failed to load resource: the server responded with a status of 405 (Method Not Allowed) > Feil method POST / GET / PUT / DELETE i views.py / api.py" };

        if (_is_txt(txt, err_msg_auth_context_signin_user_ukjent_status_kode,         lang, _eng))   { return "*** ERROR > auth_context.js > fetch_await_signin_user > response > ukjent status kode :: " };
        if (_is_txt(txt, err_msg_auth_context_signin_user_ukjent_status_kode,         lang, _no))    { return "*** ERROR > auth_context.js > fetch_await_signin_user > response > unknown status code :: " };

        if (_is_txt(txt, err_msg_auth_update_token_400_error,               lang, _eng))   { return "*** ERROR > auth_context.js > fetch_await_update_token > response > 400 :: " };
        if (_is_txt(txt, err_msg_auth_update_token_400_error,               lang, _no))    { return "*** ERROR > auth_context.js > fetch_await_update_token > response > 400 :: " };

        if (_is_txt(txt, err_msg_auth_update_token_401_error,               lang, _eng))   { return "*** ERROR > auth_context.js > fetch_await_update_token > response > 401 :: " };
        if (_is_txt(txt, err_msg_auth_update_token_401_error,               lang, _no))    { return "*** ERROR > auth_context.js > fetch_await_update_token > response > 401 :: " };

        if (_is_txt(txt, err_msg_auth_update_token_404_error,               lang, _eng))   { return "*** ERROR > auth_context.js > fetch_await_update_token > response > 404 :: " };
        if (_is_txt(txt, err_msg_auth_update_token_404_error,               lang, _no))    { return "*** ERROR > auth_context.js > fetch_await_update_token > response > 404 :: " };

        if (_is_txt(txt, err_msg_auth_update_token_ukjent_status_kode,      lang, _eng))   { return "*** ERROR > auth_context.js > fetch_await_update_token > response > Ukjent status kode :: " };
        if (_is_txt(txt, err_msg_auth_update_token_ukjent_status_kode,      lang, _no))    { return "*** ERROR > auth_context.js > fetch_await_update_token > response > Uncknown status code :: " };

        if (_is_txt(txt, err_msg_user_profil_redux_user_profil_feature_error,   lang, _eng))   { return "*** ERROR > redux_user_profil_feature.js > getUserProfil" };
        if (_is_txt(txt, err_msg_user_profil_redux_user_profil_feature_error,   lang, _no))    { return "*** ERROR > redux_user_profil_feature.js > getUserProfil" };

        if (_is_txt(txt, err_msg_quest_item_create_catch,                   lang, _eng))   { return "*** ERROR > _quest_item_create.js > posting" };
        if (_is_txt(txt, err_msg_quest_item_create_catch,                   lang, _no))    { return "*** ERROR > _quest_item_create.js > posting" };

        if (_is_txt(txt, err_msg_redux_user_profil_feature_slice,           lang, _eng))   { return "*** ERROR > Redux > user_profil_feature > userProfilSlice" };
        if (_is_txt(txt, err_msg_redux_user_profil_feature_slice,           lang, _no))    { return "*** ERROR > Redux > user_profil_feature > userProfilSlice" };

    };

    // Kommentar
    if (_) {

        if (_is_txt(txt, kommentar_create,                  lang, _eng))   { return "Write comment" };
        if (_is_txt(txt, kommentar_create,                  lang, _no))    { return "Skriv kommentar" };

    };

    // Human lang
    if (_) {

        if (_is_txt(txt, eng_tittel,                        lang, _eng))   { return "English" };
        if (_is_txt(txt, eng_tittel,                        lang, _no))    { return "Engelsk" };

        if (_is_txt(txt, no_tittel,                         lang, _eng))    { return "Norwegian" };
        if (_is_txt(txt, no_tittel,                         lang, _no))     { return "Norsk" };

    };


    /* ****************************************** */
    /* Pages */
    /* ****************************************** */

    // Uni
    if (_) {

        // Root / Landing
        if (_is_txt(txt, "_uni_root",                       lang, _eng))   { return "Home" };
        if (_is_txt(txt, "_uni_root",                       lang, _no))    { return "Hjem" };

        if (_is_txt(txt, "_uni_root_landing",               lang, _eng))   { return "Home" };
        if (_is_txt(txt, "_uni_root_landing",               lang, _no))    { return "Hjem" };

        // About
        if (_is_txt(txt, "_uni_root_about",                 lang, _eng))   { return "About" };
        if (_is_txt(txt, "_uni_root_about",                 lang, _no))    { return "Om oss" };

        // Adminx
        if (_is_txt(txt, "_uni_root_adminx",                lang, _eng))   { return "Adminx" };
        if (_is_txt(txt, "_uni_root_adminx",                lang, _no))    { return "Adminx" };

        // Profil
        if (_is_txt(txt, "_uni_root_profil",                lang, _eng))   { return "Profile" };
        if (_is_txt(txt, "_uni_root_profil",                lang, _no))    { return "Profil" };

        if (_is_txt(txt, "_uni_root_profil_profil-disp",    lang, _eng))   { return "Public profile" };
        if (_is_txt(txt, "_uni_root_profil_profil-disp",    lang, _no))    { return "Offentlig profil" };

        if (_is_txt(txt, "_uni_root_profil_profil-set",     lang, _eng))   { return "User account" };
        if (_is_txt(txt, "_uni_root_profil_profil-set",     lang, _no))    { return "Brukerkonto" };

        if (_is_txt(txt, "_uni_root_profil_user-profil-list",       lang, _eng))   { return "User list" };
        if (_is_txt(txt, "_uni_root_profil_user-profil-list",       lang, _no))    { return "Brukerliste" };

        // Quest
        if (_is_txt(txt, "_uni_root_quest",                 lang, _eng))   { return "Question" };
        if (_is_txt(txt, "_uni_root_quest",                 lang, _no))    { return "Spørsmål" };

        if (_is_txt(txt, "_uni_root_quest_quest-create",    lang, _eng))   { return "Create question" };
        if (_is_txt(txt, "_uni_root_quest_quest-create",    lang, _no))    { return "Lag spørsmål" };

        if (_is_txt(txt, "_uni_root_quest_quest-list",      lang, _eng))   { return "Question list" };
        if (_is_txt(txt, "_uni_root_quest_quest-list",      lang, _no))    { return "Spørsmålsliste" };

        if (_is_txt(txt, uni_root_quest_quest_list_quest_item,   lang, _eng))   { return uni_root_quest_quest_list_quest_item };
        if (_is_txt(txt, uni_root_quest_quest_list_quest_item,   lang, _no))    { return uni_root_quest_quest_list_quest_item };

        // Reg
        if (_is_txt(txt, "_uni_root_signup",                lang, _eng))   { return "Signup" };
        if (_is_txt(txt, "_uni_root_signup",                lang, _no))    { return "Registrer" };

        if (_is_txt(txt, "_uni_root_signin",                lang, _eng))   { return "Sign in" };
        if (_is_txt(txt, "_uni_root_signin",                lang, _no))    { return "Logg inn" };

        if (_is_txt(txt, "_uni_root_signout",               lang, _eng))   { return "Signout" };
        if (_is_txt(txt, "_uni_root_signout",               lang, _no))    { return "Logg ut" };

        // The Editor
        if (_is_txt(txt, "_uni_root_the-editor",            lang, _eng))   { return "Editor" };
        if (_is_txt(txt, "_uni_root_the-editor",            lang, _no))    { return "Editor" };

        // Lang
        if (_is_txt(txt, "_uni_root_lang",                  lang, _eng))   { return "Programming language" };
        if (_is_txt(txt, "_uni_root_lang",                  lang, _no))    { return "Programmeringsspråk" };

        if (_is_txt(txt, "_uni_root_lang_python",           lang, _eng))   { return "Python" };
        if (_is_txt(txt, "_uni_root_lang_python",           lang, _no))    { return "Python" };

        if (_is_txt(txt, "_uni_root_lang_css",              lang, _eng))   { return "CSS" };
        if (_is_txt(txt, "_uni_root_lang_css",              lang, _no))    { return "CSS" };

        if (_is_txt(txt, "_uni_root_lang_html",             lang, _eng))   { return "HTML" };
        if (_is_txt(txt, "_uni_root_lang_html",             lang, _no))    { return "HTML" };

        if (_is_txt(txt, "_uni_root_lang_javascript",       lang, _eng))   { return "JavaScript" };
        if (_is_txt(txt, "_uni_root_lang_javascript",       lang, _no))    { return "JavaScript" };

    };

    // Root
    if (_) {

        // Tittel sub
        if (_is_txt(txt, landing_tittel_sub,                lang, _eng))   { return "Programming in Norwegian 🇸🇯" };
        if (_is_txt(txt, landing_tittel_sub,                lang, _no))    { return "Programmering på norsk 🇸🇯" };

        // Info
        if (_is_txt(txt, landing_info_1,                    lang, _eng))   { return "💻 Play with" };
        if (_is_txt(txt, landing_info_1,                    lang, _no))    { return "💻 Lek med" };

        if (_is_txt(txt, landing_info_2,                    lang, _eng))   { return "💾 Download" };
        if (_is_txt(txt, landing_info_2,                    lang, _no))    { return "💾 Last ned" };

        if (_is_txt(txt, landing_info_3,                    lang, _eng))   { return "📣 Upload" };
        if (_is_txt(txt, landing_info_3,                    lang, _no))    { return "📣 Last opp" };

        if (_is_txt(txt, landing_info_4,                    lang, _eng))   { return "🔥 Check out" };
        if (_is_txt(txt, landing_info_4,                    lang, _no))    { return "🔥 Sjekk de" };

    };

    // Adminx
    if (_) {

        // Info
        if (_is_txt(txt, push_el_new_file,                  lang, _eng))   { return "Push el / new file" };
        if (_is_txt(txt, push_el_new_file,                  lang, _no))    { return "Push el / new file" };

        if (_is_txt(txt, add_dir,                           lang, _eng))   { return "Add dir" };
        if (_is_txt(txt, add_dir,                           lang, _no))    { return "Add dir" };

        if (_is_txt(txt, remove_dir,                        lang, _eng))   { return "Remove dir" };
        if (_is_txt(txt, remove_dir,                        lang, _no))    { return "Remove dir" };

        // I
        if (_is_txt(txt, admin_db_tittel,                   lang, _eng))   { return "DB" };
        if (_is_txt(txt, admin_db_tittel,                   lang, _no))    { return "DB" };

        if (_is_txt(txt, admin_user_profil_disp_data,       lang, _eng))   { return "Display user profil data" };
        if (_is_txt(txt, admin_user_profil_disp_data,       lang, _no))    { return "Display user profil data" };

        if (_is_txt(txt, admin_json_backend_cap,            lang, _eng))   { return "JSON BACKEND" };
        if (_is_txt(txt, admin_json_backend_cap,            lang, _no))    { return "JSON BACKEND" };

        // II
        if (_is_txt(txt, admin_user_profil_disp_tittel_cap, lang, _eng))   { return "USER PROFIL DISP" };
        if (_is_txt(txt, admin_user_profil_disp_tittel_cap, lang, _no))    { return "USER PROFIL DISP" };

        if (_is_txt(txt, admin_user_arr_tittel_cap,         lang, _eng))   { return "USR, USERPROFIL" };
        if (_is_txt(txt, admin_user_arr_tittel_cap,         lang, _no))    { return "USR, USERPROFIL" };

        if (_is_txt(txt, admin_email_developer_cap,         lang, _eng))   { return "DEVELOPER" };
        if (_is_txt(txt, admin_email_developer_cap,         lang, _no))    { return "DEVELOPER" };

        if (_is_txt(txt, admin_test_navigation_cap,         lang, _eng))   { return "TEST NAVIGATION" };
        if (_is_txt(txt, admin_test_navigation_cap,         lang, _no))    { return "TEST NAVIGATION" };

        if (_is_txt(txt, admin_test_navigation_btn,         lang, _eng))   { return "Test navigation" };
        if (_is_txt(txt, admin_test_navigation_btn,         lang, _no))    { return "Test navigation" };

    };

    // no_url_info
    if (_) {

        // Info
        if (_is_txt(txt, no_url_info,                       lang, _eng))   { return "Could not find the page" };
        if (_is_txt(txt, no_url_info,                       lang, _no))    { return "Kan ikke finne siden!" };

    };

    // Profil
    if (_) {

        // User profil > Disp > I
        if (_is_txt(txt, user_profil_tittel,                lang, _eng))   { return "Profile" };
        if (_is_txt(txt, user_profil_tittel,                lang, _no))    { return "Profil" };

        if (_is_txt(txt, user_profil_img_profil_tittel_txt, lang, _eng))   { return "Profile image" };
        if (_is_txt(txt, user_profil_img_profil_tittel_txt, lang, _no))    { return "Profilbilde" };

        if (_is_txt(txt, user_profil_navn,                  lang, _eng))   { return "Name" };
        if (_is_txt(txt, user_profil_navn,                  lang, _no))    { return "Navn" };

        if (_is_txt(txt, user_profil_navn_placeholder,      lang, _eng))   { return "My name" };
        if (_is_txt(txt, user_profil_navn_placeholder,      lang, _no))    { return "Mitt navn" };

        if (_is_txt(txt, user_profil_public_email,          lang, _eng))   { return "Public email" };
        if (_is_txt(txt, user_profil_public_email,          lang, _no))    { return "Offentlig epost" };

        if (_is_txt(txt, user_profil_public_email_placeholder,      lang, _eng))   { return "My public email" };
        if (_is_txt(txt, user_profil_public_email_placeholder,      lang, _no))    { return "Min offentlige epost" };

        // User profil > Disp > II
        if (_is_txt(txt, user_profil_bio,                   lang, _eng))   { return "Biography" };
        if (_is_txt(txt, user_profil_bio,                   lang, _no))    { return "Biografi" };

        if (_is_txt(txt, user_profil_bio_placeholder,       lang, _eng))   { return "About yourself" };
        if (_is_txt(txt, user_profil_bio_placeholder,       lang, _no))    { return "Om deg selv" };

        if (_is_txt(txt, user_profil_url_public,            lang, _eng))   { return "Web page" };
        if (_is_txt(txt, user_profil_url_public,            lang, _no))    { return "Nettside" };

        if (_is_txt(txt, user_profil_url_public_placeholder,    lang, _eng))   { return "Link to web page" };
        if (_is_txt(txt, user_profil_url_public_placeholder,    lang, _no))    { return "Link til nettside" };

        if (_is_txt(txt, user_profil_company,               lang, _eng))   { return "Company" };
        if (_is_txt(txt, user_profil_company,               lang, _no))    { return "Selskap" };

        if (_is_txt(txt, user_profil_company_placeholder,   lang, _eng))   { return "Employer" };
        if (_is_txt(txt, user_profil_company_placeholder,   lang, _no))    { return "Hvem jobber du for?" };

        // User profil > Item
        if (_is_txt(txt, user_profil_item_tittel_1,         lang, _eng))   { return "Question" };
        if (_is_txt(txt, user_profil_item_tittel_1,         lang, _no))    { return "Quest" };

        if (_is_txt(txt, user_profil_item_tittel_2,         lang, _eng))   { return "Answer" };
        if (_is_txt(txt, user_profil_item_tittel_2,         lang, _no))    { return "Svar" };

        if (_is_txt(txt, user_profil_item_tittel_3,         lang, _eng))   { return "Code 1" };
        if (_is_txt(txt, user_profil_item_tittel_3,         lang, _no))    { return "Kode 1" };

        if (_is_txt(txt, user_profil_item_tittel_4,         lang, _eng))   { return "Code 2" };
        if (_is_txt(txt, user_profil_item_tittel_4,         lang, _no))    { return "Kode 2" };

        // User profil > Account > Set > I
        if (_is_txt(txt, user_profil_account_tittel,        lang, _eng))   { return "Your account" };
        if (_is_txt(txt, user_profil_account_tittel,        lang, _no))    { return "Din brukerkonto" };

        if (_is_txt(txt, user_profil_account_konto_gruppe,  lang, _eng))   { return "ACCOUNT" };
        if (_is_txt(txt, user_profil_account_konto_gruppe,  lang, _no))    { return "KONTO" };

        if (_is_txt(txt, user_profil_account_item_public_profil_tittel,     lang, _eng))   { return "Profile" };
        if (_is_txt(txt, user_profil_account_item_public_profil_tittel,     lang, _no))    { return "Profil" };

        if (_is_txt(txt, user_profil_account_item_account_tittel,           lang, _eng))   { return "Username and password" };
        if (_is_txt(txt, user_profil_account_item_account_tittel,           lang, _no))    { return "Brukernavn og passord" };

        if (_is_txt(txt, user_profil_account_item_lang_tittel,              lang, _eng))   { return "Language" };
        if (_is_txt(txt, user_profil_account_item_lang_tittel,              lang, _no))    { return "Språk" };

        // User profil > Account > Set > II
        if (_is_txt(txt, user_profil_account_item_account_passord_endret_alert_success_confirm,     lang, _eng))   { return "Password changed" };
        if (_is_txt(txt, user_profil_account_item_account_passord_endret_alert_success_confirm,     lang, _no))    { return "Passord endret" };

        if (_is_txt(txt, user_profil_account_item_account_passord_endret_alert_fail_confirm,        lang, _eng))   {return "Ooops! Something went wrong, password was not changed"};
        if (_is_txt(txt, user_profil_account_item_account_passord_endret_alert_fail_confirm,        lang, _no))    {return "Ooops! Noe gikk galt, passordet ble ikke endret"};

        if (_is_txt(txt, user_profil_account_item_notifications_tittel,     lang, _eng))    { return "Notifications" };
        if (_is_txt(txt, user_profil_account_item_notifications_tittel,     lang, _no))     { return "Notifikasjoner" };

        if (_is_txt(txt, user_profil_account_item_email_tittel,             lang, _eng))   { return "Email" };
        if (_is_txt(txt, user_profil_account_item_email_tittel,             lang, _no))    { return "Epost" };

        // User profil > Account > Set > Konto > Username
        if (_is_txt(txt, usernavn_nytt_tittel,                              lang, _eng))   { return "New username" };
        if (_is_txt(txt, usernavn_nytt_tittel,                              lang, _no))    { return "Nytt brukernavn" };

        if (_is_txt(txt, usernavn_nytt_btn_endre,                           lang, _eng))   { return "Change username" };
        if (_is_txt(txt, usernavn_nytt_btn_endre,                           lang, _no))    { return "Endre brukernavn" };

        if (_is_txt(txt, usernavn_nytt_btn_update,                          lang, _eng))   { return "Update username" };
        if (_is_txt(txt, usernavn_nytt_btn_update,                          lang, _no))    { return "Oppdater brukernavn" };

        // User profil > Account > Set > Konto > Password
        if (_is_txt(txt, password_nytt_tittel,                              lang, _eng))   { return "New password" };
        if (_is_txt(txt, password_nytt_tittel,                              lang, _no))    { return "Nytt passord" };

        if (_is_txt(txt, password_nytt_btn_endre,                           lang, _eng))   { return "Change password" };
        if (_is_txt(txt, password_nytt_btn_endre,                           lang, _no))    { return "Endre passord" };

        if (_is_txt(txt, password_nytt_btn_update,                          lang, _eng))   { return "Update password" };
        if (_is_txt(txt, password_nytt_btn_update,                          lang, _no))    { return "Oppdater passord" };

        if (_is_txt(txt, password_dum,                                      lang, _eng))   { return "12345678" };
        if (_is_txt(txt, password_dum,                                      lang, _no))    { return "12345678" };

        if (_is_txt(txt, password_bekreft_tittel,                           lang, _eng))   { return "Confirm password" };
        if (_is_txt(txt, password_bekreft_tittel,                           lang, _no))    { return "Bekreft passord" };

    };

    // Reg
    if (_) {

        // I
        if (_is_txt(txt, signup_tittel,                     lang, _eng))   { return "Signup" };
        if (_is_txt(txt, signup_tittel,                     lang, _no))    { return "Registrer" };

        if (_is_txt(txt, signup_success_tittel,             lang, _eng))   { return "Signup success" };
        if (_is_txt(txt, signup_success_tittel,             lang, _no))    { return "Vellykket registrering" };

        if (_is_txt(txt, signup_allerede_reg,               lang, _eng))   { return "Already signed up? " };
        if (_is_txt(txt, signup_allerede_reg,               lang, _no))    { return "Allerede registrert? " };

        if (_is_txt(txt, signin_tittel,                     lang, _eng))   { return "Sign in" };      // Alternativ: "Epost sendt"
        if (_is_txt(txt, signin_tittel,                     lang, _no))    { return "Logg inn" };

        if (_is_txt(txt, signin_ny_prog,                    lang, _eng))   { return "New on programmering.no? " }; // Lag dynamisk
        if (_is_txt(txt, signin_ny_prog,                    lang, _no))    { return "Ny på programmering.no? " };

        // II
        if (_is_txt(txt, signin_glemt_pass,                 lang, _eng))   { return "Forgotten password?" };
        if (_is_txt(txt, signin_glemt_pass,                 lang, _no))    { return "Glemt passord?" };

        if (_is_txt(txt, signin_glemt_pass_tittel,          lang, _eng))   { return "Create new password" };
        if (_is_txt(txt, signin_glemt_pass_tittel,          lang, _no))    { return "Lag nytt passord" };

        if (_is_txt(txt, signin_glemt_pass_confirm,         lang, _eng))   { return "Email sent" }; // Alternativ: "Reset-epost sendt"
        if (_is_txt(txt, signin_glemt_pass_confirm,         lang, _no))    { return "Epost sendt" };

        if (_is_txt(txt, signin_glemt_pass_confirm_btn,     lang, _eng))   { return "Send passward reset link" };
        if (_is_txt(txt, signin_glemt_pass_confirm_btn,     lang, _no))    { return "Send passord reset-link" };

        if (_is_txt(txt, signin_glemt_pass_sett_nytt,       lang, _eng))   { return "Create new password" };
        if (_is_txt(txt, signin_glemt_pass_sett_nytt,       lang, _no))    { return "Sett nytt passord" };

        if (_is_txt(txt, signin_glemt_pass_sett_nytt_fra_epost,     lang, _eng))   { return "Create new password" };
        if (_is_txt(txt, signin_glemt_pass_sett_nytt_fra_epost,     lang, _no))    { return "Sett nytt passord" };


        if (_is_txt(txt, signout_tittel,                            lang, _eng))   { return "Signout" };
        if (_is_txt(txt, signout_tittel,                            lang, _no))    { return "Logg ut" };

        // Reg > Sign
        if (_is_txt(txt, reg_ugyldig,                               lang, _eng))   { return "Complete signup" };
        if (_is_txt(txt, reg_ugyldig,                               lang, _no))    { return "Fullfør registreringen" };

        // Reg > Username
        if (_is_txt(txt, username_tittel,                           lang, _eng))   { return "Username" };
        if (_is_txt(txt, username_tittel,                           lang, _no))    { return "Brukernavn" };

        if (_is_txt(txt, username_sub_ikke_gyldig,                  lang, _eng))   { return "Username is invalid" };      // Alternativ: "Reset-epost sendt"
        if (_is_txt(txt, username_sub_ikke_gyldig,                  lang, _no))    { return "Brukernavn er ugyldig" };    // "Minst 6 tegn, ingen spesialtegn"

        if (_is_txt(txt, username_sub_ikke_gyldig_opptatt,          lang, _eng))   { return "Username is taken" };
        if (_is_txt(txt, username_sub_ikke_gyldig_opptatt,          lang, _no))    { return "Brukernavn er opptatt" };

        if (_is_txt(txt, username_sub_gyldig,                       lang, _eng))   { return "Username is available" };
        if (_is_txt(txt, username_sub_gyldig,                       lang, _no))    { return "Brukernavn er ledig" };

        if (_is_txt(txt, username_sub_gyldig_max,                   lang, _eng))   { return "Username is available (max length)" };
        if (_is_txt(txt, username_sub_gyldig_max,                   lang, _no))    { return "Brukernavn er ledig (max lengde)" };

        // Reg > Email
        if (_is_txt(txt, email_tittel,                              lang, _eng))   { return "Email" };
        if (_is_txt(txt, email_tittel,                              lang, _no))    { return "Epost" };

        if (_is_txt(txt, email_sub_ugyldig,                         lang, _eng))   { return "Email is invalid" };
        if (_is_txt(txt, email_sub_ugyldig,                         lang, _no))    { return "Epost er ugyldig" };

        if (_is_txt(txt, email_sub_gyldig,                          lang, _eng))   { return "Email is valid" };
        if (_is_txt(txt, email_sub_gyldig,                          lang, _no))    { return "Epost er gyldig" };

        if (_is_txt(txt, email_sub_gyldig_max,                      lang, _eng))   { return "Email is valid (max lenth)" };
        if (_is_txt(txt, email_sub_gyldig_max,                      lang, _no))    { return "Epost er gyldig (max lengde)" };

        if (_is_txt(txt, reg_email_send_user_confirm_developer_txt,     lang, _eng))   { return "user confirm email" };
        if (_is_txt(txt, reg_email_send_user_confirm_developer_txt,     lang, _no))    { return "user confirm-epost" };

        if (_is_txt(txt, reg_email_send_glemt_passord_developer_txt,    lang, _eng))   { return "forgotten password email" };
        if (_is_txt(txt, reg_email_send_glemt_passord_developer_txt,    lang, _no))    { return "glemt passord-epost" };

        // Password > I
        if (_is_txt(txt, password_tittel,                           lang, _eng))   { return "Password" };
        if (_is_txt(txt, password_tittel,                           lang, _no))    { return "Passord" };

        if (_is_txt(txt, password_sub_gyldig,                       lang, _eng))   { return "Password matches" };
        if (_is_txt(txt, password_sub_gyldig,                       lang, _no))    { return "Passord matcher" };

        if (_is_txt(txt, password_sub_ikke_gyldig,                  lang, _eng))   { return "Password doesn´t match" };
        if (_is_txt(txt, password_sub_ikke_gyldig,                  lang, _no))    { return "Passord matcher ikke" };

        if (_is_txt(txt, password_svakt,                            lang, _eng))   { return "Weak (min 6 symbols)" };
        if (_is_txt(txt, password_svakt,                            lang, _no))    { return "Svakt (minst 6 tegn)" };

        // Password > II
        if (_is_txt(txt, password_middels,                          lang, _eng))   { return "Medium (min 6 symbols)" };
        if (_is_txt(txt, password_middels,                          lang, _no))    { return "Middels (minst 6 tegn)" };

        if (_is_txt(txt, password_sterkt,                           lang, _eng))   { return "Strong" };
        if (_is_txt(txt, password_sterkt,                           lang, _no))    { return "Sterkt" };

        if (_is_txt(txt, password_max_lengde,                       lang, _eng))   { return "Strong (max length)" };
        if (_is_txt(txt, password_max_lengde,                       lang, _no))    { return "Sterkt (max lengde)" };

        if (_is_txt(txt, password_for_langt,                        lang, _eng))   { return "Too long" };
        if (_is_txt(txt, password_for_langt,                        lang, _no))    { return "For langt" };

    };

    // Quest
    if (_) {

        // Info
        if (_is_txt(txt, questions_small,                    lang, _eng))   { return "questions" };
        if (_is_txt(txt, questions_small,                    lang, _no))    { return "spørsmål" };

        // Create
        if (_is_txt(txt, quest_tittel_create_txt,           lang, _eng))    { return "Title" };
        if (_is_txt(txt, quest_tittel_create_txt,           lang, _no))     { return "Tittel" };

        if (_is_txt(txt, quest_tittel_beskrivelse_txt,      lang, _eng))    { return "Description" };
        if (_is_txt(txt, quest_tittel_beskrivelse_txt,      lang, _no))     { return "Beskrivelse" };

        if (_is_txt(txt, quest_tittel_drop_zone_txt,        lang, _eng))    { return "Drop file" };
        if (_is_txt(txt, quest_tittel_drop_zone_txt,        lang, _no))     { return "Drop fil" };

        if (_is_txt(txt, quest_create_post_txt,             lang, _eng))    { return "Publish" };      // Duplikat
        if (_is_txt(txt, quest_create_post_txt,             lang, _no))     { return "Publiser" };     // -"-

        // Quest > Read
        if (_is_txt(txt, quest_spurt,                       lang, _eng))    { return "Asked" };
        if (_is_txt(txt, quest_spurt,                       lang, _no))     { return "Spurt" };

        if (_is_txt(txt, quest_aktiv,                       lang, _eng))    { return "Active" };
        if (_is_txt(txt, quest_aktiv,                       lang, _no))     { return "Aktiv" };

        if (_is_txt(txt, quest_i_dag,                       lang, _eng))    { return "today" };
        if (_is_txt(txt, quest_i_dag,                       lang, _no))     { return "i dag" };

        if (_is_txt(txt, quest_author_spurt,                lang, _eng))    { return "Asked" };
        if (_is_txt(txt, quest_author_spurt,                lang, _no))     { return "Spurt" };

        // Quest > List
        if (_is_txt(txt, quest_list_create_btn,             lang, _eng))    { return "Ask" };
        if (_is_txt(txt, quest_list_create_btn,             lang, _no))     { return "Spør" };

        if (_is_txt(txt, quest_list_create_tittel,          lang, _eng))    { return "Create question" };
        if (_is_txt(txt, quest_list_create_tittel,          lang, _no))     { return "Last opp" }; // Lag spørsmål // "Last opp spørsmål eller program"

        // Quest > Ord
        if (_is_txt(txt, quest_ord_Quest,                   lang, _eng))   { return "*** Question ***" };
        if (_is_txt(txt, quest_ord_Quest,                   lang, _no))    { return "spørsmål og programmer" };


    };

    // Spill
    if (_) {

        if (_is_txt(txt, spill_matte_velg_level,            lang, _eng))   { return "Select level" };
        if (_is_txt(txt, spill_matte_velg_level,            lang, _no))    { return "Start med å velge nivå" };

        if (_is_txt(txt, spill_matte_cancel,                lang, _eng))   { return "Cancel" };
        if (_is_txt(txt, spill_matte_cancel,                lang, _no))    { return "Avbryt" };

        if (_is_txt(txt, level_1_txt,                       lang, _eng))   { return "1" };
        if (_is_txt(txt, level_1_txt,                       lang, _no))    { return "1" };

        if (_is_txt(txt, level_2_txt,                       lang, _eng))   { return "2" };
        if (_is_txt(txt, level_2_txt,                       lang, _no))    { return "2" };

        if (_is_txt(txt, level_3_txt,                       lang, _eng))   { return "3" };
        if (_is_txt(txt, level_3_txt,                       lang, _no))    { return "3" };

        if (_is_txt(txt, level_4_txt,                       lang, _eng))   { return "4" };
        if (_is_txt(txt, level_4_txt,                       lang, _no))    { return "4" };

        if (_is_txt(txt, level_5_txt,                       lang, _eng))   { return "5" };
        if (_is_txt(txt, level_5_txt,                       lang, _no))    { return "5" };

        if (_is_txt(txt, level_6_txt,                       lang, _eng))   { return "6" };
        if (_is_txt(txt, level_6_txt,                       lang, _no))    { return "6" };

    };

    /* ****************************************** */
    /* Catch all default */
    /* ****************************************** */

    return txt

};


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    get_txt
};


/* ****************************************** */
/* End */
/* ****************************************** */