/* ****************************************** */
/* Cropper */
/* ****************************************** */

// Cropper
const crop_bilde                            = "_crop_bilde";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    crop_bilde,
};


/* ****************************************** */
/* End */
/* ****************************************** */
