import {
    fil_slug_tmp_default,
    fil_folder_slug,
    fil_tre_str_root
} from "../../utils/konstanter/components/_fil";
import {
    editor_kode_px_per_line
} from "../../utils/konstanter/gen/_layout";
import {
    _spes_arr,
    _sym_hyphen,
    _sym_dot,
    _sym_pluss,
    _sym_div_slash
} from "../../utils/konstanter/components/_sym";
import {
    tekst_line_counter
} from "../../utils/utility/_util_string";

// -*-

import {
    _gum_path_update
} from "../../utils/utility/_util_obj";
import {
    _is_all_true
} from "../../utils/utility/_util_op";


/* ****************************************** */
/* Gen */
/* ****************************************** */

const get_fil_ext                               = (fil_navn) => {

    // Med dot
    let _fil_ext_dot = _sym_dot
    const _fil_ext_dotless = fil_navn.split(_sym_dot).pop(); // '.'
    _fil_ext_dot = _fil_ext_dot + _fil_ext_dotless

    // Return > ".py"
    return _fil_ext_dot

};

const get_fil_ext_dotless                       = (fil_navn) => {

    // Init
    const _fil_base     = fil_navn.split(_sym_dot)[0]; // '.'
    const _fil_ext      = fil_navn.split(_sym_dot)[1]; // '.'

    // Return > "py"
    return [_fil_base, _fil_ext]

};

/*
def _txt_char_no_to_eng_(txt):

    ##########################################
    # Disp
    ##########################################

    ### _.print_fn(fn=_._fn_txt_char_no_to_eng_, username="Username_X", disp=False, log=False)


    ##########################################
    # String no til eng char
    ##########################################

    # Init
    _ret_str = str()

    # Iterate
    for i in range(len(txt)):

        # Norsk
        if   txt[i] == _sym0._AE_c_pre:     _ret_str += _sym0._AE_c_post
        elif txt[i] == _sym0._OE_c_pre:     _ret_str += _sym0._OE_c_post
        elif txt[i] == _sym0._AA_c_pre:     _ret_str += _sym0._AA_c_post
        elif txt[i] == _sym0._ae_s_pre:     _ret_str += _sym0._ae_s_post
        elif txt[i] == _sym0._oe_s_pre:     _ret_str += _sym0._oe_s_post
        elif txt[i] == _sym0._aa_s_pre:     _ret_str += _sym0._aa_s_post

        # Return > Alle andre char > Bypass
        else: _ret_str += txt[i]


    ##########################################
    # String no til eng char
    ##########################################

    # Return > String > F.eks. "ÆøÅ4Å" -> "aeoeaa4aa"
    return _ret_str

def _txt_char_cap_to_small(_str = str()):

    ##########################################
    # Disp
    ##########################################

    ### _.print_fn(fn=_._fn_txt_char_cap_to_small_str_, username="Username_X", disp=False, log=False)


    ##########################################
    # String med kun small char
    ##########################################

    # Init
    _ret_str = str()

    # Iterate
    for char in _str:

        if   char == _sym0._A_c:        _ret_str += _sym0._a_s
        elif char == _sym0._B_c:        _ret_str += _sym0._b_s
        elif char == _sym0._C_c:        _ret_str += _sym0._c_s
        elif char == _sym0._D_c:        _ret_str += _sym0._d_s
        elif char == _sym0._E_c:        _ret_str += _sym0._e_s
        elif char == _sym0._F_c:        _ret_str += _sym0._f_s
        elif char == _sym0._G_c:        _ret_str += _sym0._g_s
        elif char == _sym0._H_c:        _ret_str += _sym0._h_s
        elif char == _sym0._I_c:        _ret_str += _sym0._i_s
        elif char == _sym0._J_c:        _ret_str += _sym0._j_s
        elif char == _sym0._K_c:        _ret_str += _sym0._k_s
        elif char == _sym0._L_c:        _ret_str += _sym0._l_s
        elif char == _sym0._M_c:        _ret_str += _sym0._m_s
        elif char == _sym0._N_c:        _ret_str += _sym0._n_s
        elif char == _sym0._O_c:        _ret_str += _sym0._o_s
        elif char == _sym0._P_c:        _ret_str += _sym0._p_s
        elif char == _sym0._Q_c:        _ret_str += _sym0._q_s
        elif char == _sym0._R_c:        _ret_str += _sym0._r_s
        elif char == _sym0._S_c:        _ret_str += _sym0._s_s
        elif char == _sym0._T_c:        _ret_str += _sym0._t_s
        elif char == _sym0._U_c:        _ret_str += _sym0._u_s
        elif char == _sym0._V_c:        _ret_str += _sym0._v_s
        elif char == _sym0._W_c:        _ret_str += _sym0._w_s
        elif char == _sym0._X_c:        _ret_str += _sym0._x_s
        elif char == _sym0._Y_c:        _ret_str += _sym0._y_s
        elif char == _sym0._Z_c:        _ret_str += _sym0._z_s
        else:                           _ret_str += char

    ##########################################
    # String med kun small char
    ##########################################

    # Return > String > F.eks "AbC" -> "abc"
    return _ret_str

def _txt_remove_dobbel_hyphen(_str = str()):

    _ret_str = _str

    i = 0
    while i < len(_ret_str) - 1:

        if _ret_str[i] == _sym0._sym_hyphen and _ret_str[i + 1] == _sym0._sym_hyphen:

            print("YO 1 :: ", _ret_str[i])
            print("YO 2 :: ", _ret_str[i + 1])

            #_ret_str = _ret_str[:i] + _ret_str[i + 1:]

            _ret_str = _ret_str.replace(_ret_str[i], "", 1)

            i = 0

        else:

            i += 1

    return _ret_str

def _txt_remove_hyphen_first_and_last(_str = str()):

    _ret_str = _str

    if _ret_str[0] == _sym0._sym_hyphen:                    _ret_str = _ret_str[1:]
    if _ret_str[len(_ret_str) - 1] == _sym0._sym_hyphen:    _ret_str = _ret_str[:-1]

    return _ret_str

*/

// Create slug
const _txt_remove_special_char = (_str) => {

    let _ret_str = "" // str()

    for (let i = 0; i < _str.length; i++) {

        if (_spes_arr.includes(_str[i]) === true) {
            _ret_str += _sym_hyphen;
        } else {
            _ret_str += _str[i];
        };

    };

    return _ret_str

};

const txt_create_slug_from_str = (tit) => {

    let _ret_str    = tit;                                      // console.log("pre :: ", tit)
    _ret_str        = _txt_remove_special_char(_ret_str);       // console.log("Post 1 :: ", _ret_str)
    // _ret_str = _txt_char_no_to_eng_(_ret_str)               // console.log("Post 2 :: ", _ret_str)
    // _ret_str = _txt_char_cap_to_small(_ret_str)             // console.log("Post 3 :: ", _ret_str)
    // _ret_str = _txt_remove_dobbel_hyphen(_ret_str)          // console.log("Post 4 :: ", _ret_str)
    // _ret_str = _txt_remove_hyphen_first_and_last(_ret_str)  // console.log("Post 5 :: ", _ret_str)

    //  Return > String > Slug
    return _ret_str

};

const fil_get_fil_navn_ext_underscore           = (fil_navn) => {

    // Init
    const _fil_navn_arr = get_fil_ext_dotless(fil_navn);

    // undefined > "folder"
    if (_fil_navn_arr[1] === undefined) {_fil_navn_arr[1] = fil_folder_slug}

    // Fil navn ext underscore
    let _fil_navn_ext_underscore = `${_fil_navn_arr[0]}-${_fil_navn_arr[1]}` // Legacy

    // Get slug
    _fil_navn_ext_underscore = txt_create_slug_from_str(_fil_navn_ext_underscore);

    // Return
    return _fil_navn_ext_underscore

};


/* ****************************************** */
/* Str / arr */
/* ****************************************** */

const str_komma_sep_to_arr_reset                = (str) => { // console.log("str :: ", str)

    /*
    - Note
        - str > "+ root, + + Basic_dir_1, + + + Basic_dir_1_1, ..."
        - _gum_arr > ["+ root", "+ + Basic_dir_1", "+ + + Basic_dir_1_1", ...]
    */

    // Lag arr
    let _gum_arr            = [];
    let _el_str             = "";
    const _is_debug         = false;

    // Iterate
    for (let k = 0; k < str.length; k++) {
        if (str[k] === ",") {
            _gum_arr.push(_el_str.trimStart());
            _el_str = "";
        } else {
            _el_str += str[k];
        };
    };
    _gum_arr.push(_el_str.trimStart()); // console.log("_gum_arr :: ", _gum_arr);

    // Debug
    if (_is_debug) {
        for (let n = 0; n < _gum_arr.length; n++) {
            console.log("> _util_obj.js > _arr :: ", _gum_arr[n])
        };
    };

    // Return
    return _gum_arr

};

const mat_to_pluss_arr                          = (node_mat, index_i) => {

    // Init
    let _arr = [];

    // Push root
    _arr.push(fil_tre_str_root);

    // Iterate items i node_mat
    for (var n = 0; n < node_mat[index_i].length; n++) {

        // Init
        let _slug               = node_mat[index_i][n].slug;
        let _level_i            = node_mat[index_i][n].level_i;
        let _pluss_prefix       = "";
        let _slug_pluss         = "";
        let _level_cnt          = 0;

        // Iterate > Pluss prefix > "+ + + "
        while (_level_cnt < _level_i + 2) {
            _pluss_prefix += _sym_pluss + " ";
            _level_cnt += 1;
        };

        // Slug pluss > "+ + + Basic_dir_1_1"
        _slug_pluss = _pluss_prefix + _slug; // console.log("_slug_pluss :: ", _slug_pluss)

        // Push item
        _arr.push(_slug_pluss);

    };

    // Return
    return _arr

};

const arr_komma_sep_to_str                      = (arr) => { // console.log("arr :: ", arr)

    // arr > ["+ root", "+ + Basic_dir_1", "+ + + Basic_dir_1_1", ...]

    // Init
    var _str    = "";

    // Iterate > Separer komma-items
    for (var n = 0; n < arr.length; n++) {

        if (n < arr.length - 1) {
            _str += arr[n] + ", ";
        };

        if (n === arr.length - 1) {
            _str += arr[n];
        }; // console.log("> _util_obj.js > _str :: ", _str)
    }; // console.log("_str :: ", _str)

    // Return > String > "+ root, + + hei_a_1.py, + + fold, + + + hei_b_1.py, + + hei_a_2.py"
    return _str

};


/* ****************************************** */
/* Redux */
/* ****************************************** */

const set_node_selected_all_0                   = (node_mat) => {

    // Reset select til j = 0
    for (let i = 0; i < node_mat.length; i++) {
        for (let j = 0; j < node_mat[i].length; j++) {

            // Reset all > False
            node_mat[i][j].is_selected = false;

            // Set alle j = 0 til selected > True
            if (j === 0) {
                node_mat[i][j].is_selected = true;
            };

        };
    };

    // Return
    return node_mat

};

const set_node_selected_fil_slug_current_reset  = (node_mat) => {

    // Init
    let _fil_slug_current_arr = [];
    let i = 0;
    let j = 0;

    // Iterate
    while (i < node_mat.length) {
        while (j < node_mat[i].length) {

            // Reset all > False
            if (node_mat[i][j].is_folder === false) {

                // Push slug
                _fil_slug_current_arr.push(node_mat[i][j].slug)

                // Break
                j = node_mat[i].length;

            };

            // Inc j
            j += 1;

        };

        // Reset j
        j = 0;

        // Inc i
        i += 1

    };

    // Return
    return _fil_slug_current_arr

};

const set_node_selected_fil_slug_fil_entry_reset  = (node_mat, selected_arr, fil_entry_arr) => {

    // console.log("a :: ", selected_arr)

    // Init
    let _fil_slug_current_arr = selected_arr; // [];

    // Iterate
    for (let i = 0; i < node_mat.length; i++) {

        // Fil entry > Eksisterer
        if (fil_entry_arr[i] !== "") {
            // console.log("fil_entry :: ", fil_entry_arr[i]);
            _fil_slug_current_arr[i] = fil_entry_arr[i];
        };

        // Fil entry > Eksisterer ikke
        if (fil_entry_arr[i] === "") {
            // console.log("emptyz")
        };


    };

    // Return
    return _fil_slug_current_arr // selected_arr // _fil_slug_current_arr

};

const set_node_selected_fil_slug_current_do     = (node_mat, current_arr) => {

    // Reset select til j = 0
    for (let i = 0; i < node_mat.length; i++) {
        for (let j = 0; j < node_mat[i].length; j++) {

            // Set alle j = 0 til selected > True
            if (node_mat[i][j].slug === current_arr[i]) {
                node_mat[i][j].is_selected = true;
            };

        };
    };

    // Return
    return node_mat

};

const set_node_selected_fil_content_init_yy     = (node_mat) => {

    // Init
    let _fil_content_init_yy_arr = [];
    let i = 0;
    let j = 0;

    // Iterate
    while (i < node_mat.length) {
        while (j < node_mat[i].length) {

            // Reset all > False
            if (node_mat[i][j].is_selected === true) {

                // Init
                const _content          = node_mat[i][j].fil_content;
                const _line_cnt         = tekst_line_counter(_content);
                const _px_per_line      = editor_kode_px_per_line;

                // Calc yy
                const _yy               = _line_cnt * _px_per_line;

                // Push slug
                _fil_content_init_yy_arr.push(_yy);

                // Break
                j = node_mat[i].length;

            };

            // Inc j
            j += 1;

        };

        // Reset j
        j = 0;

        // Inc i
        i += 1

    };


    //


    // Return
    return _fil_content_init_yy_arr

};

const set_node_selected_fil                     = (node_mat, slug) => {

    // Reset select til j = 0
    for (let i = 0; i < node_mat.length; i++) {
        for (let j = 0; j < node_mat[i].length; j++) {

            // Set alle j = 0 til selected > True
            if (node_mat[i][j].slug === slug) {

                // Set selected
                node_mat[i][j].is_selected = true;

            };
        };
    };

    // Return
    return node_mat

};

const set_node_selected_current_editor_0        = (node_mat, index_i) => {

    // Reset alle selected i current editor
    for (let j = 0; j < node_mat[index_i].length; j++) {

        // Set > False
        node_mat[index_i][j].is_selected = false;

        // Set alle j = 0 til selected > True
        if (j === 0) {
            node_mat[index_i][j].is_selected = true;
        };

    };

    // Return
    return node_mat

};

const set_node_selected                         = (node_mat, slug, index_i, is_edit_done) => {


    // Init
    const _i                                = index_i;

    // Debug
    const _is_debug_folder_status_all       = false;    // Viser alle items
    const _is_debug_folder_status_pre_post  = false;    // Bruk denne for normal debug
    debug_node_item_open_show(node_mat, "set_node_selected() :: PRE", _is_debug_folder_status_all);
    const _is_debug_item_liste              = false;    // Standard > Viser hvordan alle items finner folder-veien sin

    // Debug > Closed > Post
    if (_is_debug_folder_status_pre_post === true) {
        for (let j = 0; j < node_mat[_i].length; j++) {
            // Disp
            if (node_mat[_i][j].is_open === false) {
                console.log("Closed > Pre :: ", node_mat[_i][j].fil_navn);
            };
        };
    };

    // Reset alle selected i current editor > False
    for (let j = 0; j < node_mat[_i].length; j++) {
        node_mat[_i][j].is_selected = false;
    };

    // Exe > While loop
    let n                       = 0;
    let k                       = 0;
    let _is_folder_clicked      = 0;
    let _folder_level_i         = 0;
    let _folder_is_open         = false;

    // Iterate > par
    while (n < node_mat[_i].length) {

        // Item
        if (node_mat[_i][n].slug === slug) {

            // Selected > True
            node_mat[_i][n].is_selected = true;

            // Is create done
            if (is_edit_done === true) {

                // If folder
                if (node_mat[_i][n].is_folder === true) {

                    // Folder is clicked
                    _is_folder_clicked = true;

                    // Bestem current status > Åpen / lukket
                    _folder_is_open = node_mat[_i][n].is_open;
                    node_mat[_i][n].is_open = !_folder_is_open;

                    // Folder level
                    _folder_level_i = node_mat[_i][n].level_i;

                    // Folder index
                    k = n + 1;

                };
            };
        };

        n += 1;

    };

    // Hvis en folder klikkes > Bypass hvis fil klikkes
    if (_is_folder_clicked === true) {

        // Hovedfolder open -> Hide all sub-items
        if (_folder_is_open === true) {

            // Iterate > Hide alt under folder level
            while (k < node_mat[_i].length &&
                node_mat[_i][k].level_i !== _folder_level_i) {
                    node_mat[_i][k].is_show = false;
                    k += 1;
            };

        };

        // Hovedfolder closed -> Show algo for sub-items
        if (_folder_is_open === false) {

            let _break_main             = false;    // Init
            let _break_folder_seek      = false;    // Init
            let _item_current_level     = 0;        // Init
            let _folder_status          = false;    // Init
            let _status_arr             = []        // Init

            // Iterate > Fremover index k
            while (_break_main === false && k < node_mat[_i].length) {

                // Debug > Start hvert item
                if (_is_debug_item_liste) {
                    console.log("");
                    console.log("+++");
                    console.log("k          :: ", k);
                    console.log("fil_navn   :: ", node_mat[index_i][k].fil_navn);
                };

                // Init
                _break_folder_seek  = false; // Start folder seek
                _item_current_level = node_mat[_i][k].level_i; // Kopier current item-level
                let l               = k; // Kopier index k -> l for å itere bakover
                let _level_diff     = 1; // Diff 1 tilsvarer første egen folder

                // Iterate folder seek > Går gjennom alle foldere mellom sub-item til folderen som klikkes
                while (_break_folder_seek === false && l > -1) {

                    // Index l er på egen folder
                    if (node_mat[_i][l].level_i === _item_current_level - _level_diff &&
                        node_mat[_i][l].level_i > -1) {

                        // Egen topp-folder status
                        _folder_status = node_mat[_i][l].is_open;

                        // Push true- / false-verdi til status arr > Alle bl i stien fra sub-item til hovedfolder må være true for at den skal vises
                        _status_arr.push(_folder_status);

                        // Debug > Folder seek > Fil navn / status arr
                        if (_is_debug_item_liste) {
                            console.log("Folder seek > _status_arr :: ", node_mat[_i][l].fil_navn);
                            console.log("Folder seek > _status_arr :: ", _status_arr);
                        };;

                        // Inc _level_diff
                        _level_diff += 1;

                    };

                    // Ink l > Gå enda et steg bakover fra sub-item til hovedfolder
                    l -= 1;

                    // l-level er samme som hovedfolder > Break ut av folder seek
                    if (node_mat[_i][l].level_i === 0) {
                        _break_folder_seek = true;
                    };

                };

                // Sett show item-status
                if (_break_folder_seek === true) {

                    // Is all true
                    const _show_status = _is_all_true(_status_arr)

                    // Sett status
                    node_mat[_i][k].is_show = _show_status;

                    // Reset status arr for neste sub-item
                    _status_arr = []

                    // Debug > Break folder seek > Fil navn / resulterende show-status til item
                    if (_is_debug_item_liste) {
                        console.log("> Break folder seek");
                        console.log("k              :: ", k);
                        console.log("fil_navn       :: ", node_mat[_i][k].fil_navn);
                        console.log("_show_status   :: ", _show_status);
                    };
                };

                // Break main > k-el har samme level som hovedfolder > Ferdig
                if (node_mat[_i][k].level_i === _folder_level_i) {

                    // Breaker ut av loop når siste sub-item er evaluert
                    _break_main = true;

                };

                // Ink k > Neste sub-item under hovedfolder
                k += 1;

            };

        };

    };

    // Debug
    debug_node_item_open_show(node_mat, "set_node_selected() :: POST", _is_debug_folder_status_all);

    // Debug > Closed > Pre
    if (_is_debug_folder_status_pre_post === true) {
        for (let j = 0; j < node_mat[_i].length; j++) {
            // Disp
            if (node_mat[_i][j].is_open === false) {
                console.log("Closed > Post :: ", node_mat[_i][j].fil_navn)
            };
        };
    };

    // Return
    return node_mat

};

const set_node_selected_current_fil_content     = (node_mat, slug, slug_current, index_i) => {

    // Note > Se README.txt > set_node_selected_current_fil_content

    // Init
    let _slug_current_update_fil_content            = "";
    const fil_create_fil_set_fil_content_empty      = false; // Sett fil content etter ferdig fil navn

    // Set selected el > True
    for (let j = 0; j < node_mat[index_i].length; j++) {
        if (node_mat[index_i][j].is_selected === true) {

            // Er fil > Sett updated slug
            if (node_mat[index_i][j].is_folder === false) {

                // Ubrukt > Oppdater created ny fil med empty fil content
                if (fil_create_fil_set_fil_content_empty === true) {
                    _slug_current_update_fil_content = slug;
                };

                // Ikke oppdater created ny fil med empty fil content
                if (fil_create_fil_set_fil_content_empty === false) {

                    // Created ny fil > Fil content er current / fra forrige fil som ble klikket
                    if (slug === fil_slug_tmp_default) {
                        _slug_current_update_fil_content = slug_current;
                    };

                    // Ikke created ny fil > Oppdater fil content
                    if (slug !== fil_slug_tmp_default) {
                        _slug_current_update_fil_content = slug;
                    };

                };

            };

            // Er folder > Sett current slug / Ikke oppdater fil content
            if (node_mat[index_i][j].is_folder === true) {
                _slug_current_update_fil_content = slug_current;
            };
        };
    };

    // Return
    return _slug_current_update_fil_content;

};

const set_node_editing_all_false                = (node_mat) => {

    // Fil mat > Reset editing > All False
    for (let i = 0; i < node_mat.length; i++) {
        for (let j = 0; j < node_mat[i].length; j++) {
            if (node_mat[i][j]) {
                node_mat[i][j].is_editing = false;
            };
        };
    };

    // Return
    return node_mat

};

const set_node_editing_current                  = (node_mat, slug, index_i) => {

    // Reset alle selected i current editor > False
    for (let i = 0; i < node_mat.length; i++) {
        for (let j = 0; j < node_mat[i].length; j++) {
            node_mat[i][j].is_editing = false;
        };
    };

    // Set editing el > True
    for (let j = 0; j < node_mat[index_i].length; j++) {
        if (node_mat[index_i][j].slug === slug) {
            /// console.log("slug HIT >>> ", node_mat[index_i][j].slug);
            node_mat[index_i][j].is_editing = true;
        };
    };

    // Return
    return node_mat

};

const set_node_delete_par_ch                    = (node_mat, index_i, index_n) => {

    // Init
    const _level_i      = node_mat[index_i][index_n].level_i;
    const _len_start    = node_mat[index_i].length - 1;         // Siste item > hvis ikke har ch
    let k               = index_n + 1;

    // Iterate
    while (index_n !== _len_start &&
        node_mat[index_i][k].level_i > _level_i &&
        k < node_mat[index_i].length) { // console.log("sub_fil_navn :: ", node_mat[index_i][k].fil_navn);

        // Delete folder content
        node_mat[index_i].splice(k, 1);

    };

    // Delete par folder / fil (hvis kun fil)
    node_mat[index_i].splice(index_n, 1);

    // Return
    return node_mat

};

const _is_bytt_plass                            = (fil_navn_1, fil_navn_2) => {

    // Init
    let _bytt_plass = false;
    let _fil_navn_arr_1 = [fil_navn_1, fil_navn_2]; // ["bbb", "aaa"];
    let _fil_navn_arr_2 = [_fil_navn_arr_1[0], _fil_navn_arr_1[1]];

    // Sort arr_1
    _fil_navn_arr_1.sort((a, b) => a.localeCompare(b));

    // Compare
    if (_fil_navn_arr_1[0] === _fil_navn_arr_2[0] && _fil_navn_arr_1[1] === _fil_navn_arr_2[1]) {
        // console.log("LIKE :: ", _fil_navn_arr_1);
        _bytt_plass = false;
    } else {
        // console.log("IKKE LIKE > _fil_navn_arr_1 :: ", _fil_navn_arr_1)
        // console.log("IKKE LIKE > _fil_navn_arr_2 :: ", _fil_navn_arr_2)
        _bytt_plass = true
    };

    // Return
    return _bytt_plass

};

const set_node_sort_alfabetisk                  = (node_mat, index_i) => {

    /* ****************************************** */
    /* Init */
    /* ****************************************** */

    // Note > Se README.txt > set_node_sort_alfabetisk()

    // Debug > Init > Sett true når debug med normal debug-konfigurasjon
    const _debug_init = false;
    if (_debug_init === true) {
        console.log("");
        console.log("");
        console.log("");
        console.log("/* ****************************************** */");
        console.log("/* Init */");
        console.log("/* ****************************************** */");
    };

    // Init > Level vari
    let _level_i_max        = -10;
    let _level_i_current    = -10;


    let _do_seq = true;
    if (node_mat[index_i] === undefined) {

        _do_seq = false;

    };

    /// console.log("YYY :: ", node_mat[index_i].length)

    if (_do_seq === true) {

    // Finn _level_i_max > Sett _level_i_current
    for (let j = 0; j < node_mat[index_i].length; j++) { // console.log("index_i :: ", node_mat[i][j].level_i);
        if (node_mat[index_i][j].level_i > _level_i_max) {
            _level_i_max = node_mat[index_i][j].level_i
        };
    };

    // Sett i current
    _level_i_current                = _level_i_max;     // Sett _level_i_current til max, for så å iterere tilbake til level_i = 0

    // Init
    let n                           = 0;                // Primær index for å iterere gjennom alle el i node_mat
    let _ch_arr_tmp                 = [];               // Arr for å samle opp sub-items i aktuell folder som byttes
    let _break                      = false;            // Bool som breaker iterasjonen når ferdig

    // Debug
    const _debug_last_n_offset      = 2;                // Begrenser n-iterasjon i node_mat -> c < node_mat[index_i].length - _debug_last_n_offset
    const _debug_disp_norm          = false;            // Normal debug-konfigurasjon


    /* ****************************************** */
    /* Loop */
    /* ****************************************** */

    // Prøv > ny condition -> node_mat[index_i].length > 1

    // Iterer rekursivt ifra level_i_max til _level_i = 0
    while (_break === false && node_mat[index_i].length > 1) {


        /* ****************************************** */
        /* Sett el 1 / 2 */
        /* ****************************************** */

        // Debug > Iterasjon
        const _debug_iterasjon = _debug_disp_norm;
        if (_debug_iterasjon === true && n > 5 && n < 10) {
            // console.log("");
            // console.log("");
            // console.log("");
            console.log("/* ****************************************** */", n);
            // console.log("/* ITERASJON :: */", n);
            // console.log("/* ****************************************** */");
        };

        // Debug break > Funker å lage fil med break her
        const _is_break = false;
        if (_is_break === true) {
            if (n === 8) {_break = true};
        };

        // Init > Bool / El som isåfall bytter plass
        let _is_bytting     = false;
        const _el_tmp_1     = node_mat[index_i][n];
        const _el_tmp_2     = node_mat[index_i][n + 1];


        /* ****************************************** */
        /* Sett el 1 / 2 */
        /* ****************************************** */

        // Condition > Alle items som bytter plass har samme level
        if (_el_tmp_1.level_i === _el_tmp_2.level_i &&
            _el_tmp_1.level_i === _level_i_current) {

            // Case 1 > Bytt foldere
            if (_el_tmp_1.is_folder === true &&
                _el_tmp_2.is_folder === true) {

                    // Bytt > Funk
                    const _is_bytt_plass_bl = _is_bytt_plass(
                        _el_tmp_1.fil_navn,
                        _el_tmp_2.fil_navn);

                    // Bytt > Exe
                    if (_is_bytt_plass_bl === true) {

                        // Bytt > True
                        _is_bytting = true;

                        // Debug > El 1 / 2
                        const _debug_bytt_foldere = _debug_disp_norm;
                        if (_debug_bytt_foldere === true) {
                            console.log("");
                            console.log("+++ Bytt foldere :: ", n);
                            console.log("_el_tmp_1.fil_navn :: ", _el_tmp_1.fil_navn);
                            console.log("_el_tmp_2.fil_navn :: ", _el_tmp_2.fil_navn);
                        };

                    };
                };

            // Condition > Bytt filer
            if (_el_tmp_1.is_folder === false &&
                _el_tmp_2.is_folder === false) {

                    // Bytt > Funk
                    const _is_bytt_plass_bl = _is_bytt_plass(
                        _el_tmp_1.fil_navn,
                        _el_tmp_2.fil_navn);

                    // Bytt > Exe
                    if (_is_bytt_plass_bl === true) {

                        // Bytt > True
                        _is_bytting = true;

                        // Debug > El 1 / 2
                        const _debug_bytt_foldere = _debug_disp_norm;
                        if (_debug_bytt_foldere === true) {
                            console.log("");
                            console.log("+++ Bytt filer :: ", n);
                            console.log("_el_tmp_1.fil_navn :: ", _el_tmp_1.fil_navn);
                            console.log("_el_tmp_2.fil_navn :: ", _el_tmp_2.fil_navn);
                        };
                    };
                };

            // Condition > Fil på top / Folder på btn
            if (_el_tmp_1.is_folder === false &&
                _el_tmp_2.is_folder === true) {

                    // Bytt > True
                    _is_bytting = true;

                    // Debug > El 1 / 2
                    const _debug_bytt_fil_folder = _debug_disp_norm;
                    if (_debug_bytt_fil_folder === true) {
                        console.log("");
                        console.log("+++ Bytt fil / folder :: ", n);
                        console.log("_el_tmp_1.fil_navn :: ", _el_tmp_1.fil_navn);
                        console.log("_el_tmp_2.fil_navn :: ", _el_tmp_2.fil_navn);
                    };

                };

        };


        /* ****************************************** */
        /* Bytt el 1 / 2 */
        /* ****************************************** */

        // Bytt > _el_tmp_2 -> _el_tmp_1
        if (_is_bytting === true) { // console.log(">> bytting true!");

            // Debug > Status
            const _debug_node_mat_status_start = _debug_disp_norm;
            if (_debug_node_mat_status_start === true) {
                console.log("");
                console.log("+++ STATUS > START > node mat :: ", n);
                for (let c = 6; c < node_mat[index_i].length - _debug_last_n_offset; c++) {
                    console.log(`node_mat[index_i][${c}].fil_navn :: `, node_mat[index_i][c].fil_navn);
                };
            };

            // _el_tmp_2 > folder
            let k           = 0;
            _ch_arr_tmp     = [];   // console.log(">> _level_i_current :: ", _level_i_current)
            if (node_mat[index_i][n + 1].is_folder === true) { // console.log(">> node_mat[index_i][n + 1].fil_navn :: ", node_mat[index_i][n + 1].fil_navn)
                k               = n + 2
                if (k < node_mat[index_i].length) {
                    while (node_mat[index_i][k].level_i > _level_i_current &&
                        k < node_mat[index_i].length) { // console.log(">> node_mat[index_i][k].fil_navn :: ", node_mat[index_i][k].fil_navn);
                            _ch_arr_tmp.push(node_mat[index_i][k]); // console.log(">> node_mat[index_i][k].level_i  :: ", node_mat[index_i][k].level_i);
                            k += 1;
                    };
                }; // console.log(">> _ch_arr_tmp ", _ch_arr_tmp)
            };

            // Debug > All > _ch_arr
            const _debug_ch_arr_tmp_done = _debug_disp_norm;
            if (_debug_ch_arr_tmp_done === true) {
                console.log("");
                console.log("+++ _ch_arr_tmp > done :: ", n);
                for (let a = 0; a < _ch_arr_tmp.length; a++) {
                    console.log(`_ch_arr_tmp[${a}].fil_navn :: `, _ch_arr_tmp[a].fil_navn);
                };
            };

            // Delete el fra node_mat
            let x = 0;
            let y = 0;
            while (x < node_mat[index_i].length) {
                while (y < _ch_arr_tmp.length) {
                    if (node_mat[index_i][x].slug === _ch_arr_tmp[y].slug) { // console.log("> Hit :: ", node_mat[index_i][x].slug)

                        // Fil navn > Debug
                        const _debug_fil_navn = node_mat[index_i][x].fil_navn;

                        // Remove
                        node_mat[index_i].splice(x, 1);

                        // Debug > Remove
                        const _debug_remove_node = false;
                        if (_debug_remove_node === true) {
                            console.log("");
                            console.log("+++ REMOVE node :: ", n);
                            console.log(`node_mat[index_i][${x}].fil_navn :: `, _debug_fil_navn);
                        };

                    };
                    y += 1;
                };
                y = 0;
                x += 1;
            };


            /* ****************************************** */
            /* Update el 1 */
            /* ****************************************** */

            // Debug > Status
            const _debug_node_mat_status_update_1_pre = _debug_disp_norm;
            if (_debug_node_mat_status_update_1_pre === true) {
                console.log("");
                console.log("+++ STATUS > UPDATE 1 > PRE > node mat :: ", n);
                for (let c = 6; c < node_mat[index_i].length - _debug_last_n_offset; c++) {
                    console.log(`node_mat[index_i][${c}].fil_navn :: `, node_mat[index_i][c].fil_navn);
                };
            };

            // _el_tmp_2 -> _el_tmp_1
            node_mat[index_i][n] = _el_tmp_2;

            // Debug > Status
            const _debug_node_mat_status_update_1_post = _debug_disp_norm;
            if (_debug_node_mat_status_update_1_post === true) {
                console.log("");
                console.log("+++ STATUS > UPDATE 1 > POST > node mat :: ", n);
                for (let c = 6; c < node_mat[index_i].length - _debug_last_n_offset; c++) {
                    console.log(`node_mat[index_i][${c}].fil_navn :: `, node_mat[index_i][c].fil_navn);
                };
            };


            /* ****************************************** */
            /* Insert */
            /* ****************************************** */

            // Reverse
            let l = 0;
            _ch_arr_tmp.reverse();

            // Debug > Reversed > _ch_arr
            const _debug_ch_arr_tmp_done_rev = false;
            if (_debug_ch_arr_tmp_done_rev === true) {
                console.log("");
                console.log("+++ _ch_arr_tmp_rev > done :: ", n);
                for (let a = 0; a < _ch_arr_tmp.length; a++) {
                    console.log(`_ch_arr_tmp[${a}].fil_navn :: `, _ch_arr_tmp[a].fil_navn);
                };
            };

            if (_ch_arr_tmp.length !== 0) {
                while (l < _ch_arr_tmp.length) { // console.log(">>> n :: ", n);

                    // Debug > Insert > _ch_arr
                    const _debug_node_mat_insert_ch_arr = false;
                    if (_debug_node_mat_insert_ch_arr === true) {
                        console.log("");
                        console.log("+++ Insert ch arr :: ", n);
                        console.log(`_ch_arr_tmp[${l}].fil_navn :: `, _ch_arr_tmp[l].fil_navn);
                    };

                    // Insert
                    node_mat[index_i].splice(n + 1, 0, _ch_arr_tmp[l]); // console.log(">>> fil navn :: ", _ch_arr_tmp[l].fil_navn);

                    // Debug > Insert
                    const _debug_node_mat_status_insert_sub_post = _debug_disp_norm;
                    if (_debug_node_mat_status_insert_sub_post === true) {
                        console.log("");
                        console.log("+++ STATUS > INSERT > SUB > POST > node mat :: ", n);
                        for (let c = 6; c < node_mat[index_i].length - _debug_last_n_offset; c++) {
                            console.log(`node_mat[index_i][${c}].fil_navn :: `, node_mat[index_i][c].fil_navn);
                        };
                    };

                    l += 1;
                };
            };


            /* ****************************************** */
            /* Update el 2 */
            /* ****************************************** */

            // Debug > Status
            const _debug_node_mat_status_update_2_pre = _debug_disp_norm;
            if (_debug_node_mat_status_update_2_pre === true) {
                console.log("");
                console.log("+++ STATUS > UPDATE 2 > PRE > node mat :: ", n);
                for (let c = 6; c < node_mat[index_i].length - _debug_last_n_offset; c++) {
                    console.log(`node_mat[index_i][${c}].fil_navn :: `, node_mat[index_i][c].fil_navn);
                };
            };

            // _el_tmp_1 -> _el_tmp_1
            node_mat[index_i][n + 1 + l] = _el_tmp_1;

            // Debug > Status
            const _debug_node_mat_status_update_2_post = _debug_disp_norm;
            if (_debug_node_mat_status_update_2_post === true) {
                console.log("");
                console.log("+++ STATUS > UPDATE 2 > POST > node mat :: ", n);
                for (let c = 6; c < node_mat[index_i].length - _debug_last_n_offset; c++) {
                    console.log(`node_mat[index_i][${c}].fil_navn :: `, node_mat[index_i][c].fil_navn);
                };
            };

        };

        // Debug > Status
        const _debug_node_mat_status_done = false;
        if (_debug_node_mat_status_done === true) {
            console.log("");
            console.log("+++ STATUS > DONE > node mat :: ", n);
            for (let c = 6; c < node_mat[index_i].length - _debug_last_n_offset; c++) {
                console.log(`node_mat[index_i][${c}].fil_navn :: `, node_mat[index_i][c].fil_navn);
            };
        };


        /* ****************************************** */
        /* Inkrement */
        /* ****************************************** */

        // Is bytting > true / false
        if (_is_bytting === true)   {n = 0}
        if (_is_bytting === false)  {

            // Inkrement
            n += 1;
            if (n === node_mat[index_i].length - 1) {

                // Level > Gen
                if (_level_i_current > -1) {
                    _level_i_current -= 1;
                    n = 0;
                };

                // Level > Root
                if (_level_i_current === -1) {
                    _break = true;
                };
            };
        };

    };

    }

    // Return
    return node_mat

};

const set_node_create_path                      = (node_mat, index_i, fil_node_create_fil_el) => {

    // Init
    const _index_i                  = index_i;
    const _is_debug                 = false;

    // Init > Add tmp root
    const _el = fil_node_create_fil_el;
    _el.level_i                     = 0;            // py: 0, // Ikke endre _el.slug / _el.fil_navn > Breaker create fil > _el.slug  = "root", _el.fil_navn = "root_navn";
    node_mat[_index_i].splice(0, 0, _el);

    // Init > Abs path
    node_mat[_index_i][0].abs_path  = _sym_div_slash;   // self._root_dir_tmp   # Set root node som dir tmp
    node_mat[_index_i][0].fil_navn  = "";           //                      # Ingen navn for bestem abs_path_current
    let _abs_path_prev              = node_mat[_index_i][0].abs_path;
    let _level_i_prev               = 1;            // py: 2;

    // Init > JS Init
    let _item_navn_current          = "";
    let _item_navn_prev             = "";
    let _level_i_current            = -10;
    let _abs_path_current           = "";

    // Iterate > Fra 1 > Skip "+ root"
    for (let n = 1; n < node_mat[_index_i].length; n++) { // console.log("redux n :: ", n)

        // ##########################################
        // Fil > Write > Tmp dir
        // ##########################################

        // Init
        _item_navn_current          = node_mat[_index_i][n].fil_navn;
        _item_navn_prev             = node_mat[_index_i][n - 1].fil_navn;
        _level_i_current            = node_mat[_index_i][n].level_i;

        // Level > Bestem abs_path_current
        if (_level_i_current > _level_i_prev) {
            _abs_path_current = _gum_path_update(_abs_path_prev, "forward", _item_navn_prev);
        };
        if (_level_i_current === _level_i_prev) {
            _abs_path_current = _gum_path_update(_abs_path_prev, "still", _item_navn_current);
        };
        if (_level_i_current < _level_i_prev) {
            _abs_path_current = _gum_path_update(_abs_path_prev, "back", _item_navn_prev);
        };

        // Update node el
        node_mat[_index_i][n].abs_path   = _abs_path_current;

        // Update prev for neste iterasjon
        _abs_path_prev      = _abs_path_current;
        _level_i_prev       = _level_i_current;

    };

    // Add tmp root
    node_mat[_index_i].splice(0, 1);

    // Debug
    if (_is_debug) {
        for (let n = 0; n < node_mat[_index_i].length; n++) {
            console.log(`rp > ${n} :: `, node_mat[_index_i][n].abs_path)
        };
    };

    // Return
    return node_mat

};

const debug_node_item_slug                      = (node_mat, debug_txt, disp) => {

    // Debug
    if (disp === true) {

        console.log("/* ****************************************** */");
        console.log(`/* ${debug_txt} :: */`);
        console.log("/* ****************************************** */");
        console.log("");
        for (let i = 0; i < node_mat.length; i++) {

            // Print kun i == 0 > Quest
            if (i === 0) {

                for (let j = 0; j < node_mat[i].length; j++) {

                    console.log("slug :: ", node_mat[i][j].slug);

                };

            };
        };

    };
};

const debug_node_item_open_show                 = (node_mat, debug_txt, disp) => {

    // Reset
    const _debug_folder         = true;
    const _debug_fil            = true;

    // Debug
    if (disp === true) {

        // console.log("");
        // console.log("");
        // console.log("");
        console.log("/* ****************************************** */");
        console.log(`/* ${debug_txt} :: */`);
        console.log("/* ****************************************** */");
        console.log("");
        for (let i = 0; i < node_mat.length; i++) {

            // Print kun i == 0 > Quest
            if (i === 0) {

                for (let j = 0; j < node_mat[i].length; j++) {

                    // Folder
                    if (_debug_folder === true &&
                        node_mat[i][j].is_folder === true) {
                        console.log("")
                        console.log("fil_navn    :: ", node_mat[i][j].fil_navn);
                        console.log("- is_folder :: ", node_mat[i][j].is_folder);
                        console.log("- is_open   :: ", node_mat[i][j].is_open);
                        console.log("- is_show   :: ", node_mat[i][j].is_show);
                    };

                    // Folder
                    if (_debug_fil === true &&
                        node_mat[i][j].is_folder === false) {
                        console.log("")
                        console.log("fil_navn    :: ", node_mat[i][j].fil_navn);
                        console.log("- is_folder :: ", node_mat[i][j].is_folder);
                        console.log("- is_open   :: ", node_mat[i][j].is_open);
                        console.log("- is_show   :: ", node_mat[i][j].is_show);
                    };

                };

            };
        };
    };

};


/* ****************************************** */
/* Export */
/* ****************************************** */

export {

    // Gen
    get_fil_ext,
    get_fil_ext_dotless,
    fil_get_fil_navn_ext_underscore,
    txt_create_slug_from_str,

    // Str / arr
    str_komma_sep_to_arr_reset,
    mat_to_pluss_arr,
    arr_komma_sep_to_str,

    // Redux
    set_node_selected_all_0,
    set_node_selected_fil_slug_current_reset,
    set_node_selected_fil_slug_fil_entry_reset,
    set_node_selected_fil_slug_current_do,
    set_node_selected_fil_content_init_yy,
    set_node_selected_fil,
    set_node_selected_current_editor_0,
    set_node_selected,
    set_node_selected_current_fil_content,
    set_node_editing_all_false,
    set_node_editing_current,
    set_node_delete_par_ch,
    set_node_sort_alfabetisk,
    set_node_create_path,

    // Debug
    debug_node_item_slug,
    debug_node_item_open_show
};