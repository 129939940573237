/* ****************************************** */
/* Filter */
/* ****************************************** */

// Filter
const filter_init                           = "filter_init";
const filter_order_nyeste_typ               = "nyeste";                 // DEV: Skole, Forlag
const filter_order_topp_typ                 = "topp";
const filter_typ_tagg                       = "filter_typ_tagg";        // DEV: Uforståelig kode (alternativ ...)
const filter_typ_tagg_id                    = "filter_typ_tagg_id";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    filter_init,
    filter_order_nyeste_typ,
    filter_order_topp_typ,
    filter_typ_tagg,
    filter_typ_tagg_id
};
