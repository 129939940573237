import Modal from "react-modal";

/// import {CubeSpinner} from "react-spinners-kit";
/// import {GridSpinner} from "react-spinners-kit";
import {
    SwapSpinner,
    // CubeSpinner,
    SequenceSpinner,
    // CombSpinner
}
from "react-spinners-kit";


const ModalLoading = (props) => {

    const is_page_spinner = false;
    const is_path_spinner = true;

    // SwapSpinner, CubeSpinner
    const is_swap_spinner_classic = false;
    const swap_spinner_classic = {
        size:           50,
        color:          "#0575e6",
        is_loading:     props.is_loading
    };

    // SequenceSpinner
    const is_seq_spinner = true;
    const seq_spinner = {
        size:           20, // 20, 18
        front_color:    "#4775A3", // Python colors
        back_color:     "#F8D35B", // Python colors
        is_loading:     props.is_loading
    };

    // Return
    return (
        <>

        {is_path_spinner &&
            <SequenceSpinner
                size            = {seq_spinner.size}
                frontColor      = {seq_spinner.front_color}
                backColor       = {seq_spinner.back_color}
                loading         = {seq_spinner.is_loading}/>}

        {is_page_spinner &&
            <div className = "modal_loading_box_page">
                <Modal
                    isOpen              = {props.is_loading}
                    contentLabel        = "My dialog"
                    className           = "modal_loading_main_page"
                    overlayClassName    = "modal_loading_overlay_page"
                    ariaHideApp         = {false}>

                    {is_seq_spinner &&
                        <SequenceSpinner
                            size            = {seq_spinner.size}
                            frontColor      = {seq_spinner.front_color}
                            backColor       = {seq_spinner.back_color}
                            loading         = {seq_spinner.is_loading}/>}

                    {is_swap_spinner_classic &&
                        <SwapSpinner
                            size            = {swap_spinner_classic.size}
                            frontcolor      = {swap_spinner_classic.color}
                            loading         = {swap_spinner_classic.is_loading}/>}
                </Modal>
            </div>}
        </>
    )

};


export default ModalLoading;
