/* ****************************************** */
/* Kommentar */
/* ****************************************** */

// Kommentar
const kommentar_create                      = "_kommentar_create";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    kommentar_create
};


/* ****************************************** */
/* End */
/* ****************************************** */
