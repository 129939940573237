import {
    font_family_spill,
    font_size_expr_1,
    font_size_expr_2_mult
} from "../../utils/konstanter/gen/_font";

import {
    _a_s,
    _b_s,
    _c_s,
    _d_s,
    _e_s,
    _f_s,
    _g_s,
    _h_s,
    _i_s,
    _j_s,
    _k_s,
    _l_s,
    _m_s,
    _n_s,
    _o_s,
    _p_s,
    _q_s,
    _r_s,
    _s_s,
    _t_s,
    _u_s,
    _v_s,
    _w_s,
    _x_s,
    _y_s,
    _z_s,
} from "../../utils/konstanter/components/_alfabet";
import {
    _t_1,
    _t_2,
    _t_3,
    _t_4,
    _t_5,
    _t_6,
    _t_7,
    _t_8,
    _t_9,
    _t_0,
} from "../../utils/konstanter/components/_num";
import {
    _L_1,
    _R_1,
    _sym_pluss,
    _sym_minus,
    _sym_div_slash,
    _sym_expo,
    _sym_empty,
    _sym_curs,
    _sym_root_sep
} from "../../utils/konstanter/components/_sym";
import {
    col_transparent,
    col_spill_txt_norm,
    col_spill_txt_curs,
    col_spill_bg_norm,
} from "../../utils/konstanter/gen/_color";
import {
    lay_xy_matte_strek,
    spill_expr_lbl_yy
} from "../../utils/konstanter/gen/_layout";
import {
    _ln,

    _l_empty,
    _l_curs,
    _l_num,
    _l_vari,
    _l_lik,
    _l_pluss,
    _l_minus,
    _l_ganging,
    _l_funk,
    _l_root,
    _l_par_1_L,
    _l_par_1_R,

    _SIZE_1,
    _SIZE_1_EMPTY,
    _SIZE_1_CURS,
    _SIZE_2,
    _SIZE_2_EMPTY,
    _SIZE_2_CURS,
    _NORM,
    _NORM_NORM,
    _NORM_BROK,
    _TELL,
    _TELL_NEVN,
    _NEVN,
    _BROK,
    _BROK_NORM,
    _G,
    _E,
    _GE,
    _R1R2,

    spill_expr_html_init
} from "../../utils/konstanter/pages/_spill";


/* ****************************************** */
/* Create views */
/* ****************************************** */

// Remove
const _remove_SCLV                              = (i, v_arr) => {

    // Debug
    const _e                = 1;
    const _d                = 0;

    let _v_arr              = v_arr;

    // Disp > PRE
    if (_d === 1) {};

    // Exe
    if (_e === 1) { _v_arr.splice(i, 1); };

    // Disp > PRE
    if (_d === 1) {};

    // Return
    return _v_arr

};

// Create 0
const create_0_expo                             = (v_arr) => {

    // Init
    let _v_arr      = v_arr;
    let i           = 0;
    let k           = 0;

    // Debug
    const _expo_level_debug                     = (d, _expo_level, _e, _p_1, _p_2) => {
        if (d === 1) {
            console.log("Expo level :: ", _expo_level)
            console.log("e          :: ", _e)
            console.log("p_1        :: ", _p_1)
            console.log("p_2        :: ", _p_2)
        };
    };

    // Iterate expo
    while (i < _v_arr.length - 1) {

        // EXPO
        if (i > -1 &&
        i < _v_arr.length &&
        _v_arr[i].str === _sym_expo) {

            // console.log("EXPO")

            k                           = i + 2;
            let expo_level              = 0;
            let _e                      = i;
            let _g                      = 0;
            let _p_1                    = 0;
            let _p_2                    = 0;
            let _p_1_L                  = 1;
            let _p_1_R                  = 0;
            let _tmp_expo_arr           = [];

            while (k < _v_arr.length &&
            _p_1_L !== _p_1_R) {

                // PAR
                if (_v_arr[k].str === _L_1) { _p_1_L += 1; }
                if (_v_arr[k].str === _R_1) { _p_1_R += 1; }

                // Multi expo
                if (_v_arr[k].str === _sym_expo) {

                    // pause > expo_level =+ 1;
                    _e          = k;
                    k           = k + 2;
                    _p_1_L      = 1;
                    _p_1_R      = 0;

                };

                k += 1;

            };

            _g      = _e - 1;
            _p_1    = _e + 1;
            _p_2    = k - 1;

            // Expo level === 0
            if (expo_level === 0) {
                _expo_level_debug(0, expo_level, _e, _p_1, _p_2);

                let m = _p_1 + 1;
                while (m < _p_2) {
                    _tmp_expo_arr.push(_v_arr[m]);
                    m += 1;
                };

                m = _p_2
                while (m > _e - 1) {
                    _v_arr = _remove_SCLV(m, _v_arr);
                    m -= 1;
                };

            };

            // Expo level > 0
            if (expo_level > 0) {
                _expo_level_debug(0, expo_level, _e, _p_1, _p_2);
            };

            // Debug arr
            for (let u = 0; u < _tmp_expo_arr.length; u++) {
                // console.log("t-arr :: ", _tmp_expo_arr[u].str)
            };
            for (let u = 0; u < _v_arr.length; u++) {
                // console.log("v-arr :: ", state._v_arr[u].str)
            };

            // Expo
            let _tmp_expo_el        = create_gui(_tmp_expo_arr, 2)[0];
            _tmp_expo_el.typ        = _E; // console.log("ex :: ", _tmp_expo_el.str)

            // Grunntall
            let _tmp_grunn_el       = create_gui([_v_arr[_g]], 1)[0];
            _tmp_grunn_el.typ       = _G; // console.log("gr :: ", _tmp_grunn_el.str)

            // Expo / Grunntall
            let _g_e_el             = create_gui([_tmp_grunn_el, _tmp_expo_el], 1)[0];
            _v_arr[_g]              = _g_e_el;
            _v_arr[_g].typ          = _NORM;

            i = -1;

        };

        i += 1;

    };

    return _v_arr

};
const _create_0_brok                            = (i, v_arr) => {

    // DEV > Sjekk ut hva _str_cur er for noe > Foreløpig ingen effekt kanskje

    // Debug
    const _e        = 1;
    const _d        = 0;

    let _v_arr      = v_arr;

    // Disp > PRE
    if (_d === 1) {

        console.log("")
        // console.log(f"> CREATE_0_BROK > PRE", self._debug_mega_cnt_iter)

        // console.log(">> _v_arr :: ")
        // console.log(">>> ", self._v_arr)

    };

    // Exe
    if (_e === 1) {

        // TELL
        let k               = i + 2;
        let _i_slash        = 0;
        let _break          = false;
        let _typ_cur        = "";
        let _str_cur        = "";

        while (k < _v_arr.length &&
        _v_arr[k].str !== _sym_div_slash &&
        _break === false) {

            _typ_cur = _v_arr[k].typ;

            // SWITCH
            if         (_typ_cur === _l_curs)           { _v_arr[k].typ = _NORM; }

            else if    (_typ_cur === _NORM)             { _v_arr[k].typ = _TELL; }
            else if    (_typ_cur === _BROK)             { _v_arr[k].typ = _TELL; }

            else if    (_typ_cur === _l_par_1_L)        { _break = true; }
            else if    (_typ_cur === _l_par_1_R)        { _break = true; }

            else                                        { _break = true; };

            k += 1;

        };

        _i_slash = k;

        // NEVN
        k               += 1;
        let _p_1_L      = 0;
        let _p_1_R      = 0;
        _break          = false;

        // Iterate
        while (k < _v_arr.length &&
        _p_1_R <= _p_1_L &&
        _break === false) {

            _typ_cur    = _v_arr[k].typ;

            // PAR
            if (_str_cur === _L_1)                      { _p_1_L += 1; }
            if (_str_cur === _R_1)                      { _p_1_R += 1; }

            // SWITCH
            if (_typ_cur === _NORM)                     { _v_arr[k].typ = _NEVN; }
            else if (_typ_cur === _BROK)                { _v_arr[k].typ = _NEVN; }

            else if (_typ_cur === _l_par_1_L)           { _break = true; }
            else if (_typ_cur === _l_par_1_R)           { _break = true; }

            else                                        { _break = true; };

            k += 1;

        };

        // console.log("")
        // console.log("*** 0_brok > k - 1    :: ", k - 1)
        // console.log("*** 0_brok > _i_slash :: ", _i_slash)
        // console.log("*** 0_brok > i + 1    :: ", i + 1)

        // Remove
        _v_arr = _remove_SCLV(k - 1,    _v_arr);
        _v_arr = _remove_SCLV(_i_slash, _v_arr);
        _v_arr = _remove_SCLV(i + 1,    _v_arr);

    };

    // Disp > POST
    if (_d === 1) {

        console.log("")
        // console.log(f"> CREATE_0_BROK > POST :: ", _debug_mega_cnt_iter)
        // console.log(">> _v_arr :: ")
        // console.log(">>> ", _v_arr)

    };

    // Return
    return _v_arr

};
const _create_0_brok_bool                       = (k, v_arr) => {

    // Debug
    const _e        = 1;
    const _d        = 0;

    // Init
    let m               = k + 1;
    let _p_1_L          = 1;
    let _p_1_R          = 0;
    let _brok_bool      = false;
    let _v_arr          = v_arr;

    // Exe
    if (_e === 1) {

        // Iterate
        while (_p_1_L !== _p_1_R) {

            if (_v_arr[m].str === _sym_div_slash) { _brok_bool = true; };

            if (_v_arr[m].str === _L_1) { _p_1_L += 1; };

            if (_v_arr[m].str === _R_1) { _p_1_R += 1; };

            m += 1;

        };

    };

    // Disp
    if (_d === 1) {};

    // Return
    return _brok_bool

};
const _create_0_funk                            = (c_typ, size, i, v_arr) => {

    // Debug
    const _e            = 1;
    const _d            = 0;

    // Arg
    let _v_arr          = v_arr;

    // Vari
    let _arr_ret        = [];

    // Exe
    if (_e === 1) {

        _v_arr[i + 1].typ = size;
        _arr_ret = _create_lbl(i + 1); // i=i + 1
        _v_arr = _arr_ret[0]; // DEV > Sjekk retur arr osv.

        _v_arr[i + 1].typ = c_typ;

        _v_arr[i + 3].typ = size;
        _arr_ret = _create_lbl(i + 3); // i=i + 3
        _v_arr = _arr_ret[0]; // DEV > Sjekk retur arr osv.

        _v_arr[i + 3].typ = c_typ;

    };

    // Disp
    if (_d === 1) {};

    // Return
    return _v_arr // DEV > Sjekk retur arr osv.

};
const _create_0_root                            = (i, v_arr) => {

    // Debug
    const _d_ix                 = 0;
    const _d_pre_post           = 0;

    // Init
    let _v_arr                  = v_arr;
    let k                       = i + 2;
    let expo_level              = 0;
    let _r                      = i;
    let _s                      = -10;
    let _p_1                    = i + 1;
    let _p_2                    = 0;
    let _p_1_L                  = 1;
    let _p_1_R                  = 0;
    let _tmp_pre_arr            = [];
    let _tmp_post_arr           = [];

    // k < _v_arr.length &&
    while (_p_1_L !== _p_1_R) {

        // PAR
        if (_v_arr[k].str === _L_1) { _p_1_L += 1; }
        if (_v_arr[k].str === _R_1) { _p_1_R += 1; }

        // Root sep
        if (_v_arr[k].str === _sym_root_sep) {

            _s = k

        };

        k += 1;

    };

    _p_2 = k - 1;

    // Debug > r(A;B)
    if (_d_ix === 1) {
        console.log("r  :: ", _r)       // 0
        console.log("p1 :: ", _p_1)     // 1
        console.log("s  :: ", _s)       // 3
        console.log("p2 :: ", _p_2)     // 5
    }

    for (let i = 0; i < _v_arr.length; i++) {
        console.log("v_arr_[i] :: ", _v_arr[i].str);
    };

    // Expo level === 0
    if (expo_level === 0) {

        let m = _p_1 + 1;
        while (m < _s) {
            _tmp_pre_arr.push(_v_arr[m]);
            m += 1;
        };

        m += 1;
        while (m < _p_2) {
            _tmp_post_arr.push(_v_arr[m]);
            m += 1;
        };

        // Remove root(pre;post)
        m = _p_2;
        while (m > _r) {
            _v_arr = _remove_SCLV(m, _v_arr);
            m -= 1;
        };

    };

    // Debug
    if (_d_pre_post === 1) {
        console.log("pre  :: ", _tmp_pre_arr);
        console.log("post :: ", _tmp_post_arr); // console.log("v_ar :: ", _v_arr);
        for (let i = 0; i < _v_arr.length; i++) {
            console.log("v_arr_[i] :: ", _v_arr[i].str);
        };
    };

    // Pre
    let _tmp_pre_el        = create_gui(_tmp_pre_arr, 2)[0];
    _tmp_pre_el.typ        = "R1";
    console.log("*pre :: ", _tmp_pre_el.str)

    // Post
    let _tmp_post_el       = create_gui(_tmp_post_arr, 1)[0];
    _tmp_post_el.typ       = "R2";
    console.log("*post :: ", _tmp_post_el.str)

    // Pre / Post
    let _pre_post_el        = create_gui([_tmp_pre_el, _tmp_post_el], 1)[0]; // console.log("_pre_post_el :: ", _pre_post_el)
    _v_arr[_r]              = _pre_post_el;
    _v_arr[_r].typ          = _NORM;

    // Return
    return _v_arr

};

// Basic
const _get_str                                  = (typ, el_1, el_2) => {

    // Typ
    if (typ === _NORM)      { return `${el_1}` };
    if (typ === _NORM_NORM) { return `${el_1}${el_2}` };
    if (typ === _BROK)      { return `${_L_1}${el_1}${_sym_div_slash}${el_2}${_R_1}` };
    if (typ === _R1R2)      { return `${_l_root}${_L_1}${el_1}${_sym_root_sep}${el_2}${_R_1}` };
    if (typ === _GE)        { return `${el_1}${_sym_expo}${_L_1}${el_2}${_R_1}` };

};
const _get_lbl_koeff                            = (size_lbl) => {

    // Init
    let _s        = 0;
    const _p      = font_size_expr_1 / 16;
    const _m      = font_size_expr_2_mult;

    // Size lbl
    if (size_lbl === 1) { _s = 1  };
    if (size_lbl === 2) { _s = _m };

    // Koeff
    const _k = _s * _p;

    // Return
    return _k

};
const _get_lbl_sym_xx                           = (sym, typ) => {

    // typ -> size
    const _is_size = (typ) => {

        // Size 1
        if (typ === _SIZE_1 ||
            typ === _SIZE_1_EMPTY ||
            typ === _SIZE_1_CURS) { return 1 };

        // Size 2
        if (typ === _SIZE_2 ||
            typ === _SIZE_2_EMPTY ||
            typ === _SIZE_2_CURS) { return 2 };

    };

    // Size
    const _size = _is_size(typ);

    // sym
    const _is_sym = (sym, size_lbl) => {

        // Koeff
        const _k = _get_lbl_koeff(size_lbl);

        // Num
        if (sym === _t_0)           { return 8  * _k }
        if (sym === _t_1)           { return 8  * _k }
        if (sym === _t_2)           { return 8  * _k }
        if (sym === _t_3)           { return 8  * _k }
        if (sym === _t_4)           { return 8  * _k }
        if (sym === _t_5)           { return 8  * _k }
        if (sym === _t_6)           { return 8  * _k }
        if (sym === _t_7)           { return 8  * _k }
        if (sym === _t_8)           { return 8  * _k }
        if (sym === _t_9)           { return 8  * _k }

        // Alfa > Small (æ = 11, ø = 8, å = 7)
        if (sym === _a_s)           { return 7  * _k }
        if (sym === _b_s)           { return 8  * _k }
        if (sym === _c_s)           { return 7  * _k }
        if (sym === _d_s)           { return 8  * _k }
        if (sym === _e_s)           { return 7  * _k }
        if (sym === _f_s)           { return 5  * _k }
        if (sym === _g_s)           { return 8  * _k }
        if (sym === _h_s)           { return 8  * _k }
        if (sym === _i_s)           { return 4  * _k }
        if (sym === _j_s)           { return 4  * _k }
        if (sym === _k_s)           { return 8  * _k }
        if (sym === _l_s)           { return 4  * _k }
        if (sym === _m_s)           { return 12 * _k }
        if (sym === _n_s)           { return 8  * _k }
        if (sym === _o_s)           { return 8  * _k }
        if (sym === _p_s)           { return 8  * _k }
        if (sym === _q_s)           { return 8  * _k }
        if (sym === _r_s)           { return 5  * _k }
        if (sym === _s_s)           { return 6  * _k }
        if (sym === _t_s)           { return 4  * _k }
        if (sym === _u_s)           { return 8  * _k }
        if (sym === _v_s)           { return 8  * _k }
        if (sym === _w_s)           { return 12 * _k }
        if (sym === _x_s)           { return 8  * _k }
        if (sym === _y_s)           { return 8  * _k }
        if (sym === _z_s)           { return 7  * _k }

        /* OP */
        if (sym === _sym_pluss)     { return 9  * _k }
        if (sym === _sym_minus)     { return 5  * _k }

        /* Special */
        if (sym === _sym_curs)      { return 0  * _k } // Default > 10
        if (sym === _sym_empty)     { return 0  * _k }

        return 8 * _k

    };

    // Return
    return _is_sym(sym, _size)

};
const _get_lbl_sym_xx_compensate                = (font_size) => {

    // Koeff
    const _k = font_size / 16;

    // Return
    return -5 * _k

};
const _get_lbl_xx_min                           = (font_size) => {

    // Koeff
    const _k = font_size / 16;

    const _xx = _k * 8

    // Return
    return _xx

};
const _get_lbl_root_yp                          = (font_size) => {

    // DEV > root_top_yp ?

    // Koeff
    // const _k = font_size_expr_1 / 16;

    // YP
    const _yp = spill_expr_lbl_yy / 4;

    // Return
    return _yp

};
const _get_lbl_root_v_xx                        = (font_size) => {

    // Koeff
    // const _k = font_size_expr_1 / 16;

    // YP
    const _v_xx = spill_expr_lbl_yy; // * 2; // DEV

    // Return
    return _v_xx

};
const _get_lbl_yy                               = (size) => {

    // Koeff
    const _k = _get_lbl_koeff(size);

    // Return
    return spill_expr_lbl_yy * _k // 18

};
const _get_lbl_font_size                        = (size) => {

    // Multiplier
    const m = font_size_expr_2_mult;

    // Koeff
    const _get_size = (size) => {
        if (size === 1) { return font_size_expr_1 * 1};
        if (size === 2) { return font_size_expr_1 * m};
    };

    // Size
    const _font_size = _get_size(size);

    // Return
    return _font_size

};
const _get_lbl_col_debug                        = (d, typ, col_default) => {

    // Bypass all
    const _bypass_all = 1; // 1

    // Bypass all > 0 > Custom debug col
    if (_bypass_all === 0) {

        // Col
        if (d === 1) {

            if (typ === _NORM)          { return "brown" };
            if (typ === _NORM_NORM)     { return "blue" };
            if (typ === _BROK)          { return "darkgreen" };
            if (typ === _R1R2)          { return "darkblue" };

            return "yellow"

        };

        // Return
        return col_default

    };

    // Bypass all > 1 > Transparent
    if (_bypass_all === 1) {

        return "transparent"

    };

};
const _get_html_el                              = (
    str,
    html,
    xp,
    xx,
    yp,
    yy,
    font_size,
    col_txt,
    col_bg
    ) => {


    if (html === spill_expr_html_init) {
        // console.log("html init :: ", str)
    };

    let _html_center = "";
    if (html === spill_expr_html_init)  { _html_center = str ;
    } else                              { _html_center = html };

    const _html_el
        = `<div style=`
        + '"'
        + `font-family:         ${font_family_spill};`
        + `font-size:           ${font_size}px;`
        + `font-style:          italic;`
        + `color:               ${col_txt};`
        + `background-color:    ${col_bg};`
        + `position:            absolute;`
        + `left:                ${xp}px;`
        + `width:               ${xx}px;`
        + `top:                 ${yp}px;`
        + `height:              ${yy}px;`
        + '"'
        + `>`
        + _html_center
        + `</div>`;

    // Return
    return _html_el

};
const get_v_el                                  = (
    str,
    html,
    typ,
    xp,
    xx,
    yp,
    yy,
    mm,
    font_size,
    col_txt,
    col_bg,
    debug
    ) => {

    const _v_el = {
        "str":          str,
        "html":         html,
        "typ":          typ,
        "xp":           xp,
        "xx":           xx,
        "yp":           yp,
        "yy":           yy,
        "mm":           mm,
        "font_size":    font_size,
        "col_txt":      col_txt,
        "col_bg":       col_bg,
        "debug":        debug
    };

    return _v_el

};

// Lbl
const _create_lbl                               = (i, v_arr) => {

    // Note
    // - xx / xp curs compensate
    //   - Se > _create_views_prep() > _set()
    //   - Gjøres i _set() fordi 2 el brukes (både i - 1 og i)
    // DEV > Font size er relative, f.eks. expo er 0.6 av norm

    // Debug
    const _e        = 1;
    const _d        = 0;

    // Arg
    let _v_arr      = v_arr;

    // sw
    let _str        = "";
    let _html       = _v_arr[i].html;
    let _typ        = _v_arr[i].typ;
    let _xp         = 0.0;
    let _xx         = _v_arr[i].xx;
    let _yp         = 0.0;
    let _yy         = _v_arr[i].yy;
    let _mm         = 0.0;
    let _font_size  = _v_arr[i].font_size;
    let _col_txt    = col_spill_txt_norm;
    let _col_bg     = _v_arr[i].col_bg;
    let _debug      = _v_arr[i].debug;

    // Exe
    if (_e === 1) {

        // SIZE 1 > Regu
        if (_typ      === _SIZE_1) {

            _yy         = _get_lbl_yy(1);
            _font_size  = _get_lbl_font_size(1);
            _col_txt    = col_spill_txt_norm; }

        else if (_typ === _SIZE_1_EMPTY) {

            _yy         = _get_lbl_yy(1);
            _font_size  = _get_lbl_font_size(1);
            _col_txt    = col_transparent; }

        else if (_typ === _SIZE_1_CURS) {

            _yy         = _get_lbl_yy(1);
            _font_size  = _get_lbl_font_size(1);
            _col_txt    = col_spill_txt_curs; }

        // SIZE 2 > Expo
        else if (_typ === _SIZE_2) {

            _yy         = _get_lbl_yy(2);
            _font_size  = _get_lbl_font_size(2);
            _col_txt    = col_spill_txt_norm; }

        else if (_typ === _SIZE_2_EMPTY) {

            _yy         = _get_lbl_yy(2);
            _font_size  = _get_lbl_font_size(2);
            _col_txt    = col_transparent; } //

        else if (_typ === _SIZE_2_CURS) {

            _yy         = _get_lbl_yy(2);
            _font_size  = _get_lbl_font_size(2);
            _col_txt    = col_spill_txt_curs; } // col_spill_txt_norm

        else {}; // Pass

        let j = 0;
        while (j < _v_arr[i].str.length) { // console.log(`typ :: ${_typ} -> str :: ${_v_arr[i].str[j]}`)

            _str            += _v_arr[i].str[j];
            _xx             += _get_lbl_sym_xx(_str, _typ);

            j += 1;

        };

        // v-arr
        _v_arr[i] = get_v_el(
            _str,
            _html,
            _typ,
            _xp,
            _xx,
            _yp,
            _yy,
            _mm,
            _font_size,
            _col_txt,
            _col_bg,
            _debug
        );

    };

    // Disp
    if (_d === 1) {};

    // Return
    return _v_arr

};

// Views
const _create_views                             = (t_p_2, i, v_arr) => {

    // Debug
    const _e                = 1;
    const _d                = 0;
    const _d_typ            = 0;
    const _d_col            = 1;

    // Arg
    let _v_arr              = v_arr;

    // Vari
    let _typ_arg            = t_p_2;
    let _typ_prep           = "";
    let _typ_super_el       = "";
    let _typ_debug          = "";
    let _str                = "";
    let _is_norm_solo       = false;
    let _ix_1               = i - 1;
    let _ix_2               = i;
    let _html_result        = "";
    let _html_super         = "";
    let _sw_bool            = true;

    // Typ
    if (_typ_arg === _NORM) {

        _sw_bool            = false;
        if (_v_arr[0].html === spill_expr_html_init) {

            _typ_prep           = _NORM;
            _typ_super_el       = _NORM;
            _typ_debug          = _NORM;
            _str                = _get_str(_NORM, _v_arr[0].str, "");
            _ix_1               = 0;
            _ix_2               = 0;

        } else {

            _is_norm_solo       = true;

        };

    };
    if (_typ_arg === _NORM_NORM) {
        _typ_prep           = _NORM_NORM;
        _typ_super_el       = _NORM;
        _typ_debug          = _NORM_NORM;
        _str                = _get_str(_NORM_NORM, _v_arr[i - 1].str, _v_arr[i].str);
    };
    if (_typ_arg === _BROK) {
        _typ_prep           = _BROK;
        _typ_super_el       = _BROK;
        _typ_debug          = _BROK;
        _str                = _get_str(_BROK, _v_arr[i - 1].str, _v_arr[i].str);
    };
    if (_typ_arg === _GE) {
        _typ_prep           = _GE;
        _typ_super_el       = _NORM;
        _typ_debug          = _NORM;
        _str                = _get_str(_GE, _v_arr[i - 1].str, _v_arr[i].str);
    };
    if (_typ_arg === _R1R2) {
        _typ_prep           = _R1R2;
        _typ_super_el       = _NORM;
        _typ_debug          = _R1R2;
        _str                = _get_str(_R1R2, _v_arr[i - 1].str, _v_arr[i].str);
    };

    // Exe
    if (_e === 1) {

        // Hvis ikke er første norm solo
        if (_is_norm_solo === false) {

            /* ****************************************** */
            /* Debug */
            /* ****************************************** */

            // Pre process
            if (_d_typ === 1) {
                console.log("*** PRE > _v_arr[i - 1].mm >>> ", _v_arr[i - 1].mm)
                console.log("*** PRE > _v_arr[i].mm     >>> ", _v_arr[i].mm)
            };


            /* ****************************************** */
            /* Prep */
            /* ****************************************** */

            // XY
            if (_typ_arg === _NORM) {} // Bypass
            else { _v_arr       = _create_views_prep(_typ_prep, i, _v_arr); };


            /* ****************************************** */
            /* HTML */
            /* ****************************************** */

            // Result
            _html_result        = _create_views_html(_typ_prep, i, _v_arr);


            /* ****************************************** */
            /* Super */
            /* ****************************************** */

            // XY
            if (_typ_arg === _NORM) {} // Bypass
            else { _v_arr       = _create_views_super(_typ_prep, i, _v_arr); };

            // HTML
            _html_super         = _get_html_el(
                "",
                _html_result,
                _v_arr[_ix_1].xp,
                _v_arr[_ix_1].xx,
                _v_arr[_ix_1].yp,
                _v_arr[_ix_1].yy,
                _v_arr[_ix_1].font_size,
                _v_arr[_ix_1].col_txt,
                _get_lbl_col_debug(_d_col, _typ_debug, _v_arr[_ix_1].col_bg)
            );

            // v_el
            _v_arr[_ix_1]         = get_v_el(
                _str,
                _html_super,
                _typ_super_el,
                _v_arr[_ix_1].xp,
                _v_arr[_ix_1].xx,
                _v_arr[_ix_1].yp,
                _v_arr[_ix_1].yy,
                _v_arr[_ix_1].mm,
                _v_arr[_ix_1].font_size,
                _v_arr[_ix_1].col_txt,
                _v_arr[_ix_1].col_bg, // "purple"
                _v_arr[_ix_2].debug // _html_super // "<div>halla</div>"
            );


            /* ****************************************** */
            /* Debug */
            /* ****************************************** */

            // Post process
            if (_typ_arg === _NORM) {} // Bypass
            else { if (_d_typ === 1) { console.log("*** POST > _v_arr[i - 1].mm >>> ", _v_arr[i - 1].mm) }; };


            /* ****************************************** */
            /* Remove */
            /* ****************************************** */

            // Remove last
            if (_typ_arg === _NORM) {} // Bypass
            else { _v_arr       = _remove_SCLV(i, _v_arr); };

        };

    };

    // Disp
    if (_d === 1) {};

    // Return
    return [_v_arr, _sw_bool]

};
const _create_views_prep                        = (typ, i, v_arr) => {

    /* Note */
    /* - Se om noen typ må bypass set() slik som _BROK i _create_views_super */
    /* - "nr" kan brukes som arg i _b_0_C() osv. for debug */

    // Debug
    const _e                    = 1;
    const _d                    = 0;
    const _d_typ                = 0;

    // Arg
    let _v_arr                  = v_arr;

    // Vari
    let _l_temp_4_i             = 0.0;
    let _l_temp_4_i_1           = 0.0;
    let _yy_expo                = 0.0;

    // Exe
    if (_e === 1) {

        const _l_4_temp = () => {

            _v_arr[i - 1].mm = _l_temp_4_i_1;

            if (_d_typ === 1) {
                console.log(">> l4Temp! > _v_arr[i - 1].mm :: ", _v_arr[i - 1].mm)
            };

        };

        const _set = () => {

            _l_temp_4_i         = 0.0;
            _l_temp_4_i_1       = 0.0;

            // Init
            let _xp_1           = 0.0;
            let _xx_2           = 0.0;
            let _xp_2           = 0.0;

            // El 1
            if (_v_arr[i - 1].str === _sym_curs) {
                _xp_1           = _get_lbl_sym_xx_compensate(_v_arr[i - 1].font_size);
                _xx_2           = 0;
            } else {
                _xp_1           = 0;
                _xx_2           = _v_arr[i - 1].xx;
            };

            // El 2
            if (_v_arr[i].str === _sym_curs) {
                _xp_2           = 0;

                // El 2 er siste el
                if (i === _v_arr.length - 1) {
                    _xp_2       = _get_lbl_sym_xx_compensate(_v_arr[i].font_size);
                };

            } else {
                _xp_2           = _v_arr[i - 1].xp;
            };

            _v_arr[i - 1].xp    = _xp_1;
            _v_arr[i].xp        = _xp_2 + _xx_2;

            /* ori > Uten curs compensate
            _v_arr[i].xp       = _v_arr[i - 1].xp + _v_arr[i - 1].xx;
            _v_arr[i - 1].xp   = 0;
            */

            // MM > 2
            if (_v_arr[i].mm === 0) {

                // console.log("mm_2 > INIT");
                _v_arr[i].mm = _v_arr[i].yy / 2;

            };

            // MM > 1
            if (_v_arr[i - 1].mm === 0) {

                // console.log("mm_1 > INIT");
                _v_arr[i - 1].mm = _v_arr[i - 1].yy / 2;

            };

        };

        const _b_0_C = () => {

            // BROK
            if (_d_typ === 1) {
                console.log("b0C > typ >                ", typ)
                console.log("b0C > _v_arr[i].typ >      ", _v_arr[i].typ)
                console.log("_v_arr[i - 1].mm > mm_1 :: ", _v_arr[i - 1].mm)
                console.log("_v_arr[i].mm > mm_2 ::     ", _v_arr[i].mm)
            };

            _l_temp_4_i        = _v_arr[i].mm;
            _l_temp_4_i_1      = _v_arr[i - 1].mm;
            _v_arr[i].yp       = 0;
            _v_arr[i - 1].yp   = _l_temp_4_i - _l_temp_4_i_1;
            _l_temp_4_i_1      = _l_temp_4_i;

        };

        const _a_0_N = () => {

            // Note > BROK - NORM

            // BROK
            if (_d_typ === 1) {
                console.log("a0N > typ >                ", typ)
                console.log("a0N > _v_arr[i].typ >      ", _v_arr[i].typ)
                console.log("_v_arr[i - 1].mm > mm_1 :: ", _v_arr[i - 1].mm)
                console.log("_v_arr[i].mm > mm_2 ::     ", _v_arr[i].mm)
            };

            _l_temp_4_i        = _v_arr[i].mm;
            _l_temp_4_i_1      = _v_arr[i - 1].mm;
            _v_arr[i].yp       = _l_temp_4_i_1 - _l_temp_4_i;
            _v_arr[i - 1].yp   = 0;

        };

        const _brok = () => {

            // Teller størst > XP
            if (_v_arr[i - 1].xx > _v_arr[i].xx) {

                // Teller > XP
                _v_arr[i - 1].xp    = 0;

                // Nevner > XP
                _v_arr[i].xp        = _v_arr[i - 1].xx / 2 - _v_arr[i].xx / 2;

            // Nevner størst > XP
            } else {

                // Teller
                _v_arr[i - 1].xp    = _v_arr[i].xx / 2 - _v_arr[i - 1].xx / 2;

                // Nevner
                _v_arr[i].xp        = 0;

            };

            // Teller > YP
            _v_arr[i - 1].yp        = 0;

            // Nevner > YP
            _v_arr[i].yp            = _v_arr[i - 1].yp + _v_arr[i - 1].yy;

            // Teller > MM
            _v_arr[i - 1].mm        = _v_arr[i - 1].yy

        };

        const _exp = () => {

            _yy_expo            = _get_lbl_yy(2);

            _l_temp_4_i        = _v_arr[i].mm;
            _l_temp_4_i_1      = _v_arr[i - 1].mm;

            _v_arr[i].yp       = 0;
            _v_arr[i - 1].yp   = _v_arr[i].yy - _yy_expo;

            _l_temp_4_i_1      = _v_arr[i - 1].yp + _l_temp_4_i_1;

        };

        const _rot = () => {

            _l_temp_4_i             = _v_arr[i].mm;
            _l_temp_4_i_1           = _v_arr[i - 1].mm;

            _v_arr[i].xp            = _v_arr[i - 1].xx + _get_lbl_root_v_xx();
            _v_arr[i].yp            = _get_lbl_root_yp();

            _v_arr[i - 1].yp        = _v_arr[i].yy - _v_arr[i - 1].yy;

            _l_temp_4_i_1           = _l_temp_4_i + _get_lbl_root_yp();

            if (_v_arr[i - 1].yy > _v_arr[i].yy) {

                _v_arr[i - 1].yp   = 0;
                _v_arr[i].yp       = _v_arr[i - 1].yy - _v_arr[i].yy + _get_lbl_root_yp();

                _l_temp_4_i_1      = _l_temp_4_i + _v_arr[i - 1].yy - _v_arr[i].yy + _get_lbl_root_yp();

            };

        };

        // Set > Merk at alle ikke nødvendigvis må ha denne (som f.eks. _BROK i super)
        _set();

        // Mid compare
        const _a  = _v_arr[i - 1].mm;
        const _b  = _v_arr[i].mm;

        if (typ === _NORM_NORM) {

            if (_a <   _b)   { _b_0_C(); }; // arg > nr = 1
            if (_a >   _b)   { _a_0_N(); }; // arg > nr = 2
            if (_a === _b)   { _b_0_C(); }; // arg > nr = 3

            _l_4_temp(); }

        if (typ === _BROK) {

            if (_a <   _b)   { _brok(); };
            if (_a >   _b)   { _brok(); };
            if (_a === _b)   { _brok(); }; };

        if (typ === _GE) {

            if (_a <   _b)   { _exp(); };
            if (_a >   _b)   { _exp(); };
            if (_a === _b)   { _exp(); };

            _l_4_temp(); }

        if (typ === _R1R2) {

            if (_a <   _b)   { _rot(); };
            if (_a >   _b)   { _rot(); };
            if (_a === _b)   { _rot(); };

            _l_4_temp(); }

    };

    // Disp
    if (_d === 1) {};

    // Return
    return _v_arr

};
const _create_views_super                       = (typ, i, v_arr) => {

    /* Note > F.eks. BROK bypasser set() */

    // Debug
    const _e            = 1;
    const _d            = 0;

    // Arg
    let _v_arr          = v_arr;
    let _l_temp_4_i_1   = 0;

    // Exe
    if (_e === 1) {

        // Typ
        const _l_4_temp = () => {

            _v_arr[i - 1].mm = _l_temp_4_i_1;

        };
        const _set = () => {

            _l_temp_4_i_1       = 0;

            _v_arr[i - 1].xp    = 0;
            _v_arr[i - 1].xx    = _v_arr[i - 1].xx + _v_arr[i].xx

        };
        const _a_0_B = () => {

            // yy: A > B
            if ((_v_arr[i - 1].yp + _v_arr[i - 1].yy) > (_v_arr[i].yp + _v_arr[i].yy)) {

                _v_arr[i - 1].yy = _v_arr[i - 1].yp + _v_arr[i - 1].yy;

            // yy: A < B
            } else {

                _v_arr[i - 1].yy = _v_arr[i].yy;

            };

            _v_arr[i - 1].yp    = 0; // Kanskje denne
            _l_temp_4_i_1       = _v_arr[i].mm;

        };
        const _A_0_b = () => {

            if (_v_arr[i - 1].yp + _v_arr[i - 1].yy >= _v_arr[i].yp + _v_arr[i].yy) {

                _v_arr[i - 1].yy = _v_arr[i - 1].yp + _v_arr[i - 1].yy;

            } else {

                _v_arr[i - 1].yy = _v_arr[i].yp + _v_arr[i].yy;

            };

            _v_arr[i - 1].yp   = 0;
            _l_temp_4_i_1      = _v_arr[i - 1].mm;


        };
        const _brok = () => {

            // XY > Stanadard
            _v_arr[i - 1].xp        = 0;
            if (_v_arr[i - 1].xx < _v_arr[i].xx) {
                _v_arr[i - 1].xx    = _v_arr[i].xx;
            };
            _v_arr[i - 1].yp        = 0;
            _v_arr[i - 1].yy        = _v_arr[i - 1].yy + _v_arr[i].yy;

            // XY > Min
            if (_v_arr[i - 1].xx < _get_lbl_xx_min(_v_arr[i - 1].font_size)) {
                _v_arr[i - 1].xx = _get_lbl_xx_min(_v_arr[i - 1].font_size);
            };

        };
        const _rot = () => {

            // Standard
            _l_temp_4_i_1       = _v_arr[i - 1].mm;

            _v_arr[i - 1].yp    = 0;
            _v_arr[i - 1].xx    = _v_arr[i - 1].xx + _get_lbl_root_v_xx();
            _v_arr[i - 1].yy    = _v_arr[i].yp + _v_arr[i].yy;

            // Min
            if (_v_arr[i - 1].xx < _get_lbl_xx_min(_v_arr[i - 1].font_size)) {
                _v_arr[i - 1].xx = _get_lbl_xx_min(_v_arr[i - 1].font_size);
            };

        };

        // Set > Bypass for BROK
        if (typ === _BROK)  {}
        else                { _set(); };

        // a vs b
        const _a  = _v_arr[i - 1].mm;
        const _b  = _v_arr[i].mm;

        // Typ
        if (typ === _NORM_NORM) {

            if (_a <   _b) { _a_0_B(); };
            if (_a >   _b) { _A_0_b(); };
            if (_a === _b) { _a_0_B(); };

            _l_4_temp();
        };
        if (typ === _BROK) {

            if (_a <   _b) { _brok(); };
            if (_a >   _b) { _brok(); };
            if (_a === _b) { _brok(); };
        };
        if (typ === _GE) {

            if (_a <   _b) { _a_0_B(); };
            if (_a >   _b) { _A_0_b(); };
            if (_a === _b) { _a_0_B(); };

            _l_4_temp();
        };
        if (typ === _R1R2) {

            if (_a <   _b) { _rot(); };
            if (_a >   _b) { _rot(); };
            if (_a === _b) { _rot(); };

            _l_4_temp();

        };

    };

    // Disp
    if (_d === 1) {};

    // Return
    return _v_arr

};
const _create_views_html                        = (typ, i, v_arr) => {

    // Debug
    const _e            = 1;
    const _d            = 0;
    const _d_col        = 0;

    // Arg
    let _v_arr          = v_arr;

    // Vari
    let _html_result    = "";

    // Exe
    if (_e === 1) {

        if (typ === _NORM) {

            // DEV
            let _xx         = _v_arr[0].xx;
            if (_d_col === 1) { _xx = 10; };

            _html_result    = _get_html_el(
                _v_arr[0].str,
                _v_arr[0].html,
                _v_arr[0].xp,
                _xx,
                _v_arr[0].yp,
                _v_arr[0].yy,
                _v_arr[0].font_size,
                _v_arr[0].col_txt,
                _get_lbl_col_debug(_d_col, _NORM, _v_arr[0].col_bg)
            );

        };

        if (typ === _NORM_NORM ||
            typ === _GE) {


            /* ****************************************** */
            /* El 1 / el 2 */
            /* ****************************************** */

            const _html_1       = _get_html_el(
                _v_arr[i - 1].str,
                _v_arr[i - 1].html,
                _v_arr[i - 1].xp,
                _v_arr[i - 1].xx,
                _v_arr[i - 1].yp,
                _v_arr[i - 1].yy,
                _v_arr[i - 1].font_size,
                _v_arr[i - 1].col_txt,
                _get_lbl_col_debug(_d_col, `${_NORM}${_NORM}`, _v_arr[i - 1].col_bg)
            );

            const _html_2       = _get_html_el(
                _v_arr[i].str,
                _v_arr[i].html,
                _v_arr[i].xp,
                _v_arr[i].xx,
                _v_arr[i].yp,
                _v_arr[i].yy,
                _v_arr[i].font_size,
                _v_arr[i].col_txt,
                _v_arr[i].col_bg // Ingen debug col
            );

            // HTML > Root
            _html_result
                = _html_1
                + _html_2;

        };

        if (typ === _R1R2) {

            /* ****************************************** */
            /* Vari */
            /* ****************************************** */

            // Init
            let _yp_R               = 0;
            let _yy_I               = 0;
            let _yy_I_1             = 0;
            let _xx_top             = 0;
            let _x_0                = 0;
            let _y_0                = 0;
            let _x_1                = 0;
            let _y_1                = 0;
            let _x_2                = 0;
            let _y_2                = 0;
            let _x_3                = 0;
            let _y_3                = 0;
            let _x_4                = 0;
            let _y_4                = 0;

            // YY :: A > B
            if (_v_arr[i - 1].yy > _v_arr[i].yy) {

                _yp_R               = _v_arr[i - 1].yy - _v_arr[i].yy + _get_lbl_root_yp();
                _yy_I               = _v_arr[i].yy;
                _yy_I_1             = _v_arr[i - 1].yy - _v_arr[i].yy;

            // YY :: B <= A
            } else {

                _yp_R               = _get_lbl_root_yp();
                _yy_I               = _v_arr[i].yy;
                _yy_I_1             = _v_arr[i - 1].yy;

            };

            _xx_top                 = _v_arr[i].xp + _v_arr[i].xx;

            // DEV > Minimum xx for empty

            // Start
            _x_0                    = 0;
            _y_0                    = _yp_R + _yy_I - _yy_I_1 * (1 / 4);

            // Vinge
            _x_1                    = _v_arr[i - 1].xx;
            _y_1                    = _y_0;

            // L-skulder
            _x_2                    = _v_arr[i - 1].xx + _get_lbl_root_v_xx() / 2;
            _y_2                    = _yp_R + _yy_I;

            // R-skulder
            _x_3                    = _v_arr[i - 1].xp + _v_arr[i - 1].xx + _get_lbl_root_v_xx();
            _y_3                    = _yp_R;

            // Top
            _x_4                    = _xx_top;
            _y_4                    = _y_3;


            /* ****************************************** */
            /* Root pre / root post */
            /* ****************************************** */

            const _html_pre         = _get_html_el(
                _v_arr[i - 1].str,
                _v_arr[i - 1].html,
                _v_arr[i - 1].xp,
                _v_arr[i - 1].xx,
                _v_arr[i - 1].yp,
                _v_arr[i - 1].yy,
                _v_arr[i - 1].font_size,
                _v_arr[i - 1].col_txt,
                _v_arr[i - 1].col_bg
            );

            const _html_post        = _get_html_el(
                _v_arr[i].str,
                _v_arr[i].html,
                _v_arr[i].xp,
                _v_arr[i].xx,
                _v_arr[i].yp,
                _v_arr[i].yy,
                _v_arr[i].font_size,
                _v_arr[i].col_txt,
                _v_arr[i].col_bg // "red"
            );


            /* ****************************************** */
            /* Root vinge */
            /* ****************************************** */

            // Root vinge > SVG
            const _svg_root_vinge       =
                `<svg width="700" height="600" xmlns="http://www.w3.org/2000/svg">
                <g id="Layer_1">
                <title>Layer 1</title>
                <line fill=${col_spill_bg_norm}
                stroke=${col_spill_txt_norm}
                x1=${_x_0}
                y1=${_y_0}
                x2=${_x_1}
                y2=${_y_1}
                id="svg_1"
                line stroke-width=${lay_xy_matte_strek}
                stroke-linejoin="undefined"
                stroke-linecap="undefined"/>
                </g>
                </svg>`

            // Root vinge > HTML
            const _html_root_vinge      = _get_html_el(
                _v_arr[i - 1].str,
                _svg_root_vinge,
                0,
                _x_1,
                0,
                _y_1,
                _v_arr[i - 1].font_size,
                _v_arr[i - 1].col_txt,
                _v_arr[i - 1].col_bg // "purple"
            );


            /* ****************************************** */
            /* Root L */
            /* ****************************************** */

            // Root L > SVG
            const _svg_root_L           =
                `<svg width="700" height="600" xmlns="http://www.w3.org/2000/svg">
                <g id="Layer_1">
                <title>Layer 1</title>
                <line fill=${col_spill_bg_norm}
                stroke=${col_spill_txt_norm}
                x1=${_x_1}
                y1=${_y_1}
                x2=${_x_2}
                y2=${_y_2}
                id="svg_1"
                line stroke-width=${lay_xy_matte_strek}
                stroke-linejoin="undefined"
                stroke-linecap="undefined"/>
                </g>
                </svg>`

            // Root L > HTML
            const _html_root_L          = _get_html_el(
                _v_arr[i - 1].str,
                _svg_root_L,
                0,
                _x_2,
                0,
                _y_2,
                _v_arr[i - 1].font_size,
                _v_arr[i - 1].col_txt,
                _v_arr[i - 1].col_bg // "darkgreen"
            );


            /* ****************************************** */
            /* Root R */
            /* ****************************************** */

            // Root R > SVG
            const _svg_root_R           =
                `<svg width="700" height="600" xmlns="http://www.w3.org/2000/svg">
                <g id="Layer_1">
                <title>Layer 1</title>
                <line fill=${col_spill_bg_norm}
                stroke=${col_spill_txt_norm}
                x1=${_x_2}
                y1=${_y_2}
                x2=${_x_3}
                y2=${_y_3}
                id="svg_1"
                line stroke-width=${lay_xy_matte_strek}
                stroke-linejoin="undefined"
                stroke-linecap="undefined"/>
                </g>
                </svg>`

            // Root R > HTML
            const _html_root_R          = _get_html_el(
                _v_arr[i - 1].str,
                _svg_root_R,
                0,
                _x_3,
                0,
                _y_3,
                _v_arr[i - 1].font_size,
                _v_arr[i - 1].col_txt,
                _v_arr[i - 1].col_bg
            );


            /* ****************************************** */
            /* Root top */
            /* ****************************************** */

            // Root top > SVG
            const _svg_root_top         =
                `<svg width="700" height="600" xmlns="http://www.w3.org/2000/svg">
                <g id="Layer_1">
                <title>Layer 1</title>
                <line fill=${col_spill_bg_norm}
                stroke=${col_spill_txt_norm}
                x1=${_x_3}
                y1=${_y_3}
                x2=${_x_4}
                y2=${_y_4}
                id="svg_1"
                line stroke-width=${lay_xy_matte_strek}
                stroke-linejoin="undefined"
                stroke-linecap="undefined"/>
                </g>
                </svg>`

            // Root top > HTML
            const _html_root_top        = _get_html_el(
                _v_arr[i - 1].str,
                _svg_root_top,
                0,
                _x_4,
                0,
                _y_4,
                _v_arr[i - 1].font_size,
                _v_arr[i - 1].col_txt,
                _v_arr[i - 1].col_bg // "green"
            );


            /* ****************************************** */
            /* Result */
            /* ****************************************** */

            // HTML > Root
            _html_result
                = _html_pre
                + _html_root_vinge
                + _html_root_L
                + _html_root_R
                + _html_root_top
                + _html_post;

        };

        if (typ === _BROK) {


            /* ****************************************** */
            /* Tell / Nevn */
            /* ****************************************** */

            const _html_tell    = _get_html_el(
                _v_arr[i - 1].str,
                _v_arr[i - 1].html,
                _v_arr[i - 1].xp,
                _v_arr[i - 1].xx,
                _v_arr[i - 1].yp,
                _v_arr[i - 1].yy,
                _v_arr[i - 1].font_size,
                _v_arr[i - 1].col_txt,
                _v_arr[i - 1].col_bg
            );

            const _html_nevn    = _get_html_el(
                _v_arr[i].str,
                _v_arr[i].html,
                _v_arr[i].xp,
                _v_arr[i].xx,
                _v_arr[i].yp,
                _v_arr[i].yy,
                _v_arr[i].font_size,
                _v_arr[i].col_txt,
                _v_arr[i].col_bg
            );


            /* ****************************************** */
            /* Brokstrek */
            /* ****************************************** */

            // Vari
            let _brokstrek_xx   = 0.0;
            let _brokstrek_yp   = 0.0;

            // Brokstrek > XX
            if (_v_arr[i - 1].xx > _v_arr[i].xx) {

                _brokstrek_xx = _v_arr[i - 1].xx;

            } else {

                _brokstrek_xx = _v_arr[i].xx;

            };

            // Brokstrek > XX > Minimum
            if (_brokstrek_xx < _get_lbl_xx_min(_v_arr[i - 1].font_size)) {
                _brokstrek_xx = _get_lbl_xx_min(_v_arr[i - 1].font_size);
            };

            // Brokstrek > YP
            _brokstrek_yp       = _v_arr[i].yp;

            // Brokstrek > HTML
            const _html_svg     =
                `<svg width="700" height="600" xmlns="http://www.w3.org/2000/svg">
                <g id="Layer_1">
                <title>Layer 1</title>
                <line fill=${col_spill_bg_norm}
                stroke=${col_spill_txt_norm}
                x1="0"
                y1=${_brokstrek_yp}
                x2=${_brokstrek_xx}
                y2=${_brokstrek_yp}
                id="svg_1"
                line stroke-width=${lay_xy_matte_strek}
                stroke-linejoin="undefined"
                stroke-linecap="undefined"/>
                </g>
                </svg>`

            const _brokstrek_xp         = 0;
            const _html_brokstrek       = _get_html_el(
                _v_arr[i - 1].str,
                _html_svg,
                _brokstrek_xp,
                _brokstrek_xx,
                _v_arr[i - 1].yp,
                _v_arr[i - 1].yy,
                _v_arr[i - 1].font_size,
                _v_arr[i - 1].col_txt,
                _v_arr[i - 1].col_bg
            );


            /* ****************************************** */
            /* Result */
            /* ****************************************** */

            _html_result
            = _html_tell
            + _html_brokstrek
            + _html_nevn;

        };

    };

    // Disp
    if (_d === 1) {};

    // Return
    return _html_result

};
const _create_views_join_sw                     = (t_p_1, i, v_arr) => {

    // Debug
    const _e                = 1;
    const _d                = 0;
    const _d_typ            = 1;

    // Arg
    let _v_arr              = v_arr;

    // Vari
    let _arr_ret            = [];
    let _is_bypass          = false;

    // Exe
    if (_e === 1) {

        // Debug
        const _debug = (fn) => {

            // const _len = _v_arr.length; && _len > 1

            if (_d_typ === 1) {

                console.log("");
                console.log(`${fn} -> ${t_p_1}`);
                // console.log("_v_arr > i - 1:: ", _v_arr[i - 1].typ)
                // console.log("_v_arr > i - 0:: ", _v_arr[i - 0].typ)
                // console.log("FN")
                // for (let i = 0; i < _v_arr.length; i++) {
                //     console.log("v_arr_el >> ", _v_arr[i].typ)
                // };

            };

        };

        // NORM
        if      (t_p_1 === _NORM)       { _debug("* @ *");  _arr_ret = _create_views(_NORM,         i, _v_arr); }

        // NORM NORM
        else if (t_p_1 === _NORM_NORM)  { _debug("* A *");  _arr_ret = _create_views( _NORM_NORM,   i, _v_arr); }

        // BROK
        else if (t_p_1 === _TELL_NEVN)  { _debug("* C *");  _arr_ret = _create_views(_BROK,         i, _v_arr); }
        else if (t_p_1 === _NORM_BROK)  { _debug("* D *");  _arr_ret = _create_views(_NORM_NORM,    i, _v_arr); }
        else if (t_p_1 === _BROK_NORM)  { _debug("* E *");  _arr_ret = _create_views(_NORM_NORM,    i, _v_arr); }

        // EXPO
        else if (t_p_1 === _GE)         { _debug("* F *");  _arr_ret = _create_views(_GE,           i, _v_arr); }

        // ROOT
        else if (t_p_1 === _R1R2)       { _debug("* G *");  _arr_ret = _create_views(_R1R2,         i, _v_arr); }

        // Default
        else                            { _debug("* X *");  _is_bypass = true;};

    };

    // Disp
    if (_d === 1) {};

    // Return
    if (_is_bypass === false) { return [ _arr_ret[0], _arr_ret[1] ] };
    if (_is_bypass === true)  { return [ _v_arr, false ] };

};
const _create_views_typ_sw                      = (typ, pp) => {

    // DEV > Se note

    // Debug
    const _e        = 1;
    const _d        = 0;

    // Exe
    if (_e === 1) {

        let _c_str_bool                 = false

        if      (typ === _l_empty)      { _c_str_bool = true }

        else if (typ === _l_curs)       { _c_str_bool = true }

        else if (typ === _l_num)        { _c_str_bool = true }

        else if (typ === _l_pluss)      { _c_str_bool = true }
        else if (typ === _l_minus)      { _c_str_bool = true }
        else if (typ === _l_ganging)    { _c_str_bool = true }

        else if (typ === _l_lik)        { _c_str_bool = true }
        else if (typ === _l_vari)       { _c_str_bool = true }
        else if (typ === _l_funk)       { _c_str_bool = true }
        else if (typ === _l_par_1_L)    {

            if (pp === false)           { _c_str_bool = true }
            else                        { };} //  pass # <- 2023 # else > Break

        else if (typ === _l_par_1_R)    {

            if (pp === false)           {_c_str_bool = true; }
            else                        { };} //  pass # <- 2023 # else > Break

        else                            { }; // # <- 2023 # else > Break

        // Return
        return _c_str_bool

    };

    // Disp
    if (_d === 1) {};

};
const _create_views_typ_size                    = (typ, size, pp, i, v_arr) => {

    // Note > Kalles med size-arg i fra regu, expo og root

    // Debug
    const _e                = 1;
    const _d                = 0;

    // Arg
    let _v_arr              = v_arr;

    // Exe
    if (_e === 1) {

        if (_v_arr[i].typ === _l_par_1_L ||
        _v_arr[i].typ === _l_par_1_R) {

            if (pp === false) {
                if (size === 1) { _v_arr[i].typ = _SIZE_1; };
                if (size === 2) { _v_arr[i].typ = _SIZE_2; };
            };}

        else if (_v_arr[i].typ === _l_empty) {

            if (size === 1) { _v_arr[i].typ = _SIZE_1_EMPTY; };
            if (size === 2) { _v_arr[i].typ = _SIZE_2_EMPTY; };

            _v_arr          = _create_lbl(i, _v_arr);
            _v_arr[i].typ   = typ; }

        else if (_v_arr[i].typ === _l_curs) {

            if (size === 1) { _v_arr[i].typ = _SIZE_1_CURS; };
            if (size === 2) { _v_arr[i].typ = _SIZE_2_CURS; };

            _v_arr          = _create_lbl(i, _v_arr);
            _v_arr[i].typ   = typ; }

        else {

            if (size === 1) { _v_arr[i].typ = _SIZE_1; };
            if (size === 2) { _v_arr[i].typ = _SIZE_2; };

            _v_arr          = _create_lbl(i, _v_arr);
            _v_arr[i].typ   = typ;

        };

        if (_v_arr[0].mm === 0) {
            _v_arr[0].mm = _v_arr[0].yy / 2;
        };

    };

    // Disp
    if (_d === 1) { };

    // Return
    return _v_arr

};

// GUI
const create_gui                                = (v_arr, size) => {

    // Debug
    const _e                    = 1;
    const _debug_start          = 0;
    const _debug_blokk          = 0;
    let _v_arr                  = v_arr;

    // Exe
    if (_e === 1) {

        // Debug
        if (_debug_start === 1) {

            // console.log("-> START > REGU :: ");
            for (let i = 0; i < _v_arr.length; i++) {
                // console.log("-> _v_arr :: ", _v_arr[i]);
            };

        };
        const _debug_sub_blokk = (sub_blokk) => {

            // console.log("")
            // console.log("_create_2_regu_SIZE_1 >> ", sub_blokk)

        };

        // Iterate
        let i = _v_arr.length - 1;
        while (i > -1) {

            // console.log("_v_arr[i].str :: ", _v_arr[i].str)

            // BROK
            if (i > -1 &&
            i < _v_arr.length &&
            _v_arr[i].typ === _l_par_1_L) {

                // state._debug_mega_cnt_iter += 1;

                // Debug
                if (_debug_blokk === 1) { _debug_sub_blokk("BROK"); };

                // Brok bool
                const _brok_bool = _create_0_brok_bool(i, _v_arr);
                if (_brok_bool === true) {

                    // Util
                    _v_arr  = _create_0_brok(i - 1, _v_arr);
                    i       = _v_arr.length;

                };

            };

            // ROOT
            if (i > -1 &&
            i < _v_arr.length &&
            _v_arr[i].str === _l_root) {

                // state._debug_mega_cnt_iter += 1;
                _debug_sub_blokk("ROOT");

                _v_arr = _create_0_root(i, _v_arr);

                i = _v_arr.length;

            };

            // FUNK > DEV > Kun for ln
            if (i > 0 &&
            i < _v_arr.length &&
            _v_arr[i - 1].str === _ln &&
            _v_arr[i].typ === _l_par_1_L) {

                /// state._debug_mega_cnt_iter += 1;

                _debug_sub_blokk("FUNK");

                // DEV > Sjekk retur arr osv. > Util
                const _arr_ret = _create_0_funk(
                    _NORM,
                    _SIZE_1,
                    i - 1,
                    _v_arr
                );
                _v_arr = _arr_ret[0];

            };

            // VIEWS > MONOS
            if (i > -1 &&
            i < _v_arr.length) {

                /// state._debug_mega_cnt_iter += 1;
                // console.log(">> MONO")
                // console.log("M > _v_arr.typ :: ", _v_arr[i].typ)

                // Debug
                if (_debug_blokk === 1) {

                    /// const _tx_1 = `MONOS :: ${state._debug_mega_cnt_iter}`
                    /// _debug_sub_blokk(_tx_1)
                };

                // DEV > Forklaring pp > true betyr > Hvis typ er paranteser > Bypass
                // DEV > Lag forklarende forskjell på paranteser som skal være i expr, og interne funksjon-par som f.eks. ^()
                const _c_str_bool = _create_views_typ_sw(_v_arr[i].typ, true);

                if (_c_str_bool === true) {

                    // Debug
                    if (_debug_blokk === 1) {

                        // const _tx_2 = `MONOS > true :: ${state._debug_mega_cnt_iter}`
                        // _debug_sub_blokk(_tx_2)

                    };

                    // Util
                    _v_arr = _create_views_typ_size(
                        _NORM,
                        size,
                        true,
                        i,
                        _v_arr
                    );
                    i = _v_arr.length;

                };

                // Debug
                if (_debug_blokk === 1) {

                    /// console.log("-> MONOS :: ", state._debug_mega_cnt_iter);
                    /// for (let i = 0; i < state._v_arr.length; i++) {
                    ///     console.log("-> _v_arr :: ", state._v_arr[i]);
                    /// };

                };

            };

            // SWITCH
            if (i > 0 &&
            i < _v_arr.length) {
                /// console.log(">> SWITCH")
                /// state._debug_mega_cnt_iter += 1;

                // Debug
                if (_debug_blokk === 1) {

                    /// const _tx_1 = `SWITCH :: ${state._debug_mega_cnt_iter}`;
                    /// _debug_sub_blokk(_tx_1);

                };

                const _t_p_1 = `${_v_arr[i - 1].typ}${_v_arr[i].typ}`; // console.log("_t_p_1 ::: ", _t_p_1)

                // Util
                const _arr = _create_views_join_sw(
                    _t_p_1,
                    i,
                    _v_arr
                );
                _v_arr                  = _arr[0];
                const _sw_bool          = _arr[1];
                if (_sw_bool === true) {

                    i = _v_arr.length;

                };

                // Debug
                if (_debug_blokk === 1) {

                    // console.log("-> SWITCH :: ", _debug_mega_cnt_iter);
                    // for (let i = 0; i < _v_arr.length; i++) {
                    //     console.log("-> _v_arr :: ", state._v_arr[i]);
                    // };

                };

            };

            // SOLO > Start
            if (_v_arr.length === 1) {

                // state._debug_mega_cnt_iter += 1;

                // Debug
                if (_debug_blokk === 1) {

                    // const _tx_1 = `SOLO :: ${state._debug_mega_cnt_iter}`;
                    // _debug_sub_blokk(_tx_1);

                };

                const _arr = _create_views_join_sw(
                    _NORM,
                    i,
                    _v_arr
                );
                _v_arr                  = _arr[0];
                const _sw_bool        = _arr[1];

                if (_sw_bool === true) { i = _v_arr.length; };

                // Debug
                if (_debug_blokk === 1) {

                    // console.log("-> SWITCH :: ", state._debug_mega_cnt_iter);
                    // for (let i = 0; i < state._v_arr.length; i++) {
                    //     console.log("-> _v_arr :: ", state._v_arr[0]);
                    // };

                };

            };

            i -= 1;

        };

    };

    // Return
    return _v_arr

};


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    get_v_el,
    create_0_expo,
    create_gui,
};
