/* ****************************************** */
/* Debug */
/* ****************************************** */

// Msg > Error > Status code
const msg_err_auth_context_400_error        = "_msg_err_auth_context_signin_user_400_error";
const msg_err_auth_context_401_error        = "_msg_err_auth_context_signin_user_401_error";



/* ****************************************** */
/* Export */
/* ****************************************** */

export {

    msg_err_auth_context_400_error,
    msg_err_auth_context_401_error,


};


/* ****************************************** */
/* End */
/* ****************************************** */
