import {
    auth_guest
} from "../konstanter/context/_context";
import {
    _sym_div_slash
} from "../konstanter/components/_sym";


/* ****************************************** */
/* Model */
/* ****************************************** */

const _gum_path_update = (path, nav, navn="") => {

    // Init
    let _path           = path;
    let _path_ret       = _path;
    let k               = 0;

    // Back > Gå ut av dir / Opp et nivå
    if (nav === "back") {
        k = _path.length - 1;
        while (_path[k] !== _sym_div_slash) {
            _path_ret = _path_ret.slice(0, -1); // Remove siste char
            k -= 1;
        }
        _path_ret = _path_ret = _path_ret.slice(0, -1); // Remove siste char
    };

    // Forward > Gå inn i sub-dir / Ned et nivå
    if (nav === "forward") {
        _path_ret += _sym_div_slash + navn;
    };

    // Still > Samme direktory > Bypass
    if (nav === "still") {};

    // # Return > Oppdatert path
    return _path_ret

};

const username_status = (user) => {

    // Init
    let _username = "";
    let _is_guest = -1;

    /* User > Post username > For tmp kode-dir */
    if (user) {
        _username = user.username;
        _is_guest = 0;}

    /* Ikke user > Username guest */
    else {
        _username = auth_guest;
        _is_guest = 1;};

    return [_username, _is_guest]

};

const get_user_or_guest = (user) => {

    let _username = auth_guest;
    if (user !== null) {
        _username = user.username;
    }; // console.log("username :: ", _username)

    return _username

};


export {
    _gum_path_update,
    username_status,
    get_user_or_guest
};
