/* ****************************************** */
/* Sym */
/* ****************************************** */

// Søkeord > Backend_frontend_speilbilder > prim_sym0.py <-> _sym.js

// Spes arr
const _spes_arr                             = [];

// str
const _blank_str                            = "";
const _sym_unit_space_str                   = " ";          _spes_arr.push(_sym_unit_space_str);

// Special
const _sym_vertical_bar                     = "│";          _spes_arr.push(_sym_vertical_bar); // Current > U+2502 │ BOX DRAWINGS LIGHT VERTICAL (&boxv;) (and various other box drawing characters in the range U+2500 to U+257F) -> DEV > Fix sym i backend, Alternativ > "｜"; // "|"; https://en.wikipedia.org/wiki/Vertical_bar
const _sym_paragraf                         = "§";          _spes_arr.push(_sym_paragraf);
const _sym_utrop                            = "!";          _spes_arr.push(_sym_utrop);
const _sym_quote_enkel                      = "'";          _spes_arr.push(_sym_quote_enkel);
const _sym_quote_dobbel                     = '"';          _spes_arr.push(_sym_quote_dobbel);
const _sym_hashtag                          = "#";          _spes_arr.push(_sym_hashtag);
const _sym_dollar                           = "$";          _spes_arr.push(_sym_dollar);
const _sym_prosent                          = "%";          _spes_arr.push(_sym_prosent);
const _sym_ampersand                        = "&";          _spes_arr.push(_sym_ampersand);
const _sym_slash                            = "/";          _spes_arr.push(_sym_slash);

// Par
const _L                                    = "{";          _spes_arr.push(_L);
const _R                                    = "}";          _spes_arr.push(_R);
const _L_1                                  = "(";          _spes_arr.push(_L_1);
const _R_1                                  = ")";          _spes_arr.push(_R_1);

// Txt
const _sym_hyphen                           = "-";          _spes_arr.push(_sym_hyphen);
const _sym_underscore                       = "_";          _spes_arr.push(_sym_underscore);
const _sym_dot                              = ".";          _spes_arr.push(_sym_dot);               // Sym > REFAC opp til bl.a. før fil som kan ha _sym_dot
const _sym_komma                            = ",";          _spes_arr.push(_sym_komma);
const _sym_kolon                            = ":";          _spes_arr.push(_sym_kolon);
const _sym_semikolon                        = ";";          _spes_arr.push(_sym_semikolon);

// Op
const _sym_pluss                            = "+";          _spes_arr.push(_sym_pluss);
const _sym_minus                            = _sym_hyphen;
const _sym_mul_dot                          = "⋅";                                                  // U+22C5 ⋅ DOT OPERATOR
const _sym_mul_star                         = "*";          _spes_arr.push(_sym_mul_dot);
const _sym_div_slash                        = _sym_slash;   _spes_arr.push(_sym_div_slash);
const _sym_expo                             = "^";          _spes_arr.push(_sym_expo);

// Rela
const _sym_lik                              = "=";          _spes_arr.push(_sym_lik);
const _sym_gt                               = ">";          _spes_arr.push(_sym_gt);

// Input > JavaScript > Special
const _sym_expo_slash                       = "^/";         // Kommer automatisk som e.key når / presses etter ^

// Escape characters
const _new_line                             = "\n";         // Split ved ny linje

// Spill > Solver
const _sym_empty                            = _sym_dollar;
const _sym_curs                             = _sym_vertical_bar;
const _sym_root_sep                         = _sym_semikolon;


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    _spes_arr,

    _blank_str,
    _sym_unit_space_str,

    _sym_vertical_bar,
    _sym_paragraf,
    _sym_utrop,
    _sym_quote_enkel,
    _sym_quote_dobbel,
    _sym_hashtag,
    _sym_dollar,
    _sym_prosent,
    _sym_ampersand,
    _sym_slash,

    _L,
    _R,
    _L_1,
    _R_1,

    _sym_hyphen,
    _sym_underscore,
    _sym_dot,
    _sym_komma,
    _sym_kolon,
    _sym_semikolon,

    _sym_pluss,
    _sym_minus,
    _sym_mul_dot,
    _sym_mul_star,
    _sym_div_slash,
    _sym_expo,

    _sym_lik,
    _sym_gt,

    _sym_expo_slash,

    _new_line,

    _sym_empty,
    _sym_curs,
    _sym_root_sep,
};
