/* ****************************************** */
/* Tabell */
/* ****************************************** */

// Tabell
const tabell_header_rad_nr                  = "_tabell_header_rad_nr";
const tabell_header_spiller                 = "_tabell_header_spiller";
const tabell_header_poeng                   = "_tabell_header_poeng";
const tabell_header_tid                     = "_tabell_header_tid";
const tabell_header_tries                   = "_tabell_header_tries";
const tabell_header_oppg                    = "_tabell_header_oppg";
const tabell_header_del                     = "_tabell_header_del";
const tabell_header_steg                    = "_tabell_header_steg";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    tabell_header_rad_nr,
    tabell_header_spiller,
    tabell_header_poeng,
    tabell_header_tid,
    tabell_header_tries,
    tabell_header_oppg,
    tabell_header_del,
    tabell_header_steg
};


/* ****************************************** */
/* End */
/* ****************************************** */
