import loadingReducer from "../components/loading/redux_is_loading_feature";

import filReducer from "../components/fil/redux_fil_feature";
import crudReducer from "../components/crud/redux_crud_feature";
import taggReducer from "../components/tagg/redux_tagg_feature";
import filterReducer from "../components/filter/redux_filter_feature";

import headerReducer from "../components/01_header/redux_header_feature";
import pathReducer from "../components/02_path/redux_path_feature";
import pageCoreReducer from "../components/03_page_core/redux_page_core_feature";

import userProfilReducer from "../pages/profil/redux_user_profil_feature";
import accountReducer from "../pages/profil/redux_account_feature";
import docReducer from "../pages/doc/redux_doc_feature";
import questReducer from "../pages/quest/redux_quest_feature";
import ruterReducer from "../pages/ruter/redux_ruter_feature";

import spillReducer from "../pages/spill/redux_spill_feature";
import solverReducer from "../pages/spill/redux_solver_feature";


const rootReducer = {
    loading:        loadingReducer,

    fil:            filReducer,
    crud:           crudReducer,
    tagg:           taggReducer,
    filter:         filterReducer,

    header:         headerReducer,
    path:           pathReducer,
    page_core:      pageCoreReducer,

    user_profil:    userProfilReducer,
    account:        accountReducer,
    doc:            docReducer,
    quest:          questReducer,

    ruter:          ruterReducer,

    spill:          spillReducer,
    solver:         solverReducer

};


export default rootReducer;
