import {
    grp_txt_norm_beige,
    grp_txt_norm_beige_hoverable,
    grp_txt_norm_grey,
    grp_txt_norm_grey_quest_tittel,
    grp_txt_norm_link,
    grp_txt_medium_grey_bold,
    grp_txt_medium_link,
    grp_txt_stor_beige,
    grp_txt_felt_liten_grey,
    grp_txt_felt_modal_beige,

    grp_icon_liten_24_16,
    grp_icon_norm_24_24,
    grp_icon_norm_48_12,
    grp_icon_norm_48_16,
    grp_icon_norm_48_20,
    grp_icon_norm_48_24,
    grp_icon_norm_110_16,
    grp_icon_medium_24_32,
    grp_icon_medium_100_32,
    grp_icon_fil_liten,
    grp_icon_tittel_norm,
    grp_icon_forside_stor,

    grp_img_mini,
    grp_img_mikro,
    grp_img_liten,
    grp_img_medium,
    grp_img_norm,
    grp_img_stor,
    grp_img_sub_bilde_profil,

    grp_btn_trans_white,
    grp_btn_grey,
    grp_btn_grey_100,
    grp_btn_blue,
    grp_btn_blue_100,
    grp_btn_green,
    grp_btn_green_100,
    grp_btn_red,
    grp_btn_red_100,
    grp_btn_red_trans_ugyldig,
    grp_btn_red_trans_100_ikke_gyldig,
    grp_btn_magenta_100,

    grp_btn_filter,
    grp_btn_filter_selected,
    grp_btn_txt_liten_grey,
    grp_btn_lang,
    grp_btn_fag,

    grp_input_felt,
    grp_input_drop,
    grp_input_display_none,
    grp_input_uttrykk_medium,
    grp_input_uttrykk_giga,

    grp_felt_100,
    grp_felt_center,
    grp_felt_left,
    grp_felt_uttrykk,
    grp_felt_uttrykk_medium,

    grp_alert_black_spill,
    grp_alert_green_100,
    grp_alert_green_reg,
    grp_alert_red_100,
    grp_alert_red_abs,
    grp_alert_red_reg
} from "../konstanter/gen/_grp";

// Txt
const is_txt_grp = (grp) => {
    if (grp === grp_txt_norm_beige)                 {return "txt_norm_beige_2"};
    if (grp === grp_txt_norm_beige_hoverable)       {return "txt_norm_beige_2_hoverable"};
    if (grp === grp_txt_norm_grey)                  {return "txt_norm_grey"};
    if (grp === grp_txt_norm_grey_quest_tittel)     {return "txt_norm_grey_quest_tittel"};

    if (grp === grp_txt_norm_link)                  {return "txt_norm_link"};

    if (grp === grp_txt_medium_grey_bold)           {return "txt_medium_grey_bold"};
    if (grp === grp_txt_medium_link)                {return "txt_medium_link"};

    if (grp === grp_txt_stor_beige)                 {return "txt_stor_beige_1"};

    if (grp === grp_txt_felt_liten_grey)            {return "txt_liten_grey"}; // create_quest
    if (grp === grp_txt_felt_modal_beige)           {return "txt_modal_beige_1"}; // cropper_modal
};

const is_txt_alert_grp = (grp) => {
    if (grp === grp_alert_red_abs)                 {return "txt_liten_white"}; // "txt_norm_beige_2"
    return "txt_liten_beige_1"
};

// Icon
const is_icon_grp = (icon_cls, icon_size) => {
    if (icon_size === grp_icon_liten_24_16)         {return `${icon_cls}_${icon_size}`;};    // 24_16  -> svg: 24px,  css: 16px (1.00rem)

    if (icon_size === grp_icon_norm_24_24)          {return `${icon_cls}_${icon_size}`;};    // 24_24  -> svg: 24px,  css: 24px (1.50rem)

    if (icon_size === grp_icon_norm_48_12)          {return `${icon_cls}_${icon_size}`;};    // 48_12  -> svg: 48px,  css: 12px (0.75rem)
    if (icon_size === grp_icon_norm_48_16)          {return `${icon_cls}_${icon_size}`;};    // 48_16  -> svg: 48px,  css: 16px (1.00rem)
    if (icon_size === grp_icon_norm_48_20)          {return `${icon_cls}_${icon_size}`;};    // 48_20  -> svg: 48px,  css: 20px (1.25rem)
    if (icon_size === grp_icon_norm_48_24)          {return `${icon_cls}_${icon_size}`;};    // 48_24  -> svg: 48px,  css: 24px (1.50rem)

    if (icon_size === grp_icon_norm_110_16)         {return `${icon_cls}_${icon_size}`;};    // 110_16 -> svg: 16px,  css: 16px (1.00rem)

    if (icon_size === grp_icon_medium_24_32)        {return `${icon_cls}_${icon_size}`;};    // 24_32  -> svg: 24px,  css: 32px (2.00rem)
    if (icon_size === grp_icon_medium_100_32)       {return `${icon_cls}_${icon_size}`;};    // 100_32 -> svg: 100px, css: 32px (2.00rem)

    if (icon_size === grp_icon_fil_liten)           {return `${icon_cls}_${icon_size}`;};    // 24_16  -> svg: 24px,  css: 16px (1.00rem)

    if (icon_size === grp_icon_tittel_norm)         {return `${icon_cls}_${icon_size}`;};    // 48_24  -> svg: 48px,  css: 24px (1.50rem)

    if (icon_size === grp_icon_forside_stor)        {return `${icon_cls}_${icon_size}`;};    // 48_24  -> svg: 48px,  css: 24px (1.50rem)

};
const is_icon_alert_grp = (grp) => {

    // Returnerer arr > Breaker i alert.js hvis ikke definert grp_altert_xyz

    if (grp === grp_alert_green_100)                {return ["icon_close_green",    grp_icon_liten_24_16]};
    if (grp === grp_alert_red_100)                  {return ["icon_close_red",      grp_icon_liten_24_16]};

    if (grp === grp_alert_red_abs)                  {return ["", ""]};

    if (grp === grp_alert_green_reg)                {return ["icon_close_green",    grp_icon_liten_24_16]};
    if (grp === grp_alert_red_reg)                  {return ["icon_close_red",      grp_icon_liten_24_16]};

    if (grp === grp_alert_black_spill)              {return ["",                    grp_icon_liten_24_16]}; // Icon foreløpig ubrukt

};

// Img
const is_img_grp = (grp, subgrp) => {
    if (subgrp === grp_img_sub_bilde_profil) {
        if (grp === grp_img_mini)                   {return "bilde_profil_mini"};
        if (grp === grp_img_mikro)                  {return "bilde_profil_mikro"};
        if (grp === grp_img_liten)                  {return "bilde_profil_liten"};
        if (grp === grp_img_norm)                   {return "bilde_profil_norm"};
        if (grp === grp_img_medium)                 {return "bilde_profil_medium"};
        if (grp === grp_img_stor)                   {return "bilde_profil_stor"};
    };
};

// Btn
const is_btn_grp = (grp) => {

    if (grp === grp_btn_blue)                       {return "btn_blue"};
    if (grp === grp_btn_blue_100)                   {return "btn_blue_100"};
    if (grp === grp_btn_green)                      {return "btn_green_resp"};
    if (grp === grp_btn_green_100)                  {return "btn_green_100"};
    if (grp === grp_btn_grey)                       {return "btn_grey"};
    if (grp === grp_btn_grey_100)                   {return "btn_grey_100"};
    if (grp === grp_btn_magenta_100)                {return "btn_magenta_100"};
    if (grp === grp_btn_red)                        {return "btn_red"};
    if (grp === grp_btn_red_100)                    {return "btn_red_100"};
    if (grp === grp_btn_red_trans_ugyldig)          {return "btn_red_trans_ugyldig"};
    if (grp === grp_btn_red_trans_100_ikke_gyldig)  {return "btn_red_trans_100_ugyldig"};

    if (grp === grp_btn_trans_white)                {return "btn_trans_white"};

    if (grp === grp_btn_filter)                     {return "btn_tagg_filter"};
    if (grp === grp_btn_filter_selected)            {return "btn_tagg_filter_selected"};
    if (grp === grp_btn_lang)                       {return "btn_tagg_lang"};
    if (grp === grp_btn_fag)                        {return "btn_tagg_fag"};

    if (grp === grp_btn_txt_liten_grey)             {return "txt_liten_grey_link"};
};

// Input
const is_input_grp = (grp) => {
    if (grp === grp_input_felt)                     {return "felt_input"};
    if (grp === grp_input_drop)                     {return "drop_container_zone_file_input"};
    if (grp === grp_input_display_none)             {return "display_none"};
    if (grp === grp_input_uttrykk_medium)           {return "felt_spill_input_medium"};
    if (grp === grp_input_uttrykk_giga)             {return "felt_spill_input_giga"};
};

// Alert
const is_alert_grp = (grp) => {
    if (grp === grp_alert_green_100)                {return "flex_alert_green"};
    if (grp === grp_alert_red_100)                  {return "flex_alert_red"};
    if (grp === grp_alert_red_abs)                  {return "flex_alert_red_abs"};
    if (grp === grp_alert_green_reg)                {return "flex_alert_green_reg"};
    if (grp === grp_alert_red_reg)                  {return "flex_alert_red_reg"};
    if (grp === grp_alert_black_spill)              {return "flex_alert_black_spill"};
};

// Felt
const is_felt_grp = (grp) => {
    if (grp === grp_felt_100)                   {return "flex_felt_100"}; // reg, profil_set, quest_create
    if (grp === grp_felt_uttrykk_medium)        {return "felt_spill_input_medium"};
    if (grp === grp_felt_uttrykk)               {return "flex_spill_felt_input"};
};
const is_felt_btn_wrapper_grp = (grp) => {
    if (grp === grp_felt_center)                    {return "flex_felt_center"};
    if (grp === grp_felt_left)                      {return "flex_felt_L"};
};

export {
    is_txt_grp,
    is_txt_alert_grp,
    is_icon_grp,
    is_icon_alert_grp,
    is_btn_grp,
    is_img_grp,
    is_input_grp,
    is_alert_grp,
    is_felt_grp,
    is_felt_btn_wrapper_grp
};
