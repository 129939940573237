import {
    redux_tagg
} from "../../utils/konstanter/gen/_redux";

import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    tagg_arr:           [],
    tagg_typ_arr:       [],
    tagg_select_arr:    []
};

const taggArrSlice = createSlice({
    name:               redux_tagg,
    initialState:       initialState,
    reducers: {

        // Tagg arr
        setTaggArr(state, action) {

            // DEV > Forklaring av input arg
            state.tagg_arr = action.payload;

        },
        setTaggTypArr(state, action) {

            // DEV > Forklaring av input arg
            state.tagg_typ_arr = action.payload;

        },

        // Tagg selected arr (foreløpig kun _Quest_item_create)
        setResetTaggSelectArr(state) {

            // DEV > Forklaring av input arg
            state.tagg_select_arr = [];

        },
        setPopTaggSelectArr(state, action) {

            // DEV > Forklaring av input arg
            state.tagg_select_arr.pop(action.payload);

        },
        setPushTaggSelectArr(state, action) {

            // DEV > Forklaring av input arg
            state.tagg_select_arr.push(action.payload);

        },
    }
});


export const {

    // Tagg arr
    setTaggArr,
    setTaggTypArr,

    // Tagg selected arr
    setResetTaggSelectArr,
    setPopTaggSelectArr,
    setPushTaggSelectArr

} = taggArrSlice.actions;

export default taggArrSlice.reducer;
