/* ****************************************** */
/* Debug */
/* ****************************************** */

// Email
const reg_signup_email_developer_override_value     = "a365b24@gmail.com";

// Password
const is_pass_hidden                    = true;

// Default signin-page > Landing / Profil disp
const reg_signin_page_root                  = "_reg_signin_page_root";
const reg_signin_page_profil_disp           = "_reg_signin_page_profil_disp";
const reg_signin_page                       = reg_signin_page_root;


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    reg_signup_email_developer_override_value,

    is_pass_hidden,

    reg_signin_page_root,
    reg_signin_page_profil_disp,
    reg_signin_page
};


/* ****************************************** */
/* End */
/* ****************************************** */
