/* ****************************************** */
/* Fil */
/* ****************************************** */

// Fil
const fil_slug_default_prefix               = "user-init-fil" // "hei";
const fil_navn_default                      = "hei.py";
const fil_content_default                   = 'print("Hei 🌍")';
const fil_is_open_default                   = true;
const fil_is_open_create_default            = false;
const fil_zip_navn_typ_prog_tit             = "_fil_zip_navn_typ_prog_tit";
const fil_zip_navn_typ_prog                 = "_fil_zip_navn_typ_prog";
const fil_zip_navn_typ                      = fil_zip_navn_typ_prog_tit;            // Default: fil_zip_navn_typ_prog_tit,


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    fil_slug_default_prefix,
    fil_navn_default,
    fil_content_default,
    fil_is_open_default,
    fil_is_open_create_default,
    fil_zip_navn_typ_prog_tit,
    fil_zip_navn_typ_prog,
    fil_zip_navn_typ,
};


/* ****************************************** */
/* End */
/* ****************************************** */
