/* ****************************************** */
/* Redux */
/* ****************************************** */

// Components
const redux_header                          = "header";
const redux_path                            = "path";
const redux_page_core                       = "page_core";
const redux_crud                            = "crud";
const redux_fil                             = "fil";
const redux_filter                          = "filter";
const redux_loading                         = "loading";
const redux_tagg                            = "tagg";

// Pages
const redux_ruter                           = "ruter";
const redux_doc                             = "doc";
const redux_user_profil                     = "user_profil";
const redux_account                         = "account";
const redux_quest                           = "quest";
const redux_spill                           = "spill";
const redux_solver                          = "solver";

  
/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    redux_header,
    redux_path,
    redux_page_core,
    redux_crud,
    redux_fil,
    redux_filter,
    redux_loading,
    redux_tagg,

    redux_ruter,
    redux_doc,
    redux_user_profil,
    redux_account,
    redux_quest,
    redux_spill,
    redux_solver

};


/* ****************************************** */
/* End */
/* ****************************************** */