/* ****************************************** */
/* Alert */
/* ****************************************** */

// Alert
const alert_email_confirm                   = "_alert_email_confirm";
const alert_fil_navn_opptatt                = "_alert_fil_navn_opptatt";
const alert_signup_success                  = "_alert_signup_success";
const alert_signin_glemt_pass               = "_alert_signin_glemt_pass";
const alert_signin_glemt_ps_confirm_info_1  = "_alert_signin_glemt_ps_confirm_info_1";
const alert_signin_glemt_ps_confirm_info_2  = "_alert_signin_glemt_ps_confirm_info_2";
const alert_signin_wrong_password           = "_alert_signin_wrong_password";
const alert_username_updated_signin_again   = "_username_updated_signin_again_alert";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    alert_email_confirm,
    alert_fil_navn_opptatt,
    alert_signup_success,
    alert_signin_glemt_pass,
    alert_signin_glemt_ps_confirm_info_1,
    alert_signin_glemt_ps_confirm_info_2,
    alert_signin_wrong_password,
    alert_username_updated_signin_again
};


/* ****************************************** */
/* End */
/* ****************************************** */
