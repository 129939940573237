/* ****************************************** */
/* Delay */
/* ****************************************** */

// Standard
const delay_fast                            = 10;
const delay_load                            = 200;  // 50: For kort -> render issue

// Pages
const delay_ruter                           = 600;  // Fra landing til ruter, 600

// DEV > Delay
const delay_initx_header_ref                = 0;    // ori > 0;                    //   0; // 400  // 1000 // Ubrukt fordi hardkodet (0) > Hardkodet (0) > Abs. minimum for dynamisk (300) > Brukt i header (init)
const delay_arg_0                           = 0;    // Når delay sendes som arg og denne er 0 / ikke-eksisterende
const delay_warning_qf                      = 100;  // Søkeord > FIX_header_warning
const delay_key                             = 10;   // Input
const delay_key_slow                        = 100;  // 1000 // Input
const delay_redux_arr                       = [100, 200, 400];  // Intern delay mellom to redux-funk rett etter hverandre
const delay_ref                             = 10;   // Etter init, brukt i header. Av og til for kjapp for Landing, men spiller kanksje ingen rolle (er uansett oppdatert på de andre sidene)
const delay_route                           = 100;
const delay_route_fetch                     = 0;    // 1000 (hvis linker til riktig side, men ikke scroller til riktig blokk)
const delay_path_yy_toggle                  = 200;  //
const delay_footer_load                     = 1000; // Alltid load sist
const delay_scroll_window                   = 300;  // Standard
const delay_scroll_1                        = 100;  // Kanskje litt rask
const delay_scroll_2_push_yp                = 200;  // -"-
const delay_dropdown_filter                 = 500;  // Kanskje unødvendig hvis _Quest_List.js > click_filter_dropdown > header_dropdown_is_down_global aldri kan bli false
const delay_progress_bar                    = 100;  //
const delay_quest_multiplier                = 1;    // 1.1
const _tmp_mult_1                           = 1.5;  // 1.5 Kun for ikke å misse load av editor kode > Kan fjerne hele denne for å gå tilbake til speed med break
const delay_editor_kode_ref_arr             = [100, 200, 300, 400] * _tmp_mult_1; // * delay_quest_multiplier;  // NB > Må kanskje opp mer! leg > 100, ref / UI load 50: ok
const delay_editor_kode_get_yy_max          = 200;
const delay_quest_fetch_arr                 = [100, 200, 300, 400] * _tmp_mult_1; // * delay_quest_multiplier;  // [200, 400, 600]: Av og til for kort, [100, 200, 300]: for kort -> gir feilmelding i browser: "ResizeObserver loop completed with undelivered notifications."
const delay_quest_navigate_1                = 500 * delay_quest_multiplier * _tmp_mult_1;
const delay_quest_navigate_2                = 700 * delay_quest_multiplier * _tmp_mult_1;
const delay_doc_fil                         = 100;
const delay_doc_route                       = 100;  // Async redirect til riktig route, etter at doc setter riktig path


/* ****************************************** */
/* Duration */
/* ****************************************** */

// Token
const duration_token_refresh                = 1000 * 60 * 4;


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    delay_fast,
    delay_load,

    delay_ruter,

    delay_initx_header_ref,
    delay_arg_0,
    delay_warning_qf,
    delay_key,
    delay_key_slow,
    delay_redux_arr,
    delay_ref,
    delay_route,
    delay_route_fetch,
    delay_path_yy_toggle,
    delay_footer_load,
    delay_scroll_window,
    delay_scroll_1,
    delay_scroll_2_push_yp,
    delay_dropdown_filter,
    delay_progress_bar,
    delay_quest_multiplier,
    _tmp_mult_1,
    delay_editor_kode_ref_arr,
    delay_editor_kode_get_yy_max,
    delay_quest_fetch_arr,
    delay_quest_navigate_1,
    delay_quest_navigate_2,
    delay_doc_fil,
    delay_doc_route,

    duration_token_refresh
};

/*

import {
    delay_fast,
    delay_load,

    delay_ruter,

    delay_initx_header_ref,
    delay_arg_0,
    delay_warning_qf,
    delay_key,
    delay_key_slow,
    delay_redux_arr,
    delay_ref,
    delay_route,
    delay_route_fetch,
    delay_path_yy_toggle,
    delay_footer_load,
    delay_scroll_window,
    delay_scroll_1,
    delay_scroll_2_push_yp,
    delay_dropdown_filter,
    delay_progress_bar,
    delay_quest_multiplier,
    _tmp_mult_1,
    delay_editor_kode_ref_arr,
    delay_editor_kode_get_yy_max,
    delay_quest_fetch_arr,
    delay_quest_navigate_1,
    delay_quest_navigate_2,
    delay_doc_fil,
    delay_doc_route,

    duration_token_refresh
} from "../../utils/konstanter/gen/_time";


*/


/* ****************************************** */
/* End */
/* ****************************************** */