import React                                        from "react";
import {BrowserRouter as Router, Routes, Route}     from "react-router-dom";
import {Provider}                                   from "react-redux";
import {AuthProvider}                               from "./context/auth_context"; // adressbar-debug > import React, {useEffect, useState} from "react";
import store                                        from "./redux/store";
import Header                                       from "./components/01_header/header";
import Path                                         from "./components/02_path/path";
import Footer                                       from "./components/04_footer/footer";

import "./static/css/_root.css";
import "./static/css/_bilde.css";
import "./static/css/_box.css";
import "./static/css/_btn.css";
import "./static/css/_cropper.css";
import "./static/css/_custom.css";
import "./static/css/_dropzone.css";
import "./static/css/_editor_quill.css";
import "./static/css/_html_tag.css";
import "./static/css/_icon.css";
import "./static/css/_splitter.css";
import "./static/css/_txt.css";
import "./static/css/01_header.css";
import "./static/css/02_footer.css";

import {
    debug_app_root_initx
} from "./utils/debug/_debug";
import {
    url_route_root,
    url_route_ruter,
    url_route_mal,
    url_route_about,
    url_route_adminx,
    url_route_doc,
    url_route_no_url,
    url_route_profil_disp,
    url_route_profil_set,
    url_route_user_profil_list,
    url_route_quest_create,
    url_route_quest_item,
    url_route_quest_list,
    url_route_signup,
    url_route_signup_success,
    url_route_signin,
    url_route_signin_email_confirm,
    url_route_signin_glemt_passord,
    url_route_signin_glemt_ps_confirm,
    url_route_signin_glemt_ps_fra_epost,
    url_route_spill,
    url_route_the_editor
} from "./utils/konstanter/gen/_url";
import {
    reg_signup_typ,
    reg_signup_success_typ,
    reg_signin_typ,
    reg_signin_email_confirm_typ,
    reg_signin_glemt_passord_typ,
    reg_signin_glemt_passord_confirm_typ,
    reg_signin_email_passord_fra_epost
} from "./utils/konstanter/pages/_reg";
import {
    tekst_debug
} from "./utils/utility/_util_tekst";

// Pages
const LandingPage           = React.lazy(() => import("./pages/landing/_Landing"));
const RuterPage             = React.lazy(() => import("./pages/ruter/_Ruter"));
const MalPage               = React.lazy(() => import("./pages/_mal/_Mal"));
const AboutPage             = React.lazy(() => import("./pages/about/_About"));
const AdminxPage            = React.lazy(() => import("./pages/adminx/_Adminx"));
const DocPage               = React.lazy(() => import("./pages/doc/_Doc"));
const NoUrlPage             = React.lazy(() => import("./pages/no_url/_No_Url"));
const ProfilDispPage        = React.lazy(() => import("./pages/profil/_Profil_Disp"));
const ProfilSetPage         = React.lazy(() => import("./pages/profil/_Profil_Set"));
const UserProfilListPage    = React.lazy(() => import("./pages/profil/_User_Profil_List"));
const QuestItemCreatePage   = React.lazy(() => import("./pages/quest/_Quest_Item_Create"));
const QuestItemPage         = React.lazy(() => import("./pages/quest/_Quest_Item"));
const QuestListPage         = React.lazy(() => import("./pages/quest/_Quest_List"));
const RegPage               = React.lazy(() => import("./pages/reg/_Reg"));
const SpillPage             = React.lazy(() => import("./pages/spill/_Spill"));
const TheEditorPage         = React.lazy(() => import("./pages/the_editor/_The_Editor"));

// Debug > Initx > 0000
if (debug_app_root_initx) { console.log(tekst_debug("Initx", "0000", "App.js", "root", "", "")); };

// DEV > Finn løsning for at daf sletter filer som er laget med quest og svar

// App
function App() {

    // Return
    return (
        <Router>
            <Provider store = {store}>
                <AuthProvider>
                    <div className = "flex_app_hele">

                        {/* Header */}
                        <Header/>

                        {/* Path */}
                        <Path/>

                        {/* Page route */}
                        <div className = "flex_app_body">
                            <React.Suspense fallback = {""}> {/* fallback={"_hardkodet"} */}
                            <Routes>

                                <Route exact path = {url_route_root}                                element = {<LandingPage/>}/>

                                {/* *** refacked *** */}
                                <Route exact path = {url_route_ruter}                               element = {<RuterPage/>}/>

                                <Route exact path = {url_route_mal}                                 element = {<MalPage/>}/>

                                <Route exact path = {url_route_about}                               element = {<AboutPage/>}/>

                                {/* *** DEV_timeout
                                <Route exact path = {url_route_adminx}                              element = {<AdminxPage/>}/>
                                */}

                                <Route exact path = {`${url_route_doc}/:pk`}                        element = {<DocPage/>}/>

                                {/* ORI > <Route exact path = {url_route_doc}                         element = {<DocPage/>}/> */}

                                <Route exact path = {url_route_no_url}                              element = {<NoUrlPage/>}/>

                                <Route exact path = {`${url_route_profil_disp}/:pk`}                element = {<ProfilDispPage/>}/>
                                <Route exact path = {url_route_profil_set}                          element = {<ProfilSetPage/>}/>
                                <Route exact path = {url_route_user_profil_list}                    element = {<UserProfilListPage/>}/>

                                <Route exact path = {url_route_quest_create}                        element = {<QuestItemCreatePage/>}/>
                                <Route exact path = {`${url_route_quest_item}/:pk`}                 element = {<QuestItemPage/>}/>
                                <Route exact path = {url_route_quest_list}                          element = {<QuestListPage/>}/>

                                <Route exact path = {url_route_signup}                              element = {<RegPage typ = {reg_signup_typ}/>}/>
                                <Route exact path = {url_route_signup_success}                      element = {<RegPage typ = {reg_signup_success_typ}/>}/>
                                <Route exact path = {url_route_signin}                              element = {<RegPage typ = {reg_signin_typ}/>}/>
                                <Route exact path = {url_route_signin_email_confirm}                element = {<RegPage typ = {reg_signin_email_confirm_typ}/>}/>
                                <Route exact path = {url_route_signin_glemt_passord}                element = {<RegPage typ = {reg_signin_glemt_passord_typ}/>}/>
                                <Route exact path = {url_route_signin_glemt_ps_confirm}             element = {<RegPage typ = {reg_signin_glemt_passord_confirm_typ}/>}/>
                                <Route exact path = {`${url_route_signin_glemt_ps_fra_epost}/:pk`}  element = {<RegPage typ = {reg_signin_email_passord_fra_epost}/>}/>

                                <Route exact path = {url_route_spill}                               element = {<SpillPage/>}/>

                                <Route exact path = {url_route_the_editor}                          element = {<TheEditorPage/>}/>

                            </Routes>
                            </React.Suspense>
                        </div>

                        {/* Footer */}
                        <Footer/>

                    </div>
                </AuthProvider>
            </Provider>
        </Router>
    );
};


export default App;
