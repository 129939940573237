import {
    redux_account
} from "../../utils/konstanter/gen/_redux";
import {
    profil_set_public_profil_item
} from "../../utils/konstanter/pages/_profil";
import {
    txt_len_password_min
} from "../../utils/tekst/_param/param";
import {
    password_sub_gyldig,
    password_sub_ikke_gyldig
} from "../../utils/tekst/pages/_reg";

import {
    change_username_get_info_cls_txt,
    change_password_1_get_info_cls_txt
} from "./txt_cls_gyldig_util";

import {createSlice} from "@reduxjs/toolkit";


const initialState = {

    // Account > Selected
    account_selected:                       profil_set_public_profil_item,

    // Username > Set / Change
    username_reg:                           "",
    username_info_txt:                      "",
    username_info_cls:                      "",
    is_username_gyldig:                     false,

    // Username > DEV
    is_username_update_endre_btn_clicked:   false,
    is_username_update_success:             false,
    is_username_update_success_lock:        false,
    username_update_str:                    "",

    // Password > Set / Change
    password_reg:                           "",
    password_1_info_txt:                    "",
    password_1_info_cls:                    "",
    password_2_info_txt:                    "",
    password_2_info_cls:                    "",
    is_password_gyldig:                     false,

    // Password > Wrong
    is_password_wrong:                      false,

    // Password > Updated
    is_password_update_endre_btn_clicked:   false,
    is_password_update_success:             false,
    is_password_update_fail:                false,

    // User profil img
    is_user_profil_img_auto:                false,
    is_user_profil_img_auto_scroll:         false,

    // Alert
    is_password_glemt_confirm_3_alert:      false

};

const accountSlice = createSlice({
    name:                                   redux_account,
    initialState:                           initialState,
    reducers: {

        // Account > Selected
        setAccountSelected(state, action) {

            // DEV > Forklaring / Eksempel param
            state.account_selected = action.payload;

        },

        // Username / Password > Reset > All
        setResetRegClsTxtAll(state) {

            // DEV > Forklaring / Eksempel param
            state.username_reg          = "";
            state.username_info_cls     = "";
            state.username_info_txt     = "";

            state.password_reg          = "";
            state.password_1_info_cls   = "";
            state.password_1_info_txt   = "";
            state.password_2_info_cls   = "";
            state.password_2_info_txt   = "";

        },

        // Username > Set / Change
        setUsernameReset(state) {

            // DEV > Forklaring / Eksempel param
            state.username_info_cls     = "";
            state.username_info_txt     = "";
            state.is_username_gyldig    = false;

        },
        setChangeUsername(state, action) {

            // action.payload > arr > [dat, e.target.value]
            // arr const _is_username_opptatt = action.payload[0]
            // arr const _username = action.payload[1]

            const _is_username_opptatt  = action.payload[0]
            const _username             = action.payload[1]

            // Passord > Set
            state.username_reg = _username;

            // Get arr > [cls, txt, is_gyldig]
            const [_username_info_cls, _username_info_txt, _username_is_gyldig]
            = change_username_get_info_cls_txt(_is_username_opptatt, _username, _username.length);

            // Set cls / txt med arr
            state.username_info_cls     = _username_info_cls;
            state.username_info_txt     = _username_info_txt;
            state.is_username_gyldig    = _username_is_gyldig;

        },

        // Username > Update
        setUsernameUpdateGrpReset(state) {

            // DEV > Forklaring / Eksempel param
            state.is_username_update_endre_btn_clicked  = false;
            state.is_username_update_success            = false;
            state.is_username_update_success_lock       = false;
            state.username_update_str                   = "";

        },
        setIsUsernameUpdateEndreBtnClickedVal(state, action) {

            // DEV > Forklaring / Eksempel param
            state.is_username_update_endre_btn_clicked = action.payload;

        },
        setIsUsernameUpdateSuccessVal(state, action) {

            // DEV > Forklaring / Eksempel param
            state.is_username_update_success = action.payload;

        },
        setIsUsernameUpdateSuccessLockVal(state, action) {

            // DEV > Forklaring / Eksempel param
            state.is_username_update_success_lock = action.payload;

        },
        setUsernameUpdateStr(state, action) {

            // DEV > Forklaring / Eksempel param
            state.username_update_str = action.payload;

        },
        setUsernameUpdateRegStr(state) {

            // DEV > Forklaring / Eksempel param
            state.username_reg = state.username_update_str;

        },

        // Password > Set / Change
        setChangePassword1(state, action) {

            // action.payload > e.target.value
            const _password_1 = action.payload;

            // Passord 1 > Set
            state.password_reg = _password_1;

            // Get arr > [cls, txt]
            const [_password_info_cls, _password_info_txt]
            = change_password_1_get_info_cls_txt(_password_1.length);

            // Set cls / txt med arr
            state.password_1_info_cls = _password_info_cls;
            state.password_1_info_txt = _password_info_txt;

            // Gyldig false fordi endring av password1 alltid krever lik endring i password2
            state.is_password_gyldig = false;

        },
        setChangePassword2(state, action) {

            // Password
            const _password = action.payload;

            // Pass 2 = 0 > Ikke gyldig
            if (_password.length === 0) {
                state.password_2_info_cls = "";
                state.password_2_info_txt = "";
                state.is_password_gyldig = false;}

            // Pass 1 = Pass 2 > Gyldig > Større enn min (max er begrenset av input-el)
            else if (
                _password === state.password_reg &&
                _password.length >= txt_len_password_min) {
                    state.password_2_info_cls = "txt_liten_green";
                    state.password_2_info_txt = password_sub_gyldig;
                    state.is_password_gyldig = true;}

            // Pass 1 != Pass 2 > Ikke gyldig
            else {
                state.password_2_info_cls = "txt_liten_yellow";
                state.password_2_info_txt = password_sub_ikke_gyldig;
                state.is_password_gyldig = false;};

        },

        // Password > Wrong
        setIsPasswordWrong(state, action) {

            // DEV > Forklaring / Eksempel param
            state.is_password_wrong = action.payload;

        },

        // Password > Update
        setPasswordUpdateGrpReset(state) {

            // DEV > Forklaring / Eksempel param
            state.is_password_update_endre_btn_clicked  = false;
            state.is_password_update_success            = false;
            state.is_password_update_fail               = false;

        },
        setIsPasswordUpdateEndreBtnClickedVal(state, action) {

            // DEV > Forklaring / Eksempel param
            state.is_password_update_endre_btn_clicked = action.payload;

        },
        setIsPasswordUpdateSuccessVal(state, action) {

            // DEV > Forklaring / Eksempel param
            state.is_password_update_success = action.payload;

        },
        setIsPasswordUpdateFailVal(state, action) {

            // DEV > Forklaring / Eksempel param
            state.is_password_update_fail = action.payload;

        },

        setUserProfilImgAuto(state, action) {

            // Settes til true hvis klikker direkte på profil img i _Profil_Disp.js > Trigger cropper i useEffect i profil_set_header.js
            state.is_user_profil_img_auto = action.payload;

        },
        setUserProfilImgAutoScroll(state, action) {

            // Scroller til auto
            state.is_user_profil_img_auto_scroll = action.payload;

        },

        setIsPasswordGlemtConfirm3Alert(state, action) {

            // Scroller til auto
            state.is_password_glemt_confirm_3_alert = action.payload;

        },

    }
});


export const {

    // Account selected
    setAccountSelected,

    // Username / Password > Reset > All
    setResetRegClsTxtAll,

    // Username > Set / Change
    setUsernameReset,
    setChangeUsername,

    // Username > Update
    setUsernameUpdateGrpReset,
    setIsUsernameUpdateEndreBtnClickedVal,
    setIsUsernameUpdateSuccessVal,
    setIsUsernameUpdateSuccessLockVal,
    setUsernameUpdateStr,
    setUsernameUpdateRegStr,

    // Password > Set / Change
    setChangePassword1,
    setChangePassword2,

    // Password > Update
    setPasswordUpdateGrpReset,
    setIsPasswordUpdateEndreBtnClickedVal,
    setIsPasswordUpdateSuccessVal,
    setIsPasswordUpdateFailVal,

    // Password > Wrong
    setIsPasswordWrong,

    // User profil img auto
    setUserProfilImgAuto,
    setUserProfilImgAutoScroll,

    setIsPasswordGlemtConfirm3Alert

} = accountSlice.actions;


export default accountSlice.reducer;
