/* ****************************************** */
/* Font */
/* ****************************************** */

// Family
const font_family_times_new_roman_          = "Times New Roman";
const font_family_verdana_                  = "Verdana";
const font_family_progress_bar              = font_family_verdana_;
const font_family_spill                     = font_family_times_new_roman_; // font_family_verdana_

// Size
const font_size_16_px                       = 16;
const font_size_12_px                       = 12;
const font_size_progress_bar                = 0.4;              // rem -> 6.4px
const font_size_checkbox_mui_box            = font_size_16_px;  // 16px
const font_size_checkbox_mui_txt            = font_size_16_px;  // 16px
const font_size_editor_kode_main            = font_size_12_px;  // 12 px
const font_size_expr_1                      = 40;               // font_size_16_px;  // px, default html > 16, verdana >
const font_size_expr_2_mult                 = 0.6;              //0.6; // 0.5;  // px, default html > 16

// Weight
const font_weight_progress_bar              = 400;


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    font_family_times_new_roman_,
    font_family_verdana_,
    font_family_progress_bar,
    font_family_spill,
    font_size_progress_bar,
    font_size_checkbox_mui_box,
    font_size_checkbox_mui_txt,
    font_size_editor_kode_main,
    font_size_expr_1,
    font_size_expr_2_mult,
    font_weight_progress_bar
};
