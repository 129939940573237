/* ****************************************** */
/* footer */
/* ****************************************** */

// Footer
const footer_tittel_sub                     = "_footer_tittel_sub";

// Info
const footer_copyright                      = "_footer_copyright";
const footer_terms_tittel                   = "_footer_terms_tittel";
const footer_kurs_tittel                    = "_footer_kurs_tittel";
const footer_about_tittel                   = "_footer_about_tittel";
const footer_about_tittel_sub               = "_footer_about_tittel_sub";


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    footer_tittel_sub,

    footer_copyright,
    footer_terms_tittel,
    footer_kurs_tittel,
    footer_about_tittel,
    footer_about_tittel_sub
};


/* ****************************************** */
/* End */
/* ****************************************** */
