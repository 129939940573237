/* ****************************************** */
/* Num */
/* ****************************************** */

import {
    _sym_pluss
} from "./_sym"

// Søkeord > Backend_frontend_speilbilder > prim_sym0.py <-> _sym.js

// Num
const _t_1                                  = "1";
const _t_2                                  = "2";
const _t_3                                  = "3";
const _t_4                                  = "4";
const _t_5                                  = "5";
const _t_6                                  = "6";
const _t_7                                  = "7";
const _t_8                                  = "8";
const _t_9                                  = "9";
const _t_0                                  = "0";

// Ubrukt frontend
const _sym_int_arr                          = [
    _t_1,
    _t_2,
    _t_3,
    _t_4,
    _t_5,
    _t_6,
    _t_7,
    _t_8,
    _t_9,
    _t_0
];

// Tall
const _pos_1                                = `${_sym_pluss}${_t_1}`;


/* ****************************************** */
/* Export */
/* ****************************************** */

export {
    _t_0,
    _t_1,
    _t_2,
    _t_3,
    _t_4,
    _t_5,
    _t_6,
    _t_7,
    _t_8,
    _t_9,

    _sym_int_arr,

    _pos_1
};
