import {
    fil_ext_html,
    fil_item_typ_fil,
    fil_item_typ_folder,
    fil_slug_tmp_default,
    fil_navn_default_blank,
    fil_content_default_blank,
    fil_abs_path_default_blank,
    fil_level_i_offset,
    fil_par_slug_root
} from "../../utils/konstanter/components/_fil";
import {
    editor_kode_align_col,
    editor_kode_term_selected_terminal,
    editor_kode_term_selected_frontend,
    editor_kode_caller_user,
    editor_kode_caller_api_norm,
    editor_kode_caller_api_inp,
    editor_kode_caller_api_cmd,
    editor_kode_reset_typ_all
} from "../../utils/konstanter/components/_editor_kode";
import {
    _blank_str,
    _new_line
} from "../../utils/konstanter/components/_sym";
import {
    _key_editor_kode_typ_delete
} from "../../utils/konstanter/gen/_key";
import {
    redux_fil
} from "../../utils/konstanter/gen/_redux";
import {
    quest_svar_create_slug_tmp
} from "../../utils/konstanter/pages/_quest";
import {
    fil_slug_default_prefix,
    fil_navn_default,
    fil_content_default,
    fil_is_open_default,
    fil_is_open_create_default
} from "../../utils/settings/components/_fil";
import {
    fil_ext_dotless
} from "../../utils/utility/_util_string";
import {
    get_time_now_mark
} from "../../utils/utility/_util_time";

import {
    txt_create_slug_from_str,
    str_komma_sep_to_arr_reset,
    set_node_selected_all_0,
    set_node_selected_fil_slug_current_reset,
    set_node_selected_fil_slug_fil_entry_reset,
    set_node_selected_fil_slug_current_do,
    set_node_selected_fil_content_init_yy,
    set_node_selected_fil,
    set_node_selected_current_editor_0,
    set_node_selected,
    set_node_selected_current_fil_content,
    set_node_editing_all_false,
    set_node_editing_current,
    set_node_delete_par_ch,
    set_node_sort_alfabetisk,
    debug_node_item_slug,
    debug_node_item_open_show,
    set_node_create_path
} from "./fil_util";


import {createSlice} from "@reduxjs/toolkit";


/*
- DEV
    - Slug
        - Lag slug på ny create fil
            - Alle slugger må ha bindestrek for søkbarhet
        - legg til username når posting
        - Alternativt ta det inn som arg her
    - fil navn
        - Må lage funk som sjekker at fil- / folder-navnet er ledig i aktuel dir
    - Pass på at download-zip alltid laster ned mappe med oppdaterte paths
        - Trenger bare å oppdatere etter add / remove fil
    - Også lag fil_entry for quest
*/


const initialState = {

    // Node mat
    _fil_node_slug_arr:                     [],
    _fil_node_username_arr:                 [],
    _fil_node_fil_mat_reset:                [],
    _fil_node_fil_mat:                      [],
    _fil_node_fil_entry_arr:                [], // prøv internal redux her
    _fil_node_svar_create_ny_default_el:    {                       // Søkeord > node_fields
        slug:                               `${fil_slug_default_prefix}_${get_time_now_mark()}`,   // fil_slug_tmp_default DEV > Sjekk om ix er med > "ix: -10"
        level_i:                            0,                      // -10,
        level_j:                            0,                      // -10,
        fil_navn:                           fil_navn_default,
        fil_content:                        fil_content_default,
        is_folder:                          false,                  // DEV > Sjekk om skal være default
        abs_path:                           fil_abs_path_default_blank,
        is_open:                            fil_is_open_default,
        is_show:                            true,
        is_selected:                        false,
        is_editing:                         false,
        fil:                                "",
        fil_ext:                            "",
        fil_base:                           ""
    },
    _fil_node_create_fil_el:                {                       // Søkeord > node_fields
        slug:                               fil_slug_tmp_default,   // DEV > Sjekk om ix er med > "ix: -10"
        level_i:                            -10,
        level_j:                            -10,
        fil_navn:                           fil_navn_default_blank,
        fil_content:                        fil_content_default_blank,
        is_folder:                          false,  // DEV > Sjekk om skal være default
        abs_path:                           fil_abs_path_default_blank,
        is_open:                            fil_is_open_create_default,
        is_show:                            true,
        is_selected:                        false,
        is_editing:                         false,
        fil:                                "",
        fil_ext:                            "",
        fil_base:                           ""
    },
    _fil_node_create_item_level_i_tmp:      -10,
    _fil_node_fil_navn_inp_arr_reset:       [-10, "", ""],          // Søkeord > INDEX_fil_node_fil_navn_inp_arr
    _fil_node_tre_arr_reset:                [],
    fil_node_index_n_case_1:                0,
    fil_node_mat:                           [],
    fil_node_mat_mirror:                    [],
    fil_node_fil_navn_is_opptatt:           true,
    fil_node_fil_navn_inp_arr:              [-10, "", ""],          // Søkeord > INDEX_fil_node_fil_navn_inp_arr > Duplisert fordi kan ikke bruke val over, uten å refacke ut til f.eks. _konstanter.js (ikke ønsket fordi dette kun er en intern re-setting)
    fil_node_fil_content_init_yy_arr:       [],
    fil_node_fil_content_init_yy_done:      false,
    fil_node_selected_slug_arr:             [],     // [editor_1_slug, editor_2_slug, ...]
    fil_node_is_editing_done:               true,
    fil_node_is_deleting_done:              true,

    // Term
    fil_term_out_arr:                       [],     // Out-stringen > Samlet i arr for alle editorene
    fil_term_out_mat:                       [],
    fil_term_inp_arr:                       [],
    fil_term_status_arr:                    [],
    fil_term_vari_ix_current_arr:           [],
    fil_term_vari_el_mat:                   [],
    fil_term_vari_val_mat:                  [],
    fil_term_cumul_out_mat:                 [],

    // Upload > Quest
    fil_upload_arr:                         [],

    // Download zip
    fil_download_zip_navn:                  "",

    // Editor kode
    fil_editor_kode_align_arr:              [],
    fil_editor_kode_term_selected_arr:      [],
    fil_editor_kode_create_typ:             _key_editor_kode_typ_delete,

    // 2024
    fil_node_mat_lock_redux_ver:            false

};

const fileSelectSlice = createSlice({
    name:                                   redux_fil,
    initialState:                           initialState,
    reducers: {

        // Hard reset all > Ubrukt
        setHardResetAll(state) {

            // Node mat
            state._fil_node_slug_arr=                     []
            state._fil_node_username_arr=                 []
            state._fil_node_fil_mat_reset=                []
            state._fil_node_fil_mat=                      []
            state._fil_node_fil_entry_arr=                [] // prøv internal redux her
            state._fil_node_svar_create_ny_default_el=    {                       // Søkeord > node_fields
                slug:                               `${fil_slug_default_prefix}_${get_time_now_mark()}`,   // fil_slug_tmp_default DEV > Sjekk om ix er med > "ix: -10"
                level_i:                            0,                      // -10,
                level_j:                            0,                      // -10,
                fil_navn:                           fil_navn_default,
                fil_content:                        fil_content_default,
                is_folder:                          false,                  // DEV > Sjekk om skal være default
                abs_path:                           fil_abs_path_default_blank,
                is_open:                            fil_is_open_default,
                is_show:                            true,

                is_selected:                        false,

                /// is_selected:                        true,

                is_editing:                         false,
                fil:                                "",
                fil_ext:                            "",
                fil_base:                           ""
            }
            state._fil_node_create_fil_el=                {                       // Søkeord > node_fields
                slug:                               fil_slug_tmp_default,   // DEV > Sjekk om ix er med > "ix: -10"
                level_i:                            -10,
                level_j:                            -10,
                fil_navn:                           fil_navn_default_blank,
                fil_content:                        fil_content_default_blank,
                is_folder:                          false,  // DEV > Sjekk om skal være default
                abs_path:                           fil_abs_path_default_blank,
                is_open:                            fil_is_open_create_default,
                is_show:                            true,
                is_selected:                        false,
                is_editing:                         false,
                fil:                                "",
                fil_ext:                            "",
                fil_base:                           ""
            }
            state._fil_node_create_item_level_i_tmp=      -10
            state._fil_node_fil_navn_inp_arr_reset=       [-10, "", ""]          // Søkeord > INDEX_fil_node_fil_navn_inp_arr
            state._fil_node_tre_arr_reset=                []
            state.fil_node_index_n_case_1=                0
            state.fil_node_mat=                           []
            state.fil_node_mat_mirror=                    []
            state.fil_node_fil_navn_is_opptatt=           true
            state.fil_node_fil_navn_inp_arr=              [-10, "", ""]          // Søkeord > INDEX_fil_node_fil_navn_inp_arr > Duplisert fordi kan ikke bruke val over, uten å refacke ut til f.eks. _konstanter.js (ikke ønsket fordi dette kun er en intern re-setting)
            state.fil_node_fil_content_init_yy_arr=       []
            state.fil_node_fil_content_init_yy_done=      false
            state.fil_node_selected_slug_arr=             []     // [editor_1_slug, editor_2_slug, ...]
            state.fil_node_is_editing_done=               true
            state.fil_node_is_deleting_done=              true

            // Term
            state.fil_term_out_arr=                       []     // Out-stringen > Samlet i arr for alle editorene
            state.fil_term_out_mat=                       []
            state.fil_term_inp_arr=                       []
            state.fil_term_status_arr=                    []
            state.fil_term_vari_ix_current_arr=           []
            state.fil_term_vari_el_mat=                   []
            state.fil_term_vari_val_mat=                  []
            state.fil_term_cumul_out_mat=                 []

            // Upload > Quest
            state.fil_upload_arr=                         []

            // Download zip
            state.fil_download_zip_navn=                  ""

            // Editor kode
            state.fil_editor_kode_align_arr=              []
            state.fil_editor_kode_term_selected_arr=      []
            state.fil_editor_kode_create_typ=             _key_editor_kode_typ_delete
        },

        // Node mat
        setFilNodeFilMatResetAdapterDoc(state, action) {

            // Dat
            const _dat_slug_center          = action.payload[0];
            const _dat_username             = action.payload[1];
            const _dat_tittel               = action.payload[2];
            const _dat_tre_arr              = action.payload[3];
            const _dat_fil_mat              = action.payload[4];
            const _dat_fil_entry_arr        = action.payload[5];

            // Init
            const _dat_slug_arr             = [];
            const _dat_username_arr         = [];
            const _fil_node_fil_mat_reset   = [];
            const _fil_entry_arr            = [];

            // Tre arr
            const _tre_arr_reset            = _dat_tre_arr

            // Tre arr / _fil_node_fil_mat
            for (let i = 0; i < _dat_fil_mat.length; i++) {
                _fil_node_fil_mat_reset.push(_dat_fil_mat[i]);
                _dat_slug_arr.push(_dat_slug_center);
                _dat_username_arr.push(_dat_username);
                _fil_entry_arr.push(_dat_fil_entry_arr[i].fil_entry);
            };

            // Quest > Slug / username
            state._fil_node_slug_arr            = _dat_slug_arr;
            state._fil_node_username_arr        = _dat_username_arr;

            // Adapter > Reset
            state._fil_node_tre_arr_reset       = _tre_arr_reset;           // ["+ root, + + item_a_1, ...", "+ root, + + item_b_1, ..."]
            state._fil_node_fil_mat_reset       = _fil_node_fil_mat_reset;  // [[slug: a_1, ...], [slug: a_1, ...]]
            state._fil_node_fil_entry_arr       = _fil_entry_arr;

            // Fil download zip navn
            state.fil_download_zip_navn         = _dat_tittel;

        },
        setFilNodeFilMatResetAdapterQuest(state, action) {

            // Init
            const _dat_quest_slug           = action.payload[0];
            const _dat_quest_username       = action.payload[1];
            const _dat_tittel               = action.payload[2];
            const _dat_tre                  = action.payload[3];
            const _dat_fil_arr              = action.payload[4];
            const _dat_svar_arr             = action.payload[5];
            const _dat_fil_entry            = action.payload[6];

            // Init
            const _dat_slug_arr             = [];
            const _dat_username_arr         = [];
            const _tre_arr_reset            = [];
            const _fil_node_fil_mat_reset   = [];
            const _fil_entry_arr            = [];

            // Slug arr
            _dat_slug_arr.push(_dat_quest_slug);
            _dat_slug_arr.push(quest_svar_create_slug_tmp);

            // Username arr
            _dat_username_arr.push(_dat_quest_username);
            _dat_username_arr.push(quest_svar_create_slug_tmp);

            // Tre arr
            _tre_arr_reset.push(_dat_tre);                                  // Quest
            _tre_arr_reset.push(_dat_tre);                                  // Svar > Ny / Kopi

            // Fil mat
            _fil_node_fil_mat_reset.push(_dat_fil_arr);                     // Quest arr
            _fil_node_fil_mat_reset.push(_dat_fil_arr);                     // Svar create arr > Kopi av quest arr

            // Fil entry
            _fil_entry_arr.push(_dat_fil_entry);                            // Quest
            _fil_entry_arr.push(_blank_str);                                // Svar create

            // Tre arr / _fil_node_fil_mat
            for (let i = 0; i < _dat_svar_arr.length; i++) {
                _dat_slug_arr.push(_dat_svar_arr[i].slug);
                _dat_username_arr.push(_dat_svar_arr[i].username);
                _tre_arr_reset.push(_dat_svar_arr[i].tre);
                _fil_node_fil_mat_reset.push(_dat_svar_arr[i].fil_arr);
                _fil_entry_arr.push(_dat_svar_arr[i].fil_entry);
            };

            // Quest > Slug / username
            state._fil_node_slug_arr            = _dat_slug_arr;
            state._fil_node_username_arr        = _dat_username_arr;

            // Adapter > Reset
            state._fil_node_tre_arr_reset       = _tre_arr_reset;           // ["+ root, + + item_a_1, ...", "+ root, + + item_b_1, ..."]
            state._fil_node_fil_mat_reset       = _fil_node_fil_mat_reset;  // [[slug: a_1, ...], [slug: a_1, ...]]
            state._fil_node_fil_entry_arr       = _fil_entry_arr;

            // Fil download zip navn
            state.fil_download_zip_navn         = _dat_tittel;

        },
        setFilNodeFilMatReset(state) {

            // Søkeord > INDEX_fil_node_fil_navn_inp_arr > Må ha riktig length og index i JSX

            /* ****************************************** */
            /* Reset */
            /* ****************************************** */

            // PRØV > Flyttet opp fil_node_mat reset fra under
            // Se _BU > 05_PRG_input_go_status for ori plassering

            // Reset pga. multiple auto-calls > Kun i JS, ikke Python
            state.fil_node_mat              = [];

            // Reset > Terminal align og selected
            state.fil_editor_kode_align_arr         = [];
            state.fil_editor_kode_term_selected_arr = [];


            /* ****************************************** */
            /* Node mat > Reset */
            /* ****************************************** */

            // Fil mat
            state._fil_node_fil_mat = state._fil_node_fil_mat_reset;

            // Edit inp arr
            state.fil_node_fil_navn_inp_arr = state._fil_node_fil_navn_inp_arr_reset;


            /* ****************************************** */
            /* Node mat */
            /* ****************************************** */

            // gum arr
            const _gum_str_arr = state._fil_node_tre_arr_reset;

            // Iternate
            for (let i = 0; i < _gum_str_arr.length; i++) {

                // Gum str arr > Gum arr
                const _gum_arr = str_komma_sep_to_arr_reset(_gum_str_arr[i]);


                /* ****************************************** */
                /* _get_linje_mat() */
                /* ****************************************** */

                // Reset pga. multiple auto-calls > Kun i JS, ikke Python
                const _fil_node_linje_mat = [];

                // Iterate gjennom alle ygg-el
                for (let n = 0; n < _gum_arr.length; n++) {

                    // Init
                    let _node_slug      = "";       // Slug
                    let _index_i        = 0;        // Index i
                    let _index_j        = -1;       // Index j

                    // Iterate gjennom alle "+"-slug for for quest / svar
                    for (let k = 0; k < _gum_arr[n].length; k++) {

                        // Telles // DEV > refac til _konstant
                        if (_gum_arr[n][k] === "+")         { _index_i += 1; }

                        // Telles ikke > Bybass
                        else if (_gum_arr[n][k] === " ")    {}

                        // Iterer frem _node_slug
                        else                                { _node_slug += _gum_arr[n][k]; };

                    };

                    // Append _node_slug / _index_i
                    _fil_node_linje_mat.push([_node_slug, _index_i]) // Format > [['root', 1]]

                    // index_j
                    for (let l = 0; l < _fil_node_linje_mat.length; l++) {

                        // Inkrement
                        if (_fil_node_linje_mat[l][1] === _index_i) { _index_j += 1; };

                    };

                    // Append index j
                    _fil_node_linje_mat[n].push(_index_j); // Format > [['root', 1, 0], ['landing', 2, 0], ['home', 2, 1]]

                    // Push placeholdere > node_fields
                    _fil_node_linje_mat[n].push("");                    // fil_navn init
                    _fil_node_linje_mat[n].push("");                    // fil_content init
                    _fil_node_linje_mat[n].push(false);                 // is_folder init         > DEV > Sjekk om skal være default
                    _fil_node_linje_mat[n].push("");                    // abs_path init
                    _fil_node_linje_mat[n].push(fil_is_open_default);   // is_open init
                    _fil_node_linje_mat[n].push(true);                  // is_show init
                    _fil_node_linje_mat[n].push(false);                 // is_selected init
                    _fil_node_linje_mat[n].push(false);                 // is_editing init
                    _fil_node_linje_mat[n].push("");                    // fil
                    _fil_node_linje_mat[n].push("");                    // fil_ext
                    _fil_node_linje_mat[n].push("");                    // fil_base

                };


                /* ****************************************** */
                /* Node arr > Data */
                /* ****************************************** */

                // Reset pga. multiple auto-calls > Kun i JS, ikke Python
                const _node_arr = [];

                // Iterate fra 1 / "+ root" droppes > Set selected el
                for (let n = 1; n < _fil_node_linje_mat.length; n++) { // console.log("state._gum_arr[i] :: ", state.fil_gum_arr);

                    // console.log("F ::: ", _fil_node_linje_mat[n])

                    // Data > Kun JS > Key-val
                    /*
                    const _fil_navn     = "yoyo.py"; // state._fil_node_fil_mat[i].find(el => el.slug === _fil_node_linje_mat[n][0]).fil_navn
                    const _fil_content  = "print(314)"; // state._fil_node_fil_mat[i].find(el => el.slug === _fil_node_linje_mat[n][0]).fil_content
                    const _is_folder    = false; //state._fil_node_fil_mat[i].find(el => el.slug === _fil_node_linje_mat[n][0]).is_folder
                    */

                    let _fil_navn       = "";
                    let _fil_content    = "";
                    let _is_folder      = "";

                    // const _fil_navn     = state._fil_node_fil_mat[i].find(el => el.slug === _fil_node_linje_mat[n][0]).fil_navn

                    // _fil_navn
                    if (state._fil_node_fil_mat[i].find(el => el.slug === _fil_node_linje_mat[n][0]) !== undefined) {

                        _fil_navn = state._fil_node_fil_mat[i].find(el => el.slug === _fil_node_linje_mat[n][0]).fil_navn

                    };

                    if (state._fil_node_fil_mat[i].find(el => el.slug === _fil_node_linje_mat[n][0]) !== undefined) {

                        _fil_content = state._fil_node_fil_mat[i].find(el => el.slug === _fil_node_linje_mat[n][0]).fil_content

                    };

                    if (state._fil_node_fil_mat[i].find(el => el.slug === _fil_node_linje_mat[n][0]) !== undefined) {

                        _is_folder = state._fil_node_fil_mat[i].find(el => el.slug === _fil_node_linje_mat[n][0]).is_folder

                    };

                    // Append > _node_obj > Søkeord > node_fields
                    _node_arr.push(
                        {
                            slug:               _fil_node_linje_mat[n][0],
                            level_i:            _fil_node_linje_mat[n][1] + fil_level_i_offset,
                            level_j:            _fil_node_linje_mat[n][2],
                            fil_navn:           _fil_navn,
                            fil_content:        _fil_content,
                            is_folder:          _is_folder,
                            abs_path:           "",
                            is_open:            fil_is_open_default,
                            is_show:            true,
                            is_selected:        false,
                            is_editing:         false,
                            fil:                "",
                            fil_ext:            "",
                            fil_base:           ""
                        }
                    );

                };


                /* ****************************************** */
                /* Node mat */
                /* ****************************************** */

                // Push
                state.fil_node_mat.push(_node_arr);

            };

            // Selected 0 > Hvis velge første item uavhengig av om er fil eller folder
            const _is_select_0 = false;
            if (_is_select_0 === true) {
                state.fil_node_mat = set_node_selected_all_0(state.fil_node_mat);
            };

            // Reset fil slug current > Default > Første fil og ikke dir for hver blokk
            state.fil_node_selected_slug_arr = set_node_selected_fil_slug_current_reset(state.fil_node_mat);

            // Reset fil slug current > Custom > Hvis fil entry er lagret
            state.fil_node_selected_slug_arr = set_node_selected_fil_slug_fil_entry_reset(state.fil_node_mat, state.fil_node_selected_slug_arr, state._fil_node_fil_entry_arr);

            // Oppdater fil slug current > Fil / ikke folder
            state.fil_node_mat = set_node_selected_fil_slug_current_do(state.fil_node_mat, state.fil_node_selected_slug_arr);

            // fil_node_fil_content_init_yy_arr
            state.fil_node_fil_content_init_yy_arr = set_node_selected_fil_content_init_yy(state.fil_node_mat);

            // Debug > Fil navn > Printer dobbelt fordi i = 0 > Quest, i = 2 > Create svar (kopi av Quest)
            debug_node_item_open_show(state.fil_node_mat, "Open / Show > Reset", false);

            // Create path
            for (let i = 0; i < state.fil_node_mat.length; i++) {
                state.fil_node_mat = set_node_create_path(state.fil_node_mat, i, state._fil_node_create_fil_el);
            };


            /* ****************************************** */
            /* Editor kode */
            /* ****************************************** */

            // Editor kode create typ reset
            state.fil_editor_kode_create_typ = "";

            // Editor kode reset
            for (let i = 0; i < state.fil_node_mat.length; i++) {

                // Align
                state.fil_editor_kode_align_arr.push(editor_kode_align_col);

                // Fil entry > Length
                const _str_len = state._fil_node_fil_entry_arr[i].length

                // html > DEV > dynamisk med lengden til "html" for int 3 og 4
                const _html = fil_ext_dotless(fil_ext_html); // fil_ext_html.slice(1, fil_ext_html.length);

                // Term select > Default
                let _term_selected_override = false;
                if (state._fil_node_fil_entry_arr[i].length >= _html.length) {
                    const _fil_ext = state._fil_node_fil_entry_arr[i].slice(_str_len - _html.length, _str_len); // console.log("_fil_ext :: ", _fil_ext)
                    if (_fil_ext === _html) {
                        _term_selected_override = true;
                    };
                };

                // Push term selected
                if (_term_selected_override === false) {
                    state.fil_editor_kode_term_selected_arr.push(editor_kode_term_selected_terminal);
                };
                if (_term_selected_override === true) {
                    state.fil_editor_kode_term_selected_arr.push(editor_kode_term_selected_frontend);
                };

            };


            /* ****************************************** */
            /* Term */
            /* ****************************************** */

            // Reset
            state.fil_term_out_arr                  = [];
            state.fil_term_out_mat                  = [];
            state.fil_term_inp_arr                  = [];
            state.fil_term_status_arr               = [];
            state.fil_term_vari_ix_current_arr      = [];
            state.fil_term_vari_el_mat              = [];
            state.fil_term_vari_val_mat             = [];
            state.fil_term_cumul_out_mat            = [];

            // Iterate > Editor kode length
            for (let i = 0; i < state.fil_node_mat.length; i++) {

                // Fill
                state.fil_term_out_arr.push("");
                state.fil_term_out_mat.push([]);
                state.fil_term_inp_arr.push("");
                state.fil_term_status_arr.push(false);
                state.fil_term_vari_ix_current_arr.push(-1);
                state.fil_term_vari_el_mat.push([]);
                state.fil_term_vari_val_mat.push([]);
                state.fil_term_cumul_out_mat.push([]);

            };

        },
        setFilNodeMatSvarCreateNyReset(state, action) {

            const _username         = action.payload[0];
            const _master_pk        = action.payload[1];

            // Slug > Unik time > "hei_1668710573577"
            const _slug_create      = state._fil_node_svar_create_ny_default_el;

            /// const _slug_el          = `${fil_slug_default_prefix}_${get_time_now_mark()}`;
            // _slug_create.slug       = _slug_el; // _slug_create.is_selected = true; // Unødvendig her fordi likevel auto-clickes

            const _time             = get_time_now_mark();
            const _slug_el          = `${_master_pk}_svar_${_username}_fil_${fil_slug_default_prefix}_${_username}_${_time}`; // console.log("_slug_created :: ", _slug_created);

            _slug_create.slug       = _slug_el;

            // Svar create ny
            state.fil_node_mat[1]   = [_slug_create];

            // Reset fil slug current > Denne må være mutet så den ikke fjerner selected fil på quest
            // state.fil_node_selected_slug_arr = set_node_selected_fil_slug_current_reset(state.fil_node_mat);

            // Oppdater fil slug current > Fil / ikke folder
            state.fil_node_mat = set_node_selected_fil_slug_current_do(state.fil_node_mat, state.fil_node_selected_slug_arr);


        },
        setFilNodeMatSvarCreateKopierReset(state) {

            // DEV > Skal velge fra dropdown med svar > Temp resett til DEV_velg_editor_kode er klar
            state.fil_node_mat[1] = state.fil_node_mat[0];

            // Reset fil slug current
            state.fil_node_selected_slug_arr = set_node_selected_fil_slug_current_reset(state.fil_node_mat);

            // Oppdater fil slug current > Fil / ikke folder
            state.fil_node_mat = set_node_selected_fil_slug_current_do(state.fil_node_mat, state.fil_node_selected_slug_arr);

        },
        setFilNodeMatCreateItem(state, action) {

            // Init
            const _index_i                      = action.payload[0];
            const _item_typ                     = action.payload[1];
            const _par_slug                     = action.payload[2];
            const _fil_node_mat_create_fil_el   = state._fil_node_create_fil_el;
            let _par_index_n                    = -10;
            let _par_level_i                    = -10;
            const _debug_slug_all_pst           = false;


            /* ****************************************** */
            /* Gen / Ikke root > Get par level */
            /* ****************************************** */

            // Gen > Get par level
            if (_par_slug !== fil_par_slug_root) {
                _par_level_i                    = state.fil_node_mat[_index_i].find(el => el.slug === _par_slug).level_i;
                for (let n = 0; n < state.fil_node_mat[_index_i].length; n++) {
                    if (state.fil_node_mat[_index_i][n].slug === _par_slug) {
                        _par_index_n            = n;
                        _par_level_i            = state.fil_node_mat[_index_i][n].level_i;
                    };
                };
            };


            /* ****************************************** */
            /* Create item > is_folder > true / False */
            /* ****************************************** */

            // Create > Fil
            if (_item_typ === fil_item_typ_fil)     {
                _fil_node_mat_create_fil_el.is_folder   = false;
            };

            // Create > Folder
            if (_item_typ === fil_item_typ_folder)  {
                _fil_node_mat_create_fil_el.is_folder   = true;
            };


            /* ****************************************** */
            /* Item > Set level > Add til node mat */
            /* ****************************************** */

            // Root
            if (_par_slug === fil_par_slug_root) {
                _fil_node_mat_create_fil_el.level_i     = fil_level_i_offset + 2;
                state.fil_node_mat[_index_i].splice(0, 0, _fil_node_mat_create_fil_el);
            };

            // Gen item
            if (_par_slug !== fil_par_slug_root) {
                _fil_node_mat_create_fil_el.level_i     = fil_level_i_offset + 3 + _par_level_i;
                state.fil_node_mat[_index_i].splice(_par_index_n + 1, 0, _fil_node_mat_create_fil_el);
            };

            // Debug
            if (_debug_slug_all_pst === true) {
                for (let i = 0; i < state.fil_node_mat.length; i++) {
                    for (let j = 0; j < state.fil_node_mat[i].length; j++) {
                        console.log("slug :: ", state.fil_node_mat[i][j].slug);
                        // console.log("- folder :: ", state.fil_node_mat[i][j].is_folder);
                    };
                };
            };

        },
        setFilNodeFilNavnInpArr(state, action) {

            // Init
            const _index_i      = action.payload[0];
            const _slug         = action.payload[1];
            const _fil_navn     = action.payload[2];

            // Fil content inp arr > _index_i / _index_j / _fil_navn
            state.fil_node_fil_navn_inp_arr = [_index_i, _slug, _fil_navn];

            // Fil navn > Opptatt > False
            if (state.fil_node_mat[_index_i].find(el => el.fil_navn === _fil_navn) === undefined) {

                // Fil navn er ledig
                state.fil_node_fil_navn_is_opptatt = false;
            // Fil navn > Opptatt > True (DEV > i folder)
            } else {

                // console.log("Fil navn opptatt!")
                state.fil_node_fil_navn_is_opptatt = true;

            }; // console.log("Er opptatt :: ", state.fil_node_fil_navn_is_opptatt)

        },
        setFilNodeFilNavnDone(state, action) {

            // Init
            const _fil_mat_is_editing_done  = action.payload[0];
            const _fil_edit_inp_arr         = action.payload[1];
            const _username                 = action.payload[2];
            const _master_pk                = action.payload[3];
            const _index_i                  = _fil_edit_inp_arr[0];
            const _slug                     = _fil_edit_inp_arr[1];
            const _fil_navn                 = _fil_edit_inp_arr[2];
            let _slug_done                  = "";
            let _is_folder                  = false; // Init

            // prøv
            const _inp_str                  = state.fil_node_fil_navn_inp_arr[2];

            // False > Ikke empty inp_str
            if (_fil_mat_is_editing_done === false &&
                _inp_str !== "") {

                // Editing_done > False > Executes når
                // - Start av editing > create ny fil / folder
                // - Slutt av editing > Trykker enter / click page core etter editing fil navn
                // - Eller bare klikker random i page_core

                /* ****************************************** */
                /* Reset editing */
                /* ****************************************** */

                // Fil mat > Reset editing
                state.fil_node_mat = set_node_editing_all_false(state.fil_node_mat);


                /* ****************************************** */
                /* Fil navn */
                /* ****************************************** */

                // Fil navn > DEV
                state.fil_node_mat[_index_i].find(el => el.slug === _slug).fil_navn = _fil_navn;

                // Sett _is_folder her fil_node_is_editing_done = false fremdeles (endres nedenfor) og RT slug i JSX
                _is_folder = state.fil_node_mat[_index_i].find(el => el.slug === _slug).is_folder


                /* ****************************************** */
                /* Slug */
                /* ****************************************** */

                let _slug_created                   = "";
                const _time                         = get_time_now_mark();
                const _slug_fil                     = txt_create_slug_from_str(_fil_navn);

                // Slug eksisterer ikke > Create ny slug
                if (_slug === fil_slug_tmp_default) {

                    // Quest > quest_fibonacci-tallene_Stine123_fanta-py_MatematikkAS_20240323121343108812
                    if (_index_i === 0) {

                        _slug_created = `${_master_pk}_fil_${_slug_fil}_${_username}_${_time}`; // console.log("_slug_created :: ", _slug_created);

                    };

                    // Svar ny > quest_fibonacci-tallene_Kramer_svar_MatematikkAS_fil_hei-py_MatematikkAS_20240323075104540609
                    if (_index_i === 1) {

                        _slug_created = `${_master_pk}_svar_${_username}_fil_${_slug_fil}_${_username}_${_time}`; // console.log("_slug_created :: ", _slug_created);

                    };

                    // Svar > "Quest_xyz_tittel_Svar_Usr_def_Fil_fil_2_py_Usr_def_20221101110545569571"
                    if (_index_i > 1) {

                        _slug_created = `${_master_pk}_svar_fil_${_slug_fil}_${_username}_${_time}`; // console.log("_slug_created :: ", _slug_created);

                    };

                    // Set > Slug unik
                    state.fil_node_mat[_index_i].find(el => el.slug === _slug).slug = _slug_created;

                    // Slug done > Created slug
                    _slug_done = _slug_created;


                // Slug eksisterer > Kun oppdater fil navn
                } else {

                    // - Velger å ikke oppdatere slug, som er funksjon av filnavnet
                    // selv om dette da blir en "lite beskrivende" slug når navnet endres
                    // - Dette fordi sluggen er det som kobler alt sammen, så hvis også
                    // denne skal endres, så må både DB og json også oppdateres

                    // console.log("Eks _slug     :: ", _slug)
                    // console.log("Eks _fil_navn :: ", _fil_navn)

                    // DEV > Hvis denne skal brukes (å gi sluggen et annet navn enn user-init, f.eks. quest_xyz som over),
                    // så må fil-select oppdateres etter navnet er endret
                    // _slug_created = `${_master_pk}_svar_fil_${_slug_fil}_${_username}_${_time}`; // console.log("_slug_created :: ", _slug_created);

                    _slug_done = _slug; // _slug_created

                };

                // Debug > Slug all
                const _debug_slug_unik = false;
                if (_debug_slug_unik === true) {

                    // console.log("_slug_done :: ", _slug_done)

                };

                // Debug > Slug all
                const _debug_all = false;
                if (_debug_all === true) {
                    for (let j = 0; j < state.fil_node_mat[_index_i].length; j++) {
                        // console.log(`slug #${j} ::`, state.fil_node_mat[_index_i][j].slug)
                    };
                };

                /*
                // Fil mat > Set change
                if (_fil_edit_inp_arr[0] !== undefined) { // if (action.payload[0] && action.payload[1] && action.payload[2]) {

                    // Fil navn > Ledig
                    if (state._fil_node_fil_mat[_fil_edit_inp_arr[0]].find(el => el.slug === _fil_edit_inp_arr[2]) === undefined) {

                        // Set fil navn
                        state._fil_node_fil_mat[_fil_edit_inp_arr[0]][_fil_edit_inp_arr[1]].slug = _fil_edit_inp_arr[2];

                    // Fil navn > Opptatt (DEV > i folder)
                    } else {
                        // console.log("Fil navn opptatt!")
                    };

                // Eksisterer ikke > Bypass
                } else {
                    // console.log("setFilMatFilNavn > Eksisterer ikke > Bypass")
                };
                */


                /* ****************************************** */
                /* - setFilEditInpReset */
                /* ****************************************** */

                // Fil mat > Reset inp arr
                state.fil_node_fil_navn_inp_arr = state._fil_node_fil_navn_inp_arr_reset; // "" > DEV_inp


                /* ****************************************** */
                /* setFilNodeIsEditingDone() */
                /* ****************************************** */

                // Is editing done > False
                state.fil_node_is_editing_done = true;


                /* ****************************************** */
                /* setFilMatRemoveEmptyFilNavnREFAC() */
                /* ****************************************** */

                // Remove empty fil > Settings > Reset all
                for (let i = 0; i < state.fil_node_mat.length; i++) {
                    for (let j = 0; j < state.fil_node_mat[i].length; j++) {

                        // Remove empty fil navn
                        if (state.fil_node_mat[i][j].fil_navn === "") {

                            // Remove empty fil
                            state.fil_node_mat[i].splice(j, 1);

                            // Reset selected > 0
                            state.fil_node_mat[i][0].is_selected = true;

                        };

                    };
                };


                /* ****************************************** */
                /* Sorter alfabetisk */
                /* ****************************************** */

                state.fil_node_mat = set_node_sort_alfabetisk(state.fil_node_mat, _index_i);


                /* ****************************************** */
                /* Current fil content (definert over) */
                /* ****************************************** */

                // Hvis folder > Ikke update selected fil content
                if (_is_folder === false) {

                    // Hvis fil > Sett Oppdatere fil content
                    state.fil_node_selected_slug_arr[_index_i] = _slug_done;

                    // Debug
                    debug_node_item_slug(state.fil_node_mat, "Slug :: ", false);

                };

                // Create path
                state.fil_node_mat = set_node_create_path(state.fil_node_mat, _index_i, state._fil_node_create_fil_el);

            };

            // Empty inp_str > Bypass
            const _stop = false;
            if (_inp_str === "" && _stop === false) {};

            // Debug > Fil navn
            const _debug = false;
            if (_debug === true) {
                for (let i = 0; i < state.fil_node_mat.length; i++) {
                    for (let j = 0; j < state.fil_node_mat[i].length; j++) {
                        console.log("fil navn :: ", state.fil_node_mat[i][j].fil_navn);
                        console.log("folder -- :: ", state.fil_node_mat[i][j].is_folder);
                    };
                };
            };

        },
        setFilNodeFilContentInp(state, action) {

            // Init
            const _index_i          = action.payload[0];
            const _fil_content      = action.payload[1];

            // Fil slug current
            const _fil_slug_current_fil_content = state.fil_node_selected_slug_arr[_index_i];

            // Content input

            if (state.fil_node_mat[_index_i].find(el => el.slug === _fil_slug_current_fil_content) !== undefined) {

                state.fil_node_mat[_index_i].find(el => el.slug === _fil_slug_current_fil_content).fil_content = _fil_content;

            };

        },
        setFilNodeFilContentInitYYDone(state, action) {

            // Init
            const _bl                               = action.payload;

            // Init yy done > True
            state.fil_node_fil_content_init_yy_done = _bl;

        },
        setFilNodeFilContentInitYYUpdate(state) {

            // Update content init yy
            state.fil_node_fil_content_init_yy_arr = set_node_selected_fil_content_init_yy(state.fil_node_mat);

        },
        setFilNodeIsSelected(state, action) {

            // Content må vise > state.fil_node_selected_slug_arr[_index_i]

            // Init
            const _index_i                      = action.payload[0];
            const _slug                         = action.payload[1];
            const _is_create_done               = state.fil_node_is_editing_done;

            // Select > Fil mat
            state.fil_node_mat                  = set_node_selected(
                state.fil_node_mat,
                _slug,
                _index_i,
                _is_create_done);

            // Select > Fil slug
            const _fil_slug_current_fil_content             = state.fil_node_selected_slug_arr[_index_i];
            const _fil_slug_current_fil_content_updated     = set_node_selected_current_fil_content(
                state.fil_node_mat,
                _slug,
                _fil_slug_current_fil_content,
                _index_i);
            state.fil_node_selected_slug_arr[_index_i]      = _fil_slug_current_fil_content_updated;

        },
        setFilNodeIsEditing(state, action) {

            // Init
            const _index_i      = action.payload[0];
            const _slug         = action.payload[1];

            // Editing current
            state.fil_node_mat = set_node_editing_current(state.fil_node_mat, _slug, _index_i);

        },
        setFilNodeIsEditingDone(state, action) {

            // Set editing done
            state.fil_node_is_editing_done = action.payload

        },
        setFilNodeDelete(state, action) {

            // Init
            const _index_i          = action.payload[0];
            const _slug             = action.payload[1];

            // PRØV > Undefined guard
            if (state.fil_node_mat[_index_i] !== undefined) { // console.log("state.fil_node_mat[_index_i] :: ", state.fil_node_mat[_index_i])

                // Set selected el
                for (let n = 0; n < state.fil_node_mat[_index_i].length; n++) {

                    // Hvis denne sluggen
                    if (state.fil_node_mat[_index_i][n].slug === _slug) { // console.log("case 0 > n :: ", n) // Se setFilNodeDeleteSelectNext()

                        // Sett _index_n for case 1 (oppover) og case 2 (nedover)
                        state.fil_node_index_n_case_1 = n;

                        // Delete par og ch
                        state.fil_node_mat = set_node_delete_par_ch(state.fil_node_mat, _index_i, n);

                    };
                };

                // Create path
                state.fil_node_mat = set_node_create_path(state.fil_node_mat, _index_i, state._fil_node_create_fil_el);

            };

            // DEV > Fix slik at new created fil forsvinner når klikker på page_core > Reset til home_screen
            if (_slug === "fil_slug_tmp_default") {};

        },
        setFilNodeIsDeletingDone(state, action) {

            // Delete done > True / false
            state.fil_node_is_deleting_done = action.payload;

        },
        setFilNodeDeleteMirror(state) {

            // Mirror
            state.fil_node_mat_mirror = state.fil_node_mat;

        },
        setFilNodeDeleteSelectNext(state, action) {

            // Init
            const _index_i                  = action.payload;
            let fil_node_index_n_case_1     = state.fil_node_index_n_case_1;
            let _index_n_case_2             = state.fil_node_index_n_case_1;
            const _debug_case               = false;
            let _iterate_case               = 1;
            let _break                      = false;

            // Case 2 > Iterate oppover
            if (_iterate_case === 1) {

                // Sett case 2 hvis 1 ikke treffer en fil oppover for å sette ny fil content current
                _iterate_case = 2;

                // Debug > Case
                if (_debug_case) {
                    console.log("case II > 1 :: ", _index_n_case_2);
                    console.log("case II > 2 :: ", state.fil_node_mat[_index_i].length);
                };

                // Iterate case 1
                while (fil_node_index_n_case_1 > -1 &&
                    _break === false) {

                    // Inc
                    fil_node_index_n_case_1 -= 1;

                    // Iterer oppover
                    if (fil_node_index_n_case_1 > -1) { // console.log("Case I > Iterer oppover!");

                        // Hvis fil
                        if (state.fil_node_mat[_index_i][fil_node_index_n_case_1].is_folder === false) {

                            // Slug
                            const _slug_current = state.fil_node_mat[_index_i][fil_node_index_n_case_1].slug;

                            // Hvis fil > Sett Oppdatere fil content
                            state.fil_node_selected_slug_arr[_index_i] = _slug_current;

                            // Selected current
                            state.fil_node_mat = set_node_selected_fil(state.fil_node_mat, _slug_current);

                            // Break
                            _break = true;

                            // Case 4 > Bypass
                            _iterate_case = 4;

                        };

                    // Break
                    } else {

                        _break = true;

                    };
                };
            };

            // Case 2 > Iterate nedover
            if (_iterate_case === 2) {

                // Init
                _iterate_case   = 2;
                _break          = false;

                // Debug > Case
                if (_debug_case) {
                    console.log("case II > 1 :: ", _index_n_case_2);
                    console.log("case II > 2 :: ", state.fil_node_mat[_index_i].length);
                };

                // Iterate case 2
                while (_index_n_case_2 < state.fil_node_mat[_index_i].length &&
                    _break === false) { // console.log("Case II > Iterer nedover!");

                    // Hvis fil
                    if (state.fil_node_mat[_index_i][_index_n_case_2].is_folder === false) {

                        // Slug
                        const _slug_current = state.fil_node_mat[_index_i][_index_n_case_2].slug;

                        // Hvis fil > Sett Oppdatere fil content
                        state.fil_node_selected_slug_arr[_index_i] = _slug_current;

                        // Selected current
                        state.fil_node_mat = set_node_selected_fil(state.fil_node_mat, _slug_current);

                        // Selected current
                        // state.fil_node_mat = set_node_selected_current_editor_0(state.fil_node_mat, _index_i);

                        // Break
                        _break = true;

                        // Case 4 > Bypass
                        _iterate_case = 4;

                    };

                    // Inc
                    _index_n_case_2 += 1;

                };
            };

            // Case 3
            if (_iterate_case === 3) {

                // Selected current
                state.fil_node_mat = set_node_selected_current_editor_0(state.fil_node_mat, _index_i);

            };

        },
        setFilNodeSortAlfabetisk(state, action) {

            // Init
            const _index_i  = action.payload;

            // Create path
            state.fil_node_mat = set_node_sort_alfabetisk(state.fil_node_mat, _index_i);

        },

        // Term
        setFilTermOutReset(state, action) {

            /* Når trykker på - eller skriver "clear" */

            // Init
            const _i                                = action.payload[0];
            const _reset_typ                        = action.payload[1];

            // editor_kode_reset_typ_all
            if (_reset_typ === editor_kode_reset_typ_all) {

                // Reset også out arr for "reset all"
                state.fil_term_out_arr[_i]          = "";

            };

            // editor_kode_reset_typ_all / editor_kode_reset_typ_inp > Resten av variablene
            state.fil_term_out_mat[_i]              = [];
            state.fil_term_inp_arr[_i]              = "";
            state.fil_term_status_arr[_i]           = false;
            state.fil_term_vari_ix_current_arr[_i]  = -1;
            state.fil_term_vari_el_mat[_i]          = [];
            state.fil_term_vari_val_mat[_i]         = [];
            state.fil_term_cumul_out_mat[_i]        = [];

        },
        setFilTermOutUpdate(state, action) {

            // Init
            const _i                    = action.payload[0];
            let _caller_arr             = action.payload[1];
            const _caller               = action.payload[2];    // "user", "api"
            let _arr                    = [];                   // Init, console.log("_A :: ", _arr)
            let _last_arr               = [];                   // Brukes kun for "n"-arr, se nedenfor

            // Reset
            state.fil_term_inp_arr[_i]          = "";


            /* ****************************************** */
            /* Nye el fra "user" / "api" -> _arr */
            /* ****************************************** */

            // Debug
            for (let k = 0; k < state.fil_term_cumul_out_mat[_i].length; k++) { // console.log("K -> ", k)
                for (let l = 0; l < state.fil_term_cumul_out_mat[_i][k].length; l++) { // console.log("out :: ", state.fil_term_cumul_out_mat[_i][k][l]);
                };
            };

            // Index
            const _j_last = state.fil_term_cumul_out_mat[_i].length - 1; // console.log("j :: ", _j_last);

            // Caller > User
            if (_caller === editor_kode_caller_user) {

                // Caller arr
                _arr = _caller_arr;

            };

            // Caller > API
            if (_caller === editor_kode_caller_api_norm ||
                _caller === editor_kode_caller_api_inp ||
                _caller === editor_kode_caller_api_cmd) {

                // Out nr. 1 > Push direkte til _arr
                if (state.fil_term_cumul_out_mat[_i].length === 1) {
                    for (let l = 0; l < state.fil_term_cumul_out_mat[_i][_j_last].length; l++) {
                        const _el_out = state.fil_term_cumul_out_mat[_i][_j_last][l]; //  console.log("out :: ", _el_out);
                        _arr.push(_el_out);
                    };
                };

                // Out nr. n (n > 1) > Push diff mellom "n - 1"-arr og "n"-arr til _arr
                if (state.fil_term_cumul_out_mat[_i].length > 1) {

                    // Len til "n - 1"-arr blir start-index til "n"-arr
                    const _len_prev_arr = state.fil_term_cumul_out_mat[_i][_j_last - 1].length;

                    // Push el
                    for (let l = _len_prev_arr; l < state.fil_term_cumul_out_mat[_i][_j_last].length; l++) {
                        const _el_out = state.fil_term_cumul_out_mat[_i][_j_last][l];
                        _last_arr.push(_el_out);
                    };

                    // Kopier alle el fra _last_arr til _arr
                    for (let l = 0; l < _last_arr.length; l++) {
                        _arr.push(_last_arr[l]);
                    };
                };
            };


            /* ****************************************** */
            /* Update out arr */
            /* ****************************************** */

            // Iterate > Make out str
            for (let i = 0; i < _arr.length; i++) {

                // El
                const _el = _arr[i];

                // Out mat
                state.fil_term_out_mat[_i].push(_el);

                // Ingen new line
                if (state.fil_term_out_arr[_i] === "") {

                    // Out arr
                    state.fil_term_out_arr[_i] = _el;

                // Add new line
                } else {

                    // Out arr
                    state.fil_term_out_arr[_i] += _new_line + _el;

                };
            };

            // CMD caller > Reset cumul_arr slik at ikke terminal output`en resettes / kontinuerlig log
            if (_caller === editor_kode_caller_api_norm ||
                _caller === editor_kode_caller_api_cmd) {

                // Reset cumul arr
                state.fil_term_cumul_out_mat[_i] = [];

            };

        },
        setFilTermOutVari(state, action) {

            // Init
            const _i                                = action.payload[0];
            const _status                           = action.payload[1];
            const _ix_current                       = action.payload[2];
            const _vari_el_arr                      = action.payload[3];

            // Debug > console.log("redux ix     :: ", _ix_current)
            // Debug > console.log("redux el_arr :: ", _vari_el_arr)

            // Set current
            state.fil_term_status_arr[_i]           = _status;
            state.fil_term_vari_ix_current_arr[_i]  = _ix_current;
            state.fil_term_vari_el_mat[_i]          = _vari_el_arr;

        },
        setFilTermOutVariElUpdate(state, action) {

            // Init
            const _i            = action.payload;

            // Vari fiels
            const i_c           = state.fil_term_vari_ix_current_arr[_i];
            const _vari_val     = state.fil_term_inp_arr[_i];
            const _vari_str     = state.fil_term_vari_el_mat[_i][i_c].vari_str;
            const _vari_navn    = state.fil_term_vari_el_mat[_i][i_c].vari_navn;

            // Sett
            state.fil_term_status_arr[_i]                   = false;
            state.fil_term_vari_el_mat[_i][i_c].vari_val    = _vari_val;
            state.fil_term_vari_el_mat[_i][i_c].vari_out    = _vari_str + _vari_val;
            state.fil_term_vari_el_mat[_i][i_c].line_upd    = `${_vari_navn} = ${_vari_val}`;

        },
        setFilTermInpChange(state, action) {

            // Init
            const _i                                = action.payload[0];
            const _str                              = action.payload[1];

            // Sett
            state.fil_term_inp_arr[_i]              = _str;

        },
        setFilTermVariValCumul(state, action) {

            // Init
            const _i                        = action.payload[0];
            const _vari_val_arr             = action.payload[1];
            const _out_arr                  = action.payload[2];
            const _caller_typ               = action.payload[3];
            const _is_debug_inp_arr_info    = false;

            // Debug > Søkeord input_info
            if (_is_debug_inp_arr_info) {
                console.log("");
                console.log("***");
                console.log("_out_arr :: ", _out_arr);
                console.log("_vari_val_arr :: ", _vari_val_arr);
            };

            // Ingen empty arr > Første iterasjon kan gi [], som ikke skal med som empty linje i term
            if (_out_arr.length !== 0) {

                // input()
                if (_caller_typ === editor_kode_caller_api_norm) { // console.log("redux > norm")

                    // Reset cumul -> Push kun siste arr
                    state.fil_term_cumul_out_mat[_i]    = []
                    state.fil_term_cumul_out_mat[_i].push(_out_arr);

                    // Push vari val el
                    state.fil_term_vari_val_mat[_i]     = [];

                };

                // input() > _out_arr
                if (_caller_typ === editor_kode_caller_api_inp) {

                    // Push out_arr til content out > Historikk for diff-sekvens
                    state.fil_term_cumul_out_mat[_i].push(_out_arr);

                };

                // CMD
                if (_caller_typ === editor_kode_caller_api_cmd) {

                    // Reset cumul -> Push kun siste arr
                    state.fil_term_cumul_out_mat[_i]    = []
                    state.fil_term_cumul_out_mat[_i].push(_out_arr);

                    // Push vari val el
                    state.fil_term_vari_val_mat[_i]     = [];

                };

            };

            // input() > _vari_val_arr > Utenfor blokken over slik at_vari_val_arr er uavhengig av _out_arr-condition
            if (_caller_typ === editor_kode_caller_api_inp) {

                // Init
                let _vari_val_non_empty             = [];

                // Iterate > Add non-empty el til arr
                for (let k = 0; k < _vari_val_arr.length; k++) {

                    // Empty el > Bypass
                    if (_vari_val_arr[k] === "") {};

                    // Non-empty el > Add el
                    if (_vari_val_arr[k] !== "") {

                        // Push vari val el
                        _vari_val_non_empty.push(_vari_val_arr[k]);

                    };

                };

                // Push vari val el
                state.fil_term_vari_val_mat[_i]      = _vari_val_non_empty;

            };

        },
        setFilTermVariValMatReset(state, action) {

            // Init
            const _i                                    = action.payload;

            // Reset arr
            state.fil_term_inp_arr[_i]                  = "";
            state.fil_term_vari_ix_current_arr[_i]      = -1;
            state.fil_term_vari_el_mat[_i]              = [];
            state.fil_term_vari_val_mat[_i]             = [];
            state.fil_term_cumul_out_mat[_i]            = [];

        },

        // Upload > Quest
        setFilUploadArrReset(state) {

            // Fil upload reset
            state.fil_upload_arr = [];

        },
        setFilUploadArrPush(state, action) {

            // Fil upload arr push
            state.fil_upload_arr.push(action.payload);

        },
        setFilUpload(state, action) {

            // Fil upload
            state.fil_upload_arr = action.payload;

        },

        // Download zip
        setFilDownloadZipNavn(state, action) {

            // Fil download zip navn
            state.fil_download_zip_navn = action.payload;

        },

        // Editor kode
        setFilEditorKodeTermSelectedArr(state, action) {

            // Set term > index, editor_kode_term_selected_terminal / editor_kode_term_selected_frontend
            state.fil_editor_kode_term_selected_arr[action.payload[0]] = action.payload[1];

        },
        setFilEditorKodeAlignArr(state, action) {

            // Set align > col / row
            state.fil_editor_kode_align_arr[action.payload[0]] = action.payload[1];

        },

        // Create typ
        setFilEditorKodeCreateTyp(state, action) {

            // Set create typ > _key_editor_kode_typ_ny / _key_editor_kode_typ_kopier / DEV, sjekk _key_editor_kode_typ_delete
            state.fil_editor_kode_create_typ = action.payload;

        },

        setFilNodeMatLockReduxVer(state, action) {

            state.fil_node_mat_lock_redux_ver = action.payload;

        }

    }
});


export const {

    // Hard reset all
    setHardResetAll,

    // Node mat
    setFilNodeFilMatResetAdapterDoc,
    setFilNodeFilMatResetAdapterQuest,
    setFilNodeFilMatReset,
    setFilNodeMatSvarCreateNyReset,
    setFilNodeMatSvarCreateKopierReset,
    setFilNodeMatCreateItem,
    setFilNodeFilNavnInpArr,
    setFilNodeFilContentInitYYDone,
    setFilNodeFilContentInitYYUpdate,
    setFilNodeFilNavnDone,
    setFilNodeFilContentInp,
    setFilNodeIsSelected,
    setFilNodeIsEditing,
    setFilNodeIsEditingDone,
    setFilNodeDelete,
    setFilNodeIsDeletingDone,
    setFilNodeDeleteMirror,
    setFilNodeDeleteSelectNext,
    setFilNodeSortAlfabetisk,

    // Term
    setFilTermOutReset,
    setFilTermOutUpdate,
    setFilTermOutVari,
    setFilTermOutVariElUpdate,
    setFilTermInpChange,
    setFilTermVariValCumul,
    setFilTermVariValMatReset,

    // Upload > Quest
    setFilUploadArrReset,
    setFilUploadArrPush,
    setFilUpload,

    // Download zip
    setFilDownloadZipNavn,

    // Editor kode
    setFilEditorKodeTermSelectedArr,
    setFilEditorKodeAlignArr,

    // Create typ
    setFilEditorKodeCreateTyp,
    setFilNodeMatLockReduxVer

} = fileSelectSlice.actions;


export default fileSelectSlice.reducer;
