import {useCallback, createContext, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import jwt_decode from "jwt-decode";

import {
    auth_tokens,
    auth_user_local,
    auth_user_id_jwt,
    auth_postfix_local,
    auth_user_id_felt_local,
    auth_username_current_local,
    auth_human_lang_local,
    auth_is_superuser_local,
    auth_img_profil_local,
    auth_full_name_local,
    auth_email_public_local,
    auth_bio_public_local,
    auth_url_public_local,
    auth_company_public_local,
    auth_poeng_local
} from "../utils/konstanter/context/_context";
import {
    debug_auth_initx,
    debug_auth_signup_fetch_signup_user,
    debug_auth_signup_fetch_user_profil,
    debug_auth_fetch_confirmation_redirect,
    debug_auth_fetch_signout_user,
    debug_auth_fetch_await_signin_user,
} from "../utils/debug/_debug";
import {
    _http_method_post,
    _http_method_get,
    _http_method_put,
    http_headers_content_type,
    http_status_code_200,
    http_status_code_400,
    http_status_code_401,
    http_status_code_404,
    http_status_code_405
} from "../utils/konstanter/gen/_http";
import {
    lay_standard_reset
} from "../utils/konstanter/gen/_layout";
import {
    slug_root,
    slug_root_signin_epost_confirm,
    slug_root_profil_disp
} from "../utils/konstanter/gen/_slug";
import {
    duration_token_refresh,
} from "../utils/konstanter/gen/_time";
import {
    url_api_token_refresh,
    url_api_token,
    url_api_user_profil_item,
    url_api_user_reg_sett_inactive,
} from "../utils/konstanter/gen/_url";
import {
    human_lang_default_hardkodet
} from "../utils/settings/gen/_human_lang";
import {
    reg_signin_page_root,
    reg_signin_page_profil_disp,
    reg_signin_page,
} from "../utils/settings/pages/_reg";
import {
    get_txt
} from "../utils/tekst/_translator/translator";
import {
    msg_err_auth_context_400_error
} from "../utils/tekst/components/_msg";
import {
    err_msg_auth_context_signin_user_401_error,
    err_msg_auth_context_signin_user_404_error,
    err_msg_auth_context_signin_user_405_error,
    err_msg_auth_context_signin_user_ukjent_status_kode,
    err_msg_auth_update_token_400_error,
    err_msg_auth_update_token_401_error,
    err_msg_auth_update_token_404_error,
    err_msg_auth_update_token_ukjent_status_kode
} from "../utils/tekst/gen/_error";
import {
    tekst_debug
} from "../utils/utility/_util_tekst";

// -*-

import {
    setIsHeaderVisible
} from "../components/01_header/redux_header_feature";
import {
    setPathIsVisible
} from "../components/02_path/redux_path_feature";
import {
    setDoRuterPre
} from "../pages/ruter/redux_ruter_feature";
import {
    setIsPasswordWrong
} from "../pages/profil/redux_account_feature";


// AuthContext
const AuthContext = createContext();
export default AuthContext;


// AuthProvider
export const AuthProvider = ({children}) => {

    // PRØV > PAUSE > Se README.txt > auth_context.js
    const _PAUSE_fetch_await_update_token           = true;

    // Redux
    const dispatch                                  = useDispatch();

    // Loading
    const [loading_user, setLoadingUser]            = useState(true);

    // Ruter
    const set_do_ruter_pre                          = useCallback( (arr) => {dispatch(setDoRuterPre(arr))}, [dispatch]);

    // Header
    const set_is_header_visible                     = (bl) => {dispatch(setIsHeaderVisible(bl))};
    const set_is_password_wrong                     = useCallback( (bl)  => {dispatch(setIsPasswordWrong(bl))}, [dispatch]);

    // Path
    const set_path_is_visible                       = useCallback( (bl) => {dispatch(setPathIsVisible(bl))}, [dispatch]);

    // Token, user (set user), local storage
    const [authTokens, setAuthTokens]               = useState(
        () => localStorage.getItem(auth_tokens) ?
        JSON.parse(localStorage.getItem(auth_tokens)) : null
    );
    const [user, setUser]                           = useState(
        () => localStorage.getItem(auth_tokens) ?
        jwt_decode(localStorage.getItem(auth_tokens)) : null
    );
    const local_storage                             = (felt) => {
        return (
            localStorage.getItem(felt) ?
            JSON.parse(localStorage.getItem(felt)) : `${felt}${auth_postfix_local}`
        )
    };

    // User
    const [user_id_felt, setUserProfilIdFelt]       = useState(local_storage(auth_user_id_felt_local));
    const [username_current, setUsernameCurrent]    = useState(local_storage(auth_username_current_local));
    const [human_lang, setHumanLang]                = useState(local_storage(auth_human_lang_local));
    const [is_superuser, setIsSuperuser]            = useState(local_storage(auth_is_superuser_local));
    const [img_profil, setImgProfil]                = useState(local_storage(auth_img_profil_local));
    const [full_name, setFullName]                  = useState(local_storage(auth_full_name_local));
    const [email_public, setEmailPublic]            = useState(local_storage(auth_email_public_local));
    const [bio_public, setBioPublic]                = useState(local_storage(auth_bio_public_local));
    const [url_public, setUrlPublic]                = useState(local_storage(auth_url_public_local));
    const [company_public, setCompanyPublic]        = useState(local_storage(auth_company_public_local));
    const [poeng, setPoeng]                         = useState(local_storage(auth_poeng_local));

    // Signout > Local storage > Remove
    const signout_user                     = useCallback( () => {

        // Ingen ruter her > Søk opp JSX

        if (debug_auth_fetch_signout_user === 1) {
            console.log("> 15. > auth_context.js > signout_user")
        };

        // Local storage
        localStorage.removeItem(auth_tokens);
        localStorage.removeItem(auth_user_local);
        localStorage.removeItem(auth_user_id_felt_local);
        localStorage.removeItem(auth_is_superuser_local);
        localStorage.removeItem(auth_username_current_local);
        localStorage.removeItem(auth_img_profil_local);
        localStorage.removeItem(auth_full_name_local);
        localStorage.removeItem(auth_email_public_local);
        localStorage.removeItem(auth_bio_public_local);
        localStorage.removeItem(auth_url_public_local);
        localStorage.removeItem(auth_company_public_local);
        localStorage.removeItem(auth_poeng_local);
        localStorage.removeItem(auth_human_lang_local);

        // Set > state
        setAuthTokens(null);
        setUser(null);
        setIsSuperuser(false);
        setUserProfilIdFelt("");
        setUsernameCurrent("");
        setImgProfil("");
        setFullName("");
        setEmailPublic("");
        setBioPublic("");
        setUrlPublic("");
        setCompanyPublic("");
        setPoeng("");

        // Dep
    }, []);

    // Fetch > User profil > Local storage > Add
    const fetch_get_user_profil_local_storage       = (username, is_create_user) => { // Første page // console.log("> fetch_get_user_profil_local_storage > username :: ", username)

        // Debug
        if (debug_auth_signup_fetch_user_profil === 1) {
            console.log("> 8. > auth_context.js > fetch_get_user_profil_local_storage > username :: ", username)
            console.log("> 9. > auth_context.js > fetch_get_user_profil_local_storage > is_create_user :: ", is_create_user)
        };

        // Fetch
        fetch(`${url_api_user_profil_item}/${username}/`, {
            method:     _http_method_get,
            headers:    http_headers_content_type
        })
        .then((res) => res.json())
        .then(dat => {

            // Debug
            if (debug_auth_signup_fetch_user_profil === 1) {
                console.log("> 10. > auth_context.js > fetch_get_user_profil_local_storage > dat :: ", dat)
                console.log("> 11. > auth_context.js > fetch_get_user_profil_local_storage > dat.user :: ", dat.user)
            };

            // Set user profil > State
            setUserProfilIdFelt(dat.user); // Note: Ikke bruk dat.id
            setUsernameCurrent(dat.username_current);
            setHumanLang(dat.human_lang);
            setIsSuperuser(dat.is_superuser)
            setImgProfil(dat.img_profil);
            setFullName(dat.full_name);
            setEmailPublic(dat.email_public);
            setBioPublic(dat.bio_public);
            setUrlPublic(dat.url_public);
            setCompanyPublic(dat.company_public);
            setPoeng(dat.poeng);

            // Set user profil > Locla storage
            localStorage.setItem(auth_user_id_felt_local, String(JSON.stringify(dat.user)));
            localStorage.setItem(auth_username_current_local, JSON.stringify(dat.username_current));
            localStorage.setItem(auth_human_lang_local, JSON.stringify(dat.human_lang));
            localStorage.setItem(auth_is_superuser_local, JSON.stringify(dat.is_superuser));
            localStorage.setItem(auth_img_profil_local, JSON.stringify(dat.img_profil));
            localStorage.setItem(auth_full_name_local, JSON.stringify(dat.full_name));
            localStorage.setItem(auth_email_public_local, JSON.stringify(dat.email_public));
            localStorage.setItem(auth_bio_public_local, JSON.stringify(dat.bio_public));
            localStorage.setItem(auth_url_public_local, JSON.stringify(dat.url_public));
            localStorage.setItem(auth_company_public_local, JSON.stringify(dat.company_public));
            localStorage.setItem(auth_poeng_local, JSON.stringify(dat.poeng));

            // Create user > True > Lag ny user
            if (is_create_user === true) {

                // Debug
                if (debug_auth_signup_fetch_user_profil === 1) {
                    console.log("> 12a. > auth_context.js > fetch_get_user_profil_local_storage > create user === true > dat :: ", dat)
                };

                // Fetch confirmation redirect
                fetch_confirmation_redirect(username);

            };

            // Create user > False > User eksisterer > Signin
            if (is_create_user === false) { // -> Logg inn første side

                // Debug
                if (debug_auth_signup_fetch_user_profil === 1) {
                    console.log("> 12b. > auth_context.js > fetch_get_user_profil_local_storage > create user === false > is_create_user :: ", is_create_user)
                };

                // Setting > Signin > Profil disp eller landing
                if (reg_signin_page === reg_signin_page_profil_disp) {

                    // Debug
                    if (debug_auth_signup_fetch_user_profil === 1) {
                        console.log("> 13a. > auth_context.js > fetch_get_user_profil_local_storage > reg_signin_page :: ", reg_signin_page)
                    };

                    // Ruter > Pre
                    set_do_ruter_pre([slug_root_profil_disp, dat.user, lay_standard_reset, []]);

                };

                // Setting > Signin > Profil disp eller landing
                if (reg_signin_page === reg_signin_page_root) {

                    // Debug
                    if (debug_auth_signup_fetch_user_profil === 1) {
                        console.log("> 13b. > auth_context.js > fetch_get_user_profil_local_storage > reg_signin_page_root :: ", reg_signin_page_root)
                    };

                    // Ruter > Pre
                    set_do_ruter_pre([slug_root, "", lay_standard_reset, []]);

                };

                // Visibile
                set_path_is_visible(true);
                set_is_header_visible(true); // Her for ikke å få 404-feil, regner ut header_yy (selv om allerede er synlig)

            };

        })

    };

    // Fetch > Signup
    const fetch_confirmation_redirect               = (username) => {

        // Debug
        if (debug_auth_fetch_confirmation_redirect === 1) {
            console.log("> 13. > auth_context.js > fetch_confirmation_redirect > username :: ", username)
        };

        // Url
        const _url      = `${url_api_user_reg_sett_inactive}/${username}/`;

        // Fetch
        fetch(_url, {
            method:     _http_method_put,
            headers:    http_headers_content_type
        })
        .then(() => { // console.log("> fetch_confirmation_redirect > response")

            if (debug_auth_fetch_confirmation_redirect === 1) {
                console.log("> 14. > auth_context.js > fetch_confirmation_redirect > then > ingen dat (kanskje lage det)")
            };

            // Signout
            signout_user();

            // Ruter > Pre
            set_do_ruter_pre([slug_root_signin_epost_confirm, "", lay_standard_reset, []]);

        })

    };
    const fetch_signup_user                         = (username) => {

        // Debug
        if (debug_auth_signup_fetch_signup_user === 1) {
            console.log("> 7. > auth_context.js > fetch_signup_user > username :: ", username)
        };

        // User profil > Get local storage
        fetch_get_user_profil_local_storage(username, true);

    };

    // Fetch > Signin
    const fetch_await_signin_user                   = async (username, password) => {

        // Debug
        if (debug_auth_fetch_await_signin_user === 1) {
            console.log("> 1. > auth_context.js > fetch_await_signin_user > username :: ", username)
            console.log("> 2. > auth_context.js > fetch_await_signin_user > password :: ", password)
        };

        // Await > Response
        const res = await fetch(url_api_token, {
            method:     _http_method_post,
            headers:    http_headers_content_type,
            body: JSON.stringify({
                "username":     username,
                "password":     password
            })
        })

        // Await > Data
        const data = await res.json()

        // 200 > OK > Set auth tokens
        if (res.status === http_status_code_200) { // Login > OK

            // Debug
            if (debug_auth_fetch_await_signin_user === 1) {
                console.log("> 3a. > auth_context.js > fetch_await_signin_user > 200 > res.status :: ", res.status)
            };

            // Password > Correct
            set_is_password_wrong(false);

            // Auth tokens
            setAuthTokens(data);

            // Auth tokens > Local storage
            localStorage.setItem(auth_tokens, JSON.stringify(data));

            // User > Access
            setUser(jwt_decode(data.access));

            // User > ID
            const usr_id = jwt_decode(data.access)[auth_user_id_jwt];

            // User > ID > Hack > Type caster fra abc til "abc"
            var usr_id_hack = {
                auth_user_id_felt_local: `${String(usr_id)}`
            };

            // User > ID > Local storage
            localStorage.setItem(`${auth_user_id_felt_local}`, JSON.stringify(usr_id_hack));

            // Fetch user profil
            fetch_get_user_profil_local_storage(username, false);

            // Path > Show
            set_is_header_visible(true);}

        // 400 > Feil brukernavn / passord
        else if (res.status === http_status_code_400) { // Feil brukernavn / passord

            // Debug
            if (debug_auth_fetch_await_signin_user === 1) {
                console.log("> 3b. > auth_context.js > fetch_await_signin_user > 400 > res.status :: ", res.status)
            };

            // Password
            set_is_password_wrong(true);

            // Console
            console.log(get_txt(msg_err_auth_context_400_error, human_lang_default_hardkodet), res.status);}

        // 401 > Feil brukernavn / passord
        else if (res.status === http_status_code_401) {

            // Debug
            if (debug_auth_fetch_await_signin_user === 1) {
                console.log("> 3c. > auth_context.js > fetch_await_signin_user > 401 > res.status :: ", res.status)
            };

            // Password
            set_is_password_wrong(true);

            // Console
            console.log(get_txt(err_msg_auth_context_signin_user_401_error, human_lang_default_hardkodet), res.status);}

        // 404 > Nettsiden finnes ikke
        else if (res.status === http_status_code_404) {

            // Debug
            if (debug_auth_fetch_await_signin_user === 1) {
                console.log("> 3d. > auth_context.js > fetch_await_signin_user > 404 > res.status :: ", res.status)
            };

            // Console
            console.log(get_txt(err_msg_auth_context_signin_user_404_error, human_lang_default_hardkodet), res.status);}

        // 405 > Method not allowed
        else if (res.status === http_status_code_405) {

            // Debug
            if (debug_auth_fetch_await_signin_user === 1) {
                console.log("> 3e. > auth_context.js > fetch_await_signin_user > 405 > res.status :: ", res.status)
            };

            // Console
            console.log(get_txt(err_msg_auth_context_signin_user_405_error, human_lang_default_hardkodet), res.status);}

        // Default > Ukjent status kode
        else {

            // Debug
            if (debug_auth_fetch_await_signin_user === 1) {
                console.log("> 3f. > auth_context.js > fetch_await_signin_user > Ukjent status kode > res.status :: ", res.status)
            };

            // Console
            console.log(get_txt(err_msg_auth_context_signin_user_ukjent_status_kode, human_lang_default_hardkodet), res.status);
        };

    };

    // Fetch > Update tokens
    const fetch_await_update_token                  = useCallback( async () => {

        // Debug
        if (debug_auth_fetch_await_signin_user === 1) {
            console.log("> xx. > auth_context.js > fetch_await_update_token")
        };

        // User > Eksisterer > Fetch update tokens
        if (user && _PAUSE_fetch_await_update_token === false) {

            // console.log("TOK :: ", authTokens?.refresh)
            console.log("url_api_token_refresh :: ", url_api_token_refresh)

            // Await > Response
            const res = await fetch(url_api_token_refresh, {
                method:     _http_method_post,
                headers:    http_headers_content_type,
                body: JSON.stringify({
                    "refresh":  authTokens?.refresh
                })
            })

            // Await > Data
            const dat = await res.json()

            // Bypass
            if (res.status === -100) {} // Bypass

            // 200 > OK > Update auth tokens
            else if (res.status === 200) {
                setAuthTokens(dat);
                setUser(jwt_decode(dat.access));
                localStorage.setItem(auth_tokens, JSON.stringify(dat));}

            // 400 > Feil brukernavn / passord
            else if (res.status === 400) {
                console.log(get_txt(err_msg_auth_update_token_400_error, human_lang_default_hardkodet), res.status);}

            // 401 > Feil brukernavn / passord
            else if (res.status === 401) {
                console.log("DENNE")

                console.log(get_txt(err_msg_auth_update_token_401_error, human_lang_default_hardkodet), res.status);}

            // 404 > Nettsiden finnes ikke
            else if (res.status === 404) {
                console.log(get_txt(err_msg_auth_update_token_404_error, human_lang_default_hardkodet), res.status);}

            // Default > Ukjent status kode > Signout user
            else {
                console.log(get_txt(err_msg_auth_update_token_ukjent_status_kode, human_lang_default_hardkodet), res.status)
                signout_user();};}

        // User > Eksisterer ikke enda
        else {

            // Debug > Initx
            if (debug_auth_initx === 1) {
                console.log(tekst_debug("Initx", "0000", "auth_context.js", "fetch_await_upd_tok", "user", "_0"));
            };

        };

        // Loading > User > False
        if (loading_user === true) {
            setLoadingUser(false);
        };

        // Dep
    }, [user,
        authTokens?.refresh,
        loading_user,
        _PAUSE_fetch_await_update_token,
        signout_user]);

    // Context data
    const contextData                               = {
        authTokens:                             authTokens,

        user:                                   user,

        user_id_felt:                           String(user_id_felt),
        username_current:                       username_current,
        human_lang:                             human_lang,
        is_superuser:                           is_superuser,
        img_profil:                             img_profil,
        full_name:                              full_name,
        email_public:                           email_public,
        bio_public:                             bio_public,
        url_public:                             url_public,
        company_public:                         company_public,
        poeng:                                  poeng,

        fetch_signup_user:                      fetch_signup_user,
        fetch_await_signin_user:                fetch_await_signin_user,
        fetch_get_user_profil_local_storage:    fetch_get_user_profil_local_storage,

        signout_user:                           signout_user,

        setUsernameCurrent:                     setUsernameCurrent,
        setImgProfil:                           setImgProfil,
        setFullName:                            setFullName,
        setEmailPublic:                         setEmailPublic,
        setBioPublic:                           setBioPublic,
        setUrlPublic:                           setUrlPublic,
        setCompanyPublic:                       setCompanyPublic
    };

    // useEffect
    useEffect( () => {

        // Loading > User > True (default) > Update tokens første gangen
        if (loading_user === true) {
            fetch_await_update_token();
        };

        // Refresh token > Interval > Start > Duration > 4 min
        const interval = setInterval( () => {
            if (authTokens) {
                fetch_await_update_token();
            };
        }, duration_token_refresh);

        // Return > Clear / Restart interval
        return () => clearInterval(interval)

        // Dep
    }, [fetch_await_update_token,
        authTokens,
        loading_user]);

    // Return
    return (
        <AuthContext.Provider value = {contextData}>
            {loading_user ? null : children}
        </AuthContext.Provider>
    )

};
